import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/Register';

class RegisterFormContainer extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (payload) => {
    const { validateID, history } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const { success, phone_number, message } = await validateID({
        ...payload,
        country_code: 'AE',
      });
      if (success) {
        this.setState({
          success,
          error: null,
          loading: false,
          message: '',
        });
        if (phone_number) {
          history.push('/verifyOTP');
        } else {
          history.push('/verifyAccount');
        }
      } else {
        this.setState({ success, error: true, message, loading: false });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, message } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

RegisterFormContainer.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  validateID: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  validateID: dispatch.member.validateID,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFormContainer);
