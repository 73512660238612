import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Label, Row } from 'reactstrap';
import Template from '../Templates/Home';
import DashboardCard from './DashboardCard';
import Loading from '../UI/Loading';

const Dashboard = ({ cardData, action, user, loading, downloadTOB, generateECard }) => {
  const breadcrumb = [];
  return (
    <Template
      pageTitle={`Welcome, ${
        user?.first_name
          ?.split(' ')
          ?.map((w) => (w !== '' ? w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase() : ''))
          ?.join(' ') || ''
      }`}
      breadcrumb={breadcrumb}
    >
      <>
        <Container fluid className="max-container">
          <h2 className="f-mlc-m policy-main-title">Your Policies</h2>
        </Container>
        <section className="metLife-gray">
          <div className="container-fluid max-container">
            <div className="card-wrapper dashboard-cards">
              <Row className="">
                {!loading ? (
                  cardData.length ? (
                    cardData.map((data) => (
                      <Col md={12} key={data.policy_number}>
                        <DashboardCard
                          data={data}
                          action={action}
                          downloadTOB={downloadTOB}
                          generateECard={generateECard}
                        />
                      </Col>
                    ))
                  ) : (
                    <Col className="text-center">
                      <Label>No Records found.</Label>
                    </Col>
                  )
                ) : (
                  <Loading />
                )}
              </Row>
            </div>
          </div>
        </section>
      </>
    </Template>
  );
};
Dashboard.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.shape()),
  action: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  downloadTOB: PropTypes.func.isRequired,
  generateECard: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  cardData: [],
  loading: false,
};

export default withRouter(Dashboard);
