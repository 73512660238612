import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
// import style from '../assets/styles/ToasterStyle';
import Alert from '../components/UI/Alert';
import Layout from '../components/Claims/ReSubmitClaim/ReSubmitClaim';

class ReSubmitClaim extends Component {
  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
      fileSelected: 0,
    };
  }

  // Submit edit/updates of claim : from step 2
  onConfirm = async () => {
    const { reSubmitClaim, history, location } = this.props;
    const { newClaim, deleted_docs } = this.state;

    const payload = { ...newClaim, claim_id: location?.state?.data?.id };
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
          this.setState((pre) => ({ ...pre, fileSelected: pre?.fileSelected + 1 }));
        });
        return;
      }
      formData.append(key, payload[key]);
    });

    // deleted docs array
    if (deleted_docs?.length) {
      deleted_docs.forEach((item, index) => {
        formData.append(`deleted_docs[${index}]`, item);
        this.setState((pre) => ({ ...pre, fileSelected: pre?.fileSelected - 1 }));
      });
    }

    const { fileSelected } = this.state;
    if (fileSelected !== 0) {
      this.setState((pre) => ({ ...pre, loading: true }));
      // POST REQUEST
      const { success } = await reSubmitClaim(formData);
      if (success) {
        history.push('/claims');
      }
      this.setState((pre) => ({ ...pre, loading: false }));
    } else {
      toast.custom(<Alert message="Please choose atleast 1 Document" />);
    }
  };

  onFileSelected = (fileList, type) => {
    if (fileList.length) {
      this.setState((pre) => ({
        ...pre,
        newClaim: { ...pre.newClaim, [type]: fileList },
      }));
    }
  };

  // Method to remove existing files
  onFileRemoved = (file_id) => {
    console.log(file_id);
    if (file_id) {
      this.setState((pre) => ({
        ...pre,
        deleted_docs: [...(pre?.deleted_docs || []), file_id],
      }));
    }
  };

  /**
   * Render
   */
  render = () => {
    const { loading } = this.state;
    const { currentPolicy, location } = this.props;
    return (
      <Layout
        loading={loading}
        defaultData={location?.state?.data}
        onConfirm={this.onConfirm}
        policyData={currentPolicy}
        onFileSelected={this.onFileSelected}
        onFileRemoved={this.onFileRemoved}
      />
    );
  };
}

ReSubmitClaim.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  reSubmitClaim: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getBankList: dispatch.claim.getBankList,
  getCurrencyList: dispatch.claim.getCurrencyList,
  getCountryList: dispatch.claim.getCountryList,
  addBankAccount: dispatch.claim.addBankAccount,
  refresh: dispatch.policy.getPolicyDetails,
  reSubmitClaim: dispatch.claim.reSubmitClaim,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReSubmitClaim);
