import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import Footer from '../UI/Footer';
import Topbar from '../UI/TopBar';
import './Home.scss';

const Template = ({ pageTitle, children }) => (
  <React.Fragment>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <div className="App wrapper">
      <Container fluid className={classNames('content')}>
        <Topbar />
        {children}
      </Container>
    </div>
    <Footer />
  </React.Fragment>
);

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Template.defaultProps = {
  pageTitle: 'MetLife',
};

export default Template;
