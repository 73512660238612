import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from 'reactstrap';
import Template from '../../../Templates/Home';
import Title from '../../Title';
import DownloadCard from '../DownloadCard';

const Certificates = ({ data, policyData, downloadCertificate, users, user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterUsers, setFilterUsers] = useState();
  const [userData, setUserData] = useState(user);

  useEffect(() => {
    console.log(data?.email, users);
    setFilterUsers(users?.length ? users?.filter((e) => e?.card_number !== user?.card_number) : []);
  }, [users]);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Certificates', active: true },
  ];
  return (
    <Template pageTitle="Certificates" breadcrumb={breadcrumb}>
      <Container fluid className="max-container">
        <Title
          policyNumber={policyData?.policyNumber}
          certificateNumber={policyData?.certificateNumber}
          expiryDate={policyData?.expiryDate}
        />
      </Container>
      <section className="metLife-gray">
        <Container fluid className="max-container">
          <Dropdown isOpen={dropdownOpen} toggle={toggle} className="personal-details-dropdown certificates mb-3">
            <DropdownToggle className="btn btn-link">
              <div className="profile">
                <div className="align-items-center media">
                  <Row className="align-items-center m-0">
                    <div>
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt=""
                          src={
                            userData?.image_path || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                          }
                          width="50px"
                          height="auto"
                        />
                      </span>
                    </div>
                    <div className="pl-3 text-left">
                      <Label>Select Insured Name</Label>
                      <div className="d-flex">
                        <a className="">{userData?.first_name}</a>
                        <svg
                          className="ml-3 mt-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.009"
                          height="6.628"
                          viewBox="0 0 11.009 6.628"
                        >
                          <g id="left_arrow" transform="translate(1.414 5.628) rotate(-90)" opacity="0.55">
                            <path
                              id="left_arrow-2"
                              data-name="left_arrow"
                              d="M0,8.181,4.214,4.09,0,0"
                              transform="translate(4.214 8.181) rotate(180)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {filterUsers ? (
                filterUsers?.map((element) => (
                  <DropdownItem
                    value={element?.id}
                    key={element?.card_number}
                    onClick={() => {
                      setUserData(element);
                      setFilterUsers(users?.filter((e) => e?.card_number !== element?.card_number));
                    }}
                  >
                    <div className="profile">
                      <div className="align-items-center media">
                        <div className="d-flex">
                          <div>
                            <span className="avatar avatar-sm rounded-circle">
                              <img
                                alt=""
                                src={
                                  element?.image_path ||
                                  'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                                }
                                width="50px"
                                height="auto"
                              />
                            </span>
                          </div>
                          <div className="p-0 pl-2">
                            <a className="">{element?.member_name}</a>
                            <Label>{element?.type}</Label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem>No Dependents</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
          <Row>
            <Col className="grid grid-2 gg-default service-list">
              {data.map((item) => (
                <DownloadCard
                  item={item}
                  downloadCertificate={downloadCertificate}
                  id={!userData?.is_principal ? userData?.id : null}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </Template>
  );
};

Certificates.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  downloadCertificate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    card_number: PropTypes.string,
    id: PropTypes.number,
    is_principal: PropTypes.bool.isRequired,
  }),
};

Certificates.defaultProps = {
  data: [],
  users: [],
  user: {},
};

export default withRouter(Certificates);
