import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';

const RecoverUsername = ({ error, loading, success, message, onFormSubmit, defaultValues }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });

  return (
    <div className="">
      <Helmet>
        <title>Trouble logging in?</title>
      </Helmet>
      <Container className="login full-width-page">
        <Row className="text-center h-100 align-content-center">
          <Col className="text-center login-left">
            <img className="logo" src={logo} alt="" />
            <h1>Trouble Logging In?</h1>
            {!error && !success ? (
              <>
                <h3>Enter the below information to complete the verification</h3>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  {!!error && <Alert color="danger">{message}</Alert>}
                  {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

                  <FormGroup className="form-label-group">
                    <Input
                      type="text"
                      name="policy_number"
                      id="policy_number"
                      placeholder="Policy Number"
                      disabled={loading}
                      invalid={!!errors.policy_number}
                      innerRef={register({ required: errorMessages.cardNumberMissing })}
                      onChange={(e) => setValue('policy_number', e.target.value)}
                    />
                    <Label>Policy Number</Label>
                    {errors.policy_number && <p className="invalid-feedback">{errors.policy_number.message}</p>}
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Input
                      type="text"
                      name="certificate_no"
                      id="certificate_no"
                      placeholder="Certificate Number"
                      disabled={loading}
                      invalid={!!errors.certificate_no}
                      innerRef={register({ required: errorMessages.cardNumberMissing })}
                      onChange={(e) => setValue('certificate_no', e.target.value)}
                    />
                    <Label>Certificate Number</Label>
                    {errors.certificate_no && <p className="invalid-feedback">{errors.certificate_no.message}</p>}
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    {/* <Label for="dob">Date of Birth</Label> */}
                    <Input
                      type="date"
                      name="dob"
                      id="dob"
                      placeholder="dd/mm/yyyy"
                      className="form-control placeholder"
                      disabled={loading}
                      invalid={!!errors.dob}
                      innerRef={register({ required: errorMessages.dobMissing })}
                      onChange={(e) => {
                        setValue('dob', e.target.value);
                      }}
                      onClick={(e) => {
                        e.target.showPicker();
                        e.target.classList.add('active');
                      }}
                      // onFocus={(e) => {
                      //   e.target.type = 'date';
                      // }}
                      // onFocus="(this.type='date')"
                      // onBlur={(e) => {
                      //   if (!e.target.value) e.target.type = 'text';
                      // }}
                      // style={{ width: '100% !important' }}
                    />
                    <Label>Date Of Birth</Label>
                    {errors.dob && <p className="invalid-feedback">{errors.dob.message}</p>}
                  </FormGroup>

                  <Button color="primary" disabled={loading} style={{ width: '100%' }}>
                    {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
                  </Button>
                </Form>
                <div className="bottom-links justify-content-center  mt-5">
                  Already Registered?
                  <Link to="/login" className="text-primary">
                    {' '}
                    Log in
                  </Link>
                </div>
                <div className="bottom-links justify-content-center  mt-3">
                  <Link to="/faq" className="text-primary">
                    Hi, Need Any Help?
                  </Link>
                </div>
              </>
            ) : (
              <div className="align-items-center">
                {/* <Label className="msg-box">{message}</Label> */}
                <Label className="msg-box">
                  {success ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      enableBackground="new 0 0 50 50"
                      version="1.1"
                      viewBox="0 0 50 50"
                      xmlSpace="preserve"
                    >
                      <circle cx="25" cy="25" r="25" fill="#99D538" />
                      <path
                        fill="none"
                        stroke="#FFF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                        d="M38 15L22 33 12 25"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      enableBackground="new 0 0 32 32"
                      overflow="visible"
                      viewBox="0 0 32 32"
                    >
                      <g>
                        <g>
                          <circle cx="16" cy="16" r="16" fill="#D72828" />
                          <path fill="#E6E6E6" d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z" />
                        </g>
                      </g>
                    </svg>
                  )}

                  {message}
                </Label>
                {success ? (
                  <Label className="text-align-center mt-3">
                    &nbsp;Back to &nbsp;
                    <Label tag={Link} to="/">
                      Login
                    </Label>
                  </Label>
                ) : (
                  <Label className="text-align-center mt-3">
                    &nbsp;Please &nbsp;
                    <Label tag={Link} to="/contactUs">
                      contact
                    </Label>
                    &nbsp;customer services for help &nbsp;
                  </Label>
                )}
              </div>
            )}
          </Col>
          <Col className="text-center login-right text-white p-5">
            <h2 className="">Introducing myMetLife Plus</h2>
            <p className="">
              myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
              your account, read the latest on health, fitness, and financial wellness, and access customer service-
              anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
              out-of- pocket expenses. Now, that's something to smile about.
            </p>
          </Col>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

RecoverUsername.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

RecoverUsername.defaultProps = {
  error: false,
  success: false,
  message: null,
  loading: false,
  defaultValues: {},
};

export default withRouter(RecoverUsername);
