import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/Newsletter';

class Newsletter extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, data: null, message: null, loading: false };
  }

  async componentDidMount() {
    const { getNewsletter } = this.props;
    const { success, data } = await getNewsletter();
    if (success) {
      console.log(data);
      this.setState({ data });
    }
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { submitNewsletter, location } = this.props;

    console.log(location.state);
    const obj = data;
    let arr = [];
    if (data?.health) {
      arr.push(1);
    }
    if (data?.financial_wellness) {
      arr.push(2);
    }
    if (data?.family_and_community) {
      arr.push(3);
    }
    obj.interests = arr;
    delete obj?.health;
    delete obj?.financial_wellness;
    delete obj?.family_and_community;
    obj.send_email = parseInt(obj.send_email, 10);
    obj.send_sms = parseInt(obj.send_sms, 10);
    obj.married = parseInt(obj.married, 10);
    arr = [];
    for (let i = 0; i < parseInt(obj?.children_count ?? 0, 10); i += 1) {
      Object.keys(obj).forEach((item) => {
        if (item === `child${i}`) {
          // if (obj[`child${i}`]) {
          arr.push(obj[`child${i}`]);
          // }
          delete obj[`child${i}`];
        }
      });
    }
    obj.children_below_eighteen = arr;
    obj.token = location?.state?.token ?? '';
    obj.email = location?.state?.email ?? '';

    this.setState({ success: null, error: null, loading: true });

    try {
      const { success, message } = await submitNewsletter(obj);
      if (success) {
        this.setState({ success, message: 'Updated successfully', error: null, loading: false });
        // setTimeout(() => {
        //   history.push('/');
        // }, 1000);
      } else {
        this.setState({ success, message, error: true, loading: false });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: true, message: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, data, message } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        data={data}
        message={message}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

Newsletter.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      token: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  submitNewsletter: PropTypes.func.isRequired,
  getNewsletter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  submitNewsletter: dispatch.member.submitNewsletter,
  getNewsletter: dispatch.member.getNewsletter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
