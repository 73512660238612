import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/ComplaintForm';
import { hasAuthToken } from '../lib/jwt';

class ComplaintForm extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  onFileSelected = (fileList) => {
    if (fileList.length) {
      this.setState((pre) => ({
        ...pre,
        newComplaint: { ...pre.newComplaint, docs: fileList },
      }));
    }
  };

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { submitComplaint, history } = this.props;
    const { newComplaint } = this.state;

    const payload = {
      ...newComplaint,
      ...data,
    };

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
        });
        return;
      }
      formData.append(key, payload[key]);
    });
    delete newComplaint?.docs;
    this.setState({ success: null, error: null, loading: true });

    try {
      const { success } = await submitComplaint(formData);
      this.setState({ success, error: null, loading: false });
      if (success) {
        if (hasAuthToken()) {
          history.push('/complaintList');
        } else {
          history.push('/contactUs');
        }
        toast.custom(<Alert message="Complaint is successfully submitted" />);
      }
    } catch (error) {
      this.setState({ loading: false, success: null, message: error.message, error: true });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { error, loading, success, message } = this.state;
    const { authUser } = this.props;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        authUser={authUser}
        onFileSelected={this.onFileSelected}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

ComplaintForm.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  submitComplaint: PropTypes.func.isRequired,
  authUser: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  authUser: state.member.user || {},
});

const mapDispatchToProps = (dispatch) => ({
  submitComplaint: dispatch.member.submitComplaint,
  logOut: dispatch.member.logOut,
  me: dispatch.member.me,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintForm);
