import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import Layout from '../components/Claims/ClaimDetails';
import Alert from '../components/UI/Alert';

class ClaimDetails extends Component {
  constructor() {
    super();
    this.state = { data: null };
  }

  componentDidMount() {
    this.getClaimDetails();
  }

  /**
   * get Claim details
   */
  getClaimDetails = async () => {
    const { getClaimDetails, location } = this.props;
    this.setState({ data: null, loading: true });
    try {
      if (location?.state?.claimId) {
        const date = new Date();
        const formattedDate = date
          .toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
          .replace(/\//g, '-');
        const { success, data } = await getClaimDetails(location?.state?.claimId);
        if (success) {
          if (data?.status === 'Pending' && data?.submission_date === formattedDate) {
            data.status = 'Submitted';
          }
          this.setState((prev) => ({ ...prev, data, loading: false }));
        }
        return success;
      }
      this.setState({ data: null, loading: false });
      return false;
    } catch (error) {
      console.log(error);
    }
    this.setState({ data: null, loading: false });
    return false;
  };

  downloadFile = async (filepath) => {
    const { download } = this.props;
    toast.promise(download({ file_path: filepath }),
      {
        loading: (
          <Alert message="Please wait while the file is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type) {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = '_blank';
            link.click();
            return (
              <Alert message="File has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download file'} />
          );
        },
        error: (
          <Alert message="Unable to download file" />
        ),
      });
  };

  /**
   * get currency list
   */
  downloadEOB = async (claim_id) => {
    const { downloadEOB } = this.props;
    toast.promise(downloadEOB(claim_id),
      {
        loading: (
          <Alert message="Please wait while the EOB is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type === 'application/pdf') {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = `EOB_${claim_id}.pdf`;
            link.click();
            return (
              <Alert message="EOB has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Medical Card'} />
          );
        },
        error: (
          <Alert message="Unable to download EOB" />
        ),
      });
  };

  /**
   * Render
   */
  render = () => {
    const { data, loading } = this.state;
    const { currentPolicy, location } = this.props;
    const breadcrumb = location?.state?.breadcrumb || [
      { label: 'Dashboard', to: '/home' },
      { label: 'Policy Details', to: '/home/policyDetails' },
      { label: 'Claims', to: '/claims' },
      { label: 'Claim Details', active: true },
    ];
    return (
      <Layout
        data={data}
        policyData={currentPolicy}
        breadcrumb={breadcrumb}
        download={this.downloadFile}
        downloadEOB={this.downloadEOB}
        loading={loading}
        showResubmit={location?.state?.showResubmit}
      />
    );
  };
}

ClaimDetails.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      claimId: PropTypes.number.isRequired,
      showResubmit: PropTypes.bool.isRequired,
      breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({}).isRequired,
  getClaimDetails: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  downloadEOB: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getClaims: dispatch.claim.getClaims,
  getClaimDetails: dispatch.claim.getClaimDetails,
  download: dispatch.claim.download,
  downloadEOB: dispatch.claim.downloadEOB,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetails);
