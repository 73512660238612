// import moment from 'moment';
import initialState from '../store/articles';
// import pagination from '../lib/pagination';
import userApi from '../api/UserApi';

export default {
  namespace: 'member',

  /**
   *  Initial state
   */
  state: initialState,

  /**
   * Effects/Actions
   */
  effects: (dispatch) => ({
    /**
     * login
     * @param {obj} data
     */
    async login(data) {
      console.log('Attempt Login');
      const res = await userApi.login(data);
      if (res.success) {
        dispatch.member.replace(res);
      }
      return res;
    },

    /**
     * verifyLoginOTP
     * @param {obj} data
     */
    async verifyLoginOTP(data) {
      console.log('Verify Login OTP');
      const res = await userApi.verifyLoginOTP(data);
      return res;
    },

    /**
     * resend LoginOTP
     * @param {obj} data
     */
    async resendLoginOTP(data) {
      console.log('Resend Login OTP');
      const res = await userApi.resendLoginOTP(data);
      return res;
    },

    /**
     * Update Password with old password
     * @param {obj} data
     */
    async updatePassword(data) {
      console.log('updatePassword');
      const res = await userApi.updatePassword(data);
      return res;
    },

    /**
     * login
     * @param {obj} data
     */
    async me() {
      const res = await userApi.me();
      dispatch.member.replaceUser(res);
      return true;
    },

    /**
     * register new user
     * @param {obj} data
     */
    async register(data) {
      return userApi.userCreate(data);
    },

    /**
     * register new user
     * @param {obj} data
     */
    async validateID(data) {
      const res = await userApi.validateID(data);
      if (res.success) {
        dispatch.member.replace(res);
      }
      return res;
    },

    /**
     * Verify OTP
     * @param {obj} data
     */
    async verifyOTP(data) {
      console.log('Verify OTP');
      return userApi.verifyOTP(data);
    },

    /**
     * Verify OTP
     */
    async resendOTP(data) {
      console.log('Verify OTP');
      return userApi.resendOTP(data);
    },

    /**
     * Verify account by questions
     * @param {obj} data
     */
    async verifyAccount(data) {
      console.log('Verify Account');
      dispatch.member.replaceQAndA(data);
      return userApi.verifyAccount(data);
    },

    /**
     * Send link for forgot password
     * @param {obj} data
     */
    async sendPasswordLink(data) {
      console.log('Send password reset');
      // return { success: true, data };
      return userApi.forgotPassword(data);
    },

    /**
     * Send link for forgot password
     * @param {obj} data
     */
    async setNewPassword(data) {
      console.log('set new password');
      return userApi.setNewPassword(data);
    },

    /**
     * Get policies
     */
    async getPolicies() {
      return userApi.getPolicies();
    },

    /**
     * Updates the user phone number
     */
    async addPhoneNumber(data) {
      return userApi.addPhoneNumber(data);
    },
    /**
     * Password reset
     * @param {obj} data
     */
    async resetPassword(data) {
      return userApi.resetPassword(data);
    },

    /**
     * Recover email/username
     */
    async recoverUsername(data) {
      return userApi.recoverUsername(data);
    },

    /**
     * Photo upload
     */
    async uploadPhoto(data) {
      const obj = Object.fromEntries(data);
      const res = await userApi.photoUpload(data);
      if (res?.success && !obj?.dependent_id) {
        dispatch.member.updateUserImage(res?.data?.image_path);
      }
      return res;
    },

    /**
     * Get videos and manuals
     */
    async getVideosAndManuals(type) {
      return userApi.getVideosAndManuals(type);
    },

    /**
     * Get complaints
     */
    async getComplaints() {
      return userApi.getComplaints();
    },

    /**
     * Download forms
     */
    async downloadForms(type) {
      return userApi.downloadForms(type);
    },

    /**
     * Get all forms
     */
    async getAllForms() {
      return userApi.getAllForms();
    },

    /**
     * Submit newsletter
     */
    async submitNewsletter(data) {
      return userApi.submitNewsletter(data);
    },

    /**
     * Get newsletter
     */
    async getNewsletter() {
      return userApi.getNewsletter();
    },

    /**
     * Get Provider Service
     */
    async getProviderPreApproval(payload = {}) {
      return userApi.getProviderPreApproval(payload);
    },

    /**
     * Get Provider Service Details
     */
    async getProviderPreApprovalDetails(id) {
      return userApi.getProviderPreApprovalDetails(id);
    },

    /**
     * Get terms and conditions
     */
    async getTermsAndConditions() {
      return userApi.getTermsAndConditions();
    },

    /**
     * Get FAQ
     */
    async getFAQ() {
      return userApi.getFAQ();
    },

    /**
     * Profile update
     */
    async submitUserProfile(data) {
      return userApi.updateUserProfile(data);
    },

    /**
     * Profile personal details form submit
     */
    async submitPersonalDetails(data) {
      return userApi.updatePersonalDetails(data);
    },

    /**
     * Profile work and residential details form submit
     */
    async submitWorkAndResidentialDetails(data) {
      return userApi.updateWorkAndResidentialDetails(data);
    },

    /**
     * Profile visa information form submit
     */
    async submitVisaInformation(data) {
      return userApi.updateVisaInformation(data);
    },

    /**
     * Profile sponsor details
     */
    async submitSponsorDetails(data) {
      return userApi.updateSponsorDetails(data);
    },

    /**
     * Profile salary information
     */
    async submitSalaryInformation(data) {
      return userApi.updateSalaryInformation(data);
    },

    /**
     * Get dependents
     */
    async getDependents() {
      return userApi.getDependents();
    },

    /**
     * Profile RI Claims
     */
    async submitRIClaims(data) {
      return userApi.updateRIClaims(data);
    },

    /**
     * Profile login credentials
     */
    async submitLoginCredentials(data) {
      return userApi.updateLoginCredentials(data);
    },

    /**
     * Get states
     */
    async getStates() {
      return userApi.getStates();
    },

    /**
     * Get states
     */
    async getProviderCountries() {
      return userApi.getProviderCountries();
    },

    /**
     * Get User Profile
     */
    async getUserProfile(data) {
      return userApi.getProfile(data);
    },

    /**
     * Get all members
     */
    async getAllMembers(policyNumber) {
      return userApi.getAllMembers(policyNumber);
    },

    /**
     * Register a complaint
     */
    async submitComplaint(data) {
      return userApi.submitComplaint(data);
    },

    async guideVideo() {
      return userApi.guideVideo();
    },

    /**
     * Logout
     */
    async logOut() {
      dispatch.member.replaceEmailAndPassword({});
      dispatch.member.replaceQAndA({});
      return userApi.logOut();
    },
  }),

  /**
   * Reducers
   */
  reducers: {
    /**
     * Replace list in store
     * @param {obj} state
     * @param {obj} payload
     */
    replace(state, payload) {
      return {
        ...state,
        phoneNumber: payload?.phone_number,
        maskedPhoneNumber: payload?.masked_phone_number,
        userID: payload.user_id,
        dependentFound: payload?.dependent_found,
        firstName: payload?.first_name,
        lastName: payload?.last_name,
      };
    },

    replaceUserInput(state, payload) {
      return {
        ...state,
        userInput: payload,
      };
    },

    replaceUser(state, payload) {
      // FILTERING UNIQUE DEPENDENTS
      const dependents = [...new Map(payload?.dependents.map((item) => [item.card_number, item])).values()];
      return {
        ...state,
        user: payload,
        dependents,
      };
    },

    replaceEmailAndPassword(state, payload) {
      return {
        ...state,
        email: payload.email || null,
        password: payload.password || null,
        user_id: payload.user_id || null,
      };
    },

    replaceQAndA(state, payload) {
      return {
        ...state,
        dependents_count: payload.dependents_count || null,
        dependent_dob: payload.dependent_dob || null,
        primary_mem_gender: payload.primary_mem_gender || null,
        dependent_gender: payload.dependent_gender || null,
        marital_status: payload.marital_status || null,
        nationality_country_code: payload.nationality_country_code || null,
        user_id: payload.user_id || null,
      };
    },

    updateUserImage(state, payload) {
      console.log('image upload', payload);
      const formData = new FormData();
      console.log(formData.get('dependent_id'));
      return {
        ...state,
        user: { ...state?.user, image_path: payload },
      };
    },
  },
};
