import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Container, Form, Label, Row, List, FormGroup, Input, Button, Spinner } from 'reactstrap';
import Template from '../../Templates/Home';
import Title from '../../PolicyDetails/Title';
import InfoLabel from '../../PolicyDetails/InfoLabel';
import CustomFileInput from './CustomFileInput';
import { errorMessages } from '../../../constants/messages';
import config from '../../../constants/config';

const ReSubmitPreApproval = ({ policyData, defaultData, onConfirm, onFileSelected, loading }) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Pre-Approvals', to: '/preApprovals' },
    { label: 'Re-submit Pre-Approvals', active: true },
  ];
  const handleSetPayload = (formdata, draft = false) => {
    if (draft) {
      onConfirm({ ...formdata });
    } else {
      onConfirm({ ...formdata }, 'confirm');
    }
  };
  return (
    <Template pageTitle="Re-Submit Pre-Approval" breadcrumb={breadcrumb}>
      <Fragment>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section color="light" className="policy-details-content metLife-gray">
          <div className="container-fluid max-container">
            <Row>
              <Col className="">
                <div className="card policy-details-tab">
                  <Row className="justify-content-between mt-3">
                    <Col md="5">
                      <Label className="f-mlc-m display-3 tab-title">
                        {`Pre-Approval #${defaultData?.reference_number || defaultData?.id}`}
                      </Label>
                    </Col>
                  </Row>
                  <Form onSubmit={handleSubmit((values) => handleSetPayload(values))} className="mt-3">
                    <div className="grid grid-2 info-list-wrapper">
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Policy Number" name={defaultData?.policy?.policy_number} />
                        </li>
                        <li>
                          <InfoLabel title="Policy Holder Name" name={defaultData?.policy?.holder_name} />
                        </li>
                      </List>
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Insured Name" name={defaultData?.insured_name} />
                        </li>
                        <li>
                          <InfoLabel title="Claimed Amount" name={defaultData?.claimed_amount} />
                        </li>
                      </List>
                    </div>
                    <FormGroup>
                      <h3 className="mt-5">Upload Documents</h3>
                      <p>Resubmit with additional documents for reassessment</p>
                      <p>
                        Please upload at least one of the following mandatory documents
                        that are required to proceed with the claim submission:
                      </p>
                      <p className="mt-3">
                        Maximum file size allowed is 8MB/Document. Allowed file types
                        .jpg, .jpeg, .tif, .bmp, .png, .doc, .docx,
                        .txt, .pdf.
                      </p>
                      <Row>
                        <Col md={6}>
                          <CustomFileInput
                            onFileSelect={onFileSelected}
                            type="docs"
                            label="Upload Documents"
                            defaultData={defaultData?.documents}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="pl-4">
                            <Input
                              type="checkbox"
                              name="declaration"
                              id="declaration"
                              disabled={loading}
                              invalid={!!errors.declaration}
                              innerRef={register({ required: errorMessages.missingData })}
                              onChange={(e) => setValue('declaration', e.target.value)}
                            />
                            <Label for="declaration">
                              I have read and accept all the
                              <a
                                href={`${config.baseURL}/claim-submission-disclaimer/AE`}
                                className="btn-link"
                                target="blank"
                              >
                                {' '}
                                Terms & conditions
                              </a>
                            </Label>
                            {errors.declaration && <p className="invalid-feedback">Please check this to continue</p>}
                          </div>
                        </Col>
                        <Col className="text-right">
                          <Button color="primary" disabled={loading}>
                            {loading ?
                              (
                                <>
                                  <Spinner
                                    size="sm"
                                    color="light"
                                  />
                                  <span>&nbsp;Confirm & Re-Submit</span>
                                </>
                              ) : 'Submit'}
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                  <hr />
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </Fragment>
    </Template>
  );
};

ReSubmitPreApproval.propTypes = {
  policyData: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  defaultData: PropTypes.shape({
    policy: PropTypes.shape({
      policy_number: PropTypes.number,
      holder_name: PropTypes.string,
    }),
    id: PropTypes.number,
    mednext_id: PropTypes.number,
    reference_number: PropTypes.string,
    claimed_amount: PropTypes.string,
    status: PropTypes.string,
    denied_amount: PropTypes.string,
    approved_amount: PropTypes.string,
    payment_reference: PropTypes.string,
    certificate_number: PropTypes.string,
    last_claim_service_date: PropTypes.string,
    paid_date: PropTypes.string,
    payment_mode: PropTypes.string,
    additional_info: PropTypes.string,
    date_of_service: PropTypes.string,
    paid_amount: PropTypes.string,
    unit: PropTypes.string,
    derived_amount: PropTypes.string,
    patient_share: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        file_path: PropTypes.string,
        description: PropTypes.string,
        file_name: PropTypes.string,
      }),
    ),
    dependent: PropTypes.shape({
      member_name: PropTypes.string,
    }),
    user: PropTypes.shape({
      member_name: PropTypes.string,
    }),
    insured_id: PropTypes.number.isRequired,
    dependant_code: PropTypes.string.isRequired,
    physician_name: PropTypes.string.isRequired,
    expected_admission_date: PropTypes.string.isRequired,
    approval_scenario: PropTypes.string.isRequired,
    insured_name: PropTypes.string.isRequired,
    hospital_name: PropTypes.string.isRequired,
    approval_submission_date: PropTypes.string.isRequired,
    expected_number_of_days: PropTypes.string.isRequired,
    diagnosis: PropTypes.string.isRequired,
    provider_name: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
};

ReSubmitPreApproval.defaultProps = {
  loading: false,
};

export default withRouter(ReSubmitPreApproval);
