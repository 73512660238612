import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, FormGroup, Label, Row, List, Card, Spinner, Input } from 'reactstrap';
import InfoLabel from '../../PolicyDetails/InfoLabel';

const BankDetailsCard = ({ data, register, setValue, defaultChecked, deleteBank, loading }) => {
  const checkBox = useRef();

  const [deleteBankId, setDeleteBankId] = useState(null);
  return (
    <Col md={6}>
      <Card className="p-4 shadow-none border mb-4">
        <Row className="justify-content-between">
          <Col>
            <FormGroup check>
              <Row check>
                <Col>
                  <Row>
                    <Col className="col-1 text-center" check>
                      <Input
                        type="radio"
                        name="bank_id"
                        id={`bank_id_${data?.id}`}
                        value={data?.id}
                        ref={checkBox}
                        defaultChecked={defaultChecked}
                        innerRef={register()}
                        onChange={(e) => setValue('bank_id', e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Label for={`bank_id_${data?.id}`} tag="h3" className="mb-0 ml-2 align-baseline">
                    {data.bank_name || 'N/A'}
                    <p className="font-weight-normal small" style={{ color: '#75787B', fontSize: '16px' }}>{data.number_type === 1 ? data?.iban_no : data.account_no}</p>
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col className="text-right col-2">
            {deleteBankId !== data?.id || (!loading && deleteBankId === data?.id) ? (
              <a
                onClick={() => {
                  // eslint-disable-next-line
                  if (window.confirm(`Are Sure, you want delete ${data.bank_name} ?`)) {
                    setDeleteBankId(data?.id);
                    deleteBank(data?.id);
                  }
                }}
              >
                <svg viewBox="0 0 17.804 19.686" xmlns="http://www.w3.org/2000/svg" width="17.804" height="19.686">
                  <defs>
                    <clipPath id="a">
                      <path
                        transform="translate(-.255 16)"
                        d="M15.468,3.681,2.834,3.686,1.469-10.094H16.845Zm1.32-17.318a1.245,1.245,0,0,1,.893.329,1.177,1.177,0,0,1,.379.852v1.181H.255v-1.181a1.177,1.177,0,0,1,.379-.852,1.245,1.245,0,0,1,.893-.329H4.245L5.3-15.313A1.559,1.559,0,0,1,6.613-16H11.7a1.533,1.533,0,0,1,1.249.743l1.12,1.619ZM6.931-14.819a.6.6,0,0,0-.607.591v.591h5.665v-.591a.6.6,0,0,0-.607-.591Z"
                        clipRule="evenodd"
                        fill="#dd2224"
                      />
                    </clipPath>
                  </defs>
                  <g transform="translate(0)" clipPath="url(#a)">
                    <path transform="translate(-1.704 14.725)" d="m-4.745-21h30.7v32.236h-30.7z" fill="#dd2224" />
                  </g>
                </svg>
              </a>
            ) : (
              <div>
                <Spinner
                  size="sm"
                  color="#dc3545"
                />
              </div>
            )}
          </Col>
        </Row>
        <div className="grid info-list-wrapper pointer">
          <List type="unstyled" className="info-list">
            <li>
              <InfoLabel title="Account Holder Name" name={data.payable_to || 'N/A'} />
            </li>
            <li>
              <InfoLabel title="Swift Code" name={data.swift || 'N/A'} />
            </li>
            {data?.number_type === 1 ? (
              <li>
                <InfoLabel title="IBAN" name={data.iban_no || 'N/A'} />
              </li>
            ) : (
              <li>
                <InfoLabel title="Account Number" name={data.account_no || 'N/A'} />
              </li>
            )}
            <li>
              <InfoLabel title="IFCS Code" name={data.ifsc_or_aba || 'N/A'} />
            </li>
            <li>
              <InfoLabel title="Payment Currency" name={data.pay_currency || 'N/A'} />
            </li>
          </List>
        </div>
      </Card>
    </Col>
  );
};

BankDetailsCard.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.shape({
    bank_name: PropTypes.string,
    number_type: PropTypes.number,
    payable_to: PropTypes.string,
    swift: PropTypes.string,
    iban_no: PropTypes.string,
    ifsc_or_aba: PropTypes.string,
    pay_currency: PropTypes.string,
    account_no: PropTypes.string,
    id: PropTypes.number,
  }),
  defaultChecked: PropTypes.bool,
  deleteBank: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
BankDetailsCard.defaultProps = {
  data: {},
  defaultChecked: false,
};

export default withRouter(BankDetailsCard);
