import React from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { Row, Col, Label, Card, CardBody } from 'reactstrap';

const ListCard = ({ data }) => (
  <Card>
    <CardBody>
      <Row className="align-items-center">
        <span className="s-icon">
          {/* <FontAwesomeIcon size="2x" /> */}
          {data?.icon}
        </span>
        <Col>
          <Label>{data.name}</Label>
          <p>{data?.subtitle}</p>
        </Col>
        <svg
          className="mr-3"
          xmlns="http://www.w3.org/2000/svg"
          width="11.417"
          height="17.414"
          viewBox="0 0 11.417 17.414"
        >
          <path
            id="_Chevron_Down_Icon_Color"
            data-name="🎨 Chevron Down Icon Color"
            d="M8,10,0,2,2,0,8,6.1,14,0l2,2Z"
            transform="translate(0.71 16.707) rotate(-90)"
            fill="#007abc"
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
          />
        </svg>
      </Row>
    </CardBody>
  </Card>
);

ListCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.shape({
      prefix: PropTypes.element,
      iconName: PropTypes.element,
      icon: PropTypes.element,
    }),
    url: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

ListCard.defaultProps = {
  data: {},
};

export default withRouter(ListCard);
