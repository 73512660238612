import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DocumentItem = ({ label, attachment, download }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="p-4 border shadow-none d-flex border-radius mb-4 align-items-center"
        onClick={() => setIsOpen(true)}
      >
        <FileIcon fill={attachment?.length ? '#007abc' : '#808080'} />
        <h4 className="ml-3 mb-0">{label}</h4>
      </div>

      <Modal
        className="claim-documents"
        size="lg"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        style={{ width: '80vw' }}
      >
        <ModalHeader>{label}</ModalHeader>
        <ModalBody>
          <ul>
            {attachment?.length
              ? attachment?.map((key) => (
                  <Card key={key?.id}>
                    <CardBody className="d-inline-flex justify-content-between">
                      <a onClick={() => download(key?.filepath ?? key?.file_path)} download>
                        {key?.file_name}
                      </a>
                    </CardBody>
                  </Card>
                ))
              : 'No files found.'}
          </ul>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="outline-primary" onClick={() => setIsOpen(!isOpen)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const FileIcon = ({ fill }) => (
  <svg
    id="Icon_Folder"
    data-name="Icon/Folder"
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <rect id="Mask" width="31" height="31" fill="none" />
    <path
      id="Icon_Folder-2"
      data-name="Icon/Folder"
      d="M2,25.222a1.294,1.294,0,0,0,1.35,1.389A1.294,1.294,0,0,0,4.7,25.222V8.556H26.3V5.778H10.774L9.019,3H2ZM6.049,9.944V26.333A1.666,1.666,0,0,1,4.43,28H27.376A1.622,1.622,0,0,0,29,26.194V9.944ZM12.124,20.5a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,12.124,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,17.523,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,22.922,20.5Z"
      fill={fill}
    />
  </svg>
);

DocumentItem.propTypes = {
  label: PropTypes.string.isRequired,
  attachment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      file_path: PropTypes.string.isRequired,
      file_name: PropTypes.string.isRequired,
      filepath: PropTypes.string.isRequired,
    }),
  ),
  download: PropTypes.func.isRequired,
};

DocumentItem.defaultProps = {
  attachment: [],
};

FileIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default withRouter(DocumentItem);
