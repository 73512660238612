import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';

const SalaryAndOtherInfo = ({ loading, onFormSubmit, defaultValues, height, data }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const [button, setButton] = useState(false);

  const submit = async (profileData) => {
    await toast.promise(onFormSubmit(profileData), {
      loading: 'Saving...',
      success: (e) => {
        setButton(false);
        return <b>{e?.message}</b>;
      },
      error: (e) => {
        setButton(false);
        return <b>{e?.message}</b>;
      },
    });
  };

  return (
    <Card name="salaryAndOtherInfo" style={{ marginBottom: height }}>
      <CardBody>
        <Row>
          <Col>
            <Label className="f-mlc-m display-3 tab-title">Salary And Other Info</Label>
          </Col>
        </Row>
        <hr />
        {button ? (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="salary_based_on_commission"
                        id="salary_based_on_commission"
                        placeholder="Salary Based On Commission"
                        disabled={loading}
                        invalid={!!errors.salary_based_on_commission}
                        innerRef={register({ required: errorMessages.missingSalaryBasedOnCommission })}
                        onChange={(e) => setValue('salary_based_on_commission', e.target.value)}
                      >
                        <option value="" defaultChecked={!data?.info?.salary_based_on_commission} disabled>
                          Salary Based On Commission
                        </option>
                        <option value="Y" defaultChecked={data?.info?.salary_based_on_commission === 'Y'}>
                          Yes
                        </option>
                        <option value="N" defaultChecked={data?.info?.salary_based_on_commission === 'N'}>
                          No
                        </option>
                      </Input>
                      <Label for="">Salary Based On Commission</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="dha_id"
                        id="dha_id"
                        placeholder="DHA ID"
                        disabled
                        invalid={!!errors.dha_id}
                        innerRef={register({ required: errorMessages.missingDHAID })}
                        onChange={(e) => setValue('dha_id', e.target.value)}
                      />
                      <Label for="">DHA ID</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="gross_salary"
                        id="gross_salary"
                        placeholder="Gross Salary"
                        disabled={loading}
                        invalid={!!errors.gross_salary}
                        innerRef={register({ required: errorMessages.missingSponsorEmail })}
                        onChange={(e) => setValue('gross_salary', e.target.value)}
                      >
                        <option value="" defaultChecked={!data?.info?.gross_salary_name} hidden disabled>
                          Gross Salary
                        </option>
                        <option
                          value="1"
                          defaultChecked={data?.info?.gross_salary_name === 'Salary less than 4,000 AED per month'}
                        >
                          Salary less than 4,000 AED per month
                        </option>
                        <option
                          value="2"
                          defaultChecked={
                            data?.info?.gross_salary_name === 'Salary between 4,001 and 12,000 AED per month'
                          }
                        >
                          Salary between 4,001 and 12,000 AED per month
                        </option>
                        <option
                          value="3"
                          defaultChecked={data?.info?.gross_salary_name === 'Salary greater than 12,000 AED per month'}
                        >
                          Salary greater than 12,000 AED per month
                        </option>
                        <option value="4" defaultChecked={data?.info?.gross_salary_name === 'No Salary'}>
                          No Salary
                        </option>
                      </Input>
                      <Label for="">Gross Salary</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="uae_insurance_for_past_six_months"
                        id="uae_insurance_for_past_six_months"
                        placeholder="Did member have insurance for past 6 months UAE"
                        disabled={loading}
                        invalid={!!errors.uae_insurance_for_past_six_months}
                        innerRef={register({ required: errorMessages.missingSponsorContact })}
                        onChange={(e) => setValue('uae_insurance_for_past_six_months', e.target.value)}
                      >
                        <option value="" defaultChecked={!data?.info?.uae_insurance_for_past_six_months} disabled>
                          Did member have insurance for past 6 months UAE
                        </option>
                        <option value="Y" defaultChecked={data?.info?.uae_insurance_for_past_six_months === 'Y'}>
                          Yes
                        </option>
                        <option value="N" defaultChecked={data?.info?.uae_insurance_for_past_six_months === 'N'}>
                          No
                        </option>
                      </Input>
                      <Label for="">Did member have insurance for past 6 months UAE</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ? 'Loading' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel
                  title="Salary Based On Commission"
                  name={data?.info?.salary_based_on_commission === 'Y' ? 'Yes' : 'No'}
                />
              </li>
              <li>
                <InfoLabel title="Gross Salary Range" name={data?.info?.gross_salary_name} />
                <small>(For regulatory purpose only.)</small>
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="DHA ID" name={data?.info?.dha_id || 'xxxxxxx'} />
              </li>
              <li>
                <InfoLabel
                  title="Did member have insurance for past 6 months in UAE"
                  name={data?.info?.uae_insurance_for_past_six_months === 'Y' ? 'Yes' : 'No'}
                />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

SalaryAndOtherInfo.propTypes = {
  data: PropTypes.shape({
    info: PropTypes.shape({
      salary_based_on_commission: PropTypes.string,
      dha_id: PropTypes.string,
      gross_salary: PropTypes.string,
      gross_salary_name: PropTypes.string,
      uae_insurance_for_past_six_months: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    salary_based_on_commission: PropTypes.string,
    dha_id: PropTypes.string,
    gross_salary: PropTypes.string,
    uae_insurance_for_past_six_months: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  height: PropTypes.string,
};

SalaryAndOtherInfo.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
  height: '',
};

export default withRouter(SalaryAndOtherInfo);
