import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Progress, Label } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import moment from 'moment';

const DashboardCard = ({ data }) => {
  const history = useHistory();
  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Pending';
      case 'Approved':
        return 'Approved';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Approved';
      case 'Suspended':
        return 'Suspended';
      default:
        return 'In Progress';
    }
  };

  // const numberFormat = (value, currency) => new Intl.NumberFormat(undefined, {
  //   style: 'currency',
  //   currency,
  // }).format(value);

  const renderProgress = (status) => {
    switch (status) {
      case 'Pending':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="danger" value="25" />
          </Progress>
        );
      case 'Re-Submitted':
      case 'Submitted':
      case 'In Progress':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
          </Progress>
        );
      case 'Rejected':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="danger" value="100" />
          </Progress>
        );
      case 'Partially Approved':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="danger" value="25" />
          </Progress>
        );
      case 'Suspended':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="danger" value="25" />
          </Progress>
        );
      case 'Approved':
        return (
          <Progress multi className="my-3" ame style={{ height: '5px' }}>
            <Progress bar color="secondary" value="100" />
          </Progress>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      onClick={() => {
        history.push({ pathname: '/preApprovalDetails', state: { preApprovalId: data?.id, showResubmit: true } });
      }}
      className="pointer mb-4"
    >
      <Row className="pt-3 px-3">
        <Col>
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li className="border-0">
                <div className="d-flex">
                  <Label className="">Pre Approval #: </Label>
                  <h4 className="pl-2">{data?.reference_number || data?.id}</h4>
                </div>
                <div className="d-flex">
                  <Label className="">Submission Date: </Label>
                  <h4 className="pl-2">{moment(data?.created_at).local().format('DD-MM-YYYY')}</h4>
                </div>
                <div className="d-flex">
                  <Label className="">PreApproval Type:</Label>
                  <h4 className="pl-2">{data?.claim_type_description}</h4>
                </div>
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li className="border-0">
                {/* <div className="d-flex">
                  <Label className="">Patient Name:</Label>
                  <h4 className="pl-2">{data?.dependent?.member_name || data?.user?.member_name}</h4>
                </div> */}
                <div className="d-flex">
                  <Label className="">Insured Name:</Label>
                  <h4 className="pl-2">{data?.insured_name}</h4>
                </div>
                {/* <div className="d-flex">
                  <Label className="">Provider Name:</Label>
                  <h4 className="pl-2">{data?.provider_name}</h4>
                </div> */}
                <div className="d-flex">
                  <Label className="">Claimed Amount:</Label>
                  <h4 className="pl-2">
                    {data?.claimed_amount_format && data?.currency ? data?.claimed_amount_format : ''}
                  </h4>
                </div>
              </li>
            </ul>
          </div>
          <Col className="p-0">{renderProgress(getStatusString(data?.status))}</Col>
        </Col>
      </Row>
      <Row className="pb-3 px-3">
        <Col>
          <div className="d-flex justify-content-between">
            <h4 className="">{getStatusString(data?.status)}</h4>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

DashboardCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    reference_number: PropTypes.string,
    approval_submission_date: PropTypes.string,
    claim_type_description: PropTypes.string,
    claimed_amount: PropTypes.string,
    claimed_amount_format: PropTypes.string,
    currency: PropTypes.string,
    insured_name: PropTypes.string,
    dependent_code: PropTypes.string,
    provider_name: PropTypes.string,
    mednext_id: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
    dependent: PropTypes.shape({
      member_name: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      member_name: PropTypes.string.isRequired,
    }),
  }),
};

DashboardCard.defaultProps = {
  data: {},
};

export default withRouter(DashboardCard);
