import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/PreApprovals/EditPreApproval/EditPreApproval';

class EditPreApproval extends Component {
  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
    };
  }

  componentDidMount() {
    this.getCountryList();
    this.getCurrencyList();
  }

  /**
   * get currency list
   */
  getCurrencyList = async () => {
    const { getCurrencyList } = this.props;
    try {
      const { success, data } = await getCurrencyList();
      if (success) {
        this.setState((prev) => ({ ...prev, currencyList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        this.setState((prev) => ({ ...prev, countryList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   *
   *Active Tab
   */
  setActive = (tab) => {
    if (tab === '2') {
      this.setState((pre) => ({ ...pre, active: tab }));
    } else if (tab === '1') {
      this.setState((pre) => ({ ...pre, active: tab }));
    }
  };

  /**
   * On Form Submission
   */
  viewDetails = async (data) => {
    const { viewDetails, history } = this.props;
    try {
      const success = await viewDetails(data);
      console.log(success);
      history.push('/preApprovals/details');
    } catch (error) {
      console.log('ERROR');
    }
  };

  onContinue = async (data, validate) => {
    if (validate) {
      this.setState((pre) => ({
        ...pre,
        newPreApp: { ...pre?.newPreApp, ...data },
        active: '2',
        firstStepPassed: true,
      }));
    }
  };

  onFileSelected = (fileList, type) => {
    if (fileList.length) {
      this.setState((pre) => ({
        ...pre,
        newPreApp: { ...pre.newPreApp, [type]: fileList },
      }));
    }
  };

  // Submit new claim : from step 2
  onSubmit = async (data) => {
    const { newPreApp, deleted_docs } = this.state;
    const { currentPolicy, editPreApproval, history, location } = this.props;

    const payload = {
      ...newPreApp,
      policy_id: currentPolicy?.id,
      id: location?.state?.data?.id,
      // submission_type: 'member_portal',
      ...data,
    };

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key === 'claimed_amount') {
        payload[key] = payload[key].replaceAll(',', '');
      }
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
        });
        return;
      }
      formData.append(key, payload[key]);
    });

    // deleted docs array
    if (deleted_docs?.length) {
      deleted_docs.forEach((item, index) => {
        formData.append(`deleted_docs[${index}]`, item);
      });
    }

    // POST REQUEST
    this.setState((pre) => ({ ...pre, loading: true }));
    const preApprovalData = await editPreApproval(formData);
    if (preApprovalData?.success) {
      if (data?.status === 'draft') {
        toast.custom(<Alert message={`Pre-Approval - Drafted successfully. Tracking number is ${preApprovalData?.reference_number}`} />);
        history.push('/preApprovals');
      } else {
        this.setState((pre) => ({
          ...pre,
          loading: false,
          success: true,
          mems_success: preApprovalData?.mems_success,
          ucrn: preApprovalData?.reference_number,
          draft: data?.status === 'draft',
        }));
      }
    } else {
      toast.custom(<Alert message={preApprovalData?.message} />);
      this.setState((pre) => ({ ...pre, loading: false, success: false, message: preApprovalData?.message }));
    }
  };

  // Method to remove existing files
  onFileRemoved = (file_id) => {
    console.log(file_id);
    if (file_id) {
      this.setState((pre) => ({
        ...pre,
        deleted_docs: [...(pre?.deleted_docs || []), file_id],
      }));
    }
  };

  /**
   * Render
   */
  render = () => {
    const { countryList, currencyList, active, loading, success, mems_success, ucrn, draft, message } = this.state;
    const { currentPolicy, location } = this.props;
    return (
      <Layout
        loading={loading}
        data={location?.state?.data}
        onContinue={this.onContinue}
        onSubmit={this.onSubmit}
        currencyList={currencyList}
        countryList={countryList}
        policyData={currentPolicy}
        active={active}
        setActive={this.setActive}
        success={success}
        memsSuccess={mems_success}
        referenceNumber={ucrn}
        draft={draft}
        message={message}
        onFileSelected={this.onFileSelected}
        onFileRemoved={this.onFileRemoved}
      />
    );
  };
}

EditPreApproval.propTypes = {
  viewDetails: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  getCurrencyList: PropTypes.func.isRequired,
  getCountryList: PropTypes.func.isRequired,
  editPreApproval: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: dispatch.policy.viewDetails,
  getCurrencyList: dispatch.pre_approval.getCurrencyList,
  getCountryList: dispatch.pre_approval.getCountryList,
  editPreApproval: dispatch.pre_approval.editPreApproval,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPreApproval);
