import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { errorMessages } from '../../../constants/messages';
import CustomFileInput from './CustomFileInput';
import config from '../../../constants/config';

const StepTwo = ({ loading, onSubmit, currencyList, countryList, onFileSelected, onFileRemoved, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [isDraft, setIsDraft] = useState(false);
  const handleSetPayload = (formdata, draft = false) => {
    setIsDraft(draft);
    if (draft) {
      onSubmit({ ...formdata, status: 'draft' });
    } else {
      onSubmit({ ...formdata, status: 'confirm' });
    }
  };

  const date = moment(new Date()).format('YYYY-MM-DD');

  useEffect(() => {
    reset({
      claim_type: data?.claim_type,
      country_id: data?.country_id,
      currency_id: data?.currency_id,
      expected_admission_date: data?.expected_admission_date
        ? moment(data?.expected_admission_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : null,
      comments: data?.comments,
    });
  }, [currencyList, countryList]);

  return (
    <Fragment>
      <Row className="justify-content-between mt-3">
        <Col md="5">
          <Label className="f-mlc-m display-3 tab-title">Pre-Approval details</Label>
        </Col>
      </Row>
      <hr />
      <Form onSubmit={handleSubmit((values) => handleSetPayload(values))} className="mt-3">
        <FormGroup>
          <Row className="justify-content-between mt-3">
            <Col md="6">
              <Label for="claim_type">PreApproval Type</Label>
              <Input
                type="select"
                name="claim_type"
                id="claim_type"
                disabled={loading}
                invalid={!!errors.claim_type}
                defaultValue="1"
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('claim_type', e.target.value)}
              >
                <option value="1">In-Patient</option>
                <option value="2">Out-Patient</option>
                <option value="3">Both</option>
              </Input>
              {errors.claim_type && <p className="invalid-feedback">{errors.claim_type.message}</p>}
            </Col>
          </Row>
          <p className="mt-3">
            (Note: If you were admitted to hospital but the stay was less than 12 hours, such cases are considered as
            'Day Care OutPatient')
          </p>
          <h3 className="mt-5">Pre-Approval Details</h3>
          <p>
            (Note: If the Country you would like to select is not in the list, It is a 'Sanctioned Country' and your
            Pre-Approval cannot be submitted online. Please send your original documents along with your Pre-Approval
            from the address listed in the Contact Us section)
          </p>
          <Row className="d-flex">
            <Col>
              <Label for="country">Country of Treatment</Label>
              <Input
                type="select"
                name="country_id"
                id="country_id"
                disabled={loading}
                invalid={!!errors.country_id}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('country_id', e.target.value)}
              >
                <option value="" selected disabled>
                  Select Country
                </option>
                {countryList?.map((country) => (
                  <option key={country.short_name} value={country.id}>
                    {country.short_name}
                  </option>
                ))}
              </Input>
              {errors.country_id && <p className="invalid-feedback">{errors.country_id.message}</p>}

              <Label for="claimed_amount" className="mt-3">
                Total Claim Amount
              </Label>
              <NumericFormat
                type="text"
                autoComplete="off"
                name="claimed_amount"
                id="claimed_amount"
                customInput={(props) => <Input {...props} />}
                allowLeadingZeros
                thousandSeparator=","
                defaultValue={data?.claimed_amount}
                placeholder="Total claim amount"
                className="form-control"
                disabled={loading}
                invalid={!!errors.claimed_amount}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('claimed_amount', e.target.value)}
              />
              {errors.claimed_amount && <p className="invalid-feedback">{errors.claimed_amount.message}</p>}

              <Label for="provider_name" className="mt-3">
                Provider Name
              </Label>
              <Input
                type="text"
                // readOnly={!editContact}
                name="provider_name"
                id="provider_name"
                defaultValue={data?.provider_name ?? ''}
                placeholder="provider name"
                disabled={loading}
                invalid={!!errors.provider_name}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('provider_name', e.target.value)}
              />
              <p className="invalid-feedback">{`${errors.provider_name ? 'Provider name required' : ''}`}</p>
            </Col>
            <Col>
              <Label for="currency_id">Currency</Label>
              <Input
                type="select"
                name="currency_id"
                id="currency_id"
                disabled={loading}
                invalid={!!errors.currency_id}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('currency_id', e.target.value)}
              >
                <option value="" selected disabled>
                  Select Currency
                </option>
                {currencyList?.map((currency) => (
                  <option key={currency.currency} value={currency.id}>
                    {currency.name}
                  </option>
                ))}
              </Input>
              {errors.currency_id && <p className="invalid-feedback">{errors.currency_id.message}</p>}

              <Label for="total_claim_amount" className="mt-3">
                Treatment Start Date
              </Label>
              <Input
                type="text"
                name="expected_admission_date"
                id="expected_admission_date"
                defaultValue={data?.expected_admission_date ?? null}
                min={date}
                placeholder="Treatment Start Date"
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                onBlur={(e) => {
                  if (!e.target.value) e.target.type = 'text';
                }}
                disabled={loading}
                invalid={!!errors.expected_admission_date}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('expected_admission_date', e.target.value)}
              />
              <p className="invalid-feedback">
                {!(date >= data?.expected_admission_date)
                  ? errors.expected_admission_date
                    ? errors.expected_admission_date.message
                    : ''
                  : 'Invalid date'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label for="claimed_amount" className="mt-3">
                Pre-Approval Additional Info
              </Label>
              <Input
                type="textarea"
                autoComplete="off"
                name="additional_info"
                id="additional_info"
                defaultValue={data?.additional_info ?? ''}
                style={{ height: '200px' }}
                placeholder="Pre-Approval additional info"
                className="form-control"
                disabled={loading}
                innerRef={register()}
                onChange={(e) => setValue('additional_info', e.target.value)}
              />
              {/* {errors.claimed_amount && <p className="invalid-feedback">{errors.comment.message}</p>} */}
            </Col>
          </Row>
          <h3 className="mt-5">Documents</h3>
          <Row>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                type="docs"
                label="Upload Documents"
                defaultData={data?.documents}
                onFileRemoved={onFileRemoved}
              />
            </Col>
          </Row>
          <p>
            *Maximum file size allowed is 8MB/Document. Allowed file types .jpg, .jpeg, .tif, .bmp, .png, .doc, .docx,
            .txt, .pdf.
          </p>
          <Row>
            <Col>
              <div className="pl-4">
                <Input
                  type="checkbox"
                  name="declaration"
                  id="declaration"
                  disabled={loading}
                  invalid={!!errors.declaration}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('declaration', e.target.value)}
                />
                <Label for="declaration">
                  I have read and accept all the
                  <a href={`${config.baseURL}/claim-submission-disclaimer/AE`} className="btn-link" target="blank">
                    {' '}
                    Terms & conditions
                  </a>
                </Label>
                {errors.declaration && <p className="invalid-feedback">Please check this to continue</p>}
              </div>
            </Col>

            <Col className="text-right">
              <Button
                type="button"
                disabled={loading}
                color="outline-primary"
                className="mr-3"
                onClick={() => handleSetPayload(getValues(), true)}
              >
                {isDraft && loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="#007ABC"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'SAVE AS DRAFT'}
              </Button>
              <Button color="primary" disabled={loading}>
                {!isDraft && loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'SUBMIT'}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

StepTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFileSelected: PropTypes.func.isRequired,
  data: PropTypes.shape({
    claim_type: PropTypes.number,
    country_id: PropTypes.number,
    claimed_amount: PropTypes.string,
    currency_id: PropTypes.number,
    expected_admission_date: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    comments: PropTypes.string,
    provider_name: PropTypes.string,
    additional_info: PropTypes.string,
  }).isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

StepTwo.defaultProps = {};

export default withRouter(StepTwo);
