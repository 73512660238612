import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import Layout from '../components/Profile/Profile';
import Alert from '../components/UI/Alert';
import Encryption from '../encryption/encryption';
// import style from '../assets/styles/ToasterStyle';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      message: null,
      success: null,
      newClaim: null,
      formSuccess: null,
      loading: false,
      listLoading: false,
      data: {},
      bankDeleteLoading: false,
    };
  }

  async componentDidMount() {
    console.log('profile');
    this.setState({ listLoading: true });
    const { location } = this.props;
    if (location?.state?.userId) {
      if (location?.state?.isPrincipalMember) {
        this.getUserProfile();
      } else {
        this.getUserProfile(location?.state?.userId);
      }
    } else {
      this.getUserProfile();
    }
    this.getBankList();
    this.getDependents();
    this.getCountryList();
    this.getCurrencyList();
    this.getStateList();
  }

  getUserProfile = async (id) => {
    this.setState({ data: {} });
    const { getUserProfile } = this.props;
    // const { users } = this.state;
    const { data } = await getUserProfile(id);
    console.log(data);
    await this.setState({
      listLoading: false,
      data,
      userId: data?.id,
      isPrincipleUser: data?.is_principle_user,
      // filterUser: this.filterUsers(data?.card_number, users),
    });
  };

  submitUserProfile = async (profile) => {
    this.setState({ loading: true });
    const { submitUserProfile, getPolicyDetails, currentPolicy } = this.props;
    const { userId, isPrincipleUser } = this.state;
    const profileData = profile;
    if (profileData?.bank_id) {
      profileData.payment_method = 0;
    }
    this.setState({ loading: true });
    if (!isPrincipleUser) {
      profileData.dependent_id = userId;
    }
    return submitUserProfile(profileData)
      .then((res) => {
        if (res?.success) {
          if (currentPolicy) {
            getPolicyDetails(currentPolicy);
          }
          this.setState({
            data: res?.data,
            success: res?.success,
            message: res?.message,
            error: false,
            loading: false,
          });
        } else {
          this.setState({ error: true, message: res?.message, success: res?.success, loading: false });
        }
        return res;
      })
      .catch((error) => error);
    // await toast.promise(submitUserProfile(profileData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     this.setState({ data: e?.data, success: e?.success, message: e?.message, error: false, loading: false });
    //     return <b>{e?.message}</b>;
    //   },
    //   error: (e) => {
    //     this.setState({ error: true, message: e?.message, success: e?.success, loading: false });
    //     return <b>{e?.message}</b>;
    //   },
    // });
  };

  submitLoginCredentials = async (loginData) => {
    const { submitLoginCredentials, metaData } = this.props;

    this.setState({ loading: true });

    const publicKeyData = await metaData();
    const oldPasswordEncrypted = Encryption.encrypt(publicKeyData?.data, loginData?.old_password);
    const passwordEncrypted = Encryption.encrypt(publicKeyData?.data, loginData?.password);
    const payload = {
      old_password: oldPasswordEncrypted,
      password: passwordEncrypted,
    };

    if (publicKeyData?.success) {
      return submitLoginCredentials(payload)
        .then((res) => {
          if (res?.success) {
            this.setState({
              success: res?.success,
              message: res?.message,
              error: false,
              loading: false,
            });
          } else {
            this.setState({
              success: res?.success,
              message: res?.message,
              error: true,
              loading: false,
            });
          }
          return res;
        })
        .catch((error) => {
          throw error;
        });
    }
    this.setState({
      success: publicKeyData?.success,
      message: publicKeyData?.message,
      error: true,
      loading: false,
    });
    throw publicKeyData?.messsage;
    // const { success, message } = await submitLoginCredentials(loginData);
    // await toast.promise(submitLoginCredentials(loginData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     this.setState({ success, message: 'Successfully Updated', type: 7, error: false });
    //     return <b>{e?.message}</b>;
    //   },
    //   error: (e) => {
    //     this.setState({ error: true, message, success, type: 7 });
    //     return <b>{e?.message}</b>;
    //   },
    // });
  };

  /**
   * get bank list
   */
  getBankList = async () => {
    const { getBankList } = this.props;
    try {
      const { success, data } = await getBankList();
      if (success) {
        this.setState((prev) => ({ ...prev, bankList: data }));
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getDependents = async () => {
    const { getDependents } = this.props;
    const { success, data } = await getDependents();
    console.log(success);
    if (success) {
      this.setState({
        users: data,
        filterUser: this.filterUsers(data?.card_number, data),
      });
    }
  };

  /**
   * get currency list
   */
  getCurrencyList = async () => {
    const { getCurrencyList } = this.props;
    try {
      const { success, data } = await getCurrencyList();
      if (success) {
        this.setState((prev) => ({ ...prev, currencyList: data }));
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        this.setState((prev) => ({ ...prev, countryList: data }));

        this.setState((prev) => {
          const countryId =
            prev?.countryList
              ?.filter((e) => e?.id.toString() === prev?.data?.info?.residing_country)[0]
              ?.id.toString() || '234';
          return {
            data: { ...prev?.data, info: { ...prev?.data?.info, residing_country: countryId } },
          };
        });
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  addBankAccount = async (addBankData) => {
    const { addBankAccount } = this.props;
    try {
      this.setState((val) => ({ ...val, loading: true }));
      const { success, message } = await addBankAccount(addBankData);
      if (success) {
        const { getUserProfile } = this.props;
        const { data } = await getUserProfile();
        await this.setState({ loading: false, data });
        toast.custom(<Alert message="Bank Account Added" />);
      } else {
        toast.custom(<Alert message={message} />);
      }
      this.setState((val) => ({ ...val, loading: false }));
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  deleteBank = async (bankId) => {
    const { getUserProfile, deleteBank } = this.props;
    console.log(bankId);
    this.setState((val) => ({ ...val, bankDeleteLoading: true }));
    const { success, message } = await deleteBank(bankId);
    if (success) {
      const { data } = await getUserProfile();
      this.setState({
        data: { ...data, bank_accounts: data?.bank_accounts },
      });
      toast.custom(<Alert message="Bank Account Deleted" />);
    } else {
      toast.custom(<Alert message={message} />);
    }
    this.setState((val) => ({ ...val, bankDeleteLoading: false }));
  };

  getStateList = async () => {
    const { getStates } = this.props;
    const { success, data } = await getStates();
    if (success) {
      this.setState((prev) => ({ ...prev, stateList: data }));
    }
  };

  showToast = (message, success) => {
    toast.custom(<Alert message={message} />);
    console.log(success);
  };

  /* eslint-disable */
  filterUsers = (cardNumber, data) => {
    return data?.length ? data?.filter((e) => e?.card_number !== cardNumber && e?.policy_status === 1) : [];
  };

  photoUpload = async (photoUploadData) => {
    const { photoUpload } = this.props;
    await toast.promise(photoUpload(photoUploadData), {
      loading: 'Saving...',
      success: (e) => {
        console.log(e);
        this.setState((prev) => ({
          ...prev,
          data: {
            ...prev?.data,
            image_path: e?.data?.image_path,
            users: prev?.users.map((element) => {
              const usersData = element;
              if (element.id === e.data.id) {
                usersData.image_path = e?.data?.image_path;
              }
              return usersData;
            }),
          },
          success: e?.success,
          error: false,
          loading: false,
        }));
        return <b>Photo uploaded</b>;
      },
      error: (e) => {
        this.setState({ error: true, message: e?.message, success: e?.success, loading: false });
        return <b>{e?.message}</b>;
      },
    });
  };

  /**
   * Render
   */
  render = () => {
    const {
      error,
      loading,
      success,
      message,
      type,
      formSuccess,
      isPrincipleUser,
      bankList,
      users,
      countryList,
      currencyList,
      stateList,
      data,
      listLoading,
      bankDeleteLoading,
    } = this.state;

    const menu = [
      {
        id: 1,
        menu: 'Personal Details',
        url: 'personalDetails',
        active: true,
      },
      {
        id: 2,
        menu: 'Work and Residence',
        url: 'workAndResidentialDetails',
        active: false,
      },
      {
        id: 3,
        menu: 'Visa Information',
        url: 'visaInformation',
        active: false,
      },
      {
        id: 4,
        menu: 'Sponsor Details',
        url: 'sponsorDetails',
        active: false,
      },
      {
        id: 5,
        menu: 'Salary and other info',
        url: 'salaryAndOtherInfo',
        active: false,
      },
      {
        id: 6,
        menu: 'Reimbursement Claims',
        url: 'RIClaims',
        active: false,
      },
      {
        id: 7,
        menu: 'Login Credentials',
        url: 'loginCredentials',
        active: false,
      },
      // {
      //   id: 8,
      //   menu: 'Preferences',
      //   url: 'newsletter',
      //   active: false,
      // },
    ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        type={type}
        formSuccess={formSuccess}
        menu={menu}
        data={data}
        isPrincipleUser={isPrincipleUser}
        getUserProfile={this.getUserProfile}
        submitUserProfile={this.submitUserProfile}
        submitLoginCredentials={this.submitLoginCredentials}
        addBank={this.addBankAccount}
        users={users}
        getBankList={bankList}
        photoUpload={this.photoUpload}
        getCurrencyList={currencyList}
        getCountryList={countryList}
        deleteBank={this.deleteBank}
        stateList={stateList}
        props={this.props}
        listLoading={listLoading}
        bankDeleteLoading={bankDeleteLoading}
      />
    );
  };
}

Profile.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  submitUserProfile: PropTypes.func.isRequired,
  submitLoginCredentials: PropTypes.func.isRequired,
  addBankAccount: PropTypes.func.isRequired,
  getDependents: PropTypes.func.isRequired,
  getBankList: PropTypes.func.isRequired,
  getCurrencyList: PropTypes.func.isRequired,
  getCountryList: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  photoUpload: PropTypes.func.isRequired,
  getPolicyDetails: PropTypes.func.isRequired,
  currentPolicy: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      userId: PropTypes.number.isRequired,
      isPrincipalMember: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  deleteBank: PropTypes.func.isRequired,
  metaData: PropTypes.func.isRequired,
  bankDeleteLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentPolicy: state.policy.currentPolicy || null,
  bankDeleteLoading: false,
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: dispatch.member.getUserProfile,
  metaData: dispatch.encryption.metaData,
  submitUserProfile: dispatch.member.submitUserProfile,
  submitLoginCredentials: dispatch.member.submitLoginCredentials,
  addBankAccount: dispatch.claim.addBankAccount,
  getDependents: dispatch.member.getDependents,
  getBankList: dispatch.claim.getBankList,
  photoUpload: dispatch.member.uploadPhoto,
  getPolicyDetails: dispatch.policy.getPolicyDetails,
  getCurrencyList: dispatch.claim.getCurrencyList,
  getCountryList: dispatch.claim.getCountryList,
  deleteBank: dispatch.claim.deleteBank,
  getStates: dispatch.member.getStates,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
