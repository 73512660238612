const { host } = window.location;

/**
 * Environments
 */
let env = 'production'; // Defaults to production
if (process.env.NODE_ENV === 'development' || host.includes('local')) env = 'development';
if (host.includes('stage.')) env = 'stage';

const baseURL = env === 'production' ? 'https://member.mlportal.com/apis' : 'http://localhost/mednet-app-api/public';
/**
 * Config object to export
 */
export default {
  // App Details
  appName: 'ReactStarterKit',

  // Build Configuration - eg. Debug or Release?
  isDevEnv: env === 'development',
  ENV: env,

  // Date Format
  dateFormat: 'Do MMM YYYY',

  baseURL,
  //
  apiBaseUrl: `${baseURL}/api`,
  // apiBaseUrl: (env !== 'production')
  // ? 'https://member.mlportal.com/api'
  // : 'https://mobile-demo.mednet.com/healthpass-api/api/',
  // : 'http://localhost:8000/api/',
  // : 'https://mobile-demo.mednet.com/metlife/api',
  // Google Analytics - uses a 'dev' account while we're testing
  gaTrackingId: env === 'production' ? 'UA-84284256-2' : 'UA-84284256-1',
};
