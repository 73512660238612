import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import Template from './Templates/Home';

const PrivacyPolicy = () => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Privacy Policy', active: true },
  ];

  return (
    <Template pageTitle="Privacy Policy" breadcrumb={breadcrumb}>
      <Container fluid className="max-container text-justify">
        <p>
          Please read the Terms of Use in in conjunction with this privacy policy (the “Policy”). The terms used and
          defined in the Terms of Use shall have the same meaning herein.
        </p>
        <p>
          We provide this Policy to explain MetLife&#39;s privacy and data collection practices along with how we
          protect your information. For clarity, this Policy applies to both our website and to our MetLife Mobile
          Application (both hereinafter referred to as the “Website”). The Website provide customers with gateways to
          services (“Service”) offered by American Life Insurance Company - MetLife and its subsidiaries and affiliates
          and partners (individually, &quot;MetLife&quot; and collectively &quot;MetLife Companies&quot;, &quot;we&quot;
          or &quot;us&quot;). The term “you” or “I” refers to the user of the Website and Services
        </p>
        <p>
          MetLife are committed to protecting and respecting your privacy in connection with your use to the Services.
          This Policy set forth the basis on which any personal data we collect from you, or that you provide to us, in
          connection with the Services will be processed by us. Please read the following carefully to understand our
          practices regarding your personal data and how we will collect, use and disclose your personal data.
        </p>
        <br />
        <br />
        <p>
          Our data collection and use policies with respect to visitors to the Website are set forth in this (“Policy”).
          By using the Website, you signify your acceptance of this Policy.
        </p>
        <p>
          <b>What is &quot;personally identifiable information?&quot;</b>
          <br />
          When reviewing this Policy, you should understand that when we use the term &quot;personally identifiable
          information&quot; we mean any information that can be used to identify or contact a specific individual, such
          as a name, address, telephone number or e-mail address. When we refer to &quot;aggregate information&quot; we
          mean information that does not allow us to identify or contact a specific individual such as demographic
          information, purchase categories (e.g., life insurance), or visitors using a particular browser to access the
          Website.
        </p>
        <p>Data We May Collect</p>
        <p>We may collect and process the following data about you:</p>
        <p>
          <ul>
            <li>
              Personal data, for example, your name, e-mail address, password, and in certain instances, telephone
              number, data about your usage of the Services, data you provide in connection with your usage of the
              Services that may identify you as an individual or allow online contact with you as an individual.
            </li>
            <li>Device information such as operating system version, device type, and system performance data.</li>
            <li>Data collected via tracking technologies, as described below.</li>
            <li>
              All the information you need to fill in the open text fields during account registration is mandatory. You
              agree that the personal data you provide about yourself to us will be accurate, complete and unequivocal.
            </li>
            <li>
              We may collect data/information from your phone called Log Data. This Log Data may include information
              such as your device Internet Protocol (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and date of your use of the Service, and
              other relevant statistics. The information that we request will be retained by us and used as described in
              this Policy.
            </li>
            <li>
              We may access certain data stored on your device, including images from your phone's gallery, folders
              containing stored documents, and location information. This data is utilized to facilitate functions such
              as claim submissions, user profile setup, and the search for medical providers. Additionally, we collect
              contact data to enhance user experience by enabling the storage of provider contacts for direct inquiries.
              The data is used solely to improve your app experience and will not be shared with any third parties
              without your consent.
            </li>
          </ul>
        </p>
        <h5 className="mt-5 h2">Does MetLife collect any information about visitors to the Website?</h5>
        <p>
          <b>Our Use of Cookies </b>
          <br />
          Cookies are small pieces of information that our server places on a visitor&#39;s computer hard drive to
          enable that person to more easily communicate and interact with the Website and to provide us with important
          information on how visitors use the Website.
        </p>
        <p>
          The Website uses cookies to allow you to select a preferred location view and default home page view and to
          let you access recently visited links (such as products and services) more easily. Many Web browsers are
          automatically set to accept cookies. You may change your browser settings to either reject cookies or notify
          you when a cookie is about to be placed on your computer. Please note, however, that rejecting cookies while
          visiting the Website may result in certain parts of the Website not operating correctly or as efficiently as
          if they were allowed. You will not be able to access a preferred location view, a customized default home page
          view or your recently visited links. In addition, we may use a service that collects data remotely by using
          so-called &quot;pixel tags,&quot; &quot;web beacons,&quot; &quot;clear GIFs&quot; or similar means
          (collectively, &quot;Pixel Tags&quot;) embedded in the Website&#39;s content. A Pixel Tag is an electronic
          image, often a single pixel (1x1), that is ordinarily not visible to Website visitors and may be associated
          with cookies on the visitors&#39; hard drives. The data we collect includes which operating system and browser
          you use, how you navigate to and through our site, and how long you stay on our web pages. This information is
          anonymous and does not include your name, e-mail address or any other contact information.
        </p>
        <h5 className="mt-5 h2">
          {' '}
          Does MetLife collect or share personally identifiable information with other companies?
        </h5>
        <p>
          MetLife collects personally identifiable information on the Website.  However, when we use other companies to
          provide services for us, we require them to protect the confidentiality of personal information they receive.
        </p>
        <br />
        <br />
        <p>We use information held about you in the following ways:</p>
        <p>
          <ul>
            <li>To provide you with the Services.</li>
            <li>To answer your questions or requests for information or handle your complaints.</li>
            <li>
              To ensure that content provided by the Services is presented in the most effective manner for you and for
              your Device.
            </li>
            <li>
              To provide you with promotional communications, such as email, to the extent that you have provided
              consent and/or consistent with notice and any opt-out rights to receive such communications under
              applicable law.
            </li>
            <li>To carry out our obligations arising from any agreements entered into between you and us.</li>
            <li>To notify you about updates or changes to Services features and content.</li>
            <li>
              To provide you with the SMS Service to the extent that you have provided consent for such practices under
              applicable law. We may also ask you to complete surveys that we use for research purposes, although you do
              not have to respond to them.
            </li>
          </ul>
        </p>
        <p>
          MetLife is a MetLife, Inc. company, and as such, your personally identifiable information may also be shared
          with other MetLife companies and as such you hereby grant Us unambiguous consent, to process, share, and
          transfer Your personal data to any recipient whether inside or outside the country, including but not limited
          to Our Headquarters in the USA, Our branches, affiliates, Reinsurers, business partners, professional
          advisers, insurance brokers and/or service providers where we believe that the transfer or share, of such
          personal data is necessary for: (i) the performance of the Policy; (ii) assisting Us in the development of our
          business and products; (iii) improving Our customers experience; (iv) for the compliance with the applicable
          laws and regulations; or (v) for the compliance with other law enforcement agencies for international
          sanctions and other regulations applicable to Us. We will ensure that such recipients will have sufficient
          confidentiality obligations to procure the confidentiality of the personal information and provided We
          complies with applicable laws in respect of such processing, sharing and transferring of that personal data.
          For clarity, personal data means any data/information related to You and/or Your family which might include
          any health, identity and financial information or contact details, disclosed to Us at any time..
        </p>
        <h5 className="mt-5 h2">What does MetLife do with aggregate information collected on the Website?​</h5>
        <p>
          Because aggregate information collected on the Website does not personally identify you, we may use aggregate
          information for any purpose, and share aggregate information with third parties for any purpose. We may share
          the information gathered using cookies, Pixel Tags and other similar technologies with other MetLife companies
          and with our third-party vendors. The use of this data is to improve our site by responding to our users&#39;
          interests and providing more relevant and useful information. For example, we use aggregate information
          collected on the Website to evaluate how the Website is being used. In addition, we may collect and analyze
          information about how many visitors arrived at certain pages of the Website, how long they stayed on those
          pages, and where they went afterwards. This information allows us to determine which pages might be the most
          interesting and popular with visitors. It enables us to provide visitors with a better and richer website
          experience.
        </p>
        <h5 className="mt-5 h2">
          What if I link to another site and provide information to that site, how will that information be handled?
          What sites are covered by this Website Privacy Policy?
        </h5>
        <p>
          This Privacy Policy applies to the MetLife website:
          <a href="https://mymetlife.net/polWeb/public" target="_blank" rel="noreferrer">
            {' '}
            https://mymetlife.net/polWeb/public.
          </a>
          <p>
            It does not apply to any linked sites (including MetLife sites). To learn about the privacy practices of a
            linked site, please refer to the privacy policy for that site.
          </p>
        </p>
        <h5 className="mt-5 h2">What information can third parties collect through the Website?​</h5>
        <p>
          We may use third party advertisement-serving companies (&quot;Advertisers&quot;) to serve advertisements on
          the Website. Please be advised that such Advertisers may use cookies, Pixel Tags and other technologies to,
          among other things, enable such Advertisers to monitor which ads have been served to your browser and which
          Website pages you were viewing when such ads were delivered.
        </p>
        <h5 className="mt-5 h2">How does MetLife protect website information?​</h5>
        <p>
          The security of your personal data is important to us. We follow generally accepted standards to protect the
          personal data submitted to us, both during transmission and once it is received. MetLife maintains technical,
          administrative and physical safeguards to help protect the security of your personal information against
          unauthorized access, destruction, loss, alteration, misuse or disclosure. Your personal information is
          accessible to only a limited number of personnel who need access to the information to perform their duties.
          Please note, however, that no method of transmission over the Internet or method of electronic storage is 100%
          secure.
        </p>
        <h5 className="mt-5 h2">Changes to MetLife's Website Privacy Policy​</h5>
        <p>
          MetLife may modify this Privacy Policy at any time to comply with applicable laws, or to reflect our updated
          business practices, without prior notice. In the event of a change, we will post the revised Policy to the
          Website with the effective date.
        </p>
        <h5 className="mt-5 h2">
          <u>Use of MetLife Mobile Application:</u>
        </h5>
        <p>
          The MetLife Mobile Application provides users (the “Users”) of the Application with an enhanced online digital
          experience and gives them access to perform services related to their insurance policy. The MetLife Mobile
          Application enables Users to submit claims, view claim history, and access their medical card, provides
          regulatory information, provides a searchable list of medical providers and more.
        </p>
        <p>
          For the use of this MetLife Mobile Application, and for the User to submit claims, set up personal user
          profiles and conduct a search of the medical provider list, MetLife requires access to users’ images from
          their phone gallery, users’ folders with stored documents and contacts’ list. By making use of the
          Application, you are therefore deemed to have granted consent to MetLife for such access.
        </p>
        <p>
          We collect contact data to store provider contacts for direct inquiries. We assure your data is used
          solely to enhance your app experience and is not shared with third parties without your consent.
        </p>
        <br />
        <br />
        <p>
          Contact Us If you have any queries about our Policy, you can contact us at 800 638 5433. If you are calling
          from outside the UAE please contact us at +971 4 415 4555.
        </p>
      </Container>
    </Template>
  );
};

export default withRouter(PrivacyPolicy);
