import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Layout from '../components/PolicyDetails/Services/DownloadForm/DownloadForm';
import Alert from '../components/UI/Alert';

class DownloadForm extends Component {
  constructor() {
    super();
    this.state = { forms: [], error: null, success: null, loading: false };
  }

  async componentDidMount() {
    const { getAllForms } = this.props;
    this.setState({ loading: true });
    const { success, data } = await getAllForms();
    if (success) {
      this.setState({ forms: data, loading: false });
    }
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Beneficiary');
    const { onFormSubmit } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const success = await onFormSubmit(data);
      this.setState({ success, error: null, loading: false });
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  downloadForms = async (type, title) => {
    const { downloadForms } = this.props;
    // const pdf = await downloadForms(type);
    toast.promise(downloadForms(type), {
      loading: (
        <Alert message="Please wait while the form is downloading" isOpen />
      ),
      success: (pdf) => {
        console.log(typeof pdf.type);
        if (pdf?.type === 'application/pdf') {
          // const file = new Blob([...pdf], { type: 'application/pdf' });
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(pdf);
          link.download = `${title}.pdf`;
          link.click();
          return (
            <Alert message="Form has downloaded" />
          );
        }
        this.setState((state) => ({ ...state, ecardDownload: false }));
        return (
          <Alert message={pdf?.message || 'Unable to download Form'} />
        );
      },
      error: 'Unable to download Form',
    });
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, policyData } = this.props;
    const { error, loading, success, forms } = this.state;

    // const downloadFormsData = [
    //   {
    //     id: 1,
    //     name: 'PreDetermination',
    //     type: 'pre-determination',
    //     url: '',
    //   },
    //   {
    //     id: 2,
    //     name: 'Medical Claim Form',
    //     type: 'medical-claim-form',
    //     url: '',
    //   },
    // ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        data={forms}
        downloadForms={this.downloadForms}
        policyData={policyData}
      />
    );
  };
}

DownloadForm.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  policyData: PropTypes.shape({}).isRequired,
  downloadForms: PropTypes.func.isRequired,
  getAllForms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  policyData: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.articles.save,
  downloadForms: dispatch.member.downloadForms,
  getAllForms: dispatch.member.getAllForms,
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadForm);
