import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import InputField, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Alert, Form, FormGroup, Button, Label, Spinner } from 'reactstrap';
import logo from '../assets/images/myMetlife-logo.png';
import mailLogo from '../assets/images/mail.png';
import Footer from './UI/Footer';

const AddPhoneNumber = ({ error, loading, success, message, onFormSubmit, defaultValues, verified }) => {
  const { register, control, handleSubmit, setValue } = useForm({ defaultValues });
  const [num, setNum] = useState(null);
  const [click, setClick] = useState(false);

  useEffect(() => {
    setNum(`+${defaultValues?.phone_number?.replace('-', '').replace('+', '')}`);
  }, []);

  return (
    <Container className="login full-width-page">
      <Helmet>
        <title>Add Phone Number</title>
      </Helmet>
      <Row>
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          {verified ? (
            <div className="msg-box" style={{ maxHeight: 'initial', height: 'auto' }}>
              <img className="logo" src={mailLogo} alt="" style={{ width: '80px', marginBottom: '10px' }} />
              <h2 style={{ marginBottom: 0 }}>50% Completed</h2>
              <h3>Check your inbox</h3>
              <h3 style={{ fontWeight: '400' }}>{message ?? 'We have sent you an email to your registered email address with a link to set your new password. Please check your email and follow the link to set your new password.'}</h3>
              <Link to="/">
                <h3>Login</h3>
              </Link>
            </div>
          ) : (
            <>
              <h1>Phone Number</h1>
              <h3>Please add your phone number to continue.</h3>
              <Form
                onSubmit={handleSubmit((data) => {
                  setClick(true);
                  if (num ? isValidPhoneNumber(num) : false) {
                    onFormSubmit(data);
                  }
                })}
              >
                {!!success && <Alert color="success">{message}</Alert>}
                {!!error && <Alert color="danger">{message}</Alert>}
                {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

                <FormGroup className="mt-3 form-label-group">
                  <Controller
                    name="phone_number"
                    id="phone_number"
                    control={control}
                    innerRef={register({
                      required:
                        click && num
                          ? isValidPhoneNumber(num)
                            ? ''
                            : 'Invalid phone number'
                          : 'Phone number required',
                    })}
                    render={() => (
                      <InputField
                        type="text"
                        // readOnly={!editContact}
                        name="phone_number"
                        id="phone_number"
                        placeholder="Phone Number"
                        international
                        withCountryCallingCode
                        className={`form-control ${
                          click ? (num ? (!isValidPhoneNumber(num) ? 'is-invalid' : '') : 'is-invalid') : ''
                          }`}
                        disabled={loading}
                        invalid={click && num ? !isValidPhoneNumber(num) : true}
                        error
                        value={num}
                        onChange={(e) => {
                          setNum(e);
                          setValue('phone_number', e);
                        }}
                      />
                    )}
                  />
                  <Label>Phone Number</Label>
                  <p className="invalid-feedback">
                    {`${
                      click
                      ? num
                        ? isValidPhoneNumber(num)
                          ? ''
                          : 'Invalid phone number'
                        : 'Phone number required'
                      : ''
                      }`}
                  </p>
                  <p className="phone-info">
                    Please enter your phone number prefixed with the country dialing code, without appending any zeroes.
                    Eg.(+971 123 1 1234)
                  </p>
                </FormGroup>

                <Button color="primary" disabled={loading} style={{ width: '100%', marginBottom: '15px' }}>
                  {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Update'}
                </Button>
              </Form>
            </>
          )}
          For Additional Assistance, Please Call
          <div className="bottom-links justify-content-center text-center">
            <Link to="/contactUs">1-866-937-MET(7387)</Link>
          </div>
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

AddPhoneNumber.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  defaultValues: PropTypes.shape({
    user_id: PropTypes.string,
    phone_number: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  verified: PropTypes.bool,
};

AddPhoneNumber.defaultProps = {
  error: null,
  success: null,
  message: null,
  loading: false,
  defaultValues: {},
  verified: false,
};

export default withRouter(AddPhoneNumber);
