import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/VerifyLoginOTP';
import Encryption from '../encryption/encryption';

class VerifyLoginOTP extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, message: null };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { verifyLoginOTP, history, me, location } = this.props;

    this.setState({ success: null, error: null, loading: true, message: null });

    try {
      if (location?.state?.publicKey) {
        const encryptedPassword = Encryption.encrypt(location?.state?.publicKey, data?.password);
        const payload = {
          ...data,
          password: encryptedPassword,
          browser_finger_print: location?.state?.browserFingerPrint,
        };
        const { success, message, token } = await verifyLoginOTP(payload);
        this.setState({ success, error: null, loading: false });
        if (success) {
          this.setState({
            success,
            error: null,
            loading: false,
            verified: true,
          });
          if (token) {
            me();
            history.push('/home');
          }
        } else {
          this.setState({ success, error: true, loading: false, message });
        }
      } else {
        this.setState({
          success: false,
          error: true,
          loading: false,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * Resend Login OTP
   */
  resendLoginOTP = async () => {
    const { resendLoginOTP, location } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      console.log(location?.state);
      if (location?.state?.publicKey) {
        const data = { ...location?.state };
        delete data?.publicKey;
        data.password = Encryption.encrypt(location?.state?.publicKey, data.password);
        const { success } = await resendLoginOTP(data);
        if (success) {
          this.setState({ success, message: 'New OTP has been sent.', error: null, loading: false });
        } else {
          this.setState({ success, error: true, message: 'Failed !!', loading: false });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { location } = this.props;
    const { error, loading, success, message, verified } = this.state;

    return (
      <Layout
        verified={verified}
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={location?.state}
        onFormSubmit={this.onFormSubmit}
        resendLoginOTP={this.resendLoginOTP}
      />
    );
  };
}

VerifyLoginOTP.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  verifyLoginOTP: PropTypes.func.isRequired,
  resendLoginOTP: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      password: PropTypes.string,
      email: PropTypes.string,
      publicKey: PropTypes.string,
      browserFingerPrint: PropTypes.string,
    }),
  }).isRequired,
  me: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: {
    phone_number: state.member.phoneNumber,
    user_id: state.member.userID,
    first_name: state.member.firstName,
    last_name: state.member.lastName,
  },
});

const mapDispatchToProps = (dispatch) => ({
  me: dispatch.member.me,
  verifyLoginOTP: dispatch.member.verifyLoginOTP,
  resendLoginOTP: dispatch.member.resendLoginOTP,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyLoginOTP);
