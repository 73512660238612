import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import Template from '../../Templates/Home';
import Title from '../Title';
import ProviderCard from './ProviderCard';
import Loading from '../../UI/Loading';

const SearchProvider = ({
  data,
  user,
  totalCount,
  cities,
  specialities,
  filter,
  facilities,
  countries,
  policyData,
  regions,
  // benefits,
  onFormSubmit,
  loading,
}) => {
  const { register, getValues, reset } = useForm({
    country_id: user?.country_id ?? '',
    city_id: '',
    search: '',
    facility_type_id: '',
    speciality_ids: '',
  });

  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Search Providers', active: true },
  ];

  return (
    <Template pageTitle="Search Provider" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData.expiryDate}
          >
            <Link
              to={{
                pathname: '/home/services',
              }}
              className="btn btn-outline-primary mr-3"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.688" viewBox="0 0 16 16.688">
                <path
                  id="icon_settings"
                  data-name="icon/settings"
                  d="M14.938,10.348a2.488,2.488,0,0,1-1.287-2.027,2.483,2.483,0,0,1,1.29-2.027c.448-.3.909-.575,1.372-.854L14.786,2.8c-.47.261-.939.522-1.423.76a2.487,2.487,0,0,1-2.4.1A2.491,2.491,0,0,1,9.85,1.534C9.815,1.026,9.807.513,9.8,0H6.75C6.74.513,6.731,1.026,6.7,1.534A2.489,2.489,0,0,1,5.586,3.667a2.487,2.487,0,0,1-2.4-.1c-.443-.219-.877-.458-1.308-.7L.352,5.511c.424.255.846.508,1.257.784A2.482,2.482,0,0,1,2.9,8.321a2.487,2.487,0,0,1-1.29,2.027c-.417.28-.847.538-1.279.8l1.525,2.644c.44-.246.879-.488,1.33-.709a2.485,2.485,0,0,1,2.4-.1A2.481,2.481,0,0,1,6.7,15.1c.034.527.044,1.057.052,1.584H9.8c.009-.522.018-1.057.052-1.584a2.484,2.484,0,0,1,1.112-2.13,2.486,2.486,0,0,1,2.4.1c.489.239.968.508,1.446.772l1.523-2.639C15.862,10.934,15.392,10.653,14.938,10.348Zm-6.607.083a2.086,2.086,0,1,1,2.086-2.086A2.086,2.086,0,0,1,8.331,10.432Z"
                  transform="translate(-0.33)"
                  fill="#117abc"
                />
              </svg>
              Services
            </Link>
          </Title>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row className="search-filter">
              <Col>
                <FormGroup className="form-label-group">
                  <Input
                    type="select"
                    name="country_id"
                    id="country_id"
                    placeholder="Territory"
                    onChange={(e) => {
                      filter(e.target.value, 'country_id');
                      reset({
                        country_id: e.target.value,
                        city_id: '',
                        region_id: '',
                      });
                    }}
                    innerRef={register()}
                  >
                    <option hidden>Select</option>
                    {countries?.map((element) => (
                      <option value={element?.id} selected={element?.id === user?.country_id}>
                        {element?.short_name}
                      </option>
                    ))}
                  </Input>
                  <Label>Territory</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-label-group">
                  <Input
                    type="select"
                    name="city_id"
                    id="city_id"
                    onChange={(e) => {
                      filter(e.target.value, 'city_id');
                    }}
                    innerRef={register()}
                  >
                    <option value="" selected>
                      All
                    </option>
                    {countries.length
                      ? cities?.map((element) => <option value={element?.id}>{element?.name}</option>)
                      : ''}
                  </Input>
                  <Label>City</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-label-group">
                  <Input type="select" name="region_id" id="region_id" innerRef={register()}>
                    <option value="" selected>
                      All
                    </option>
                    {regions?.map((element) => (
                      <option value={element?.id}>{element?.name}</option>
                    ))}
                  </Input>
                  <Label>Area</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-label-group">
                  <Input
                    type="text"
                    name="search"
                    id="search"
                    autoComplete="off"
                    placeholder="Provider Name"
                    innerRef={register()}
                  />
                  <Label>Provider Name</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-label-group">
                  <Input type="select" name="facility_type_id" id="facility_type_id" innerRef={register()}>
                    <option value="" selected>
                      All
                    </option>
                    {facilities?.map((element) => (
                      <option value={element?.id}>{element?.name}</option>
                    ))}
                  </Input>
                  <Label>Category</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-label-group">
                  <Input type="select" name="speciality_ids" id="speciality_ids" innerRef={register()}>
                    <option value="" selected>
                      All
                    </option>
                    {specialities?.map((element) => (
                      <option value={element?.id}>{element?.name}</option>
                    ))}
                  </Input>
                  <Label>Speciality</Label>
                </FormGroup>
              </Col>
              {/* <Col>
                <FormGroup className="form-label-group">
                  <Input type="select" name="benefit_id" id="benefit_id" innerRef={register()}>
                    <option value="" selected>
                      All
                    </option>
                    {benefits?.map((element) => (
                      <option value={element?.id}>{element?.name}</option>
                    ))}
                  </Input>
                  <Label>Benefits</Label>
                </FormGroup>
              </Col> */}
              <Col className="text-right">
                <Button
                  className="reset mr-3 btn-default"
                  onClick={() => {
                    reset({
                      country_id: user?.country_id,
                      city_id: '',
                      search: '',
                      facility_type_id: '',
                      speciality_ids: '',
                      region_id: '',
                      benefit_id: '',
                    });
                    filter(user?.country_id ?? '', 'country_id');
                    onFormSubmit(getValues(), false);
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 4C9.69818 4 7.62561 5.02187 6.19328 6.65856C5.82957 7.07417 5.1978 7.11624 4.78219 6.75253C4.36658 6.38881 4.32451 5.75705 4.68823 5.34144C6.47858 3.29564 9.08928 2 12 2C17.3671 2 21.6932 6.38925 21.7981 11.7989L22.285 11.3009C22.6711 10.906 23.3042 10.8989 23.6991 11.285C24.094 11.6711 24.1011 12.3042 23.715 12.6991L21.515 14.9491C21.3269 15.1415 21.0691 15.25 20.8 15.25C20.5309 15.25 20.2731 15.1415 20.085 14.9491L17.885 12.6991C17.4989 12.3042 17.506 11.6711 17.9009 11.285C18.2958 10.8989 18.9289 10.906 19.315 11.3009L19.7975 11.7943C19.691 7.45096 16.2203 4 12 4ZM3.2 8.75C3.46911 8.75 3.72687 8.85847 3.91501 9.05088L6.11501 11.3009C6.50112 11.6958 6.49401 12.3289 6.09912 12.715C5.70423 13.1011 5.07111 13.094 4.68499 12.6991L4.20252 12.2057C4.30903 16.549 7.77965 20 12 20C14.3018 20 16.3744 18.9781 17.8067 17.3414C18.1704 16.9258 18.8022 16.8838 19.2178 17.2475C19.6334 17.6112 19.6755 18.243 19.3118 18.6586C17.5214 20.7044 14.9107 22 12 22C6.6329 22 2.30681 17.6107 2.20195 12.2011L1.71501 12.6991C1.3289 13.094 0.69577 13.1011 0.300883 12.715C-0.0940045 12.3289 -0.101118 11.6958 0.284994 11.3009L2.48499 9.05088C2.67313 8.85847 2.93089 8.75 3.2 8.75Z"
                        fill="#293644"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
                <Button
                  className="search-provider"
                  onClick={() => {
                    onFormSubmit(getValues());
                  }}
                >
                  <svg id="Icon-Search" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                    <path
                      id="Icon_Search"
                      data-name="Icon/Search"
                      d="M18.752,21h0L21,18.795l-6.033-5.916a7.956,7.956,0,0,0,.67-8.452A8.331,8.331,0,0,0,.466,5.433,8.036,8.036,0,0,0,3.1,14.458a8.4,8.4,0,0,0,9.551.555L18.751,21ZM8.27,3.118a5.058,5.058,0,0,1,5.1,5,5.1,5.1,0,0,1-10.2,0A5.058,5.058,0,0,1,8.27,3.118Z"
                      transform="translate(5 5)"
                      fill="#fff"
                    />
                  </svg>
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <section className="metLife-gray">
          <Container fluid className="max-container search-provider-page">
            <Row className="search-result-header">
              {!!totalCount && (
                <Col>
                  <Label style={{ margin: '5px' }}>{totalCount}</Label>
                  <Label>Results</Label>
                  {totalCount ? (
                    <a
                      className="btn-btn-link ml-3 border-left pl-3"
                      onClick={() => {
                        onFormSubmit(getValues(), false, true);
                      }}
                    >
                      <b>Download</b>
                    </a>
                  ) : (
                    ''
                  )}
                </Col>
              )}
            </Row>
            <Row className="grid grid-2 search-provider-results">
              {data.length
                ? data.map((element) => (
                    <div key={element.key}>
                      <ProviderCard data={element} totalCount={totalCount} key={element.key} />
                    </div>
                  ))
                : null}
            </Row>
            <Row>
              {!totalCount && !loading ? (
                <Col>
                  <div className="no-results">
                    <Card
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <svg
                        className="noresults-searchicon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                      >
                        <path
                          id="Icon_Search"
                          data-name="Icon/Search"
                          d="M18.752,21h0L21,18.795l-6.033-5.916a7.956,7.956,0,0,0,.67-8.452A8.331,8.331,0,0,0,.466,5.433,8.036,8.036,0,0,0,3.1,14.458a8.4,8.4,0,0,0,9.551.555L18.751,21ZM8.27,3.118a5.058,5.058,0,0,1,5.1,5,5.1,5.1,0,0,1-10.2,0A5.058,5.058,0,0,1,8.27,3.118Z"
                          transform="translate(5 5)"
                          fill="#ddd"
                        />
                      </svg>
                      <CardBody className="pt-0">
                        <CardTitle tag="h5">No Results found</CardTitle>
                        <CardText>No content matched your criteria. Try searching for something else.</CardText>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              ) : loading ? (
                <Loading />
              ) : null}
            </Row>
          </Container>
        </section>
      </>
    </Template>
  );
};

SearchProvider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    country_id: PropTypes.number,
  }),
  totalCount: PropTypes.number,
  policyData: PropTypes.shape({
    policyNumber: PropTypes.string,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  specialities: PropTypes.arrayOf(PropTypes.shape({})),
  regions: PropTypes.arrayOf(PropTypes.shape({})),
  facilities: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  // benefits: PropTypes.arrayOf(PropTypes.shape({})),
  onFormSubmit: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SearchProvider.defaultProps = {
  data: [],
  user: {},
  totalCount: null,
  cities: [],
  specialities: [],
  regions: [],
  // benefits: [],
  facilities: [],
  countries: [],
  policyData: {},
  loading: true,
};

export default withRouter(SearchProvider);
