import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/ContactUs';

class VideoAndManuals extends Component {
  constructor() {
    super();
    this.state = {};
  }

  /**
   * Render
   */
  render = () => {
    const { policyData, authUser } = this.props;

    const data = [
      {
        id: 1,
        label: 'UAE',
        tollfree: '800 Benefits (23633487)',
        landline: '+971 45687309',
        outside: '+971 43758583',
      },
      {
        id: 1,
        label: 'Bahrain',
        tollfree: '80001801',
        landline: '+973 17536631',
        outside: '+973 17536766',
      },
      {
        id: 1,
        label: 'Oman',
        tollfree: '800 MCARE (62273)',
        landline: '+968 2456 5641',
        outside: '+968 2456 2273',
      },
    ];

    return <Layout contacts={data} policyData={policyData} authUser={authUser} />;
  };
}

VideoAndManuals.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  policyData: PropTypes.shape({}).isRequired,
  authUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    card_number: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  policyData: state.policy.currentPolicy || {},
  authUser: state.member.user || {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VideoAndManuals);
