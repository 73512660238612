import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Layout from '../components/PolicyDetails/Services/Certificates/Certificates';
import Alert from '../components/UI/Alert';

class Certificates extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  componentDidMount() {
    this.me();
    // this.getDependents();
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Beneficiary');
    const { onFormSubmit } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const success = await onFormSubmit(data);
      this.setState({ success, error: null, loading: false });
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  downloadCertificate = async (type, certificateName, id) => {
    const { downloadCertificate } = this.props;
    // toast.custom(<Alert message="'Please wait while the Certificate is downloading" />, {
    //   duration: 3000,
    // });
    // const pdf = await downloadCertificate({ type, id });
    toast.promise(downloadCertificate({ type, id }), {
      loading: (
        <Alert message="Please wait while the Certificate is downloading" isOpen />
      ),
      success: (pdf) => {
        console.log(typeof pdf.type);
        if (pdf?.type === 'application/pdf') {
          // const file = new Blob([...pdf], { type: 'application/pdf' });
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          this.setState((state) => ({ ...state, ecardDownload: false }));
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(pdf);
          link.download = 'Certificate.pdf';
          link.click();
          return (
            <Alert message="Certificate has downloaded" />
          );
        }
        this.setState((state) => ({ ...state, ecardDownload: false }));
        return (
          <Alert message={pdf?.message || 'Unable to download Certificate'} />
        );
      },
      error: (
        <div className="custom-alert alert alert-danger alert-dismissible">
          <span className="alert-icon">!</span>
          <h3>Error:</h3>
          Unable to download Certificate
          <button type="button" className="close">×</button>
        </div>
      ),
    });
    console.log(typeof pdf);
  };

  me = async () => {
    const { me } = this.props;
    const data = await me();
    console.log('user', data);
  };

  // getDependents = async () => {
  //   const { getDependents } = this.props;
  //   const { success, data } = await getDependents();
  //   console.log(success);
  //   if (success) {
  //     this.setState({
  //       users: data,
  //     });
  //   }
  // };

  /**
   * Render
   */
  render = () => {
    const { userInput, policyData, user } = this.props;
    const { error, loading, success } = this.state;

    const users = [user, ...policyData?.dependents];
    const certificates = [
      // {
      //   id: 1,
      //   name: 'Life Insurance Certificate',
      //   desc: 'Certificate',
      //   type: 'Certificate',
      //   url: '',
      // },
      {
        id: 2,
        name: 'Medical Insurance Certificate',
        desc: 'Certificate',
        type: 'Certificate',
        url: '',
      },
      // {
      //   id: 3,
      //   name: 'Temporary Medical Certificate',
      //   desc: 'Certificate',
      //   type: 'Certificate',
      //   url: '',
      // },
      // {
      //   id: 4,
      //   name: 'Medical Replacement',
      //   desc: 'Certificate',
      //   type: 'Certificate',
      //   url: '',
      // },
      // {
      //   id: 5,
      //   name: 'HAAD Medical Insurance Certificate',
      //   desc: 'Certificate',
      //   type: 'Certificate',
      //   url: '',
      // },
    ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        policyData={policyData}
        data={certificates}
        users={users}
        user={user}
        downloadCertificate={this.downloadCertificate}
      />
    );
  };
}

Certificates.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  policyData: PropTypes.shape({
    dependents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // getDependents: PropTypes.func.isRequired,
  downloadCertificate: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  policyData: state.policy.currentPolicy || {},
  userInput: state.articles.userInput || {},
  user: state.member.user,
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.articles.save,
  getDependents: dispatch.member.getDependents,
  downloadCertificate: dispatch.policy.downloadCertificate,
  me: dispatch.member.me,
});

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
