import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const PasswordExpiredAlert = ({ isOpenAlert, member_name, email, login }) => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const updatePassword = () => {
    // Push a new entry onto the history stack
    setIsOpen(!isOpen);
    history.push({ pathname: '/updatePassword', state: { email } });
  };

  useEffect(() => {
    setIsOpen(isOpenAlert);
  }, [isOpenAlert]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="modal-dialog-normal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader>
        Password Expired
      </ModalHeader>
      <ModalBody>

        {member_name && (
          <Label>
            Dear &nbsp;
            {member_name}
            ,
          </Label>
        )}
        <Label>
          To ensure your account safety, it is highly recommended to change your password at least every 6 months.
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button color="outline-primary" onClick={() => login()}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => updatePassword()}>
          Change Password
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PasswordExpiredAlert.propTypes = {
  isOpenAlert: PropTypes.bool,
  member_name: PropTypes.string,
  email: PropTypes.string,
  login: PropTypes.func.isRequired,
};

PasswordExpiredAlert.defaultProps = {
  isOpenAlert: false,
  member_name: null,
  email: null,
};

export default withRouter(PasswordExpiredAlert);
