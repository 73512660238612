import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Container, Label, Row, Col, CardTitle } from 'reactstrap';
import Template from '../../../Templates/Home';
import Title from '../../Title';
import Loading from '../../../UI/Loading';

const VideoAndManuals = ({ data, policyData, loading }) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Video And Manuals', active: true },
  ];
  return (
    <Template pageTitle="Video And Manuals" breadcrumb={breadcrumb}>
      <Container fluid className="max-container">
        <Title
          policyNumber={policyData?.policyNumber}
          certificateNumber={policyData?.certificateNumber}
          expiryDate={policyData?.expiryDate}
        />
      </Container>
      <section className="metLife-gray">
        <Container fluid className="max-container">
          <Row className="video-wrapper">
            {data?.length ? (
              data.map((item) => (
                <Col lg="6">
                  <Card className="mb-4">
                    <CardBody className="p-0">
                      <video id="video" width="100%" poster={item?.thumbnail} controls>
                        <source src={item.video_url} />
                        <track kind="captions" />
                      </video>
                      <Row className="m-0 pb-2">
                        <Col>
                          <Label className="mb-0">{item.name}</Label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : loading ? (
              <Col>
                <Loading />
              </Col>
            ) : (
              <Col>
                <div className="no-results">
                  <Card
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <svg
                      className="noresults-searchicon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                    >
                      <path
                        id="Icon_Search"
                        data-name="Icon/Search"
                        d="M18.752,21h0L21,18.795l-6.033-5.916a7.956,7.956,0,0,0,.67-8.452A8.331,8.331,0,0,0,.466,5.433,8.036,8.036,0,0,0,3.1,14.458a8.4,8.4,0,0,0,9.551.555L18.751,21ZM8.27,3.118a5.058,5.058,0,0,1,5.1,5,5.1,5.1,0,0,1-10.2,0A5.058,5.058,0,0,1,8.27,3.118Z"
                        transform="translate(5 5)"
                        fill="#ddd"
                      />
                    </svg>
                    <CardBody className="pt-0">
                      <CardTitle tag="h5">No Results found</CardTitle>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </Template>
  );
};

VideoAndManuals.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
};

VideoAndManuals.defaultProps = {
  data: [],
  loading: false,
};

export default withRouter(VideoAndManuals);
