export default {
  listPaginated: {
    1: [
      {
        placeholder: true,
        id: 0,
        name: '---- --- -- ------',
        content: '---- --- -- ------ ---- --- -- ------ ---- --- -- ------ ---- --- -- ------',
        excerpt: '---- --- -- ------ ---- --- -- ------ ---- --- -- ------ ---- --- -- ------',
        image: 'https://www.digitalsupply.co/wp-content/uploads/2018/03/glacier-blue.jpg',
        date: '-- / -- / ----',
        slug: '-----',
        link: '----.---.--/------',
      },
    ],
  },
  listFlat: [
    {
      placeholder: true,
      id: 0,
      name: '---- --- -- ------',
      content: '---- --- -- ------ ---- --- -- ------ ---- --- -- ------ ---- --- -- ------',
      excerpt: '---- --- -- ------ ---- --- -- ------ ---- --- -- ------ ---- --- -- ------',
      image: 'https://www.digitalsupply.co/wp-content/uploads/2018/03/glacier-blue.jpg',
      date: '-- / -- / ----',
      slug: '-----',
      link: '----.---.--/------',
    },
  ],
  meta: {
    page: 1,
    lastPage: null,
    total: null,
  },
  lastSync: {},
  pagination: [],
  userInput: { username: '' },
};
