import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Label } from 'reactstrap';

const DownloadCard = ({ item, downloadCertificate, downloadForms, id }) => (
  <Card
    key={item.id}
    className="shadow-none border"
    onClick={() => {
      console.log(item.url);
      if (item?.type === 'Certificate') {
        console.log(id);
        downloadCertificate(item?.id, item?.name, id);
      } else {
        downloadForms(item?.type, item?.title);
      }
    }}
    style={{ cursor: 'pointer' }}
  >
    <CardBody>
      <div className="align-items-center row">
        <span className="s-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24.646" height="32.887" viewBox="0 0 24.646 32.887">
            <g id="Documents" transform="translate(0.376 0.5)">
              <path
                id="Exclusion_3"
                data-name="Exclusion 3"
                d="M6.962,31.887h0C6.892,31.814.016,24.522,0,24.5L0,1.731A1.679,1.679,0,0,1,1.622,0H22.028a1.679,1.679,0,0,1,1.619,1.731v28.42a1.679,1.679,0,0,1-1.619,1.731ZM5.7,12.947v2.5H18.727v-2.5Zm0-7.12v2.5H18.727v-2.5Z"
                transform="translate(0.124)"
                fill="#007abc"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
              />
              <path
                id="Path_10954"
                data-name="Path 10954"
                d="M-4905.465-977.956h2.8a1.119,1.119,0,0,1,1.081,1.154v2.954"
                transform="translate(4905.465 1005.849)"
                fill="#007abc"
                stroke="#fff"
                strokeWidth="1.1"
              />
            </g>
          </svg>
        </span>
        <Col>
          <Label>{item.name ?? item?.title}</Label>
        </Col>
        <Button className="btn btn-link" color="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
            <g id="Icon_Download" data-name="Icon/Download" transform="translate(0 -0.3)">
              <rect id="Mask" width="38" height="38" transform="translate(0 0.3)" fill="none" />
              <path
                id="Bottom"
                d="M11.71,0h-8.3V.739A3.486,3.486,0,0,1,.065,4.357H15.448A3.573,3.573,0,0,0,18.972.739V0Z"
                transform="translate(16.175 28.149)"
                fill="#007abc"
              />
              <path
                id="Middle"
                d="M3.522,3.486A3.34,3.34,0,0,0,.235,0H17.476a4.81,4.81,0,0,1,4.836,4.784l.08,10.134v3.759H10.763v1.075a3.554,3.554,0,0,1-3.519,3.486h-.2a3.5,3.5,0,0,1-3.523-3.486v-3.4"
                transform="translate(6.945 6.364)"
                fill="#007abc"
              />
              <path
                id="Arrow"
                d="M0,4.353H3.626V0H5.081V4.353H8.715L4.353,8.713Z"
                transform="translate(15.544 12.174)"
                fill="#fff"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Corner"
                d="M2.614,0A2.685,2.685,0,0,0,0,2.723v.762H5.229V2.723A2.684,2.684,0,0,0,2.614,0"
                transform="translate(3.195 8.688)"
                fill="#007abc"
              />
            </g>
          </svg>
        </Button>
      </div>
    </CardBody>
  </Card>
);

DownloadCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    desc: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    file_path: PropTypes.string,
  }),
  downloadCertificate: PropTypes.func.isRequired,
  downloadForms: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

DownloadCard.defaultProps = {
  item: {},
};

export default withRouter(DownloadCard);
