import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import Template from './Templates/Home';
import { hasAuthToken } from '../lib/jwt';

const ContactUs = ({ authUser }) => {
  console.log(authUser);
  const [user, setUser] = useState({});
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Contact Us', active: true },
  ];

  useEffect(() => {
    setUser(authUser);
  }, [authUser]);

  const onChat = () => {
    let targetAddress = '';
    let deploymentKey = '';
    switch (authUser?.country_code) {
      case 'AE':
        targetAddress = 'MetLife_Chat_UAE';
        deploymentKey = 'dec9e663-ae39-4419-90c9-948cbd2f2c91';
        break;
      case 'OM':
        targetAddress = 'MetLife_Chat_Oman';
        deploymentKey = 'c1fd7c58-b35e-4758-b2b7-6d8b5ae8db73';
        break;

      default:
        targetAddress = '';
    }
    if (authUser?.country_code !== 'BH') {
      window.customPlugin(
        authUser.first_name,
        authUser.last_name,
        authUser.email,
        authUser.card_number,
        authUser.phone_number,
        targetAddress,
        deploymentKey,
        authUser?.vipstatus !== '1' ? 'N' : 'Y',
        authUser?.policy_number,
      );
    } else {
      window.customPlugin(
        authUser.first_name,
        authUser.last_name,
        authUser.email,
        authUser.card_number,
        authUser.phone_number,
        targetAddress,
        deploymentKey,
        authUser?.vipstatus !== '1' ? 'N' : 'Y',
        authUser?.policy_number,
        'BH',
        'https://chat-widget-loader.ximasoftware.com/chat/bb25ad9e-2b98-42ff-aeac-e042c566d80e',
      );
    }
  };

  return (
    <Template pageTitle="Contact Us" breadcrumb={breadcrumb}>
      <section className="">
        <Container fluid className="max-container contact-page">
          <Row className="d-flex gap-2 justify-content-center">
            {hasAuthToken() && !['KW', 'QA'].includes(user?.country_code) && (
              <Col className="" md="4">
                <Card className="px-3 py-4 align-items-center my-3">
                  <svg
                    id="Icon_ContactUsHeadphone"
                    data-name="Icon/ContactUsHeadphone"
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="81"
                    viewBox="0 0 80 81"
                  >
                    <path
                      id="Icon_ContactUsHeadphone-2"
                      data-name="Icon/ContactUsHeadphone"
                      d="M75.051,31.142a30.165,30.165,0,0,0-9.458-20.25A33.925,33.925,0,0,0,42.38,1.8a34.73,34.73,0,0,0-23.214,9.092,31.046,31.046,0,0,0-9.458,20.25A17.792,17.792,0,0,0,2.4,45.193C2.4,54.7,10.568,62.55,20.455,62.55a3.324,3.324,0,0,0,3.439-3.306v-28.1a3.33,3.33,0,0,0-3.439-3.306,7.587,7.587,0,0,0-3.009.413,25.715,25.715,0,0,1,49.867,0c-.86,0-2.149-.413-3.009-.413a3.324,3.324,0,0,0-3.439,3.306v28.1A3.33,3.33,0,0,0,64.3,62.55a8.694,8.694,0,0,0,3.439-.413V70.4A3.324,3.324,0,0,1,64.3,73.708H57.856a3.726,3.726,0,0,0-3.439-2.48H41.95a3.324,3.324,0,0,0-3.439,3.306v4.959A3.33,3.33,0,0,0,41.95,82.8H54.417a3.252,3.252,0,0,0,3.439-2.48H64.3A10.443,10.443,0,0,0,75.051,69.989V58.417a17.792,17.792,0,0,0,7.308-14.051c.43-4.959-2.579-9.918-7.308-13.224"
                      transform="translate(-2.4 -1.8)"
                      fill="#007abc"
                      fillRule="evenodd"
                    />
                  </svg>

                  <p className="text-center my-3">
                    Chat With our
                    <br />
                    Support team.
                  </p>
                  <Button color="primary" onClick={() => onChat()}>
                    <a style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                      Chat With Us
                    </a>
                  </Button>
                </Card>
              </Col>
            )}
            <Col md="4">
              <Card className="px-3 py-4 align-items-center my-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="86.369" height="81.57" viewBox="0 0 86.369 81.57">
                  <path
                    id="Icon_MissingPolicy"
                    data-name="Icon/MissingPolicy"
                    d="M89.885,70.176h-47.7v2.4a12.625,12.625,0,0,1-6.678,10.076,7.919,7.919,0,0,1-5.247,1.919H78.914a9.131,9.131,0,0,0,5.724-1.919,11.709,11.709,0,0,0,5.724-10.076v-2.4M4.024,3V68.256A10.855,10.855,0,0,0,14.518,79.772h.954A11.232,11.232,0,0,0,26.92,68.736V60.579H66.035V3ZM37.414,50.983a4.114,4.114,0,0,1-2.862,1.439A4.288,4.288,0,0,1,30.259,48.1a5.164,5.164,0,0,1,.954-2.879,4.114,4.114,0,0,1,2.862-1.439A4.288,4.288,0,0,1,38.368,48.1,6.847,6.847,0,0,1,37.414,50.983Zm9.54-24.471a9.388,9.388,0,0,1-4.293,4.318l-2.385,1.439a5.765,5.765,0,0,0-2.385,2.4,6.965,6.965,0,0,0-.954,3.359v.48h-6.2V36.588a14.081,14.081,0,0,1,.954-4.318,12.912,12.912,0,0,1,3.339-3.839l2.862-1.919a5.917,5.917,0,0,0,2.385-4.8,2.9,2.9,0,0,0-.477-1.919c-.477-.48-.529-.96-1.006-1.439a1.484,1.484,0,0,0-1.379-1.439,4.279,4.279,0,0,0-2.385-.48,6.018,6.018,0,0,0-2.862.48,6.589,6.589,0,0,0-1.908,1.439,3.646,3.646,0,0,0-.954,1.919c0,.96-.477,1.439-.477,2.4v.96l-7.155-.96V21.233a17.136,17.136,0,0,1,.954-4.8,7.347,7.347,0,0,1,2.385-3.359L29.305,10.2a23.814,23.814,0,0,1,5.724-.96,23.815,23.815,0,0,1,5.724.96,19.3,19.3,0,0,1,4.293,2.4,10.457,10.457,0,0,1,2.385,3.839,14.08,14.08,0,0,1,.954,4.318A11.451,11.451,0,0,1,46.955,26.511Z"
                    transform="translate(-3.994 -3)"
                    fill="#007abc"
                  />
                </svg>

                <p className="text-center my-3">
                  Checkout our
                  <br />
                  frequently asked questions.
                </p>
                <Button color="primary" tag={Link} to="/faq">
                  View FAQs
                </Button>
              </Card>
            </Col>
            <Col md="4">
              <Card className="px-3 py-4 align-items-center my-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="81" viewBox="0 0 86 75.906">
                  <g id="Brand-Icons-Utility-Plans" transform="translate(11)">
                    <path
                      id="_Plans_Icon_Color"
                      data-name="🎨 Plans Icon Color"
                      d="M24.569,75.906h0Zm.037,0a11.961,11.961,0,0,0,5.25-1.2l.252-.112c3.947-1.679,6.545-4.909,6.864-8.525V63.917H86v2.162c-.015,3.445-2.249,6.636-5.882,8.393a11.971,11.971,0,0,1-5.694,1.435Zm-13.19-4.465c-.126,0-.252,0-.372,0-.155,0-.3,0-.455-.01a11.313,11.313,0,0,1-7.9-3.6A10.016,10.016,0,0,1,.01,60.6V0h61.3V53.582H22.823V60.6c.01.166.01.356,0,.615-.164,5.733-5.045,10.227-11.116,10.227C11.615,71.446,11.513,71.446,11.416,71.441Zm.871-35.489H45.046V27.96H12.287Zm0-15.98H45.046V11.979H12.287Z"
                      transform="translate(-11)"
                      fill="#007abc"
                    />
                  </g>
                </svg>

                <p className="text-center my-3">
                  Help us to improve our
                  <br />
                  products and services.
                </p>
                <Button color="primary" tag={Link} to={hasAuthToken() ? '/complaintList' : '/complaints'}>
                  Complaints
                </Button>
              </Card>
            </Col>
          </Row>
          <Row className="pl-2">
            <p className="mt-5 mb-3 f-ns-m">For further assistance, you can contact us on the numbers below:</p>
          </Row>
          <Row className="mb-5">
            <Col className="p-2 " sm={12}>
              <h2 className="f-mlc-m">UAE</h2>
              <div className="row">
                <div className="col-md-4">
                  <h4>Toll-free</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">800 Benefits (23633487)</h3>
                  </span>
                </div>
                <div className="col-md-4">
                  <h4>International</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+971 45687309</h3>
                  </span>
                </div>
                {/* <div className="col-md-4">
                  <h4>Expat</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,
                        24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,
                        1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,
                        2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,
                        2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+971 43758583</h3>
                  </span>
                </div> */}
                <div className="col-md-6 mt-3">
                  <h4>Email</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 455.862 455.862">
                      <path
                        fill="#007abc"
                        d="M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659 c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523 l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496 c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"
                      />
                    </svg>
                    <a href="mailto:customerservice@metlife.metnotices.com" className="ml-3 text-primary f-mlc-m">
                      <h3>customerservice@metlife.metnotices.com</h3>
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="px-2">
              <hr />
            </Col>
            <Col className="p-2 " sm={12}>
              <h2 className="f-mlc-m">Bahrain</h2>
              <div className="row">
                <div className="col-md-4">
                  <h4>Toll-free</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">80001801</h3>
                  </span>
                </div>
                <div className="col-md-4">
                  <h4>International</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+973 17536631</h3>
                  </span>
                </div>
                {/* <div className="col-md-4">
                  <h4>Expat</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,
                        24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,
                        1.52-1.52,1.635,1.635,0,0,1,1.52,
                        1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,
                        2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,
                        2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+973 17536766</h3>
                  </span>
                </div> */}
                <div className="col-md-6 mt-3">
                  <h4>Email</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 455.862 455.862">
                      <path
                        fill="#007abc"
                        d="M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659 c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523 l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496 c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"
                      />
                    </svg>
                    <a href="mailto:CustomerCare.BH@metlife.metnotices.com" className="ml-3 text-primary f-mlc-m">
                      <h3>CustomerCare.BH@metlife.metnotices.com</h3>
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="px-2">
              <hr />
            </Col>
            <Col className="p-2 " sm={12}>
              <h2 className="f-mlc-m">Oman</h2>
              <div className="row">
                <div className="col-md-4">
                  <h4>Toll-free</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">800 MCARE (62273)</h3>
                  </span>
                </div>
                <div className="col-md-4">
                  <h4>International</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+968 2456 5641</h3>
                  </span>
                </div>
                {/* <div className="col-md-4">
                  <h4>Expat</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,
                        24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,
                        1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,
                        2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,
                        2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+968 2456 2273</h3>
                  </span>
                </div> */}
                <div className="col-md-6 mt-3">
                  <h4>Email</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 455.862 455.862">
                      <path
                        fill="#007abc"
                        d="M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659 c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523 l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496 c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"
                      />
                    </svg>
                    <a href="mailto:CustomerCare.OM@metlife.metnotices.com" className="ml-3 text-primary f-mlc-m">
                      <h3>CustomerCare.OM@metlife.metnotices.com</h3>
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="px-2">
              <hr />
            </Col>
            <Col className="p-2 " sm={12}>
              <h2 className="f-mlc-m">QATAR</h2>
              <div className="row">
                <div className="col-md-4">
                  <h4>Toll-free</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">8009711</h3>
                  </span>
                </div>
                <div className="col-md-4">
                  <h4>International</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+97144154555</h3>
                  </span>
                </div>
                {/* <div className="col-md-6 mt-3">
                  <h4>Email</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 455.862 455.862">
                      <path fill="#007abc" d="M441.088,63.154H14.774C6.615,63.154,0,69.77
                      ,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 c8.16,
                      14.775-6.614,14.775-14.775V77.93C455.862,69.77,
                      449.248,63.154,441.088,63.154z M403.394,316.659 c6.256,5.43,6.926,14.903,1.497,
                      21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-8
                      8.252-105.452-91.523 l-40.515,35.164c-2.82,2.448-6.326,
                      3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 c-3.77,
                      3.272-101.576,88.159-105.452,91.523c-6.257,
                      5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 L154.7,
                      227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,
                      21.161-1.496 c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,
                      150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 c5.43,6.257,
                      4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z" />
                    </svg>
                    <h3 className="ml-3 text-primary f-mlc-m">customerservice@metlife.metnotices.com</h3>
                  </span>
                </div> */}
              </div>
            </Col>
            <Col className="px-2">
              <hr />
            </Col>
            <Col className="p-2 " sm={12}>
              <h2 className="f-mlc-m">KUWAIT</h2>
              <div className="row">
                <div className="col-md-4">
                  <h4>Toll-free</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">22089333</h3>
                  </span>
                </div>
                <div className="col-md-4">
                  <h4>International</h4>
                  <span className="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
                      <path
                        id="Icon_Phone"
                        data-name="Icon/Phone"
                        d="M6,7.067H21.2V21H6Zm0,15.2V24.8a2.541,2.541,0,0,0,2.533,2.533H18.667A2.541,2.541,0,0,0,21.2,24.8V22.266Zm7.6,4.053a1.54,1.54,0,0,1-1.52-1.52,1.635,1.635,0,0,1,1.52-1.52,1.635,1.635,0,0,1,1.52,1.52A1.54,1.54,0,0,1,13.6,26.32ZM18.667,2H8.533A2.541,2.541,0,0,0,6,4.533V5.8H21.2V4.533A2.541,2.541,0,0,0,18.667,2ZM16.133,4.28H11.067V3.267h5.067Z"
                        transform="translate(-6 -2)"
                        fill="#007abc"
                      />
                    </svg>

                    <h3 className="ml-3 text-primary f-mlc-m">+96522089333</h3>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Template>
  );
};

//  {contacts?.map((c) => (
//     <Col className="p-2 mb-4" key={c?.id} sm={12} md={6} lg={4}>
//       <h2 className="f-mlc-m">{c?.label}</h2>
//       <span className="d-flex">
//         <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="22.333" viewBox="0 0 15.2 25.333">
//           <path
//             id="Icon_Phone"
//             data-name="Icon/Phone"
//             transform="translate(-6 -2)"
//             fill="#007abc"
//           />
//         </svg>

//         <h3 className="ml-3 text-primary f-mlc-m">{c?.number}</h3>
//       </span>
//     </Col>
//   ))}

ContactUs.propTypes = {
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  // contacts: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.number,
  //     label: PropTypes?.string,
  //     number: PropTypes.string,
  //   }),
  // ),
  authUser: PropTypes.shape({
    first_name: PropTypes.string,
    country_code: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    card_number: PropTypes.string,
    vipstatus: PropTypes.string,
    policy_number: PropTypes.string,
  }).isRequired,
};

ContactUs.defaultProps = {
  // contacts: [],
};

export default withRouter(ContactUs);
