import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/AddSecurityQuestions';
import userApi from '../api/UserApi';
import Encryption from '../encryption/encryption';

class AddSecurityQuestions extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  async componentDidMount() {
    const data = await userApi.getAllQuestions();
    if (data.success) {
      this.setState((prevState) => ({ ...prevState, questions: data.data }));
    }
  }

  /**
   * On Form Submission
   */
  onSubmit = async (data) => {
    const { setNewPassword, history, location, metaData } = this.props;
    try {
      if (data.length < 3) {
        this.setState({
          success: false,
          error: true,
          loading: false,
          message: 'Please answer at least 3 question!',
        });
        return;
      }
      const publicKeyData = await metaData();

      if (publicKeyData?.success) {
        const passwordEncrypted = Encryption.encrypt(publicKeyData?.data, location.state?.password);
        const conPasswordEncrypted = Encryption.encrypt(publicKeyData?.data, location.state?.conpassword);
        const payload = {
          security_questions: data,
          ...location.state,
          password: passwordEncrypted,
          conpassword: conPasswordEncrypted,
        };
        const res = await setNewPassword(payload);
        if (res.success) {
          this.setState({
            success: res.success,
            error: !res.success,
            loading: false,
            message: res?.message,
          });
          setTimeout(() => {
            history.push({
              pathname: 'login',
              state: {
                token: location.state.token,
                email: location.state.email,
              },
            });
          });
        } else {
          this.setState({
            success: res.success,
            error: !res.success,
            loading: false,
            message: res?.message,
          });
        }
        toast.custom(<Alert message={res?.message} />);
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: true, message: error.message });
      toast.custom(<Alert message={error?.message} />);
    }
  };

  /**
   * Render
   */
  render = () => {
    const { error, loading, success, message, questions } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        questions={questions}
        onSubmit={this.onSubmit}
      />
    );
  };
}

AddSecurityQuestions.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      password: PropTypes.string,
      conpassword: PropTypes.string,
      email: PropTypes.string,
      token: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setNewPassword: PropTypes.func,
  metaData: PropTypes.func.isRequired,
};

AddSecurityQuestions.defaultProps = {
  setNewPassword: () => false,
  location: {
    state: {},
  },
};
const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  setNewPassword: dispatch.member.setNewPassword,
  metaData: dispatch.encryption.metaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSecurityQuestions);
