import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Label, List } from 'reactstrap';
import InfoLabel from '../InfoLabel';

const MemberDetails = ({ generateECard, data, downloadTOB, status }) => (
  <Col>
    <Row className="justify-content-between align-items-center">
      <Col lg="5" md="12" xs="9">
        <Label className="f-mlc-m display-3 tab-title">Member Info</Label>
      </Col>
      <Col className="text-right download-buttons">
        <input id="download-toggle" type="checkbox" />
        <Label for="download-toggle" className="more-btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="5">
            <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z" />
          </svg>
        </Label>

        <div className="more-menu-drop-down">
          {status ? (
            <Link
              to="#"
              onClick={() => {
                generateECard();
              }}
              className="mr-4"
            >
              <svg
                id="Icon_Download"
                data-name="Icon/Download"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect id="Mask" width="24" height="24" fill="none" />
                <path
                  id="Bottom"
                  d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                  transform="translate(10.125 17.636)"
                  fill="#007abc"
                />
                <path
                  id="Middle"
                  d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                  transform="translate(4.347 4)"
                  fill="#007abc"
                />
                <path
                  id="Arrow"
                  d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                  transform="translate(9.729 7.637)"
                  fill="#fff"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
                <path
                  id="Corner"
                  d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                  transform="translate(2 5.455)"
                  fill="#007abc"
                />
              </svg>
              Download Medical Card
            </Link>
          ) : null}

          <Link to="searchProvider" className="mr-4">
            <svg
              id="Icon_Download"
              data-name="Icon/Download"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <rect id="Mask" width="24" height="24" fill="none" />
              <path
                id="Bottom"
                d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                transform="translate(10.125 17.636)"
                fill="#007abc"
              />
              <path
                id="Middle"
                d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                transform="translate(4.347 4)"
                fill="#007abc"
              />
              <path
                id="Arrow"
                d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                transform="translate(9.729 7.637)"
                fill="#fff"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Corner"
                d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                transform="translate(2 5.455)"
                fill="#007abc"
              />
            </svg>
            Download Eligible Network
          </Link>
          {data?.tob_file_uploaded_at && status ? (
            <Link
              to="#"
              onClick={() => {
                downloadTOB();
              }}
            >
              <div>
                <svg
                  id="Icon_Download"
                  data-name="Icon/Download"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <rect id="Mask" width="24" height="24" fill="none" />
                  <path
                    id="Bottom"
                    d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                    transform="translate(10.125 17.636)"
                    fill="#007abc"
                  />
                  <path
                    id="Middle"
                    d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                    transform="translate(4.347 4)"
                    fill="#007abc"
                  />
                  <path
                    id="Arrow"
                    d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                    transform="translate(9.729 7.637)"
                    fill="#fff"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <path
                    id="Corner"
                    d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                    transform="translate(2 5.455)"
                    fill="#007abc"
                  />
                </svg>
                Download TOB
              </div>

              <Label className="text-grey upload-label">
                <small>{`Uploaded on ${data?.tob_file_uploaded_at}`}</small>
              </Label>
            </Link>
          ) : null}
        </div>
      </Col>
    </Row>
    <hr />
    <Row className="my-4">
      <Col>
        <span className="avatar avatar-sm rounded-circle mr-3">
          <img
            className="rounded-circle"
            src={data.image_path || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
            alt="user"
            width="70"
            height="70"
          />
        </span>
        <h6 className="d-inline">{data.member_name}</h6>
      </Col>
      <Col className="text-right">
        <Link
          to={{
            pathname: '/profile',
            state: {
              userId: data?.id,
              isPrincipalMember: true,
            },
          }}
        >
          <svg
            id="Icon_PencilEdit"
            data-name="Icon/PencilEdit"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
          >
            <rect id="Mask" width="24" height="24" fill="none" />
            <g id="Icon_PencilEdit-2" data-name="Icon/PencilEdit" transform="translate(-0.5 0.514)">
              <rect
                id="Rectangle_95"
                data-name="Rectangle 95"
                width="3.4"
                height="4.8"
                transform="translate(16.195 4.391) rotate(-45)"
                fill="#0090da"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                fill="#007abc"
              />
            </g>
          </svg>
          <b>Edit</b>
        </Link>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="grid grid-2 info-list-wrapper">
          <List type="unstyled" className="info-list">
            <li>
              <InfoLabel title="Member Name" name={data.member_name} />
            </li>
            <li>
              <InfoLabel title="Designation" name={data.designation} />
            </li>
            <li>
              <InfoLabel title="Sponsor ID" name={data.sponsor_id} />
            </li>
            <li>
              <InfoLabel title="Date of Birth" name={data.dob ? moment(data?.dob).format('DD-MM-Y') : ''} />
            </li>
            <li>
              <InfoLabel title="UID number" name={data.uid_number} />
            </li>
            <li>
              <InfoLabel title="Certificate Number" name={data.certificate_number} />
            </li>
            <li>
              <InfoLabel title="Gender" name={data.gender_value} />
            </li>
            <li>
              <InfoLabel title="Marital Status" name={data.marital_status} />
            </li>
            <li>
              <InfoLabel title="Email Address" name={data.email} />
            </li>
            <li>
              <InfoLabel title="Passport Number" name={data.passport_number} />
            </li>
            <li>
              <InfoLabel title="Salary Currency" name={data.salary_currency} />
            </li>
            <li>
              <InfoLabel title="Salary Mode" name={data.salary_mode} />
            </li>
            <li>
              <InfoLabel title="Visa Place of Issuance" name={data.visa_place_of_issuance} />
            </li>
            <li>
              <InfoLabel title="Visa File Number" name={data.visa_file_number} />
            </li>
          </List>
          <List type="unstyled" className="info-list">
            <li>
              <InfoLabel title="Status" name={data.status} />
            </li>
            <li>
              <InfoLabel
                title="Effective Date"
                name={data.effective_date ? moment(data?.effective_date).format('DD-MM-Y') : ''}
              />
            </li>
            <li>
              <InfoLabel title="Well Being Connect Eligibility" name={data.well_being_connect_eligibility} />
            </li>
            <li>
              <InfoLabel title="Basic Salary" tooltip="Basic Salary." name={data.basic_salary} />
            </li>
            <li>
              <InfoLabel
                title="Employment Date"
                name={data.employment_date ? moment(data.employment_date).format('DD-MM-Y') : ''}
              />
            </li>
            <li>
              <InfoLabel title="Employee Number" name={data.employee_number} />
            </li>
            <li>
              <InfoLabel title="Department" name={data.department} />
            </li>
            <li>
              <InfoLabel title="LSB Indicator" name={data.lsb_indicator} />
            </li>
            <li>
              <InfoLabel title="Sub Office" name={data.sub_office} />
            </li>
            <li>
              <InfoLabel title="National ID Number" name={data.national_id_number} />
            </li>
            <li>
              <InfoLabel title="Nationality" name={data.nationality} />
            </li>
            <li>
              <InfoLabel title="Is Thiqa Plan" name={data.is_thiqa_plan === 1 ? 'Yes' : 'No'} />
            </li>
            <li>
              <InfoLabel title="Thiqa plan Type" name={data.thiqa_plan_type} />
            </li>
            <li>
              <InfoLabel title="Family Book Issued From" name={data.family_book_issued_from} />
            </li>
          </List>
        </div>
        <h6 className="mt-5 mb-3 subtitle f-mlc">Regulatory Information</h6>
        <div className="grid grid-2 info-list-wrapper">
          <List type="unstyled" className="info-list">
            <li>
              <InfoLabel title="Sponsor Type" name={data.sponsor_type} />
            </li>
            <li>
              <InfoLabel title="Sponsor Email Address" name={data.sponsor_email_address} />
            </li>
            <li>
              <InfoLabel title="Sponsor Contact Number" name={data.sponsor_contact_number} />
            </li>
            <li>
              <InfoLabel title="Work Location" name={data.work_location} />
            </li>
            <li>
              <InfoLabel title="Residing Location" name={data.residing_location} />
            </li>
            <li>
              <InfoLabel title="Residing Country" name={data.residing_country} />
            </li>
          </List>
          <List type="unstyled" className="info-list">
            <li>
              <InfoLabel title="Gross Salary Range" name={data.gross_salary_name} />
            </li>
            <li>
              <InfoLabel
                title="Salary Based on Commission"
                name={data.salary_based_on_commission === 'Y' ? 'Yes' : 'No'}
              />
            </li>
            <li>
              <InfoLabel title="DHA ID" name={data.dha_id} />
            </li>
            <li>
              <InfoLabel title="Work Emirate" name={data.work_location_emirate} />
            </li>
            <li>
              <InfoLabel title="Residing Emirate" name={data.residing_emirates} />
            </li>
            <li>
              <InfoLabel
                title="Did member Have Insurance for Past 6 Months in UAE"
                name={data.uae_insurance_for_past_six_months === 'Y' ? 'Yes' : 'No'}
              />
            </li>
          </List>
        </div>
      </Col>
    </Row>
  </Col>
);

MemberDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    pic_group_id: PropTypes.number,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    phone_country_id: PropTypes.string,
    phone_number_verified: PropTypes.number,
    gender: PropTypes.number,
    dob: PropTypes.string,
    country_id: PropTypes.number,
    network_id: PropTypes.number,
    card_number: PropTypes.string,
    vipstatus: PropTypes.string,
    national_id: PropTypes.string,
    default_network_id: PropTypes.string,
    default_network_description: PropTypes.string,
    package_number: PropTypes.string,
    package_long_description: PropTypes.string,
    nationality_country_code: PropTypes.string,
    country_of_residency_id: PropTypes.string,
    policy_number: PropTypes.string,
    policy_holder: PropTypes.string,
    expiry_date: PropTypes.string,
    expire_status: PropTypes.number,
    login_attempt: PropTypes.number,
    account_verified: PropTypes.number,
    marital_status: PropTypes.string,
    image_path: PropTypes.string,
    bank_id: PropTypes.number,
    payment_method: PropTypes.number,
    member_number: PropTypes.string,
    member_name: PropTypes.string,
    insurance_company_number: PropTypes.string,
    gender_value: PropTypes.string,
    status: PropTypes.string,
    bank_accounts: PropTypes.arrayOf(PropTypes.shape({})),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    user_id: PropTypes.number,
    certificate_number: PropTypes.string,
    employee_number: PropTypes.string,
    sub_office: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    employment_date: PropTypes.string,
    basic_salary: PropTypes.string,
    passport_number: PropTypes.string,
    nationality: PropTypes.string,
    email_address: PropTypes.string,
    visa_place_of_issuance: PropTypes.string,
    uid_number: PropTypes.string,
    well_being_connect_eligibility: PropTypes.string,
    lsb_indicator: PropTypes.string,
    sponsor_type: PropTypes.string,
    gross_salary: PropTypes.string,
    residing_location: PropTypes.string,
    uae_insurance_for_past_six_months: PropTypes.string,
    salary_based_on_commission: PropTypes.string,
    sponsor_email_address: PropTypes.string,
    sponsor_contact_number: PropTypes.string,
    work_location_emirate: PropTypes.string,
    deleted_at: PropTypes.string,
    residing_country: PropTypes.string,
    residing_emirates: PropTypes.string,
    work_location: PropTypes.string,
    national_id_number: PropTypes.string,
    visa_number: PropTypes.string,
    visa_sponsor_id: PropTypes.string,
    dha_id: PropTypes.string,
    sponsor_id: PropTypes.string,
    salary_currency: PropTypes.string,
    salary_mode: PropTypes.string,
    visa_file_number: PropTypes.string,
    effective_date: PropTypes.string,
    is_thiqa_plan: PropTypes.number,
    thiqa_plan_type: PropTypes.number,
    residing_country_name: PropTypes.string,
    visa_place_of_issuance_name: PropTypes.string,
    gross_salary_name: PropTypes.string,
    family_book_issued_from: PropTypes.string,
    tob_file_uploaded_at: PropTypes.string,
  }),
  generateECard: PropTypes.func.isRequired,
  downloadTOB: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
};

MemberDetails.defaultProps = {
  data: {},
};

export default withRouter(MemberDetails);
