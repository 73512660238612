import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/UpdatePassword';
import Encryption from '../encryption/encryption';

class UpdatePassword extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, loginLoading: false };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { updatePassword, login, history, me, metaData } = this.props;
    this.setState({ success: null, error: null, loading: true });

    try {
      const publicKeyData = await metaData();

      if (publicKeyData?.success) {
        const oldPasswordEncrypted = Encryption.encrypt(publicKeyData?.data, data?.old_password);
        const newPasswordEncrypted = Encryption.encrypt(publicKeyData?.data, data?.new_password);
        const conPasswordEncrypted = Encryption.encrypt(publicKeyData?.data, data?.new_password);
        const payload = {
          ...data,
          old_password: oldPasswordEncrypted,
          new_password: newPasswordEncrypted,
          conpassword: conPasswordEncrypted,
        };
        const { success, message } = await updatePassword(payload);
        this.setState({ success, error: null, loading: false });
        if (success) {
          this.setState({ success: true, error: null, loading: false, message });
          const loginPayload = {
            email: data?.email,
            password: newPasswordEncrypted,
          };
          this.setState({ loginLoading: true });
          toast.custom(<Alert message="Logging In" />, {
            duration: 3000,
          });
          const loginResult = await login(loginPayload);
          if (loginResult?.success) {
            if (loginResult?.phone_number_verified) {
              // Account is also considered verified
              if (loginResult?.token) {
                me();
                history.push('/home');
              } else {
                history.push({
                  pathname: '/verifyLoginOTP',
                  state: { email: data?.email, password: data?.new_password, publicKey: publicKeyData?.data },
                });
              }
            }
            this.setState({ loginLoading: false });
          } else {
            history.push('/login');
          }
        } else {
          this.setState({ success, error: true, message, loading: false });
        }
      }
    } catch (error) {
      this.setState({ loading: false, success: null, message: error.message, error: true });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { location } = this.props;
    const { error, loading, success, message, loginLoading } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={location?.state}
        onFormSubmit={this.onFormSubmit}
        loginLoading={loginLoading}
      />
    );
  };
}

UpdatePassword.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.number.isRequired,
      browserFingerPrint: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  metaData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: dispatch.member.updatePassword,
  me: dispatch.member.me,
  login: dispatch.member.login,
  metaData: dispatch.encryption.metaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
