import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Layout from '../components/Dashboard/Dashboard';
import userApi from '../api/UserApi';
import Alert from '../components/UI/Alert';

class DashboardContainer extends Component {
  policyClick = false;

  constructor() {
    super();
    this.state = {
      cardData: [],
    };
  }

  async componentDidMount() {
    this.setState((pre) => ({ ...pre, loading: true }));
    const { data } = await userApi.getPolicies();
    this.setState((pre) => ({ ...pre, cardData: data, loading: false }));
  }

  downloadTOB = async (policy, dependent_id = null) => {
    const { downloadTOB } = this.props;
    toast.promise(downloadTOB({ policy_id: policy?.id, dependent_id }), {
      loading: (
        <Alert message="Please wait while the Table of benefits Card is downloading" isOpen />
      ),
      success: (pdf) => {
        console.log(typeof pdf.type);
        if (pdf?.type === 'application/pdf') {
          // const file = new Blob([...pdf], { type: 'application/pdf' });
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          this.setState((state) => ({ ...state, ecardDownload: false }));
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(pdf);
          link.download = 'TableOfBenefits.pdf';
          link.click();
          return (
            <Alert message="Table Of Benefits has downloaded" />
          );
        }
        this.setState((state) => ({ ...state, ecardDownload: false }));
        return (
          <Alert message={pdf?.message || 'Unable to download Table Of Benefits'} />
        );
      },
      error: (
        <div className="custom-alert alert alert-danger alert-dismissible">
          <span className="alert-icon">!</span>
          <h3>Error:</h3>
          Unable to download Medical Card
          <button type="button" className="close">×</button>
        </div>
      ),
    });
  };

  generateECard = async (policyId) => {
    const { generateECard } = this.props;

    this.setState((state) => ({ ...state, ecardDownload: true }));
    // const pdf = await generateECard({ id, policyId: currentPolicy?.id });
    toast.promise(generateECard({ policyId }),
      {
        loading: (
          <Alert message="Please wait while the Medical Card is downloading" isOpen />
        ),
        success: (pdf) => {
          console.log(typeof pdf.type);
          if (pdf?.type === 'application/pdf') {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = 'MedicalCard.pdf';
            link.click();
            return (
              <Alert message="Medical Card has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Medical Card'} />
          );
        },
        error: (
          <div className="custom-alert alert alert-danger alert-dismissible">
            <span className="alert-icon">!</span>
            <h3>Error:</h3>
            Unable to download Medical Card
            <button type="button" className="close">×</button>
          </div>
        ),
      });
  };

  /**
   * On dropdown item click in dash board
   */
  action = async (pathname, payload) => {
    try {
      if (!this.policyClick) {
        this.policyClick = true;
        const { viewDetails, history } = this.props;
        this.setState({ loading: true });
        const { success, data } = await viewDetails(payload);
        if (success) {
          history.push({
            pathname,
            state: { policyData: data },
          });
        }
      }
    } catch (error) {
      console.log('ERROR');
    }
    this.setState({ loading: false });
  };

  /**
   * Render
   */
  render = () => {
    const { user } = this.props;
    const { cardData, loading } = this.state;
    return (
      <Layout
        cardData={cardData}
        action={this.action}
        user={user}
        loading={loading}
        downloadTOB={this.downloadTOB}
        generateECard={this.generateECard}
      />
    );
  };
}

DashboardContainer.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  downloadTOB: PropTypes.func.isRequired,
  generateECard: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  viewDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  user: state.member.user || {},
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: dispatch.policy.getPolicyDetails,
  downloadTOB: dispatch.policy.downloadTOB,
  generateECard: dispatch.policy.generateECard,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
