import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Template from '../../../Templates/Home';
import Preapprovals from './Preapprovals/Preapprovals';
import Claims from './Claims/Claims';
import Title from '../../Title';

const TransactionHistory = ({
  policyData,
  loading,
  claims,
  preApprovals,
  getClaims,
  getPreApprovals,
  downloadEOB,
  dependents,
}) => {
  const [tabs, setTabs] = useState('a');
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Transaction History', active: true },
  ];
  return (
    <Template pageTitle="Transaction History" breadcrumb={breadcrumb}>
      <Container fluid className="transaction-history max-container">
        <Title
          policyNumber={policyData?.policyNumber}
          certificateNumber={policyData?.certificateNumber}
          expiryDate={policyData?.expiryDate}
        />
      </Container>
      <section className="transaction-history metLife-gray">
        <Container fluid className="max-container">
          <Row>
            <Col className="tab-default">
              <Nav tabs className="w-100">
                <NavItem>
                  <NavLink
                    className={tabs === 'a' ? 'active' : ''}
                    onClick={() => {
                      setTabs('a');
                    }}
                  >
                    Claims
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tabs === 'b' ? 'active' : ''}
                    onClick={() => {
                      setTabs('b');
                    }}
                  >
                    Pre-Approvals
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={tabs}>
                <TabPane tabId="a">
                  <Claims
                    data={claims}
                    policyData={policyData}
                    loading={loading}
                    getClaims={getClaims}
                    downloadEOB={downloadEOB}
                    dependents={dependents}
                  />
                </TabPane>
                <TabPane tabId="b">
                  <Preapprovals
                    preApprovals={preApprovals}
                    policyData={policyData}
                    loading={loading}
                    getPreApprovals={getPreApprovals}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </Template>
  );
};

TransactionHistory.propTypes = {
  preApprovals: PropTypes.arrayOf(PropTypes.shape({})),
  claims: PropTypes.shape({}),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  getClaims: PropTypes.func.isRequired,
  downloadEOB: PropTypes.func.isRequired,
  getPreApprovals: PropTypes.func.isRequired,
  dependents: PropTypes.arrayOf(PropTypes.shape({})),
};

TransactionHistory.defaultProps = {
  loading: false,
  preApprovals: [],
  claims: {},
  dependents: [],
};

export default withRouter(TransactionHistory);
