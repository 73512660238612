import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/AddPhoneNumber';

class VerifyOTP extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, message: null };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { addPhoneNumber, userInput } = this.props;

    this.setState({ success: null, error: null, loading: true, message: null });

    try {
      const { success, message } = await addPhoneNumber({ ...userInput, ...data });
      this.setState({ success, error: null, loading: false });
      if (success) {
        this.setState({
          success,
          message:
            'We have sent you an email to your registered email address with a link to set your new password. Please check your email and follow the link to set your new password.',
          error: null,
          loading: false,
          verified: true,
        });
        // setTimeout(() => history.push('/home'), 2000);
      } else {
        this.setState({ success, error: true, loading: false, message });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message, message: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, message, verified } = this.state;

    return (
      <Layout
        verified={verified}
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
        resendOTP={this.resendOTP}
      />
    );
  };
}

VerifyOTP.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  addPhoneNumber: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: {
    user_id: state.member.userID,
    dependents_count: state.member.dependents_count,
    dependent_dob: state.member.dependent_dob,
    primary_mem_gender: state.member.primary_mem_gender,
    dependent_gender: state.member.dependent_gender,
    marital_status: state.member.marital_status,
    nationality_country_code: state.member.nationality_country_code,
    phone_number: state.member.phoneNumber,
  },
});

const mapDispatchToProps = (dispatch) => ({
  addPhoneNumber: dispatch.member.addPhoneNumber,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);
