import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';
import Alert from '../UI/Alert';

const WorkAndResidentialDetails = ({ loading, countryList, stateList, onFormSubmit, defaultValues, data }) => {
  const { register, handleSubmit, errors, setValue, reset } = useForm({ defaultValues });
  const [button, setButton] = useState(false);
  const [residingCountry, setResidingCountry] = useState(null);
  const [cities, setCities] = useState(null);

  const submit = async (profileData) => {
    const res = await onFormSubmit(profileData);
    if (res?.success) {
      setButton(false);
    }
    toast.custom(<Alert message={res?.message} />);
    // await toast.promise(onFormSubmit(profileData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    //   error: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    // });
  };

  useEffect(() => {
    if (!residingCountry) {
      setResidingCountry(data?.info?.residing_country_name);
    }
    stateList?.map((e) => (e?.name === data?.info?.residing_emirates ? setCities(e?.cities) : []));
  }, [data, stateList]);

  return (
    <Card name="workAndResidentialDetails">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Work and Residence Details</Label>
          </div>
          <div className="text-right text-nowrap">
            {!button ? (
              <a
                className="btn-link edit"
                onClick={() => {
                  if (button) {
                    setButton(false);
                  } else {
                    setButton(true);
                    reset(defaultValues);
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                    <rect
                      id="Rectangle_95"
                      data-name="Rectangle 95"
                      width="3.4"
                      height="4.8"
                      transform="translate(16.195 4.391) rotate(-45)"
                      fill="#0090da"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                      fill="#007abc"
                    />
                  </g>
                </svg>
                Edit
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr />
        {button ? (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="residing_country"
                        id="residing_country"
                        placeholder="Residing Country"
                        disabled={loading}
                        invalid={!!errors.firstName}
                        innerRef={register({ required: errorMessages.missingfirstName })}
                        onChange={(e) => {
                          setValue('residing_country', e.target.value);
                          setResidingCountry(e.target.value);
                          if (e.target.value !== 'ARE') {
                            setValue('residing_location', '');
                          }
                        }}
                      >
                        <option value="" defaultChecked disabled>
                          Residing Country
                        </option>
                        {countryList.map((element) => (
                          <option
                            value={element?.iso3}
                            selected={
                              element?.iso3?.toUpperCase() === data?.info?.residing_country_name?.toUpperCase()
                            }
                            key={element?.id}
                          >
                            {element?.short_name}
                          </option>
                        ))}
                      </Input>
                      <Label for="">Residing Country</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="work_location"
                        id="work_location"
                        placeholder="Work Location"
                        disabled={loading}
                        invalid={!!errors.middleName}
                        innerRef={register({ required: errorMessages.missingmiddleName })}
                        onChange={(e) => setValue('work_location', e.target.value)}
                      />
                      <Label for="">Work Location</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                {residingCountry === 'ARE' ? (
                  <Row>
                    <Col>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="select"
                          name="residing_emirates"
                          id="residing_emirates"
                          placeholder="Residing Emirate"
                          disabled={loading}
                          invalid={!!errors.residingEmirates}
                          innerRef={register({ required: errorMessages.missingresidingEmirates })}
                          onChange={(e) => {
                            setValue('residing_emirates', e.target.value);
                            stateList?.forEach(async (element) => {
                              if (e.target.value === element?.name) {
                                await setCities(element?.cities);
                              }
                            });
                          }}
                        >
                          <option value="" defaultChecked={!data?.info?.residing_emirates} hidden disabled>
                            Residing Emirate
                          </option>
                          {stateList?.map((element) => (
                            <option
                              value={element?.name}
                              defaultChecked={element?.name === data?.info?.residing_emirates}
                              key={element?.id}
                            >
                              {element?.name}
                            </option>
                          ))}
                        </Input>
                        <Label for="">Residing Emirates</Label>
                        {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="text"
                          name="work_emirates"
                          id="work_emirates"
                          placeholder="Work Emirate"
                          disabled={loading}
                          invalid={!!errors.mobile}
                          innerRef={register({ required: errorMessages.missingmobile })}
                          onChange={(e) => setValue('work_emirates', e.target.value)}
                        />
                        <Label for="">Work Emirates</Label>
                        {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      {residingCountry === 'ARE' ? (
                        <Input
                          type="select"
                          name="residing_location"
                          id="residing_location"
                          placeholder="Residing Location"
                          disabled={loading}
                          invalid={!!errors.lastName}
                          innerRef={register({ required: errorMessages.missinglastName })}
                          onChange={(e) => setValue('residing_location', e.target.value)}
                        >
                          <option value="" defaultChecked={!data?.info?.residing_location} disabled>
                            Residing Location
                          </option>
                          {cities?.map((element) => (
                            <option
                              value={element?.name}
                              key={element?.id}
                              defaultChecked={element?.name === data?.info?.residing_location}
                            >
                              {element?.name}
                            </option>
                          ))}
                        </Input>
                      ) : (
                        <Input
                          type="text"
                          name="residing_location"
                          id="residing_location"
                          placeholder="Residing Location"
                          disabled={loading}
                          invalid={!!errors.lastName}
                          innerRef={register({ required: errorMessages.missinglastName })}
                          onChange={(e) => setValue('residing_location', e.target.value)}
                        />
                      )}
                      <Label for="">Residing Location</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  {residingCountry !== 'ARE' ? (
                    <Col>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="text"
                          name="work_emirates"
                          id="work_emirates"
                          placeholder="Work Emirate"
                          disabled={loading}
                          invalid={!!errors.mobile}
                          innerRef={register({ required: errorMessages.missingmobile })}
                          onChange={(e) => setValue('work_emirates', e.target.value)}
                        />
                        {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                      </FormGroup>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>

                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setResidingCountry(data?.info?.residing_country_name);
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ?
                        (
                          <>
                            <Spinner
                              size="sm"
                              color="light"
                            />
                            <span>&nbsp;Loading</span>
                          </>
                        ) : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Residing Country" name={data?.info?.residing_country_name} />
              </li>
              {residingCountry === 'ARE' ? (
                <li>
                  {' '}
                  <InfoLabel title="Residing Emirate" name={data?.info?.residing_emirates} />
                </li>
              ) : (
                ''
              )}
              <li>
                <InfoLabel title="Residing Location" name={data?.info?.residing_location} />
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Work Location" name={data?.info?.work_location} />
              </li>
              <li>
                <InfoLabel title="Work Emirate" name={data?.info?.work_emirates} />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

WorkAndResidentialDetails.propTypes = {
  data: PropTypes.shape({
    info: PropTypes.shape({
      residing_country: PropTypes.string,
      residing_location: PropTypes.string,
      residing_emirates: PropTypes.string,
      residing_country_name: PropTypes.string,
      work_location: PropTypes.string,
      work_emirates: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  defaultValues: PropTypes.shape({
    residing_country: PropTypes.string,
    residing_location: PropTypes.string,
    residing_emirates: PropTypes.string,
    work_location: PropTypes.string,
    work_emirates: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})),
};

WorkAndResidentialDetails.defaultProps = {
  data: {},
  countryList: [],
  loading: false,
  defaultValues: {},
  stateList: [],
};

export default withRouter(WorkAndResidentialDetails);
