import React, { Fragment, useEffect, useState } from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import InputField, { isValidPhoneNumber } from 'react-phone-number-input/input';
import validator from 'validator';
import { errorMessages } from '../../../constants/messages';

const StepOne = ({ loading, onContinue, data }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  // const [editContact, setEditContact] = useState(false);
  const [num, setNum] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    setNum(
      data?.member_details?.phone_number
        ? `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`
        : '',
    );
    setEmail(data?.member_details?.email);
    reset({
      email: data?.member_details?.email,
      mobile_number: `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`,
      provider_name: data?.provider_name,
    });
  }, []);

  const changePatient = (patient_id) => {
    if (patient_id !== '0') {
      const patient = data?.dependents?.filter((item) => item?.id === parseInt(patient_id, 10))[0];
      if (patient?.phone_number) {
        setNum(patient?.phone_number ? `+${patient?.phone_number.replace('-', '').replace('+', '')}` : '');
        setValue('mobile_number', `+${patient?.phone_number.replace('-', '').replace('+', '')}`);
      }
      if (patient?.email) {
        setEmail(patient?.email);
        setValue('email', patient?.email);
      }
    } else {
      setNum(
        data?.member_details?.phone_number
          ? `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`
          : '',
      );
      setEmail(data?.member_details?.email);
      setValue('mobile_number', `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`);
      setValue('email', data?.member_details?.email);
    }
    setValue('patient', patient_id);
  };

  return (
    <Fragment>
      <Row className="justify-content-between mt-3">
        <Col md="5">
          <Label className="f-mlc-m display-3 tab-title">Personal details</Label>
        </Col>
      </Row>
      <hr />
      <p>Before submitting Pre-Approval information, please check the following details.</p>
      <Form
        onSubmit={handleSubmit((formData) => {
          console.log(formData);
          const numValidation = num ? isValidPhoneNumber(num) : false;
          const emailValidation = validator.isEmail(getValues()?.email ?? '');
          onContinue(formData, numValidation && emailValidation);
        })}
        className="mt-3"
      >
        <FormGroup>
          <Row className="justify-content-between mt-3">
            <Col md="6">
              <div className="form-label-group form-group mb-0">
                <Input
                  type="select"
                  name="dependent_id"
                  id="dependent_id"
                  placeholder="Your answer"
                  disabled={loading}
                  invalid={!!errors.dependent_id}
                  innerRef={register()}
                  onChange={(e) => changePatient(e.target.value)}
                >
                  <option key="principal_member" value={0}>
                    {data?.member_details?.member_name}
                  </option>
                  {data?.dependents.map((dependent_id) => (
                    <option key={dependent_id.id} value={dependent_id.id}>
                      {dependent_id.member_name}
                    </option>
                  ))}
                </Input>
                {errors.dependent_id && <p className="invalid-feedback">{errors.dependent_id.message}</p>}
                <Label for="dependent_id">Pre-Approval is for</Label>
              </div>
            </Col>
            {/* <Col md="6">
              <div className="form-label-group form-group mb-0">
                <Input
                  type="text"
                  // readOnly={!editContact}
                  name="provider_name"
                  id="provider_name"
                  placeholder="provider_name"
                  disabled={loading}
                  invalid={!!errors.provider_name}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('provider_name', e.target.value)}
                />
                <Label for="provider_name">Provider name</Label>
                <p className="invalid-feedback">{`${errors.provider_name ? 'Provider name required' : ''}`}</p>
              </div>
            </Col> */}
          </Row>

          <h3 className="mt-5">How should we contact you about the Pre-Approval?</h3>
          <p>This will only be used for this specific Pre-Approval unless you update it from the profile page.</p>
          <Row className="align-items-end mt-3">
            <Col className="col-md-6 col-sm-12 mb-4 mb-md-auto">
              <div className="form-label-group form-group mb-0">
                <Controller
                  name="mobile_number"
                  id="mobile_number"
                  control={control}
                  innerRef={register({ required: errorMessages.missingData })}
                  render={() => (
                    <InputField
                      type="text"
                      // readOnly={!editContact}
                      name="mobile_number"
                      id="mobile_number"
                      international
                      withCountryCallingCode
                      className={`form-control ${num ? (!isValidPhoneNumber(num) ? 'is-invalid' : '') : 'is-invalid'}`}
                      disabled={loading}
                      invalid={!!errors.mobile_number}
                      value={num}
                      onChange={(e) => {
                        setNum(e);
                        setValue('mobile_number', e);
                      }}
                    />
                  )}
                />
                <p className="invalid-feedback">
                  {`${num ? (isValidPhoneNumber(num) ? '' : 'Invalid phone number') : 'Phone number required'}`}
                </p>
                <Label for="mobile_number">Mobile Number</Label>
                <p className="phone-info">
                  Please enter your phone number prefixed with the country dialing code, without appending any zeroes.
                  Eg.(+971 123 1 1234)
                </p>
              </div>
            </Col>
            <Col className="col-md-6 col-sm-12 mb-3 mb-md-auto">
              <div className="form-label-group form-group mb-0">
                <Input
                  type="text"
                  // readOnly={!editContact}
                  name="email"
                  id="email"
                  placeholder="email address"
                  disabled={loading}
                  invalid={!validator.isEmail(email ?? '')}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => {
                    setValue('email', e.target.value);
                    setEmail(e.target.value);
                  }}
                />
                <Label for="email">Email Address</Label>
                <p className="invalid-feedback">{`${!validator.isEmail(email ?? '') ? 'Invalid Email' : ''}`}</p>
              </div>
            </Col>
            {/* <Col>
              <Button
                className="px-3 claim-contact-edit"
                type="button"
                onClick={() => setEditContact(!editContact)}
                color="outline-primary"
              >
                <svg
                  className="mr-0"
                  id="Icon_PencilEdit"
                  data-name="Icon/PencilEdit"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.519"
                  height="22.519"
                  viewBox="0 0 22.519 22.519"
                >
                  <rect
                    id="Rectangle_95"
                    data-name="Rectangle 95"
                    width="4.03"
                    height="5.689"
                    transform="translate(15.639 2.849) rotate(-45)"
                    fill="#0090da"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M5.252,18.107,21.489,2l4.03,4.027L9.4,22.253Zm2.37,5.8-4.03-4.027L3,24.5Z"
                    transform="translate(-3 -1.984)"
                    fill="#007abc"
                  />
                </svg>
              </Button>
            </Col> */}
          </Row>
          <Row>
            <Col className="text-right">
              <Button color="primary" className="mr-3">
                Confirm & Next
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

StepOne.propTypes = {
  data: shape({
    dependents: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.string,
      }),
    ),
    user: shape({
      email: PropTypes.string,
      phone_number: PropTypes.string,
      bank_accounts: PropTypes.arrayOf({}),
    }),
  }).isRequired,
  onContinue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

StepOne.defaultProps = {
  loading: false,
};

export default withRouter(StepOne);
