import initialState from '../store/articles';
import userApi from '../api/UserApi';
// import userApi from '../api/UserApi';

export default {
  namespace: 'policy',

  /**
   *  Initial state
   */
  state: initialState,

  /**
   * Effects/Actions
   */
  effects: (dispatch) => ({
    /**
     * login
     * @param {obj} data
     */
    async viewDetails(data) {
      console.log(data, 'View policy details');
    },

    /**
     * register new user
     * @param {obj} data
     */
    async register(data) {
      console.log(data, 'Register');
    },

    /**
     * Verify OTP
     * @param {obj} data
     */
    async verifyOTP(data) {
      console.log(data, 'OTP');
    },

    /**
     * Verify OTP
     */
    async resendOTP() {
      console.log('RESEND OTP');
    },

    /**
     * Verify account by questions
     * @param {obj} data
     */
    async verifyAccount(data) {
      console.log('Verify Account', data);
    },

    /**
     * Get policies
     */
    async getPolicies() {
      return userApi.getPolicies();
    },

    /**
     * Get policy details
     * @param {string} policyNumber
     */
    async getPolicyDetails(policyData) {
      const res = await userApi.getPolicyDetails(policyData);
      if (res?.success) {
        dispatch.policy.replaceCurrentPolicy(res.data);
      }
      return res;
    },

    /**
     * Get providers
     */
    async getProviders(data) {
      return userApi.getProviders(data);
    },

    /**
     * Filter
     */
    async filterProvidersObject(value, type) {
      return { type, value };
    },

    /**
     * Get cities
     */
    async getCities(id) {
      return userApi.getCities(id);
    },

    /**
     * Get countries
     */
    async getCountries() {
      return userApi.getCountries();
    },

    /**
     * Get specialities
     */
    async getSpecialities() {
      return userApi.getSpecialities();
    },

    /**
     * Get facilities
     */
    async getFacilities() {
      return userApi.getFacilities();
    },

    /**
     * Generate card
     */
    async generateECard({ id, policyId }) {
      return userApi.generateECard(id, policyId);
    },

    /**
     * Get all regions
     */
    async getAllRegions() {
      return userApi.getAllRegions();
    },

    /**
     * Download TOB
     */
    async downloadTOB(data) {
      console.log('POLICY MODEL', data);
      return userApi.downloadTOB(data?.policy_id, data?.dependent_id);
    },

    /**
     * Download providers
     */
    async downloadProviders(data) {
      return userApi.downloadProviders(data);
    },

    /**
     * Download medical insurance certificate
     */
    async downloadCertificate({ type, id }) {
      console.log('data', { type, id });
      return userApi.downloadCertificate(type, id);
    },

    /**
     * Get member coverage details
     */
    async getMemberCoverageDetails(policyId) {
      return userApi.getMemberCoverageDetails(policyId);
    },
  }),

  /**
   * Reducers
   */
  reducers: {
    /**
     * current policy data
     */
    replaceCurrentPolicy(state, payload) {
      const member_details = { ...payload?.user, ...payload?.user?.info, id: payload?.user?.id };
      delete member_details?.info;
      return {
        ...state,
        currentPolicy: {
          member_details,
          dependents: payload?.dependents,
          beneficiary_details: payload?.beneficiary,
          policyNumber: payload?.ext_policy_number,
          policyNbr: payload?.policy_number,
          certificateNumber: member_details?.certificate_number,
          expiryDate: payload?.expiry_date,
          id: payload?.id,
          status: payload?.status,
        },
      };
    },
  },
};
