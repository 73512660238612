import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/Claims/UpdatePaymentInfo/UpdatePaymentInfo';

class UpdatePaymentInfo extends Component {
  files = {
    physician_notes: false,
    hospital_receipts: false,
    discharge_report: false,
    proof_of_payment: false,
    lab_tests: false,
  };

  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
      newClaim: {},
      bankSelected: true,
      isMandatoryFiles: false,
      mandatoryFilesUploaded: this.files,
      btnClick: false,
      fileSelected: 0,
    };
  }

  componentDidMount() {
    this.getBankList();
    this.getCountryList();
    this.getCurrencyList();
  }

  /**
   * get bank list
   */
  getBankList = async () => {
    const { getBankList } = this.props;
    try {
      const { success, data } = await getBankList();
      if (success) {
        this.setState((prev) => ({ ...prev, bankList: data }));
      }
      return false;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCurrencyList = async () => {
    const { getCurrencyList } = this.props;
    try {
      const { success, data } = await getCurrencyList();
      if (success) {
        this.setState((prev) => ({ ...prev, currencyList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        this.setState((prev) => ({ ...prev, countryList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  addBankAccount = async (data) => {
    const { addBankAccount, refresh, currentPolicy } = this.props;
    this.setState((pre) => ({ ...pre, loading: true }));
    try {
      const { success } = await addBankAccount(data);
      if (success) {
        refresh(currentPolicy);
      }
      this.setState((pre) => ({ ...pre, loading: false }));
      return success;
    } catch (error) {
      console.log(error);
    }
    this.setState((pre) => ({ ...pre, loading: false }));
    return [];
  };

  transform = (rawData) => ({
    claim_id: rawData?.claim_id,
    policy_id: rawData?.policy_id,
    bank_id: rawData?.bank_id,
    country_id: rawData?.country_id ?? null,
  });

  // Submit edit/updates of claim : from step 2
  onConfirm = async (data) => {
    const { updatePaymentInfo, currentPolicy, history } = this.props;
    const { newClaim } = this.state;

    const payload = {
      ...newClaim,
      ...this.transform({ ...newClaim, ...data, policy_id: currentPolicy?.id }),
    };

    const selectedBank = currentPolicy?.member_details?.bank_accounts?.filter(
      (bank) => bank?.id?.toString() === payload?.bank_id,
    )[0];
    // Checks if the selected bank contains the iban number which
    if (['234', '118', '179'].includes(selectedBank?.bank_country) && !selectedBank?.iban_no) {
      toast.custom(<Alert message="Please choose a bank account with an IBAN number. IBAN is mandatory for UAE, Qatar and Kuwait." />, {
        duration: 3000,
      });
      return;
    }

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    const resData = await updatePaymentInfo(formData);
    if (resData?.success) {
      toast.custom(
        <Alert message={`Payment info updated successfully. Tracking number is ${resData?.data?.reference_number}`} />,
        {
          duration: 3000,
        },
      );
      history.push('/claims');
    } else {
      this.setState((pre) => ({ ...pre, loading: false, success: false, message: resData?.message }));
      toast.custom(<Alert message={resData?.message} />, {
        duration: 3000,
      });
    }
  };

  deleteBank = async (bankId) => {
    const { deleteBank, refresh, currentPolicy } = this.props;
    const { success } = await deleteBank(bankId);
    if (success) {
      refresh(currentPolicy);
    }
  };

  /**
   * Render
   */
  render = () => {
    const {
      bankList,
      countryList,
      currencyList,
      loading,
      bankSelected,
      mandatoryFilesUploaded,
      btnClick,
      success,
      mems_success,
      ucrn,
      draft,
      message,
    } = this.state;
    const { currentPolicy, location } = this.props;
    return (
      <Layout
        loading={loading}
        defaultData={location?.state?.data}
        onConfirm={this.onConfirm}
        bankList={bankList}
        currencyList={currencyList}
        countryList={countryList}
        policyData={currentPolicy}
        addBankAccount={this.addBankAccount}
        deleteBank={this.deleteBank}
        bankSelected={bankSelected}
        btnClick={btnClick}
        success={success}
        memsSuccess={mems_success}
        ucrn={ucrn}
        draft={draft}
        message={message}
        mandatoryFilesUploaded={mandatoryFilesUploaded}
      />
    );
  };
}

UpdatePaymentInfo.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    member_details: PropTypes.shape({
      bank_accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.shape({}),
  }).isRequired,
  getBankList: PropTypes.func.isRequired,
  getCurrencyList: PropTypes.func.isRequired,
  getCountryList: PropTypes.func.isRequired,
  addBankAccount: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  deleteBank: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getBankList: dispatch.claim.getBankList,
  getCurrencyList: dispatch.claim.getCurrencyList,
  getCountryList: dispatch.claim.getCountryList,
  addBankAccount: dispatch.claim.addBankAccount,
  refresh: dispatch.policy.getPolicyDetails,
  deleteBank: dispatch.claim.deleteBank,
  updatePaymentInfo: dispatch.claim.UpdatePaymentInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentInfo);
