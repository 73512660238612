import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustomRoute from './Route';
import PrivateRoute from './PrivateRoute';

// Containers
import {
  ArticlesList,
  ArticlesSingle,
  ArticlesForm,
  PasswordReset,
  PolicyDetails,
  AddBeneficiary,
  Services,
  MemberPhotoUpload,
  LoginForm,
  RegisterForm,
  VerifyOTP,
  VerifyAccount,
  Dashboard,
  Certificates,
  DownloadForm,
  VideoAndManuals,
  TransactionHistory,
  PreApprovals,
  PreApprovalDetails,
  AddPreApproval,
  ForgotPassword,
  SetNewPassword,
  RecoverUsername,
  AddPhoneNumber,
  AddSecurityQuestions,
  SearchProvider,
  AddClaim,
  Profile,
  Claims,
  ClaimDetails,
  EditClaim,
  VideoTutorials,
  ContactUs,
  EditPreApproval,
  FAQ,
  ReSubmitClaim,
  ReSubmitPreApprovals,
  ComplaintForm,
  // Newsletter,
  ProviderPreApproval,
  ProviderPreApprovalDetails,
  VerifyLoginOTP,
  UpdatePassword,
  ComplaintDetails,
  ComplaintList,
  UpdatePaymentInfo,
} from '../containers';

// Components
import Error from '../components/UI/Error';
import About from '../components/About';
import PrivacyPolicy from '../components/PrivacyPolicy';

/**
 * All of the routes
 */
const Index = () => (
  <Switch>
    {/* <Route path="/" exact component={About} /> */}
    <Route path="/" exact component={LoginForm} />
    <Route path="/passwordReset/:param" exact component={PasswordReset} />

    {/* Private routes */}
    <PrivateRoute path="/home" exact component={Dashboard} />
    <PrivateRoute path="/home/policyDetails" exact component={PolicyDetails} />
    <PrivateRoute path="/home/addBeneficiary" exact component={AddBeneficiary} />
    <PrivateRoute path="/home/services" exact component={Services} />
    <PrivateRoute path="/home/memberPhotoUpload" exact component={MemberPhotoUpload} />
    <PrivateRoute path="/home/certificates" exact component={Certificates} />
    <PrivateRoute path="/home/downloadForm" exact component={DownloadForm} />
    <PrivateRoute path="/home/videoAndManuals" exact component={VideoAndManuals} />
    <PrivateRoute path="/videoTutorials" exact component={VideoTutorials} />
    <PrivateRoute path="/home/transactionHistory" exact component={TransactionHistory} />
    <PrivateRoute path="/preApprovals" exact component={PreApprovals} />
    <PrivateRoute path="/preApprovalDetails" exact component={PreApprovalDetails} />
    <PrivateRoute path="/addPreApprovals" exact component={AddPreApproval} />
    <PrivateRoute path="/editPreApproval" exact component={EditPreApproval} />
    <PrivateRoute path="/reSubmitPreApproval" exact component={ReSubmitPreApprovals} />
    <PrivateRoute path="/home/searchProvider" exact component={SearchProvider} />
    <PrivateRoute path="/addClaim" exact component={AddClaim} />
    <PrivateRoute path="/claims" exact component={Claims} />
    <PrivateRoute path="/home/providerPreApproval" exact component={ProviderPreApproval} />
    <PrivateRoute path="/home/providerPreApprovalDetails" exact component={ProviderPreApprovalDetails} />
    <PrivateRoute path="/claimDetails" exact component={ClaimDetails} />
    <PrivateRoute path="/editClaim" exact component={EditClaim} />
    <PrivateRoute path="/reSubmitClaim" exact component={ReSubmitClaim} />
    <PrivateRoute path="/updatePaymentInfo" exact component={UpdatePaymentInfo} />
    <PrivateRoute path="/profile" exact component={Profile} />
    <Route path="/contactUs" exact component={ContactUs} />
    <Route path="/addPhoneNumber" exact component={AddPhoneNumber} />
    <Route path="/addSecurityQuestion" exact component={AddSecurityQuestions} />
    <Route path="/faq" exact component={FAQ} />
    <Route path="/complaints" exact component={ComplaintForm} />
    <Route path="/complaintList" exact component={ComplaintList} />
    <Route path="/complaintDetails" exact component={ComplaintDetails} />
    <Route path="/updatePassword" exact component={UpdatePassword} />

    {/* <Route path="/newsletter" exact component={Newsletter} /> */}

    <Route path="/login" exact component={LoginForm} />
    <Route path="/verifyLoginOTP" exact component={VerifyLoginOTP} />
    <Route path="/register" exact component={RegisterForm} />
    <Route path="/verifyOTP" exact component={VerifyOTP} />
    <Route path="/verifyAccount" exact component={VerifyAccount} />
    <Route path="/forgotPassword" exact component={ForgotPassword} />
    <Route path="/setNewPassword/:param" component={SetNewPassword} />
    <Route path="/recoverUsername" exact component={RecoverUsername} />
    <Route path="/aboutUs" exact component={About} />
    <Route path="/privacyPolicy" exact component={PrivacyPolicy} />

    {/* Articles */}
    <CustomRoute path="/articles/:page?" component={ArticlesList} />
    <CustomRoute path="/article/:id" component={ArticlesSingle} />
    <CustomRoute path="/example-form" component={ArticlesForm} />

    {/* 404 */}
    <Route
      render={(props) => <Error {...props} title="404" content="Sorry, the route you requested does not exist" />}
    />
  </Switch>
);

export default Index;
