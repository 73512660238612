import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, List } from 'reactstrap';
import toast from 'react-hot-toast';
import Alert from '../../UI/Alert';

const CustomFileInput = ({ onFileSelect, type, label, btnClick, mandatoryFilesUploaded }) => {
  const file = useRef();
  const [files, setFiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const checkValid = (filesObject) => {
    let valid = true;
    Object.values(filesObject).every((singlefile) => {
      if (singlefile?.size > 8388608) {
        toast.custom(<Alert message="Documents have to be less than 8MB." />);
        valid = false;
        return false;
      }
      return true;
    });
    return valid;
  };

  const handleFileRemove = (index) => {
    const dt = new DataTransfer();
    Object.keys(files).forEach((key) => {
      if (index !== key) dt.items.add(files[key]);
    });
    setFiles(dt.files);
    onFileSelect(dt.files, type);
  };

  const handleFileChange = (fileList) => {
    const dt = new DataTransfer();

    // EXISTING FILES
    Object.keys(files).forEach((key) => {
      dt.items.add(files[key]);
    });

    // NEWLY SELECTED FILES
    Object.keys(fileList).forEach((key) => {
      dt.items.add(fileList[key]);
    });

    if (checkValid(dt.files)) {
      setFiles(dt.files);
      onFileSelect(dt.files, type);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (files.length) {
            setIsOpen(true);
          } else if (file) {
            file.current.value = null;
            file.current.click();
          }
        }}
        className={
          typeof mandatoryFilesUploaded !== 'undefined' && !Object.keys(files).length && btnClick
            ? 'p-4 border shadow-none d-flex border-radius mb-4 align-items-center pointer border-danger'
            : 'p-4 border shadow-none d-flex border-radius mb-4 align-items-center pointer'
        }
      >
        <FileIcon fill={files.length ? '#007abc' : '#808080'} />
        <h4 className="mb-0 ml-2">
          {label}
          {/* {Object.keys(mandatoryFilesUploaded).length ? <span style={{ color: 'red' }}>*</span> : ''} */}
          {/* <svg
            className="ml-3"
            id="Brand-Icons-Utility-Info"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g id="_Info_Icon_Color" data-name="🎨 Info Icon Color">
              <path
                id="Path"
                d="M16,8a8,8,0,1,1-2.343-5.657A8,8,0,0,1,16,8Z"
                transform="translate(0 0)"
                fill="#007abc"
              />
              <path
                id="Shape"
                d="M.174,10.087V3.826H2.261v6.261ZM0,1.231a1.217,1.217,0,1,1,1.227,1.2h-.01A1.217,1.217,0,0,1,0,1.231Z"
                transform="translate(6.782 2.783)"
                fill="#fff"
              />
            </g>
          </svg> */}
          {/* -----info icon------ */}
        </h4>
        <input
          type="file"
          id={`file${1 + 1}`}
          name={`file${1 + 1}`}
          ref={file}
          accept=".jpg, .jpeg, .tif, .bmp, .png, .doc, .docx, .txt, .pdf"
          style={{ display: 'none' }}
          onChange={(e) => handleFileChange(e.target.files)}
          multiple
        />
        <svg
          className="ml-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="28.001"
          height="28.001"
          viewBox="0 0 28.001 28.001"
        >
          <g id="Group_31351" data-name="Group 31351" transform="translate(1.5 1.5)">
            <path
              id="Path_69"
              data-name="Path 69"
              d="M29.5,22.5v6.019a2.9,2.9,0,0,1-2.778,3.009H7.278A2.9,2.9,0,0,1,4.5,28.519V22.5"
              transform="translate(-4.5 -6.528)"
              fill="none"
              stroke="#007abc"
              strokeWidth="3"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M21.38,12.023,15.94,4.5,10.5,12.023"
              transform="translate(-3.44 -4.5)"
              fill="none"
              stroke="#007abc"
              strokeWidth="3"
              style={{ strokeLinecap: 'round', strokeLinejoin: 'round' }}
            />
            <path
              id="Path_71"
              data-name="Path 71"
              d="M18,4.5V22.556"
              transform="translate(-5.5 -4.5)"
              fill="none"
              stroke="#007abc"
              style={{ strokeLinecap: 'round', strokeLinejoin: 'round' }}
              strokeWidth="3"
            />
          </g>
        </svg>
      </div>

      <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} style={{ width: '80vw' }}>
        <ModalHeader>{label}</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h3>Selected Files</h3>
            <Button type="button" size="sm" color="primary" onClick={() => file && file.current.click()}>
              + Add Files
            </Button>
          </div>
          <List type="unstyled" className="selected-files">
            {Object.keys(files).map((key) => (
              <li className="my-3" key={key}>
                <Card>
                  <CardBody className="d-inline-flex justify-content-between">
                    <p>{files[key].name}</p>
                    <Button color="danger" type="button" onClick={() => handleFileRemove(key)}>
                      Remove
                    </Button>
                  </CardBody>
                </Card>
              </li>
            ))}
          </List>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="outline-primary" onClick={() => setIsOpen(!isOpen)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const FileIcon = ({ fill }) => (
  <svg
    id="Icon_Folder"
    data-name="Icon/Folder"
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <rect id="Mask" width="31" height="31" fill="none" />
    <path
      id="Icon_Folder-2"
      data-name="Icon/Folder"
      d="M2,25.222a1.294,1.294,0,0,0,1.35,1.389A1.294,1.294,0,0,0,4.7,25.222V8.556H26.3V5.778H10.774L9.019,3H2ZM6.049,9.944V26.333A1.666,1.666,0,0,1,4.43,28H27.376A1.622,1.622,0,0,0,29,26.194V9.944ZM12.124,20.5a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,12.124,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,17.523,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,22.922,20.5Z"
      fill={fill}
    />
  </svg>
);

CustomFileInput.propTypes = {
  type: PropTypes.string.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  btnClick: PropTypes.bool,
  mandatoryFilesUploaded: PropTypes.shape({}),
};

CustomFileInput.defaultProps = {
  btnClick: false,
  mandatoryFilesUploaded: {},
};

FileIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default withRouter(CustomFileInput);
