import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/PasswordReset';
import userApi from '../api/UserApi';
import Encryption from '../encryption/encryption';

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      success: false,
      loading: false,
      data: {},
      passwordValidation: null,
      // email: null,
    };
  }

  // async componentDidMount() {
  //   const { email } = this.state;
  //   const data = await userApi.getAllQuestions(email);
  //   this.setState((pre) => ({ ...pre, data }));
  // }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Password Reset');
    const { onFormSubmit, history, metaData } = this.props;

    if (JSON.stringify(data.password) === JSON.stringify(data.conpassword)) {
      this.setState({ success: null, error: null, loading: true, passwordValidation: '' });

      try {
        const publicKeyData = await metaData();

        if (publicKeyData?.success) {
          const payload = data;
          const passwordEncrypted = Encryption.encrypt(publicKeyData?.data, data?.password);
          payload.password = passwordEncrypted;
          delete payload?.conpassword;
          const res = await onFormSubmit(payload);
          this.setState({
            success: res.success,
            error: !res.success,
            loading: false,
            message: res.message,
            passwordValidation: null,
          });
          if (res.success) {
            toast.custom(<Alert message={res?.message} />);
            history.push('/');
          }
        }
      } catch (error) {
        this.setState({ loading: false, success: null, error: true, message: error.message });
      }
    } else {
      this.setState({ passwordValidation: 'Password does not match' });
    }
  };

  setEmail = async (email) => {
    this.setState({ loading: true });
    const data = await userApi.getAllQuestions(email);
    this.setState((pre) => ({ ...pre, data, loading: false }));
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, message, data, passwordValidation } = this.state;

    console.log(data);

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        data={data}
        message={message}
        passwordValidation={passwordValidation}
        onFormSubmit={this.onFormSubmit}
        setEmail={this.setEmail}
      />
    );
  };
}

PasswordReset.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  metaData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.member.resetPassword,
  metaData: dispatch.encryption.metaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
