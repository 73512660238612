import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/PreApprovals/PreApprovals';

class PreApprovals extends Component {
  constructor() {
    super();
    this.state = {
      cardData: [],
    };
  }

  componentDidMount() {
    this.getPreApprovalList();
  }

  /**
   * On Form Submission
   */
  viewDetails = async (data) => {
    const { viewDetails, history } = this.props;

    try {
      const success = await viewDetails(data);
      console.log(success);
      history.push('/preApprovals/details');
    } catch (error) {
      console.log('ERROR');
    }
  };

  /**
   * Get all Pre approvals
   */
  getPreApprovalList = async (filters = {}) => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      .replace(/\//g, '-');
    const { getPreApproval, currentPolicy } = this.props;
    try {
      this.setState((prev) => ({ ...prev, loading: true }));
      // FOR SUBMITTED STATUS
      let tempFilters = filters;
      if (filters) {
        if (filters?.status === '-1') tempFilters = { ...filters, status: 0 };
      }
      const { success, data } = await getPreApproval([currentPolicy?.id, tempFilters]);
      if (success) {
        data?.forEach((preApproval, index) => {
          if (preApproval?.status === 'Pending' && preApproval?.approval_submission_date === formattedDate) {
            data[index].status = 'Submitted';
          }
        });
        let final = data;
        if (filters?.status) {
          if (filters?.status === '-1') {
            final = data?.filter((preApproval) => preApproval?.status === 'Submitted');
          } else {
            final = data?.filter((preApproval) => preApproval?.status !== 'Submitted');
            console.log('Submitted Deleted', filters);
          }
        }
        this.setState((prev) => ({ ...prev, loading: false, data: final }));
      }
      return false;
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loading: false }));
    }
    return [];
  };

  /**
   * Render
   */
  render = () => {
    // const { userInput } = this.props;
    const { data, loading } = this.state;
    const { currentPolicy } = this.props;
    return (
      <Layout
        data={data}
        policyData={currentPolicy}
        loading={loading}
        viewDetails={this.viewDetails}
        getPreApprovalList={this.getPreApprovalList}
      />
    );
  };
}

PreApprovals.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  viewDetails: PropTypes.func.isRequired,
  getPreApproval: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: dispatch.policy.viewDetails,
  getPreApproval: dispatch.pre_approval.getPreApprovals,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreApprovals);
