import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/TermsAndConditions';

class TermsAndConditions extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
    };
  }

  async componentDidMount() {
    const { getTermsAndConditions } = this.props;
    const data = await getTermsAndConditions();
    console.log(data);
  }

  /**
   * Render
   */
  render = () => {
    const { data } = this.state;
    return <Layout data={data} />;
  };
}

TermsAndConditions.propTypes = {
  data: PropTypes.shape({}).isRequired,
  getTermsAndConditions: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getTermsAndConditions: dispatch.member.getTermsAndConditions,
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
