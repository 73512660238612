// import moment from 'moment';
import initialState from '../store/articles';
// import pagination from '../lib/pagination';
import userApi from '../api/UserApi';

export default {
  namespace: 'encryption',

  /**
   *  Initial state
   */
  state: initialState,

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * RSA public key
     */
    async metaData() {
      const res = userApi.metaData();
      return res;
    },
  }),

  /**
   * Reducers
   */
  reducers: {
    /**
     * Replace list in store
     * @param {obj} state
     * @param {obj} payload
     */

    updatePublicKey(state, public_key) {
      return {
        ...state,
        public_key,
      };
    },
  },
};
