import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import Alert from '../UI/Alert';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';

const VisaInformation = ({ loading, onFormSubmit, countryList, defaultValues, data }) => {
  const { register, handleSubmit, errors, setValue, reset } = useForm({ defaultValues });
  const [button, setButton] = useState(false);

  const submit = async (profileData) => {
    const res = await onFormSubmit(profileData);
    if (res?.success) {
      setButton(false);
    }
    toast.custom(<Alert message={res?.message} />);

    // await toast.promise(onFormSubmit(profileData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    //   error: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    // });
  };

  return (
    <Card name="visaInformation">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Visa Information</Label>
          </div>
          <div className="text-right">
            {!button ? (
              <a
                className="btn-link edit"
                onClick={() => {
                  if (button) {
                    setButton(false);
                  } else {
                    setButton(true);
                    reset(defaultValues);
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                    <rect
                      id="Rectangle_95"
                      data-name="Rectangle 95"
                      width="3.4"
                      height="4.8"
                      transform="translate(16.195 4.391) rotate(-45)"
                      fill="#0090da"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                      fill="#007abc"
                    />
                  </g>
                </svg>
                Edit
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr />
        {button ? (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="passport_number"
                        id="passport_number"
                        placeholder="Passport Number"
                        disabled={loading}
                        invalid={!!errors.firstName}
                        innerRef={register({ required: errorMessages.missingfirstName })}
                        onChange={(e) => setValue('passport_number', e.target.value)}
                      />
                      <Label for="">Passport Number</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="uid_number"
                        id="uid_number"
                        placeholder="UID Number"
                        disabled
                        invalid={!!errors.uid_number}
                        innerRef={register({ required: errorMessages.missingUID_number })}
                        onChange={(e) => setValue('uid_number', e.target.value)}
                      />
                      <Label for="">UID Number</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="national_id_number"
                        id="national_id_number"
                        placeholder="National ID Number"
                        disabled
                        invalid={!!errors.national_id_number}
                        innerRef={register({ required: errorMessages.missingNationalIDNumber })}
                        onChange={(e) => setValue('national_id_number', e.target.value)}
                      />
                      <Label for="">National ID Number</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="nationality"
                        id="nationality"
                        placeholder="Nationality"
                        disabled
                        invalid={!!errors.nationality}
                        innerRef={register({ required: errorMessages.missingNationality })}
                        onChange={(e) => setValue('nationality', e.target.value)}
                      />
                      <Label for="">Nationality</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="visa_place_of_issuance"
                        id="visa_place_of_issuance"
                        placeholder="Visa Place of Issuance"
                        disabled={loading}
                        invalid={!!errors.visa_place_of_issuance}
                        innerRef={register({ required: errorMessages.missingVisaPlaceOfInsurance })}
                        onChange={(e) => setValue('visa_place_of_issuance', e.target.value)}
                      >
                        <option value="" selected disabled>
                          Visa Place of Issuance
                        </option>
                        {countryList?.map((element) => (
                          <option
                            value={element?.short_name}
                            defaultChecked={element?.short_name === data?.info?.visa_place_of_issuance_name}
                            key={element?.id}
                          >
                            {element?.short_name}
                          </option>
                        ))}
                      </Input>
                      <Label for="">Visa Place of Issuance</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="visa_number"
                        id="visa_number"
                        placeholder="Visa Number"
                        disabled={loading}
                        invalid={!!errors.visa_number}
                        innerRef={register({ required: errorMessages.missingVisaNumber })}
                        onChange={(e) => setValue('visa_number', e.target.value)}
                      />
                      <Label for="">Visa Number</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ?
                        (
                          <>
                            <Spinner
                              size="sm"
                              color="light"
                            />
                            <span>&nbsp;Loading</span>
                          </>
                        ) : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Passport Number" name={data?.info?.passport_number} />
              </li>
              <li>
                <InfoLabel title="National ID Number" name={data?.info?.national_id_number} />
              </li>
              <li>
                <InfoLabel title="Visa Place of Issuance" name={data?.info?.visa_place_of_issuance_name} />
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="UID Number" name={data?.info?.uid_number} />
              </li>
              <li>
                <InfoLabel title="Nationality" name={data?.info?.nationality} />
              </li>
              <li>
                <InfoLabel title="Visa Number" name={data?.info?.visa_number} />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

VisaInformation.propTypes = {
  data: PropTypes.shape({
    info: PropTypes.shape({
      passport_number: PropTypes.string,
      national_id_number: PropTypes.string,
      visa_place_of_issuance: PropTypes.string,
      visa_place_of_issuance_name: PropTypes.string,
      uid_number: PropTypes.string,
      nationality: PropTypes.string,
      visa_number: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    passport_number: PropTypes.string,
    national_id_number: PropTypes.string,
    visa_place_of_issuance: PropTypes.string,
    uid_number: PropTypes.string,
    nationality: PropTypes.string,
    visa_number: PropTypes.string,
  }),
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  onFormSubmit: PropTypes.func.isRequired,
};

VisaInformation.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
  countryList: [],
};

export default withRouter(VisaInformation);
