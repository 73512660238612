import React, { useState, useEffect } from 'react';
import { Alert, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const AlertExample = ({ message, isOpen = false }) => {
  const [visible, setVisible] = useState(true);
  console.log(isOpen);
  const onDismiss = () => setVisible(false);
  useEffect(() => {
    if (!isOpen) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
    }
  }, [isOpen]);

  return (
    <div className="custom-alert">
      <Alert color="info" isOpen={visible} toggle={onDismiss}>
        {!isOpen && <span className="alert-icon">!</span>}
        {isOpen && <Spinner size="sm" className="alert-icon-loading" />}
        {message}
      </Alert>
    </div>
  );
};
AlertExample.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

AlertExample.defaultProps = {
  isOpen: false,
};
export default AlertExample;
// import React from 'react';
// import PropTypes from 'prop-types';

// const Alert = ({ message, className, close }) => (
//   <div className={`custom-alert ${className}`}>
//     <h3>
//       <span className="alert-icon">!</span>
//       {message}
//     </h3>
//     <span onClick={close}> X</span>
//   </div>
// );

// Alert.propTypes = {
//   message: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   close: PropTypes.func.isRequired,
// };
// Alert.defaultProps = { className: '' };
// export default Alert;
