import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';

const SponsorDetails = ({ loading, onFormSubmit, defaultValues, data }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const [button, setButton] = useState(false);

  const submit = async (profileData) => {
    await toast.promise(onFormSubmit(profileData), {
      loading: 'Saving...',
      success: (e) => {
        setButton(false);
        return <b>{e?.message}</b>;
      },
      error: (e) => {
        setButton(false);
        return <b>{e?.message}</b>;
      },
    });
  };

  return (
    <Card name="sponsorDetails">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Sponsor Details</Label>
          </div>
        </div>
        <hr />
        {button ? (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="select"
                        name="sponsor_type"
                        id="sponsor_type"
                        placeholder="Sponsor Type"
                        disabled={loading}
                        invalid={!!errors.sponsor_type}
                        innerRef={register({ required: errorMessages.missingSponsorType })}
                        onChange={(e) => setValue('sponsor_type', e.target.value)}
                      >
                        <option value="" defaultChecked={!data?.info?.sponsor_type} disabled>Sponsor Type</option>
                        <option value="Residential" defaultChecked={data?.info?.sponsor_type === 'Residential'}>
                          Residentails
                        </option>
                        <option value="Citizen" defaultChecked={data?.info?.sponsor_type === 'Citizen'}>
                          Citizen
                        </option>
                        <option value="Establishment" defaultChecked={data?.info?.sponsor_type === 'Establishment'}>
                          Establishment
                        </option>
                        <option value="Property Owner" defaultChecked={data?.info?.sponsor_type === 'Property Owner'}>
                          Property Owner
                        </option>
                      </Input>
                      <Label for="">Sponsor Type</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="visa_sponsor_id"
                        id="visa_sponsor_id"
                        placeholder="Visa Sponsor"
                        disabled
                        invalid={!!errors.visa_sponsor_id}
                        innerRef={register({ required: errorMessages.missingVisaSponsorID })}
                        onChange={(e) => setValue('visa_sponsor_id', e.target.value)}
                      />
                      <Label for="">Visa Sponsor</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="email"
                        name="sponsor_email_address"
                        id="sponsor_email_address"
                        placeholder="Sponsor Email"
                        disabled={loading}
                        invalid={!!errors.sponsor_email_address}
                        innerRef={register({ required: errorMessages.missingSponsorEmail })}
                        onChange={(e) => setValue('sponsor_email_address', e.target.value)}
                      />
                      <Label for="">Sponsor Email</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="number"
                        name="sponsor_contact_number"
                        id="sponsor_contact_number"
                        placeholder="Sponsor Contact"
                        disabled={loading}
                        invalid={!!errors.sponsor_contact_number}
                        innerRef={register({ required: errorMessages.missingSponsorContact })}
                        onChange={(e) => setValue('sponsor_contact_number', e.target.value)}
                      />
                      <Label for="">Sponsor Contact</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ? 'Loading' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Sponsor Type" name={data?.info?.sponsor_type} />
              </li>
              <li>
                <InfoLabel title="Email ID" name={data?.info?.sponsor_email_address} />
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Visa Sponsor ID" name={data?.info?.visa_sponsor_id} />
              </li>
              <li>
                <InfoLabel title="Contact Number" name={data?.info?.sponsor_contact_number} />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

SponsorDetails.propTypes = {
  data: PropTypes.shape({
    info: PropTypes.shape({
      sponsor_type: PropTypes.string,
      visa_sponsor_id: PropTypes.string,
      sponsor_email_address: PropTypes.string,
      sponsor_contact_number: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    sponsor_type: PropTypes.string,
    visa_sponsor_id: PropTypes.string,
    sponsor_email_address: PropTypes.string,
    sponsor_contact_number: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

SponsorDetails.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
};

export default withRouter(SponsorDetails);
