import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Input, FormGroup, Label } from 'reactstrap';
import logo from '../assets/images/myMetlife-logo.png';
// import qicon from '../assets/images/pet.png';
import Footer from './UI/Footer';

const AddSecurityQuestion = ({ error, loading, success, message, questions, onSubmit }) => {
  // const [active, setActive] = useState(0);
  const [question, setQuestion] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [questionsFiltered, setQuestionsFiltered] = useState([]);

  const questionsLength = ['a', 'b', 'c'];

  // const parsePayload = (id, ans) => {
  //   const newItem = { id, answer: ans };
  //   const newPayload = [...payload];
  //   newPayload.push(newItem);
  //   setPayload(newPayload);
  // };

  useEffect(() => {
    const arr = [];
    questionsLength.forEach((e, i) => {
      arr[i] = [...questions];
    });
    setQuestionsFiltered(arr);
  }, [questions]);

  const handleNext = () => {
    let payloadData = [];
    if (question.length > 2) {
      if (answer.length > 2) {
        question.forEach((e, i) => {
          payloadData = [...payloadData, { id: e, answer: answer[i] }];
        });
      }
    }
    // if (active === questions.length - 1) {
    onSubmit(payloadData);
    // } else {
    //   setActive(active + 1);
    // }
  };

  return (
    <Container className="login full-width-page" tag="section">
      <Row className="text-center align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          <h1>Add Security Questions</h1>
          {!!success && <Alert color="success">{message}</Alert>}
          {!!error && <Alert color="danger">{message}</Alert>}
          {!!loading && <Alert color="warning">Loading...</Alert>}
          {/* <TabContent activeTab={active} className="py-4 w-100">
            <TabPane tabId={index} key={item.id}> */}
          {/* <h2>{item.question}</h2> */}
          {/* <img className="q-img" src={item?.image_path || qicon} alt="" /> */}
          {questionsLength.map((element, i) => (
            <Row key={element}>
              <Col>
                <FormGroup className="form-label-group mt-4">
                  <Input
                    type="select"
                    name={`question${i + 1}`}
                    id={`question${i + 1}`}
                    placeholder="Your answer"
                    disabled={loading}
                    className="form-control"
                    // invalid={!!errors.patient}
                    // innerRef={register()}
                    onChange={(e) => {
                      const arr = question;
                      const ans = answer;
                      arr[i] = e.target.value;
                      ans[i] = '';
                      setQuestion(question);
                      setAnswer(answer);
                      if (questionsFiltered.length) {
                        const questionsFilteredArr = questionsFiltered;
                        questionsFiltered.forEach((questionsFilteredElement, index) => {
                          if (i !== index) {
                            questionsFilteredElement.forEach((questionFilteredArrItem) => {
                              if (questionFilteredArrItem?.id === parseInt(e.target.value, 10)) {
                                questionsFilteredArr[index] = [
                                  ...questionsFilteredElement.filter(
                                    (questionFilter) => questionFilter?.id !== parseInt(e.target.value, 10),
                                  ),
                                ];
                              }
                            });
                          }
                        });
                        setQuestionsFiltered(JSON.parse(JSON.stringify(questionsFilteredArr)));
                      }
                      // changePatient(e.target.value);
                    }}
                    defaultValue={`question${i}`}
                  >
                    <option value={`question${i}`} disabled>
                      Choose Question
                    </option>
                    {questionsFiltered[i]?.map((questionElement) => (
                      <option key={questionElement?.id + element} value={questionElement?.id}>
                        {questionElement?.question}
                      </option>
                    ))}
                  </Input>
                  <Label>{`Question ${i + 1}`}</Label>
                </FormGroup>

                <FormGroup className="form-label-group">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="your answer here"
                    onInput={(e) => {
                      const ans = answer;
                      ans[i] = e.target.value;
                      setAnswer(ans);
                    }}
                  />
                  <Label>Your answer here</Label>
                </FormGroup>
              </Col>
            </Row>
          ))}
          {/* </TabPane>
          </TabContent> */}
          <Row>
            {/* <Col className="d-flex justify-content-start">
              {active ? (
                <input
                  type="button"
                  className="btn btn-primary px-lg-5"
                  value="Prev"
                  onClick={() => active && setActive(active - 1)}
                />
              ) : null}
            </Col> */}
            <Col className="d-flex justify-content-end">
              <input
                type="button"
                className="btn btn-primary px-lg-5 w-100"
                value="Continue"
                onClick={() => handleNext()}
              />
            </Col>
          </Row>
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

AddSecurityQuestion.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      image_path: PropTypes.string,
    }),
  ),
};

AddSecurityQuestion.defaultProps = {
  message: null,
  error: null,
  success: null,
  loading: false,
  defaultValues: {},
  questions: [],
};

export default withRouter(AddSecurityQuestion);
