import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Row, Container, Label, FormGroup, Input, Button, Form, List, Spinner } from 'reactstrap';
import Template from '../../Templates/Home';
import Title from '../../PolicyDetails/Title';
import { errorMessages } from '../../../constants/messages';
import CustomFileInput from './CustomFileInput';
import InfoLabel from '../../PolicyDetails/InfoLabel';
import config from '../../../constants/config';

const ReSubmitClaim = ({ defaultData, loading, policyData, onFileSelected, onFileRemoved, onConfirm }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const handleSetPayload = (formdata, draft = false) => {
    if (draft) {
      onConfirm({ ...formdata });
    } else {
      onConfirm({ ...formdata }, 'confirm');
    }
  };
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Claims', to: '/claims' },
    { label: 'Re-submit Claim', active: true },
  ];

  return (
    <Template pageTitle="Re-Submit Claim" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section color="light" className="policy-details-content metLife-gray">
          <div className="container-fluid max-container">
            <Row>
              <Col className="">
                <div className="card policy-details-tab">
                  <>
                    <Row className="justify-content-between mt-3">
                      <Col md="5">
                        <Label className="f-mlc-m display-3 tab-title">
                          {`Claim #${defaultData?.ucrn_no ? `UCRN${defaultData?.ucrn_no}` : null || defaultData?.id}`}
                        </Label>
                      </Col>
                    </Row>
                    <hr />
                    <Form onSubmit={handleSubmit((values) => handleSetPayload(values))} className="mt-3">
                      <div className="grid grid-2 info-list-wrapper">
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Policy Number" name={defaultData?.policy?.policy_number} />
                          </li>
                          <li>
                            <InfoLabel title="Insured Name" name={defaultData?.insured_name} />
                          </li>
                        </List>
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Claimed Amount" name={defaultData?.claimed_amount} />
                          </li>
                          <li>
                            <InfoLabel title="Payment Mode" name={defaultData?.payment_mode} />
                          </li>
                        </List>
                      </div>
                      <FormGroup>
                        <h3 className="mt-5">Upload Documents</h3>
                        <p>Resubmit with additional documents for reassessment</p>
                        <p>
                          Please upload at least one of the following mandatory documents
                          that are required to proceed with the claim submission:
                        </p>
                        <p className="mt-3">
                          Maximum file size allowed is 8MB/Document. Allowed file types
                          .jpg, .jpeg, .tif, .bmp, .png, .doc, .docx,
                          .txt, .pdf.
                        </p>
                        <Row>
                          <Col md={6}>
                            <CustomFileInput
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              label="Metlife claim form with physician notes/medical report"
                              type="physician_notes"
                              defaultData={defaultData?.attachments?.physician_notes}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              type="hospital_receipts"
                              defaultData={defaultData?.attachments?.hospital_receipts}
                              label="Medical Provider Receipt"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              label="Discharge Report"
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              type="discharge_report"
                              defaultData={defaultData?.attachments?.discharge_report}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              label="Proof of Payment"
                              type="proof_of_payment"
                              defaultData={defaultData?.attachments?.proof_of_payment}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              label="Results of lab tests, radiology"
                              type="lab_tests"
                              defaultData={defaultData?.attachments?.lab_tests}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              label="Other Supporting Documents"
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              type="other_supporting_docs"
                              defaultData={defaultData?.attachments?.other_supporting_docs}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileInput
                              onFileSelect={onFileSelected}
                              onFileRemoved={onFileRemoved}
                              label="Translated Documents"
                              type="translated_docs"
                              defaultData={defaultData?.attachments?.translated_docs}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="pl-4">
                              <Input
                                type="checkbox"
                                name="declaration"
                                id="declaration"
                                disabled={loading}
                                invalid={!!errors.declaration}
                                innerRef={register({ required: errorMessages.missingData })}
                                onChange={(e) => setValue('declaration', e.target.value)}
                              />
                              <Label for="declaration">
                                I have read and accept all the
                                <a
                                  href={`${config.baseURL}/claim-submission-disclaimer/AE`}
                                  className="btn-link"
                                  target="blank"
                                >
                                  {' '}
                                  Terms & conditions
                                </a>
                              </Label>
                              {errors.declaration && <p className="invalid-feedback">Please check this to continue</p>}
                            </div>
                          </Col>
                          <Col className="text-right">
                            <Button color="primary" disabled={loading}>
                              {loading ?
                                (
                                  <>
                                    <Spinner
                                      size="sm"
                                      color="light"
                                    />
                                    <span>&nbsp;Loading</span>
                                  </>
                                ) : 'SUBMIT'}
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Form>
                  </>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        )
      </>
    </Template>
  );
};

ReSubmitClaim.propTypes = {
  policyData: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  defaultData: PropTypes.shape({
    id: PropTypes.number,
    claimed_amount: PropTypes.string,
    payment_mode: PropTypes.number,
    policy: PropTypes.shape({
      policy_number: PropTypes.string,
    }),
    ucrn_no: PropTypes.number,
    insured_name: PropTypes.string,
    attachments: PropTypes.shape({
      lab_tests: PropTypes.arrayOf(PropTypes.shape),
      physician_notes: PropTypes.arrayOf(PropTypes.shape),
      hospital_receipts: PropTypes.arrayOf(PropTypes.shape),
      discharge_report: PropTypes.arrayOf(PropTypes.shape),
      proof_of_payment: PropTypes.arrayOf(PropTypes.shape),
      other_supporting_docs: PropTypes.arrayOf(PropTypes.shape),
      translated_docs: PropTypes.arrayOf(PropTypes.shape),
    }),
  }).isRequired,
};

ReSubmitClaim.defaultProps = {
  loading: false,
};

export default withRouter(ReSubmitClaim);
