import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/PolicyDetails/Services/VideoTutorials/VideoTutorials';

class VideoTutorials extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  componentDidMount() {
    this.getVideos();
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Beneficiary');
    const { onFormSubmit } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const success = await onFormSubmit(data);
      this.setState({ success, error: null, loading: false });
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  getVideos = async () => {
    const { getVideosAndManuals } = this.props;
    this.setState({ loading: true });
    const { success, data } = await getVideosAndManuals('tutorial');
    console.log(data);
    if (success) {
      this.setState({ data, loading: false });
    }
    this.setState({ loading: false });
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, policyData } = this.props;
    const { error, loading, success, data } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        data={data}
        policyData={policyData}
      />
    );
  };
}

VideoTutorials.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  policyData: PropTypes.shape({}).isRequired,
  getVideosAndManuals: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  policyData: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.articles.save,
  getVideosAndManuals: dispatch.member.getVideosAndManuals,
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoTutorials);
