import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withRouter, useParams } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
// import Template from './Templates/Dashboard';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';

const setNewPassword = ({ error, loading, success, message, data, onFormSubmit, defaultValues }) => {
  const { param } = useParams();
  const { email, token, password_reset } = JSON.parse(Buffer.from(param, 'base64').toString('ascii'));

  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: { ...defaultValues, email, token },
  });

  console.log(email, token);
  console.log(data);

  return (
    <Container className="login full-width-page" tag="section">
      <Row className="text-center align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          <h1>Set New Password</h1>
          <h3>Enter your new password</h3>
          <Form onSubmit={handleSubmit((formData) => onFormSubmit(formData, password_reset))}>
            {!!success && message && <Alert color="success">{message}</Alert>}
            {!!error && message && <Alert color="danger">{message}</Alert>}
            {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

            <FormGroup className="form-label-group">
              <Input type="text" value={email} name="user" id="user" disabled />
              <Input
                type="hidden"
                name="email"
                id="email"
                innerRef={register({ required: errorMessages.missingEmail })}
              />
              <Input
                type="hidden"
                name="token"
                id="token"
                innerRef={register({ required: errorMessages.missingEmail })}
              />
            </FormGroup>
            <FormGroup className="form-label-group">
              <Input
                type="password"
                name="password"
                autoComplete="off"
                id="password"
                placeholder="New Password"
                disabled={loading}
                invalid={!!errors.password}
                innerRef={register({
                  required: errorMessages.missingPassword,
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                    message:
                      'Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one number',
                  },
                })}
                onChange={(e) => setValue('password', e.target.value)}
              />
              <Label>New Password</Label>
              {errors.password ? (
                <p className="invalid-feedback">{errors.password.message}</p>
              ) : (
                <p style={{ fontSize: '12px', textAlign: 'left', padding: '2px 8px' }}>
                  Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one
                  number
                </p>
              )}
            </FormGroup>
            <FormGroup className="form-label-group">
              <Input
                type="password"
                name="conpassword"
                autoComplete="off"
                id="conpassword"
                placeholder="Confirm password"
                disabled={loading}
                invalid={!!errors.conpassword}
                innerRef={register({
                  required: true,
                  validate: (val) => {
                    const { password } = getValues();
                    return password === val || errorMessages.passwordsDontMatch;
                  },
                })}
                onChange={(e) => setValue('conpassword', e.target.value)}
              />
              <Label>Confirm Password</Label>
              {errors.conpassword && <p className="invalid-feedback">{errors.conpassword.message}</p>}
            </FormGroup>
            <Button color="primary" disabled={loading} style={{ width: '100%' }}>
              {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
            </Button>
          </Form>
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

setNewPassword.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.string,
  message: PropTypes.string,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  defaultValues: PropTypes.shape({}),
  onFormSubmit: PropTypes.func.isRequired,
};

setNewPassword.defaultProps = {
  error: null,
  success: null,
  message: '',
  data: {},
  loading: false,
  defaultValues: {},
};

export default withRouter(setNewPassword);
