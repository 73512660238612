import React from 'react';
import PropTypes, { bool, node } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const InfoLabel = ({ title, name, link, download, tooltip }) => (
  <>
    <div className="d-flex justify-content-between">
      <Label>
        {title}
        {tooltip !== '' ? <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-primary" title={tooltip} /> : null}
      </Label>

      {link && !name ? (
        <Link
          to="#"
          onClick={() => {
            download();
          }}
        >
          View/Download
        </Link>
      ) : (
        <h4>{name || ''}</h4>
      )}
    </div>
  </>
);

InfoLabel.propTypes = {
  title: node,
  name: node,
  link: bool,
  download: PropTypes.func,
  tooltip: PropTypes.string,
};

InfoLabel.defaultProps = {
  title: '',
  name: '',
  link: false,
  tooltip: '',
  download: () => {},
};

export default withRouter(InfoLabel);
