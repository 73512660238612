import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/PreApprovals/ReSubmitPreApproval/ReSubmitPreApproval';

class ReSubmitPreApprovals extends Component {
  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
    };
  }

  onFileSelected = (fileList, type) => {
    if (fileList.length) {
      this.setState((pre) => ({
        ...pre,
        newPreApp: { ...pre.newPreApp, [type]: fileList },
      }));
    }
  };

  // Re Submit Pre Approval
  onSubmit = async (data) => {
    const { newPreApp } = this.state;
    const { currentPolicy, reSubmitPreApproval, history, location } = this.props;

    const payload = {
      ...newPreApp,
      policy_id: currentPolicy?.id,
      pre_approval_id: location?.state?.data?.id,
      ...data,
    };

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
        });
        return;
      }
      formData.append(key, payload[key]);
    });

    // POST REQUEST
    this.setState((pre) => ({ ...pre, loading: true }));
    const { success } = await reSubmitPreApproval(formData);
    if (success) {
      history.push('/preApprovals');
    }
    this.setState((pre) => ({ ...pre, loading: false }));
  };

  /**
   * Render
   */
  render = () => {
    const { currentPolicy, location } = this.props;
    const { loading } = this.state;
    return (
      <Layout
        defaultData={location?.state?.data}
        onConfirm={this.onSubmit}
        policyData={currentPolicy}
        onFileSelected={this.onFileSelected}
        loading={loading}
      />
    );
  };
}

ReSubmitPreApprovals.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  reSubmitPreApproval: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  reSubmitPreApproval: dispatch.pre_approval.reSubmitPreApproval,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReSubmitPreApprovals);
