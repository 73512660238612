import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Topbar from './TopBar';

const Content = ({ isOpen, toggle, children, pageTitle, breadcrumb }) => (
  <Container fluid className={classNames('content', { 'is-open': isOpen })}>
    <Topbar toggle={toggle} />
    <div className="inner-content">
      <div className="container-fluid max-container">
        <div className="page-header">
          {breadcrumb.length ? (
            <div className="breadcrumb-wrap">
              <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                {breadcrumb.map((item) => (
                  <li key={item?.label} className={`${item?.active ? 'active' : ''} breadcrumb-item`}>
                    {item?.active ? item?.label : <Link to={item?.to}>{item?.label}</Link>}
                  </li>
                ))}
              </ol>
            </div>
          ) : null}
          <h2 className="page-title">{pageTitle}</h2>
        </div>
      </div>
      {children}
    </div>
  </Container>
);

Content.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.element.isRequired,
  pageTitle: PropTypes.string.isRequired,
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Content.defaultProps = {
  isOpen: true,
};

export default Content;
