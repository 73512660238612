import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Toaster } from 'react-hot-toast';
import { Link } from 'react-scroll';
import { Col, Container, Label, Row } from 'reactstrap';
import Template from '../Templates/Profile';
import PersonalDetails from './PersonalDetails';
import WorkAndResidentialDetails from './WorkAndResidentialDetails';
import VisaInformation from './VisaInformation';
import SponsorDetails from './SponsorDetails';
import SalaryAndOtherInfo from './SalaryAndOtherInfo';
import RIClaims from './RIClaims';
import LoginCredentials from './LoginCredentials';
import Loading from '../UI/Loading';

const profile = ({
  menu,
  data,
  error,
  loading,
  success,
  type,
  listLoading,
  message,
  getUserProfile,
  submitUserProfile,
  submitLoginCredentials,
  addBank,
  users,
  photoUpload,
  isPrincipleUser,
  getBankList,
  getCurrencyList,
  deleteBank,
  getCountryList,
  stateList,
  props,
  bankDeleteLoading,
}) => {
  const defaultValues = { ...data, ...data?.info };
  const [activeUrl, setActiveUrl] = useState('personalDetails');
  const [height, setHeight] = useState(null);
  setTimeout(() => {
    const cardHeight = document.getElementsByName(isPrincipleUser ? 'loginCredentials' : 'salaryAndOtherInfo')[0]
      ?.clientHeight;
    const footerHeight = document.getElementsByTagName('footer')[0]?.clientHeight;
    const bodyHeight = document.getElementsByTagName('body')[0]?.clientHeight;
    setHeight(`${bodyHeight - cardHeight - footerHeight}px`);
  });

  return (
    <Template pageTitle="Profile">
      <section className="bg-gray-200 py-4 metLife-gray">
        <Container fluid className="max-container">
          <Row>
            <Col md="3" className="profile-sidemenu sticky">
              <ul>
                {menu.map((element, index) => (
                  <li key={element.id}>
                    <Link
                      className={activeUrl === element.url ? 'active' : ''}
                      onClick={() => {
                        if (
                          isPrincipleUser &&
                          (element?.menu === 'Reimbursement Claims' || element?.menu === 'Login Credentials')
                        ) {
                          menu.forEach((e, i) => {
                            if (index === i) {
                              e.active = true;
                              setActiveUrl(e.id);
                            } else {
                              e.active = false;
                            }
                          });
                        }
                        if (element?.url === 'newsletter') {
                          props.history.push(`/${element?.url}`);
                        }
                      }}
                      to={element.url}
                      spy
                      smooth
                      duration={500}
                      onSetActive={(e) => {
                        setActiveUrl(e);
                      }}
                      offset={-100}
                    >
                      <Label>{element.menu}</Label>
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
            {
              !listLoading ?
                (
                  <Col className="profile-content">
                    <PersonalDetails
                      data={data}
                      error={error}
                      loading={loading}
                      success={success}
                      name="personalDetails"
                      type={type}
                      message={message}
                      getUserProfile={getUserProfile}
                      users={users}
                      photoUpload={photoUpload}
                      defaultValues={defaultValues}
                      onFormSubmit={submitUserProfile}
                    />
                    <WorkAndResidentialDetails
                      data={data}
                      error={error}
                      loading={loading}
                      success={success}
                      name="workAndResidentialDetails"
                      type={type}
                      message={message}
                      countryList={getCountryList}
                      stateList={stateList}
                      defaultValues={defaultValues}
                      onFormSubmit={submitUserProfile}
                    />
                    <VisaInformation
                      data={data}
                      error={error}
                      loading={loading}
                      success={success}
                      name="visaInformation"
                      type={type}
                      message={message}
                      countryList={getCountryList}
                      defaultValues={defaultValues}
                      onFormSubmit={submitUserProfile}
                    />
                    <SponsorDetails
                      data={data}
                      error={error}
                      loading={loading}
                      success={success}
                      name="sponsorDetails"
                      type={type}
                      message={message}
                      defaultValues={defaultValues}
                      onFormSubmit={submitUserProfile}
                    />
                    <SalaryAndOtherInfo
                      data={data}
                      error={error}
                      loading={loading}
                      success={success}
                      name="salaryAndOtherInfo"
                      type={type}
                      message={message}
                      defaultValues={defaultValues}
                      onFormSubmit={submitUserProfile}
                      height={!isPrincipleUser ? height : null}
                    />
                    {isPrincipleUser ? (
                      <RIClaims
                        data={data}
                        error={error}
                        loading={loading}
                        success={success}
                        name="RIClaims"
                        type={type}
                        message={message}
                        defaultValues={data}
                        onFormSubmit={submitUserProfile}
                        addBank={addBank}
                        getBankList={getBankList}
                        getCountryList={getCountryList}
                        deleteBank={deleteBank}
                        getCurrencyList={getCurrencyList}
                        bankDeleteLoading={bankDeleteLoading}
                      />
                    ) : (
                      ''
                    )}
                    {isPrincipleUser ? (
                      <LoginCredentials
                        data={data}
                        error={error}
                        loading={loading}
                        success={success}
                        name="loginCredentials"
                        type={type}
                        message={message}
                        defaultValues={defaultValues}
                        onFormSubmit={submitLoginCredentials}
                        height={height}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                ) : ''
            }
            {(!Object.keys(data).length && loading) || listLoading ? (
              <Loading />
            ) : ''}
          </Row>
        </Container>
      </section>
    </Template>
  );
};

profile.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    info: PropTypes.shape({
      certificate_number: PropTypes.string,
      residing_country: PropTypes.string,
      residing_location: PropTypes.string,
      residing_emirates: PropTypes.string,
      work_location: PropTypes.string,
      work_emirates: PropTypes.string,
    }),
  }),
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.number,
  listLoading: PropTypes.bool,
  success: PropTypes.bool,
  photoUpload: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  submitUserProfile: PropTypes.func.isRequired,
  submitLoginCredentials: PropTypes.func.isRequired,
  addBank: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  isPrincipleUser: PropTypes.bool,
  getBankList: PropTypes.arrayOf(PropTypes.shape({})),
  getCountryList: PropTypes.arrayOf(PropTypes.shape({})),
  getCurrencyList: PropTypes.arrayOf(PropTypes.shape({})),
  deleteBank: PropTypes.func.isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})),
  props: PropTypes.shape({
    history: PropTypes.shape({}),
  }).isRequired,
  bankDeleteLoading: PropTypes.bool,
};

profile.defaultProps = {
  menu: [],
  data: {},
  message: null,
  error: null,
  success: null,
  type: null,
  loading: false,
  getBankList: [],
  users: [],
  isPrincipleUser: true,
  getCountryList: [],
  getCurrencyList: [],
  stateList: [],
  bankDeleteLoading: false,
};

export default withRouter(profile);
