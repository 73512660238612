import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Template from '../../Templates/Home';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import Title from '../../PolicyDetails/Title';
import PreApprovalMessage from '../PreApprovalMessage';

const EditPreApproval = ({
  onContinue,
  onSubmit,
  policyData,
  currencyList,
  countryList,
  active,
  setActive,
  success,
  memsSuccess,
  referenceNumber,
  draft,
  // message,
  onFileSelected,
  data,
  onFileRemoved,
  loading,
}) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Pre-Approvals', to: '/preApprovals' },
    { label: 'Edit Pre-Approvals', active: true },
  ];
  return (
    <Template pageTitle="Edit PreApproval" breadcrumb={breadcrumb}>
      <Fragment>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section color="light" className="policy-details-content metLife-gray">
          {!success ? (
            <div className="container-fluid max-container">
              <Row>
                <Col className="">
                  <div className="card policy-details-tab">
                    <Nav className="w-100" tabs>
                      <NavItem>
                        <NavLink
                          className={active === '1' ? 'active' : ''}
                          onClick={() => {
                            setActive('1');
                          }}
                        >
                          Validate Personal Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={active === '2' ? 'active' : ''}
                          onClick={() => {
                            setActive('2');
                          }}
                        >
                          Submit Pre-Approval details
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={active} className="py-4 w-100">
                      <TabPane tabId="1">
                        <StepOne data={data} onContinue={onContinue} policyData={policyData} />
                      </TabPane>
                      <TabPane tabId="2">
                        <StepTwo
                          loading={loading}
                          data={data}
                          onSubmit={onSubmit}
                          currencyList={currencyList}
                          countryList={countryList}
                          onFileSelected={onFileSelected}
                          onFileRemoved={onFileRemoved}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <PreApprovalMessage memsSuccess={memsSuccess} referenceNumber={referenceNumber} draft={draft} />
          )}
        </section>
      </Fragment>
    </Template>
  );
};

EditPreApproval.propTypes = {
  active: PropTypes.string.isRequired,
  policyData: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
  }).isRequired,
  success: PropTypes.bool,
  memsSuccess: PropTypes.bool,
  referenceNumber: PropTypes.string,
  draft: PropTypes.bool,
  // message: PropTypes.string,
  onContinue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})),
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  setActive: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

EditPreApproval.defaultProps = {
  currencyList: [],
  countryList: [],
  success: false,
  memsSuccess: false,
  referenceNumber: '',
  draft: false,
  // message: '',
};

export default withRouter(EditPreApproval);
