import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row, List, Card, CardBody, Label } from 'reactstrap';
import moment from 'moment';
import Template from '../Templates/Home';
import Title from '../PolicyDetails/Title';
import InfoLabel from '../PolicyDetails/InfoLabel';

const ProviderPreApprovalDetails = ({ data, policyData }) => {
  //   const { register, setValue, getValues, reset } = useForm();
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Provider PreApproval', to: '/home/providerPreApproval' },
    { label: 'Provider PreApproval Details', active: true },
  ];
  return (
    <Template pageTitle="Provider PreApproval Details" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Fragment>
            <Title
              policyNumber={policyData?.policyNumber}
              certificateNumber={policyData?.certificateNumber}
              expiryDate={policyData?.expiryDate}
            />
          </Fragment>
        </Container>
        <section className="metLife-gray">
          <Container fluid className="max-container">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Label className="f-mlc-m display-3 tab-title">
                      {`Authorization #${
                        data?.authorization_reference
                          ? `${data?.authorization_reference}`
                          : null || data?.authorization_reference
                      }`}
                      <span className="badge badge-secondary ml-3">
                        <small>{data?.status}</small>
                      </span>
                    </Label>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <div className="grid grid-2 info-list-wrapper">
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Authorization" name={data?.authorization_reference} />
                        </li>
                        <li>
                          <InfoLabel title="Submitted On" name={moment(data?.submit_date).format('DD-MM-YYYY')} />
                        </li>
                        <li>
                          <InfoLabel title="Processed On" name={moment(data?.processed_date).format('DD-MM-YYYY')} />
                        </li>
                      </List>
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Hospital" name={data?.hospital} />
                        </li>
                        <li>
                          <InfoLabel title="Reason" name={data?.rejection_reason} />
                        </li>
                      </List>
                    </div>
                    <br />
                    <br />
                    <Row>
                      <Col className="mb-3">
                        <h3>Principal Diagnosis:</h3>
                        <div className="d-flex flex-column">
                          <Label className="mb-1">{data?.principal_diagnosis?.diagnosis_description}</Label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <h3>Other Diagnosis:</h3>
                        <div className="d-flex flex-column">
                          {data?.other_diagnosis?.map((item) => (
                            <Label className="mb-1">{item?.diagnosis_description}</Label>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <h3>Procedure:</h3>
                        <div className="d-flex flex-column">
                          <ul className="procedure-list">
                            {data?.procedures?.map((item) => (
                              <li className="mb-2">
                                <Label className="mb-1">{item?.procedure_description}</Label>
                                <p className="small font-weight-bold">
                                  Status: &nbsp;
                                  {item?.status}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
      </>
    </Template>
  );
};

ProviderPreApprovalDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    dependents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        member_name: PropTypes.string,
      }),
    ),
    member_details: PropTypes.shape({
      member_name: PropTypes.string,
    }),
  }).isRequired,
};

ProviderPreApprovalDetails.defaultProps = {
  data: [],
};

export default withRouter(ProviderPreApprovalDetails);
