import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DependentInfoCard from './DependentInfoCard';

const DependentInfo = ({ data, generateECard, downloadTOB, status }) => (
  <Col>
    <Row className="my-3">
      <Col>
        <Label className="f-mlc-m display-3 tab-title">Dependents</Label>
      </Col>
    </Row>
    <Row>
      <Col className="p-0">
        {data.length
          ? data.map((element) => (
              <DependentInfoCard
                data={element}
                key={element.id}
                generateECard={generateECard}
                downloadTOB={downloadTOB}
                status={status}
              />
            ))
          : 'No dependents found.'}
      </Col>
    </Row>
  </Col>
);

DependentInfo.propTypes = {
  data: PropTypes.arrayOf(shape({})),
  generateECard: PropTypes.func.isRequired,
  downloadTOB: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
};

DependentInfo.defaultProps = {
  data: [],
};

export default withRouter(DependentInfo);
