import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Toaster } from 'react-hot-toast';

import configureStore from './store/index';
import * as serviceWorker from './lib/service-worker';
import Routes from './routes/index';

// Components
import Loading from './components/UI/Loading';

// Load css
import './assets/styles/style.scss';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

const { persistor, store, dispatch } = configureStore();
// persistor.purge(); // Debug to clear persist

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Toaster
        toastOptions={{
          style: {
            padding: '5px 10px',
            minWidth: '50%',
            maxWidth: '50%',
            textAlign: 'left',
            fontSize: '15px',
            boxShadow: 'none',
          },
          icon: null,
          loading: {
            style: {
              background: 'transparent',
            },
          },
          success: {
            style: {
              background: 'transparent',
            },
          },
          error: {
            style: {
              background: 'transparent',
            },
          },
        }}
      />
      <Router dispatch={dispatch} store={store} basename="/metlife-app">
        <Routes />
      </Router>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
