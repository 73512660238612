import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/Login';
import Encryption from '../encryption/encryption';

class LoginFormContainer extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      success: null,
      loading: false,
      passwordExpired: false,
      member_name: null,
      email: null,
      token: null,
      loginOTPData: null,
      guideVideoURL: null,
      thumbnail: null,
    };
  }

  async componentDidMount() {
    const { logOut, guideVideo } = this.props;
    const { success, data } = await guideVideo();
    if (success) {
      this.setState((value) => ({ ...value, guideVideoURL: data?.video_url, thumbnail: data?.thumbnail }));
    } else {
      console.log('guide video url error');
    }
    await logOut();
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { login, history, me, metaData } = this.props;

    this.setState(((val) => ({ ...val, success: null, error: null, loading: true })));
    const res = this.state;
    try {
      const publicKeyData = await metaData();

      if (publicKeyData?.success) {
        const encryptedPassword = Encryption.encrypt(publicKeyData?.data, data?.password);
        const payload = {
          ...data,
          password: encryptedPassword,
          browser_finger_print: res?.browserFingerPrint,
        };
        const {
          success,
          token,
          message,
          account_verified,
          phone_number_verified,
          has_phone_number,
          password_expired,
          member_name,
        } = await login(payload);
        this.setState({ error: null, loading: false, passwordExpired: false });
        if (success) {
          if (phone_number_verified) {
            // password expired
            if (password_expired) {
              console.log('password expired');
              // history.push({ pathname: '/updatePassword', state: { email: data?.email } });
              this.setState({
                passwordExpired: true,
                member_name,
                email: data?.email,
                token,
                loginOTPData: { ...data, publicKey: publicKeyData?.data },
              });
              return;
            }
            this.setState({ success });
            // Account is also considered verified
            if (token) {
              me();
              history.push('/home');
            } else {
              history.push({
                pathname: '/verifyLoginOTP',
                state: {
                  ...data,
                  publicKey: publicKeyData?.data,
                },
              });
            }
          } else if (has_phone_number) {
            history.push('/verifyOTP');
          } else if (!account_verified) {
            //   Has no phone number
            history.push('/verifyAccount'); // Question-answer page
          } else if (account_verified && !has_phone_number) {
            history.push('addPhoneNumber');
          } else if (account_verified) {
            history.push('/verifyOTP');
          }
        } else {
          this.setState({ success, error: true, message, loading: false });
        }
      } else {
        this.setState({
          success: publicKeyData?.success,
          error: true,
          message: publicKeyData?.message,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, message: error.message, error: true });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, me } = this.props;
    const {
      error,
      loading,
      success,
      message,
      passwordExpired,
      member_name,
      token,
      loginOTPData,
      email,
      guideVideoURL,
      thumbnail,
    } = this.state;
    console.log(guideVideoURL);

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
        isOpenAlert={passwordExpired}
        member_name={member_name}
        token={token}
        email={email}
        me={me}
        loginOTPData={loginOTPData}
        guideVideoURL={guideVideoURL}
        thumbnail={thumbnail}
      />
    );
  };
}

LoginFormContainer.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  metaData: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  guideVideo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  login: dispatch.member.login,
  metaData: dispatch.encryption.metaData,
  logOut: dispatch.member.logOut,
  me: dispatch.member.me,
  guideVideo: dispatch.member.guideVideo,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormContainer);
