import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/Claims/EditClaim/EditClaim';
// import style from '../assets/styles/ToasterStyle';

class EditClaim extends Component {
  files = {
    physician_notes: false,
    hospital_receipts: false,
    discharge_report: false,
    proof_of_payment: false,
    lab_tests: false,
  };

  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
      newClaim: {},
      bankSelected: true,
      isMandatoryFiles: false,
      mandatoryFilesUploaded: this.files,
      btnClick: false,
      fileSelected: 0,
      bankDeleteLoading: false,
    };
  }

  componentDidMount() {
    this.getBankList();
    this.getCountryList();
    this.getCurrencyList();
    const { location } = this.props;
    const locationObj = location;
    Object.keys(this.files).forEach((item) => {
      if (locationObj?.state?.data?.attachments && locationObj?.state?.data?.attachments[item]?.length) {
        this.files[item] = true;
        this.setState((pre) => ({ ...pre, fileSelected: pre?.fileSelected + 1 }));
      }
    });
  }

  /**
   * get bank list
   */
  getBankList = async () => {
    const { getBankList } = this.props;
    try {
      const { success, data } = await getBankList();
      if (success) {
        this.setState((prev) => ({ ...prev, bankList: data }));
      }
      return false;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCurrencyList = async () => {
    const { getCurrencyList } = this.props;
    try {
      const { success, data } = await getCurrencyList();
      if (success) {
        this.setState((prev) => ({ ...prev, currencyList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        this.setState((prev) => ({ ...prev, countryList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  addBankAccount = async (data) => {
    const { addBankAccount, refresh, currentPolicy } = this.props;
    this.setState((pre) => ({ ...pre, loading: true }));
    try {
      const { success, message } = await addBankAccount(data);
      if (success) {
        toast.custom(<Alert message="Bank Account Added" />);
        refresh(currentPolicy);
      } else {
        toast.custom(<Alert message={message} />);
      }
      this.setState((pre) => ({ ...pre, loading: false }));
      return success;
    } catch (error) {
      console.log(error);
    }
    this.setState((pre) => ({ ...pre, loading: false }));
    return [];
  };

  transform = (rawData) => ({
    claim_id: rawData?.claim_id,
    policy_id: rawData?.policy_id,
    claim_date: rawData?.claim_date || '',
    claim_type: rawData?.claim_type || '',
    payable: rawData?.payable_to || '',
    dependent_id: rawData?.patient !== '0' ? rawData?.patient : '',
    treatment_date: rawData?.treatment_date || '',
    additional_info: '',
    bank_name: rawData?.bank_name || '',
    iban_no: rawData?.iban_no || '',
    swift: rawData?.swift || '',
    bank_address: rawData?.ifsc_or_aba || '',
    claimed_amount: rawData?.claimed_amount || '',
    payment_method: rawData?.payment_method || '',
    scheduled_to_pay: rawData?.claim_date || '',
    account_number: rawData?.account_no || '',
    status: rawData?.status,
    country_id: rawData?.country_id,
    currency_id: rawData?.currency_id || null,
    submission_type: 'member_portal',
  });

  // Submit edit/updates of claim : from step 2
  onConfirm = async (data, status = 'draft') => {
    const { editClaim, currentPolicy, history } = this.props;
    const { newClaim, deleted_docs } = this.state;

    // let isMandatory = null;
    // if (status !== 'draft') {
    //   this.setState({ btnClick: true });

    //   const locationObj = location;

    //   Object.keys(this.files).forEach((item) => {
    //     if (
    //       Object.keys(newClaim[item] || {})?.length ||
    //       (locationObj?.state?.data?.attachments && locationObj?.state?.data?.attachments[item]?.length)
    //     ) {
    //       this.files[item] = true;
    //     } else {
    //       this.files[item] = false;
    //     }
    //   });

    //   // if (locationObj?.state?.data?.attachments[type]?.length) {
    //   //   this.files[type] = await true;
    //   // } else {
    //   //   this.files[type] = await false;
    //   // }

    //   isMandatory = true;

    //   Object.keys(this.files).forEach((item) => {
    //     if (!this.files[item]) {
    //       isMandatory = false;
    //     }
    //   });
    // }

    // if (isMandatory || status === 'draft') {
    const payload = {
      ...newClaim,
      ...this.transform({ ...newClaim, ...data, status, policy_id: currentPolicy?.id }),
    };

    const selectedBank = currentPolicy?.member_details?.bank_accounts?.filter(
      (bank) => bank?.id?.toString() === payload?.bank_id,
    )[0];
    // Checks if the selected bank contains the iban number which
    if (['234', '118', '179'].includes(selectedBank?.bank_country) && !selectedBank?.iban_no) {
      toast.custom(<Alert message="Please choose a bank account with an IBAN number. IBAN is mandatory for UAE, Qatar and Kuwait." />, {
        duration: 3000,
      });
      return;
    }

    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (key === 'claimed_amount') {
        payload[key] = payload[key].replaceAll(',', '');
      }
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
          this.setState((pre) => ({ ...pre, fileSelected: pre?.fileSelected + 1 }));
        });
        return;
      }
      formData.append(key, payload[key]);
    });

    // deleted docs array
    if (deleted_docs?.length) {
      deleted_docs.forEach((item, index) => {
        formData.append(`deleted_docs[${index}]`, item);
        this.setState((pre) => ({ ...pre, fileSelected: pre?.fileSelected - 1 }));
      });
    }

    const { fileSelected } = this.state;
    if (fileSelected !== 0 || status === 'draft') {
      this.setState((pre) => ({ ...pre, loading: true }));
      // POST REQUEST
      const resData = await editClaim(formData);
      if (resData?.success) {
        if (status === 'draft') {
          toast.custom(<Alert message={`Claim - Drafted successfully. Tracking number is ${resData?.reference_number}`} />);
          history.push('/claims');
        } else {
          this.setState((pre) => ({
            ...pre,
            loading: false,
            success: true,
            mems_success: resData?.mems_success,
            ucrn: resData?.reference_number,
            draft: status === 'draft',
          }));
        }
      } else {
        this.setState((pre) => ({ ...pre, loading: false, success: false, message: resData?.message }));
        toast.custom(<Alert message={data?.message} />);
      }
    } else {
      toast.custom(<Alert message="Please choose at least 1 Document" />);
    }
    // } else {
    //   console.log('Mandatory files');
    // }
  };

  // The continue button in step 1
  // this function only saves the current state in client
  onContinue = async (data, validation) => {
    if (validation) {
      if (data?.bank_id) {
        this.setState((pre) => ({
          ...pre,
          newClaim: { ...pre?.newClaim, ...data, payment_method: '0' },
          active: '2',
          firstStepPassed: true,
          bankSelected: true,
        }));
        console.log('CONTINUE');
      } else {
        this.setState((pre) => ({ ...pre, newClaim: { ...pre?.newClaim, ...data }, bankSelected: false }));
        console.log('STOP');
      }
    }
  };

  setActive = (tab) => {
    const { firstStepPassed } = this.state;
    if (tab === '2' && firstStepPassed) {
      this.setState((pre) => ({ ...pre, active: tab }));
    } else if (tab === '1') {
      this.setState((pre) => ({ ...pre, active: tab }));
    }
  };

  onFileSelected = async (fileList, type) => {
    const { location } = this.props;
    console.log(fileList);
    console.log(location?.state?.data);
    if (fileList.length) {
      this.files[type] = true;
      await this.setState((pre) => ({
        ...pre,
        newClaim: { ...pre.newClaim, [type]: fileList },
        mandatoryFilesUploaded: this.files,
      }));
    } else {
      await this.setState((pre) => ({
        ...pre,
        newClaim: { ...pre.newClaim, [type]: {} },
        mandatoryFilesUploaded: this.files,
      }));
    }
  };

  deleteBank = async (bankId) => {
    const { deleteBank, refresh, currentPolicy } = this.props;
    console.log(bankId);
    this.setState((val) => ({ ...val, bankDeleteLoading: true }));
    const { success, message } = await deleteBank(bankId);
    if (success) {
      await refresh(currentPolicy);
      toast.custom(<Alert message="Bank Account Deleted" />);
    } else {
      toast.custom(<Alert message={message} />);
    }
    this.setState((val) => ({ ...val, bankDeleteLoading: false }));
  };

  // Method to remove existing files
  onFileRemoved = async (file_id, type) => {
    const { newClaim } = this.state;
    const { location } = this.props;
    const locationObj = location;
    console.log(file_id);
    console.log(location?.state?.data);
    console.log(newClaim);
    if (file_id) {
      locationObj?.state?.data?.attachments[type].forEach((item, index) => {
        if (item?.id === file_id) {
          locationObj?.state?.data?.attachments[type].splice(index, 1);
        }
      });
      console.log(locationObj?.state?.data?.attachments[type]?.length, newClaim);
      if (locationObj?.state?.data?.attachments[type]?.length) {
        this.files[type] = await true;
      } else {
        this.files[type] = await false;
      }
      await console.log(locationObj?.state?.data?.attachments[type]?.length);
      await this.setState((pre) => ({
        ...pre,
        deleted_docs: [...(pre?.deleted_docs || []), file_id],
        mandatoryFilesUploaded: this.files,
      }));
    }
  };

  /**
   * Render
   */
  render = () => {
    const {
      bankList,
      countryList,
      currencyList,
      active,
      loading,
      bankSelected,
      mandatoryFilesUploaded,
      btnClick,
      success,
      mems_success,
      ucrn,
      draft,
      message,
      bankDeleteLoading,
    } = this.state;
    const { currentPolicy, location } = this.props;
    return (
      <Layout
        loading={loading}
        data={location?.state?.data}
        defaultData={location?.state?.data}
        onConfirm={this.onConfirm}
        onContinue={this.onContinue}
        bankList={bankList}
        currencyList={currencyList}
        countryList={countryList}
        policyData={currentPolicy}
        active={active}
        setActive={this.setActive}
        addBankAccount={this.addBankAccount}
        onFileSelected={this.onFileSelected}
        deleteBank={this.deleteBank}
        onFileRemoved={this.onFileRemoved}
        bankSelected={bankSelected}
        btnClick={btnClick}
        success={success}
        memsSuccess={mems_success}
        ucrn={ucrn}
        draft={draft}
        message={message}
        mandatoryFilesUploaded={mandatoryFilesUploaded}
        bankDeleteLoading={bankDeleteLoading}
      />
    );
  };
}

EditClaim.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    member_details: PropTypes.shape({
      bank_accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.shape({}),
  }).isRequired,
  getBankList: PropTypes.func.isRequired,
  getCurrencyList: PropTypes.func.isRequired,
  getCountryList: PropTypes.func.isRequired,
  addBankAccount: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  editClaim: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  deleteBank: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getBankList: dispatch.claim.getBankList,
  getCurrencyList: dispatch.claim.getCurrencyList,
  getCountryList: dispatch.claim.getCountryList,
  addBankAccount: dispatch.claim.addBankAccount,
  refresh: dispatch.policy.getPolicyDetails,
  deleteBank: dispatch.claim.deleteBank,
  editClaim: dispatch.claim.editClaim,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClaim);
