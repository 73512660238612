import React from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Container } from 'reactstrap';
import ClaimMessage from '../ClaimMessage';
import Template from '../../Templates/Home';
import Title from '../../PolicyDetails/Title';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const AddClaim = ({
  loading,
  data,
  onConfirm,
  onContinue,
  policyData,
  bankList,
  currencyList,
  countryList,
  addBankAccount,
  active,
  setActive,
  onFileSelected,
  deleteBank,
  bankSelected,
  btnClick,
  success,
  memsSuccess,
  ucrn,
  draft,
  // message,
  mandatoryFilesUploaded,
  bankDeleteLoading,
}) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Claims', to: '/claims' },
    { label: 'Add Claim', active: true },
  ];

  return (
    <Template pageTitle="Add Claim" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section color="light" className="policy-details-content metLife-gray">
          <div className="container-fluid max-container">
            {!success ? (
              <Row>
                <Col className="">
                  <div className="card policy-details-tab add-claim-tab">
                    <Nav className="w-100" tabs>
                      <NavItem>
                        <NavLink
                          className={active === '1' ? 'active' : ''}
                          onClick={() => {
                            setActive('1');
                          }}
                        >
                          <span className="badge badge-pill">1</span>
                          Validate Personal Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={active === '2' ? 'active' : ''}
                          onClick={() => {
                            setActive('2');
                          }}
                        >
                          <span className="badge badge-pill">2</span>
                          Submit Claim details
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={active} className="py-4 w-100">
                      <TabPane tabId="1">
                        <StepOne
                          data={policyData}
                          onConfirm={onConfirm}
                          onContinue={onContinue}
                          bankList={bankList}
                          currencyList={currencyList}
                          countryList={countryList}
                          addBankAccount={addBankAccount}
                          loading={loading}
                          deleteBank={deleteBank}
                          bankSelected={bankSelected}
                          bankDeleteLoading={bankDeleteLoading}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <StepTwo
                          loading={loading}
                          data={data}
                          onConfirm={onConfirm}
                          onContinue={onContinue}
                          currencyList={currencyList}
                          countryList={countryList}
                          onFileSelected={onFileSelected}
                          btnClick={btnClick}
                          mandatoryFilesUploaded={mandatoryFilesUploaded}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            ) : (
              <ClaimMessage memsSuccess={memsSuccess} ucrn={ucrn} draft={draft} />
            )}
          </div>
        </section>
      </>
    </Template>
  );
};

AddClaim.propTypes = {
  data: shape({
    patients: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.string,
      }),
    ),
    claim_types: arrayOf(
      shape({
        type: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.string,
      }),
    ),
    currencies: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
    countries: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
  }).isRequired,

  active: PropTypes.string.isRequired,
  policyData: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  bankList: PropTypes.arrayOf(PropTypes.shape({})),
  currencyList: PropTypes.arrayOf(PropTypes.shape({})),
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  addBankAccount: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  deleteBank: PropTypes.func.isRequired,
  bankSelected: PropTypes.bool.isRequired,
  btnClick: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  memsSuccess: PropTypes.bool,
  ucrn: PropTypes.string,
  draft: PropTypes.bool,
  // message: PropTypes.string,
  mandatoryFilesUploaded: PropTypes.shape({}).isRequired,
  bankDeleteLoading: PropTypes.bool,
};

AddClaim.defaultProps = {
  bankList: [],
  currencyList: [],
  countryList: [],
  loading: false,
  success: false,
  memsSuccess: false,
  ucrn: '',
  draft: false,
  bankDeleteLoading: false,
  // message: '',
};

export default withRouter(AddClaim);
