import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import parse from 'html-react-parser';
import Template from './Templates/Home';
import { hasAuthToken } from '../lib/jwt';
import Loading from './UI/Loading';

const FAQ = ({ data, loading }) => {
  const breadcrumb = hasAuthToken()
    ? [
        { label: 'Dashboard', to: '/home' },
        { label: 'Contact Us', to: '/contactUs' },
        { label: 'FAQ', active: true },
      ]
    : [];

  const isHTML = (str) => {
    let isHtml = false;
    const a = document.createElement('div');
    a.innerHTML = str;

    for (let i = 0; i < a.childNodes.length; i += 1) {
      if (a.childNodes[i].nodeType === 1) {
        isHtml = true;
        i = a.childNodes.length;
      }
    }
    return isHtml;
  };

  return (
    <Template pageTitle="FAQ" breadcrumb={breadcrumb}>
      <section className="bg-gray-200 py-4 metLife-gray">
        <Container fluid className="max-container">
          <div className="accordion" id="accordionExample">
            {!loading ? (
              data?.length ? (
                data?.map((element) => (
                  <div className="card" key={element?.id}>
                    <div className="card-header" id={element?.id}>
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left pl-0"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <h2>{element?.name}</h2>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby={element?.id}
                      data-parent="#accordionExample"
                    >
                      {element?.faq?.map((e) => (
                        <div className="card-body" key={e?.id}>
                          <p className="f-mlc-m">{e?.question}</p>
                          {/* {isHTML(e?.answer)} */}
                          <span style={{ whiteSpace: 'pre-line' }}>
                            {isHTML(e?.answer)
                              ? parse(e?.answer.replaceAll('<p>', '').replaceAll('</p>', ''))
                              : e?.answer}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-results mt-3">
                  <Card
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <CardBody className="pt-4">
                      <CardTitle tag="h5">FAQs not found</CardTitle>
                    </CardBody>
                  </Card>
                </div>
              )
            ) : (
              <Loading />
            )}
          </div>
        </Container>
      </section>
    </Template>
  );
};

FAQ.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

FAQ.defaultProps = {
  data: [],
  loading: false,
};

export default withRouter(FAQ);
