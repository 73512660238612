import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/PolicyDetails/Services/MemberPhotoUpload/MemberPhotoUpload';

class MemberPhotoUpload extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: true };
  }

  async componentDidMount() {
    const { history, getAllMembers } = this.props;
    console.log(history);
    const { data } = await getAllMembers(history.location.state.policyData.policyNbr);
    this.setState({ data, loading: false });
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Beneficiary');
    const { onFormSubmit } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const success = await onFormSubmit(data);
      this.setState({ success, error: null, loading: false });
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  async photoUpload(photoUploadData) {
    const { photoUpload } = this.props;
    const { data } = await photoUpload(photoUploadData);
    console.log(data);
    return data;
  }

  /**
   * Render
   */
  render = () => {
    const { userInput, photoUpload, history } = this.props;
    const { error, loading, success, data } = this.state;
    // const { policyData } = location.state;

    // const dependents = [
    //   {
    //     id: 1,
    //     first_name: 'first name',
    //     last_name: 'last name',
    //     avatar: 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png',
    //     dependent_code: 'code',
    //     relationship: 'relationship',
    //     status: 'status',
    //     status_effective_date: 'date',
    //     tob: 'tob',
    //     network_list: 'network list',
    //     network_provider_list: 'network provider list',
    //     medical_card_print: 'medical card print',
    //     type: 'Dependent',
    //   },
    // ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        data={data}
        photoUpload={photoUpload}
        policyData={history.location.state.policyData}
      />
    );
  };
}

MemberPhotoUpload.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        policyData: PropTypes.shape({
          policyNumber: PropTypes.number,
          policyNbr: PropTypes.number,
          certificateNumber: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  getAllMembers: PropTypes.func.isRequired,
  photoUpload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  location: {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.articles.save,
  getAllMembers: dispatch.member.getAllMembers,
  photoUpload: dispatch.member.uploadPhoto,
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberPhotoUpload);
