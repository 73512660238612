import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row, List, Card, CardBody, Label } from 'reactstrap';
import moment from 'moment';
import Template from './Templates/Home';
import InfoLabel from './PolicyDetails/InfoLabel';
import DocumentItem from './Claims/DocumentItem';

const ComplaintDetails = ({ data, download }) => {
  //   const { register, setValue, getValues, reset } = useForm();
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Complaint List', to: '/complaintList' },
    { label: 'Complaint Details', active: true },
  ];
  return (
    <Template pageTitle="Complaint Details" breadcrumb={breadcrumb}>
      <>
        <section className="metLife-gray">
          <Container fluid className="max-container">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Label className="f-mlc-m display-3 tab-title">
                      {`Reference #${
                        data?.reference_complaint_id ? `${data?.reference_complaint_id}` : null || data?.id
                      }`}
                      {/* <span className="badge badge-secondary ml-3">
                        <small>{data?.status}</small>
                      </span> */}
                    </Label>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <div className="grid grid-2 info-list-wrapper">
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Submitted On" name={moment(data?.created_at).format('DD-MM-YYYY')} />
                        </li>
                        <li>
                          <InfoLabel title="Complaint related to" name={data?.complaint_relation} />
                        </li>
                        <li>
                          <InfoLabel
                            title="Contact Preference"
                            name={data?.contact_preference === 1 ? 'Email' : 'Call'}
                          />
                        </li>
                      </List>
                      <List type="unstyled" className="info-list">
                        <li>
                          <InfoLabel title="Email" name={data?.email} />
                        </li>
                        <li>
                          <InfoLabel title="Phone" name={data?.phone_number} />
                        </li>
                        {data?.time_to_call ? (
                          <li>
                            <InfoLabel title="Best time to call" name={data?.time_to_call} />
                          </li>
                        ) : (
                          ''
                        )}
                      </List>
                    </div>
                    <Row>
                      <Col md={6}>
                        <h3>Documents</h3>
                        {data?.documents?.length ? (
                          <DocumentItem download={download} attachment={data?.documents} label="File Attachments" />
                        ) : (
                          'No files attached'
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
      </>
    </Template>
  );
};

ComplaintDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    reference_complaint_id: PropTypes.string,
    created_at: PropTypes.string,
    complaint_relation: PropTypes.string,
    contact_preference: PropTypes.number,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    time_to_call: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  download: PropTypes.func.isRequired,
};

ComplaintDetails.defaultProps = {
  data: {},
};

export default withRouter(ComplaintDetails);
