import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/FAQ';

class FAQ extends Component {
  constructor() {
    super();
    this.state = { data: [], loading: false };
  }

  componentDidMount() {
    this.getFAQ();
  }

  getFAQ = async () => {
    this.setState({ loading: true });
    const { getFAQ } = this.props;
    const { data } = await getFAQ();
    this.setState({ data, loading: false });
  };

  /**
   * Render
   */
  render = () => {
    const { data, loading } = this.state;
    return <Layout data={data} loading={loading} />;
  };
}

FAQ.propTypes = {
  getFAQ: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getFAQ: dispatch.member.getFAQ,
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
