import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import moment from 'moment';

const ClaimCard = ({ data, policyData, downloadEOB }) => {
  const history = useHistory();
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Transaction History', to: '/home/transactionHistory' },
    { label: 'Claim details', active: true },
  ];
  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Suspended';
      case 'Approved':
        return 'Paid';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Paid';
      case 'Payment Failed':
        return 'Payment Failed';
      case 'Payment Pending':
        return 'Payment Pending';
      default:
        return 'In Progress';
    }
  };
  return (
    <Card className="pointer">
      <CardBody>
        <Row
          className="d-flex justify-content-between"
          onClick={() => {
            history.push({ pathname: '/claimDetails', state: { claimId: data?.id, breadcrumb, showResubmit: false } });
          }}
        >
          <Col className="info-list-wrapper col-8">
            <div className="info-list list-unstyled">
              {/* <li>{`Claim #:${data?.id}`}</li>
              <li>{`Insured Name :${data?.payable}`}</li>
              <li>{`Claimed Amount :${data?.claim_type}`}</li>
              <li>{`Submission Date: ${data?.scheduled_to_pay}`}</li>
              <li>{`Claim Type: ${data?.claim_type}`}</li> */}
              <Row className="mx-0 align-items-center mb-1">
                <Label>Claim #: </Label>
                <h4 className="ml-2">{data?.reference_number ? data?.reference_number : data?.id}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Policy Number: </Label>
                <h4 className="ml-2">{policyData?.policyNumber}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Dependant Code: </Label>
                <h4 className="ml-2">{data?.dependent?.dependent_code}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Insured Name: </Label>
                <h4>{data?.dependent?.member_name || data?.user?.member_name}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Claim Type: </Label>
                <h4 className="ml-2">{data?.patient_type}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Submission Date: </Label>
                <h4 className="ml-2">{moment(data?.created_at).local().format('DD-MM-YYYY')}</h4>
              </Row>
            </div>
          </Col>
          <Col className="text-right">
            <span className="avatar avatar-sm rounded-circle mr-3">
              <img
                className="rounded-circle"
                src={
                  data?.dependent?.image_path ||
                  data?.user?.image_path ||
                  'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                }
                alt="user"
                width="70"
                height="70"
              />
            </span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            onClick={() => {
              history.push({
                pathname: '/claimDetails',
                state: {
                  claimId: data?.id,
                  breadcrumb,
                  showResubmit: false,
                },
              });
            }}
          >
            <h4 className="badge badge-secondary my-1">{getStatusString(data?.status)}</h4>
          </Col>
          <Col className="text-right">
            {data?.submission_type !== 4 && (
              <a
                className="pointer"
                onClick={() => {
                  downloadEOB(data?.id);
                }}
              >
                <svg
                  id="Icon_Download"
                  data-name="Icon/Download"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <rect id="Mask" width="24" height="24" fill="none" />
                  <path
                    id="Bottom"
                    d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                    transform="translate(10.125 17.636)"
                    fill="#007abc"
                  />
                  <path
                    id="Middle"
                    d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                    transform="translate(4.347 4)"
                    fill="#007abc"
                  />
                  <path
                    id="Arrow"
                    d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                    transform="translate(9.729 7.637)"
                    fill="#fff"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <path
                    id="Corner"
                    d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                    transform="translate(2 5.455)"
                    fill="#007abc"
                  />
                </svg>
                <Label className="pointer">Download EOB</Label>
              </a>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
ClaimCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    reference_number: PropTypes.string,
    created_at: PropTypes.string,
    scheduled_to_pay: PropTypes.string,
    patient_type: PropTypes.string,
    status: PropTypes.string,
    submission_type: PropTypes.number,
    user: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
    }),
    dependent: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
      dependent_code: PropTypes.number,
    }),
  }),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
  }).isRequired,
  downloadEOB: PropTypes.func.isRequired,
};

ClaimCard.defaultProps = {
  data: {},
};

export default withRouter(ClaimCard);
