import React from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Label, Progress, Row } from 'reactstrap';
import moment from 'moment';

const ClaimCard = ({ data, downloadEOB, deleteClaim }) => {
  const history = useHistory();
  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Suspended';
      case 'Approved':
        return 'Paid';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Paid';
      case 'Payment Failed':
        return 'Payment Failed';
      case 'Payment Pending':
        return 'Payment Pending';
      default:
        return 'In Progress';
    }
  };

  // const currencyByCountryId = (id) => {
  //   switch (id) {
  //     case 234:
  //       return 'AED';
  //     case 18:
  //       return 'BHD';
  //     case 166:
  //       return 'OMR';
  //     default:
  //       return '';
  //   }
  // };

  /* eslint-disable */
  // const numberFormat = (value, currency) => {
  //   if (currency) {
  //     return new Intl.NumberFormat('en-IN', {
  //       style: 'currency',
  //       currency: currency ?? currencyByCountryId(data?.country_id),
  //     }).format(value);
  //   }
  // };

  const renderProgress = (status) => {
    switch (status) {
      case 'On Hold':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="danger" value="25" />
          </Progress>
        );
      case 'Pending':
      case 'Re Submitted':
      case 'Submitted':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
          </Progress>
        );
      case 'Payment Pending':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="80" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="secondary" value="2" />
          </Progress>
        );
      case 'Payment Failed':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="50" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="danger" value="5" />
            <Progress bar color="light" value=".5" />
            <Progress bar color="danger" value="5" />
          </Progress>
        );
      case 'Rejected':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="danger" value="100" />
          </Progress>
        );
      case 'Partially Approved':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="30" />
            <Progress bar color="danger" value="25" />
          </Progress>
        );
      case 'Approved':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="secondary" value="100" />
          </Progress>
        );
      case 'Drafted':
        return (
          <Progress multi className="my-3" style={{ height: '5px' }}>
            <Progress bar color="primary" value="30" />
          </Progress>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="pointer">
      <CardBody>
        <Row className="d-flex justify-content-between claim-list-item">
          <Col
            className="info-list-wrapper"
            md="8"
            onClick={() =>
              history.push({ pathname: '/claimDetails', state: { claimId: data?.id, showResubmit: true } })
            }
          >
            <div className="info-list list-unstyled">
              {/* <li>{`Claim #:${data?.id}`}</li>
              <li>{`Insured Name :${data?.payable}`}</li>
              <li>{`Claimed Amount :${data?.claim_type}`}</li>
              <li>{`Submission Date: ${data?.scheduled_to_pay}`}</li>
              <li>{`Claim Type: ${data?.claim_type}`}</li> */}

              <Row className="mx-0 align-items-center mb-1">
                <Label>Claim #: </Label>
                <h4>
                  &nbsp;
                  {data?.reference_number ? data?.reference_number : data?.id}
                </h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Insured Name: </Label>
                <h4>{data?.dependent?.member_name || data?.user?.member_name}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Claimed Amount: </Label>
                <h4>
                  &nbsp;
                  {data?.claimed_amount_format ?? ''}
                </h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Submission Date: </Label>
                <h4>
                  &nbsp;
                  {moment(data?.created_at).local().format('DD-MM-YYYY')}
                </h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Claim Type: </Label>
                <h4>
                  &nbsp;
                  {data?.patient_type}
                </h4>
              </Row>
            </div>
          </Col>
          <Col className="text-right">
            <span className="avatar avatar-sm rounded-circle mr-3">
              <img
                className="rounded-circle"
                src={
                  data?.dependent?.image_path ||
                  data?.user?.image_path ||
                  'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                }
                alt="user"
                width="70"
                height="70"
              />
            </span>
          </Col>
        </Row>
        <Row>
          {getStatusString(data?.status) === 'Suspended' ||
          getStatusString(data?.status) === 'Rejected' ||
          getStatusString(data?.status) === 'Payment Failed' ||
          getStatusString(data?.status) === 'Paid' ? (
            <Col className="text-right">
              <a
                className="pointer"
                onClick={() => {
                  downloadEOB(data?.id);
                }}
              >
                <svg
                  id="Icon_Download"
                  data-name="Icon/Download"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <rect id="Mask" width="24" height="24" fill="none" />
                  <path
                    id="Bottom"
                    d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                    transform="translate(10.125 17.636)"
                    fill="#007abc"
                  />
                  <path
                    id="Middle"
                    d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                    transform="translate(4.347 4)"
                    fill="#007abc"
                  />
                  <path
                    id="Arrow"
                    d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                    transform="translate(9.729 7.637)"
                    fill="#fff"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <path
                    id="Corner"
                    d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                    transform="translate(2 5.455)"
                    fill="#007abc"
                  />
                </svg>
                <Label className="pointer">Download EOB</Label>
              </a>
            </Col>
          ) : null}
        </Row>
        <Row
          onClick={() => history.push({ pathname: '/claimDetails', state: { claimId: data?.id, showResubmit: true } })}
        >
          <Col>{renderProgress(data?.status)}</Col>
        </Row>
        <Row>
          <Col
            onClick={() =>
              history.push({ pathname: '/claimDetails', state: { claimId: data?.id, showResubmit: true } })
            }
          >
            <h4>{getStatusString(data?.status)}</h4>
          </Col>
          {data?.status === 'Rejected' ||
          data?.status === 'On Hold' ||
          data?.status === 'Partially Approved' ||
          data?.status === 'Payment Failed' ? (
            <Col className="text-right">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1 text-danger" />
              <small className="mr-2">Action Required</small>
            </Col>
          ) : data?.status === 'Drafted' ? (
            <Col className="text-right">
              <Col className="text-right">
                <a
                  onClick={() => {
                    // eslint-disable-next-line
                    if (window.confirm(`Are you sure you want to delete the draft?`)) deleteClaim(data?.id);
                  }}
                >
                  <svg viewBox="0 0 17.804 19.686" xmlns="http://www.w3.org/2000/svg" width="17.804" height="19.686">
                    <defs>
                      <clipPath id="a">
                        <path
                          transform="translate(-.255 16)"
                          d="M15.468,3.681,2.834,3.686,1.469-10.094H16.845Zm1.32-17.318a1.245,1.245,0,0,1,.893.329,1.177,1.177,0,0,1,.379.852v1.181H.255v-1.181a1.177,1.177,0,0,1,.379-.852,1.245,1.245,0,0,1,.893-.329H4.245L5.3-15.313A1.559,1.559,0,0,1,6.613-16H11.7a1.533,1.533,0,0,1,1.249.743l1.12,1.619ZM6.931-14.819a.6.6,0,0,0-.607.591v.591h5.665v-.591a.6.6,0,0,0-.607-.591Z"
                          clipRule="evenodd"
                          fill="#dd2224"
                        />
                      </clipPath>
                    </defs>
                    <g transform="translate(0)" clipPath="url(#a)">
                      <path transform="translate(-1.704 14.725)" d="m-4.745-21h30.7v32.236h-30.7z" fill="#dd2224" />
                    </g>
                  </svg>
                </a>
              </Col>
            </Col>
          ) : null}
        </Row>
      </CardBody>
    </Card>
  );
};
ClaimCard.propTypes = {
  downloadEOB: PropTypes.func.isRequired,
  deleteClaim: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    reference_number: PropTypes.string,
    payable: PropTypes.string,
    claimed_amount: PropTypes.string,
    submission_date: PropTypes.string,
    scheduled_to_pay: PropTypes.string,
    patient_type: PropTypes.string,
    image_path: PropTypes.string,
    status: PropTypes.string,
    currency: PropTypes.string,
    user: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
    }),
    dependent: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
    }),
  }),
};

ClaimCard.defaultProps = {
  data: {},
};

export default withRouter(ClaimCard);
