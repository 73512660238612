import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Label, Card, CardBody, CardTitle } from 'reactstrap';
import moment from 'moment';
import Loading from './UI/Loading';
import Template from './Templates/Home';

const complaintList = ({ loading, complaintListData }) => {
  const history = useHistory();

  const breadcrumb = [
    { label: 'Contact Us', to: '/contactUs' },
    { label: 'Complaint List', active: true },
  ];
  return (
    <Template pageTitle="Complaints" breadcrumb={breadcrumb}>
      <div className="bg-g">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <Container fluid className="full-width-page complaint-page max-container">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="f-mlc-m policy-main-title mb-0">Your complaints</h3>
                  <Button className="btn-primary ml-auto" color="primary" onClick={() => history.push('/complaints')}>
                    + Submit Complaint
                  </Button>
                </div>
              </Container>
              <div className="bg-light policy-details-content metLife-gray mt-3 pb-4">
                <Container fluid className="full-width-page complaint-page max-container">
                  <Row>
                    <Col>
                      <Row>
                        {complaintListData?.length ? (
                          complaintListData?.map((item) => (
                            <Col lg="6" key={item?.id}>
                              <Card
                                className="pointer mt-4"
                                onClick={() => history.push({ pathname: '/complaintDetails', state: { data: item } })}
                              >
                                <CardBody>
                                  <Row className="d-flex justify-content-between claim-list-item">
                                    <Col className="info-list-wrapper" md="8">
                                      <div className="info-list list-unstyled">
                                        <Row className="mx-0 align-items-center mb-1">
                                          <Label>Complaint #: </Label>
                                          <h4>
                                            &nbsp;
                                            {item?.reference_number || item?.id}
                                          </h4>
                                        </Row>
                                        <Row className="mx-0 align-items-center mb-1">
                                          <Label>Name: </Label>
                                          &nbsp;
                                          <h4>{item?.name}</h4>
                                        </Row>
                                        <Row className="mx-0 align-items-center mb-1">
                                          <Label>Email: </Label>
                                          <h4>
                                            &nbsp;
                                            {item?.email}
                                          </h4>
                                        </Row>
                                        <Row className="mx-0 align-items-center mb-1">
                                          <Label>Submission Date: </Label>
                                          <h4>
                                            &nbsp;
                                            {moment(item?.created_at).format('DD-MM-YYYY')}
                                          </h4>
                                        </Row>
                                        <Row className="mx-0 align-items-center mb-1">
                                          <Label>Contact Preference: </Label>
                                          <h4>
                                            &nbsp;
                                            {item?.contact_preference === 1 ? 'Email' : 'Call'}
                                          </h4>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          ))
                        ) : (
                          <Col lg="12">
                            <div className="no-results mt-3">
                              <Card
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <CardBody className="pt-4">
                                  <CardTitle tag="h5">No previous complaints submitted</CardTitle>
                                </CardBody>
                              </Card>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )}
        </div>
      </div>
    </Template>
  );
};

complaintList.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool,
  complaintList: PropTypes.arrayOf(PropTypes.shape({})),
};

complaintList.defaultProps = {
  message: null,
  error: null,
  success: null,
  loading: false,
  complaintList: [],
};

export default withRouter(complaintList);
