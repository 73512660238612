import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/ForgotPassword';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, message: '' };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { submit } = this.props;

    this.setState({ success: null, error: null, loading: true, message: '' });

    try {
      const { success, message } = await submit(data);
      this.setState({ success, error: null, loading: false, message });
      if (success) {
        this.setState({
          success,
          error: null,
          loading: false,
          message,
        });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, message } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

ForgotPassword.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  submit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  submit: dispatch.member.sendPasswordLink,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
