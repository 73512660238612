import React, { useState } from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import DetailsCard from './DetailsCard';
import Loading from '../../../../UI/Loading';

const Preapprovals = ({ preApprovals, policyData, getPreApprovals, loading }) => {
  const [minDate, setMinDate] = useState(null);
  const maxDate = moment().format('YYYY-MM-DD');

  const { register, setValue, getValues, reset } = useForm();

  return (
    <Row>
      <Col>
        <Form>
          <div className="search-filter row">
            <Col>
              <FormGroup className="form-label-group">
                <Input
                  type="select"
                  name="dependent_id"
                  id="dependent_id"
                  innerRef={register()}
                  onChange={(e) => setValue('dependent_id', e.target.value)}
                >
                  <option key="principal_member" value={0}>
                    {policyData?.member_details?.member_name}
                  </option>
                  {policyData?.dependents?.map((patient) => (
                    <option key={patient.id} value={patient.id}>
                      {patient.member_name}
                    </option>
                  ))}
                </Input>
                <Label>Patient</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-label-group">
                <Input
                  innerRef={register()}
                  onChange={(e) => setValue('status', e.target.value)}
                  type="select"
                  name="status"
                  id="status"
                >
                  <option value="">All</option>
                  <option value="1">Approved</option>
                  <option value="3">Partially Approved</option>
                  <option value="2">Rejected</option>
                  <option value="6">Suspended</option>
                </Input>
                <Label>Status</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-label-group">
                <Input
                  name="from_date"
                  innerRef={register()}
                  onChange={(e) => {
                    setValue('from_date', e.target.value);
                    setMinDate(moment(e.target.value).format('YYYY-MM-DD'));
                  }}
                  type="text"
                  max={maxDate}
                  onFocus={(e) => {
                    e.target.type = 'date';
                  }}
                  placeholder="Date From"
                  onBlur={(e) => {
                    if (!e.target.value) e.target.type = 'text';
                  }}
                />
                <Label>Date From</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-label-group">
                <Input
                  name="to_date"
                  innerRef={register()}
                  onChange={(e) => setValue('to_date', e.target.value)}
                  placeholder="Date To"
                  type="text"
                  min={minDate}
                  max={maxDate}
                  onFocus={(e) => {
                    e.target.type = 'date';
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) e.target.type = 'text';
                  }}
                />
                <Label>Date To</Label>
              </FormGroup>
            </Col>
            <Col className="text-right">
              <Button
                className="reset mr-3"
                onClick={() => {
                  reset();
                  getPreApprovals();
                }}
              >
                <svg
                  className="mr-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 4C9.69818 4 7.62561 5.02187 6.19328 6.65856C5.82957 7.07417 5.1978 7.11624 4.78219 6.75253C4.36658 6.38881 4.32451 5.75705 4.68823 5.34144C6.47858 3.29564 9.08928 2 12 2C17.3671 2 21.6932 6.38925 21.7981 11.7989L22.285 11.3009C22.6711 10.906 23.3042 10.8989 23.6991 11.285C24.094 11.6711 24.1011 12.3042 23.715 12.6991L21.515 14.9491C21.3269 15.1415 21.0691 15.25 20.8 15.25C20.5309 15.25 20.2731 15.1415 20.085 14.9491L17.885 12.6991C17.4989 12.3042 17.506 11.6711 17.9009 11.285C18.2958 10.8989 18.9289 10.906 19.315 11.3009L19.7975 11.7943C19.691 7.45096 16.2203 4 12 4ZM3.2 8.75C3.46911 8.75 3.72687 8.85847 3.91501 9.05088L6.11501 11.3009C6.50112 11.6958 6.49401 12.3289 6.09912 12.715C5.70423 13.1011 5.07111 13.094 4.68499 12.6991L4.20252 12.2057C4.30903 16.549 7.77965 20 12 20C14.3018 20 16.3744 18.9781 17.8067 17.3414C18.1704 16.9258 18.8022 16.8838 19.2178 17.2475C19.6334 17.6112 19.6755 18.243 19.3118 18.6586C17.5214 20.7044 14.9107 22 12 22C6.6329 22 2.30681 17.6107 2.20195 12.2011L1.71501 12.6991C1.3289 13.094 0.69577 13.1011 0.300883 12.715C-0.0940045 12.3289 -0.101118 11.6958 0.284994 11.3009L2.48499 9.05088C2.67313 8.85847 2.93089 8.75 3.2 8.75Z"
                      fill="#293644"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
              <Button
                className="search-provider"
                onClick={() => {
                  getPreApprovals(getValues());
                }}
              >
                <svg id="Icon-Search" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                  <path
                    id="Icon_Search"
                    data-name="Icon/Search"
                    d="M18.752,21h0L21,18.795l-6.033-5.916a7.956,7.956,0,0,0,.67-8.452A8.331,8.331,0,0,0,.466,5.433,8.036,8.036,0,0,0,3.1,14.458a8.4,8.4,0,0,0,9.551.555L18.751,21ZM8.27,3.118a5.058,5.058,0,0,1,5.1,5,5.1,5.1,0,0,1-10.2,0A5.058,5.058,0,0,1,8.27,3.118Z"
                    transform="translate(5 5)"
                    fill="#fff"
                  />
                </svg>
              </Button>
            </Col>
          </div>
        </Form>
        <Row className="search-provider-results">
          {!!preApprovals?.length && !loading ? (
            preApprovals?.map((element) => (
              <Col lg="6" key={element.id} className="mb-3">
                <DetailsCard data={element} policyData={policyData} />
              </Col>
            ))
          ) : loading ? (
            <Col>
              <Loading />
            </Col>
          ) : (
            <Col>
              <div className="no-results">
                <Card
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <svg
                    className="noresults-searchicon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                  >
                    <path
                      id="Icon_Search"
                      data-name="Icon/Search"
                      d="M18.752,21h0L21,18.795l-6.033-5.916a7.956,7.956,0,0,0,.67-8.452A8.331,8.331,0,0,0,.466,5.433,8.036,8.036,0,0,0,3.1,14.458a8.4,8.4,0,0,0,9.551.555L18.751,21ZM8.27,3.118a5.058,5.058,0,0,1,5.1,5,5.1,5.1,0,0,1-10.2,0A5.058,5.058,0,0,1,8.27,3.118Z"
                      transform="translate(5 5)"
                      fill="#ddd"
                    />
                  </svg>
                  <CardBody className="pt-0">
                    <CardTitle tag="h5">No Results found</CardTitle>
                    <CardText>No content matched your criteria. Try searching for something else.</CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

Preapprovals.propTypes = {
  preApprovals: PropTypes.arrayOf(PropTypes.shape({})),
  policyData: PropTypes.shape({
    dependents: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.string,
      }),
    ),
    member_details: shape({
      email: PropTypes.string,
      phone_number: PropTypes.string,
      bank_accounts: PropTypes.arrayOf({}),
    }),
  }).isRequired,
  getPreApprovals: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Preapprovals.defaultProps = {
  preApprovals: [],
  loading: false,
};

export default withRouter(Preapprovals);
