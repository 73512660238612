import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { errorMessages } from '../../../constants/messages';
import CustomFileInput from './CustomFileInput';
import config from '../../../constants/config';

const StepTwo = ({
  loading,
  onConfirm,
  currencyList,
  countryList,
  onFileSelected,
  onFileRemoved,
  defaultData,
  btnClick,
  mandatoryFilesUploaded,
}) => {
  const { register, handleSubmit, errors, setValue, getValues, reset } = useForm();
  const [isDraft, setIsDraft] = useState(false);
  const handleSetPayload = async (formdata, draft = false) => {
    setIsDraft(draft);
    if (draft) {
      onConfirm(formdata);
    } else {
      onConfirm(formdata, 'confirm');
    }
  };
  const date = moment(new Date()).format('YYYY-MM-DD');
  const minDate = moment(new Date()).subtract(2, 'years').format('YYYY-MM-DD');

  useEffect(() => {
    reset({
      country_id: defaultData?.country_id,
      currency_id: defaultData?.currency_id,
    });
  }, [currencyList, countryList]);

  // const moneyFormat = (price) => {
  //   const pieces = parseFloat(price).toFixed(2).split('');
  //   let length = pieces.length - 3;
  //   while ((length - 3) > 0) {
  //     pieces.splice(length, 0, ',');
  //   }
  //   return pieces.join('');
  // };

  return (
    <Fragment>
      <Row className="justify-content-between mt-3">
        <Col md="5">
          <Label className="f-mlc-m display-3 tab-title">Claim details</Label>
        </Col>
      </Row>
      <hr />
      <Form onSubmit={handleSubmit((values) => handleSetPayload(values))} className="mt-3">
        <FormGroup>
          <Row className="justify-content-between mt-3">
            <Col md="6">
              <Input
                type="select"
                name="claim_type"
                id="claim_type"
                disabled={loading}
                invalid={!!errors.claim_type}
                innerRef={register({ required: errorMessages.missingData })}
                defaultValue={defaultData?.claim_type}
                onChange={(e) => setValue('claim_type', e.target.value)}
              >
                <option value="1">In-Patient</option>
                <option value="2">Out-Patient</option>
                <option value="3">Both</option>
              </Input>
              {errors.claim_type && <p className="invalid-feedback">{errors.claim_type.message}</p>}
            </Col>
          </Row>
          <p className="mt-3">
            (Note: If you were admitted to hospital but the stay was less than 12 hours, such cases are considered as
            'Day Care OutPatient')
          </p>
          <h3 className="mt-5">Claim Details</h3>
          <p>
            (Note: If the Country you would like to select is not in the list, It is a 'Sanctioned Country' and your
            claim cannot be submitted online. Please send your original documents along with your Claim from the address
            listed in the Contact Us section)
          </p>
          <Row className="d-flex">
            <Col>
              <Label for="country">Country of Treatment</Label>
              <Input
                type="select"
                name="country_id"
                id="country_id"
                disabled={loading}
                invalid={!!errors.country_id}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('country_id', e.target.value)}
              >
                <option value="" selected disabled>
                  Select Country
                </option>
                {countryList?.map((country) => (
                  <option key={country.short_name} value={country.id}>
                    {country.short_name}
                  </option>
                ))}
              </Input>
              {errors.country && <p className="invalid-feedback">{errors.country.message}</p>}

              <Label for="claimed_amount" className="mt-3">
                Total Claim Amount
              </Label>
              <NumericFormat
                type="text"
                name="claimed_amount"
                id="claimed_amount"
                customInput={(props) => <Input {...props} />}
                allowLeadingZeros
                thousandSeparator=","
                placeholder="Total claim amount"
                className="form-control"
                disabled={loading}
                invalid={!!errors.claimed_amount}
                defaultValue={defaultData?.claimed_amount}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('claimed_amount', e.target.value)}
              />
              {errors.claimed_amount && <p className="invalid-feedback">{errors.claimed_amount.message}</p>}
            </Col>
            <Col>
              <Label for="currency_id">Claim Currency</Label>
              <Input
                type="select"
                name="currency_id"
                id="currency_id"
                disabled={loading}
                invalid={!!errors.currency_id}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('currency_id', e.target.value)}
              >
                <option value="" selected disabled>
                  Select Claim Currency
                </option>
                {currencyList?.map((currency) => (
                  <option key={currency.currency} value={currency.id}>
                    {currency.name}
                  </option>
                ))}
              </Input>
              {errors.currency && <p className="invalid-feedback">{errors.currency.message}</p>}

              <Label for="treatment_date" className="mt-3">
                Treatment Start Date
              </Label>
              <Input
                type="date"
                name="treatment_date"
                id="treatment_date"
                min={minDate}
                max={date}
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                onBlur={(e) => {
                  if (!e.target.value) e.target.type = 'text';
                }}
                disabled={loading}
                defaultValue={defaultData?.treatment_date}
                invalid={!!errors.treatment_date}
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('treatment_date', e.target.value)}
              />
              {errors.treatment_date && <p className="invalid-feedback">{errors.treatment_date.message}</p>}
            </Col>
          </Row>
          <h3 className="mt-5">Documents</h3>
          <p>
            Please upload at least one of the following mandatory documents
            that are required to proceed with the claim submission:
          </p>
          <p className="mt-3">
            Maximum file size allowed is 8MB/Document. Allowed file types .jpg, .jpeg, .tif, .bmp, .png, .doc, .docx,
            .txt, .pdf.
          </p>

          <Row>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                label="Metlife claim form with physician notes/medical report"
                type="physician_notes"
                defaultData={defaultData?.attachments?.physician_notes}
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                type="hospital_receipts"
                defaultData={defaultData?.attachments?.hospital_receipts}
                label="Medical Provider Receipt"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                label="Discharge Report"
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                type="discharge_report"
                defaultData={defaultData?.attachments?.discharge_report}
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                label="Proof of Payment"
                type="proof_of_payment"
                defaultData={defaultData?.attachments?.proof_of_payment}
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                label="Results of lab tests, radiology"
                type="lab_tests"
                defaultData={defaultData?.attachments?.lab_tests}
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                label="Other Supporting Documents"
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                type="other_supporting_docs"
                defaultData={defaultData?.attachments?.other_supporting_docs}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                onFileRemoved={onFileRemoved}
                label="Translated Documents"
                type="translated_docs"
                defaultData={defaultData?.attachments?.translated_docs}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pl-4">
                <Input
                  type="checkbox"
                  name="declaration"
                  id="declaration"
                  disabled={loading}
                  invalid={!!errors.declaration}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('declaration', e.target.value)}
                />
                <Label for="declaration">
                  I have read and accept all The
                  <a href={`${config.baseURL}/claim-submission-disclaimer/AE`} className="btn-link" target="blank">
                    {' '}
                    Terms & conditions
                  </a>
                </Label>
                {errors.declaration && <p className="invalid-feedback">Please check this to continue</p>}
              </div>
            </Col>
            <Col className="text-right">
              {defaultData?.status === 'Drafted' && (
                <Button
                  type="button"
                  color="outline-primary"
                  className="mr-3"
                  disabled={loading}
                  onClick={() => {
                    // console.log(getValues());
                    handleSetPayload(getValues(), true);
                  }}
                >
                  {isDraft && loading ?
                    (
                      <>
                        <Spinner
                          size="sm"
                          color="#007ABC"
                        />
                        <span>&nbsp;Loading</span>
                      </>
                    ) : 'SAVE AS DRAFT'}
                </Button>
              )}
              <Button color="primary" disabled={loading}>
                {!isDraft && loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'SUBMIT'}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

StepTwo.propTypes = {
  defaultData: PropTypes.shape({
    status: PropTypes.string,
    country_id: PropTypes.number,
    claim_type: PropTypes.number,
    currency_id: PropTypes.number,
    claimed_amount: PropTypes.string,
    treatment_date: PropTypes.string,
    attachments: PropTypes.shape({
      lab_tests: PropTypes.arrayOf(PropTypes.shape),
      physician_notes: PropTypes.arrayOf(PropTypes.shape),
      hospital_receipts: PropTypes.arrayOf(PropTypes.shape),
      discharge_report: PropTypes.arrayOf(PropTypes.shape),
      proof_of_payment: PropTypes.arrayOf(PropTypes.shape),
      other_supporting_docs: PropTypes.arrayOf(PropTypes.shape),
      translated_docs: PropTypes.arrayOf(PropTypes.shape),
    }),
  }),
  onConfirm: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  onFileSelected: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  btnClick: PropTypes.bool.isRequired,
  mandatoryFilesUploaded: PropTypes.shape({
    physician_notes: PropTypes.bool.isRequired,
    hospital_receipts: PropTypes.bool.isRequired,
  }).isRequired,
};

StepTwo.defaultProps = {
  loading: false,
  defaultData: {},
};

export default withRouter(StepTwo);
