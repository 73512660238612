import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
// import Template from './Templates/Dashboard';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';
import Loading from './UI/Loading';

const UpdatePassword = ({ error, loading, success, message, onFormSubmit, defaultValues, loginLoading }) => {
  const { register, handleSubmit, errors, setValue, getValues, trigger } = useForm({
    defaultValues,
  });

  return (
    <Container className="login full-width-page" tag="section">
      <Row className="text-center align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          <h1>Update Password</h1>
          <h3>Enter your new password</h3>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            {!!success && message && <Alert color="success">{message}</Alert>}
            {!!error && message && <Alert color="danger">{message}</Alert>}
            {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

            <FormGroup className="form-label-group">
              <Input
                type="hidden"
                name="email"
                id="email"
                innerRef={register({ required: errorMessages.missingEmail })}
              />
            </FormGroup>
            <FormGroup className="form-label-group">
              <Input
                type="password"
                name="old_password"
                id="old_password"
                placeholder="Old Password"
                autoComplete="off"
                disabled={loading}
                invalid={!!errors.old_password}
                innerRef={register({
                  required: errorMessages.missingPassword,
                })}
                onChange={(e) => setValue('old_password', e.target.value)}
              />
              <Label>Old Password</Label>
              {errors.old_password && <p className="invalid-feedback">{errors.old_password.message}</p>}
            </FormGroup>
            <FormGroup className="form-label-group">
              <Input
                type="password"
                name="new_password"
                autoComplete="off"
                id="new_password"
                placeholder="New Password"
                disabled={loading}
                invalid={!!errors.new_password}
                innerRef={register({
                  required: errorMessages.missingPassword,
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                    message:
                      'Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one number',
                  },
                  validate: (value) => {
                    const oldPassword = getValues('old_password');
                    if (value === oldPassword) {
                      return 'New password cannot be the same as the old password';
                    }
                    return true;
                  },
                })}
                onChange={(e) => {
                  setValue('password', e.target.value);
                  trigger('new_password');
                }}
              />
              <Label>New Password</Label>
              {errors.new_password ? (
                <p className="invalid-feedback">{errors.new_password.message}</p>
              ) : (
                <p style={{ fontSize: '12px', textAlign: 'left', padding: '2px 8px' }}>
                  Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one
                  number
                </p>
              )}
            </FormGroup>
            <FormGroup className="form-label-group">
              <Input
                type="password"
                name="conpassword"
                autoComplete="off"
                id="conpassword"
                placeholder="Confirm password"
                disabled={loading}
                invalid={!!errors.conpassword}
                innerRef={register({
                  required: true,
                  validate: (val) => {
                    const { new_password } = getValues();
                    return new_password === val || errorMessages.passwordsDontMatch;
                  },
                })}
                onChange={(e) => {
                  setValue('conpassword', e.target.value);
                  trigger('conpassword');
                }}
              />
              <Label>Confirm Password</Label>
              {errors.conpassword && <p className="invalid-feedback">{errors.conpassword.message}</p>}
            </FormGroup>
            {loginLoading ? (
              <Loading />
            ) : (
              <>
                <Button color="primary" disabled={loading} style={{ width: '100%' }}>
                  {loading ?
                    (
                      <>
                        <Spinner
                          size="sm"
                          color="light"
                        />
                        <span>&nbsp;Loading</span>
                      </>
                    ) : 'Submit'}
                </Button>
              </>
            )}
          </Form>
          {!loginLoading ? (
            <div className="bottom-links justify-content-center  mt-5">
              Back to
              <Link to="/login" className="text-primary">
                {' '}
                Log in
              </Link>
            </div>
          ) : null}
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

UpdatePassword.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.string,
  message: PropTypes.string,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  defaultValues: PropTypes.shape({}),
  onFormSubmit: PropTypes.func.isRequired,
  loginLoading: PropTypes.bool,
};

UpdatePassword.defaultProps = {
  error: null,
  success: null,
  message: '',
  data: {},
  loading: false,
  defaultValues: {},
  loginLoading: false,
};

export default withRouter(UpdatePassword);
