import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import moment from 'moment';

const ClaimCard = ({ data, policyData }) => {
  const history = useHistory();
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Transaction History', to: '/home/transactionHistory' },
    { label: 'Pre-Approval details', active: true },
  ];
  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Suspended';
      case 'Approved':
        return 'Approved';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Approved';
      case 'Suspended':
        return 'Suspended';
      default:
        return 'In Progress';
    }
  };

  /* eslint-disable */
  const numberFormat = (value, currency) =>
    new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
    }).format(value);

  return (
    <Card
      className="pointer"
      onClick={() => {
        history.push({
          pathname: '/preApprovalDetails',
          state: { preApprovalId: data?.id, breadcrumb, showResubmit: false },
        });
      }}
    >
      <CardBody>
        <Row className="d-flex justify-content-between">
          <Col className="info-list-wrapper" md="8">
            <div className="info-list list-unstyled">
              {/* <li>{`Claim #:${data?.id}`}</li>
              <li>{`Insured Name :${data?.payable}`}</li>
              <li>{`Claimed Amount :${data?.claim_type}`}</li>
              <li>{`Submission Date: ${data?.approval_submission_date}`}</li>
              <li>{`Claim Type: ${data?.claim_type}`}</li> */}
              <Row className="mx-0 align-items-center mb-1">
                <Label>Pre-Approval #: </Label>
                <h4>{data?.reference_number || data?.id}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Policy Number: </Label>
                <h4>{policyData?.policyNumber}</h4>
              </Row>
              {/* <Row className="mx-0 align-items-center mb-1">
                <Label>Dependant Code: </Label>
                <h4 className="ml-2">{data?.dependent?.dependent_code}</h4>
              </Row> */}
              <Row className="mx-0 align-items-center mb-1">
                <Label>Insured Name: </Label>
                <h4>{data?.insured_name}</h4>
              </Row>
              {/* <Row className="mx-0 align-items-center mb-1">
                <Label>Patient Name: </Label>
                <h4>{data?.dependent?.member_name || data?.user?.member_name}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Provider Name: </Label>
                <h4>{data?.provider_name}</h4>
              </Row> */}
              <Row className="mx-0 align-items-center mb-1">
                <Label>Submission Date: </Label>
                <h4>{moment(data?.created_at).local().format('DD-MM-YYYY')}</h4>
              </Row>
              <Row className="mx-0 align-items-center mb-1">
                <Label>Claimed Amount: </Label>
                <h4>{data?.claimed_amount ? numberFormat(data?.claimed_amount, data?.currency) : ''}</h4>
              </Row>
            </div>
          </Col>
          <Col className="text-right">
            <span className="avatar avatar-sm rounded-circle mr-3">
              <img
                className="rounded-circle"
                src={
                  data?.dependent?.image_path ||
                  data?.user?.image_path ||
                  'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                }
                alt="user"
                width="70"
                height="70"
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="badge badge-secondary my-1">{getStatusString(data?.status)}</h4>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
ClaimCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    mednext_id: PropTypes.string,
    reference_number: PropTypes.string,
    payable: PropTypes.string,
    claimed_amount: PropTypes.string,
    created_at: PropTypes.string,
    approval_submission_date: PropTypes.string,
    patient_type: PropTypes.string,
    image_path: PropTypes.string,
    status: PropTypes.string,
    currency: PropTypes.string,
    insured_name: PropTypes.string,
    provider_name: PropTypes.string,
    user: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
    }),
    dependent: PropTypes.shape({
      image_path: PropTypes.string,
      member_name: PropTypes.string,
      dependent_code: PropTypes.string,
    }),
  }),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
  }).isRequired,
};

ClaimCard.defaultProps = {
  data: {},
};

export default withRouter(ClaimCard);
