// import moment from 'moment';
import initialState from '../store/articles';
// import pagination from '../lib/pagination';
import userApi from '../api/UserApi';

export default {
  namespace: 'claim',

  /**
   *  Initial state
   */
  state: initialState,

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * get bank list
     */
    async getBankList() {
      return userApi.getBankList();
    },

    /**
     * get bank list
     */
    async getCurrencyList() {
      return userApi.getCurrencyList();
    },

    /**
     * get bank list
     */
    async getCountryList() {
      return userApi.getCountryList();
    },

    /**
     * Add bank User Account
     */
    async addBankAccount(payload) {
      return userApi.addBankAccount(payload);
    },

    /**
     * Add Claim
     */
    async addClaim(payload) {
      return userApi.addClaim(payload);
    },

    /**
     * Get claim List
     */
    async getClaims([policyId, filters]) {
      return userApi.getClaims(policyId, filters);
    },

    /**
     * Get claim Details
     */
    async getClaimDetails(id) {
      return userApi.getClaimDetails(id);
    },

    /**
     * Edit Claim
     */ async editClaim(payload) {
      return userApi.editClaim(payload);
    },

    /**
     * Re Submit Claim
     */ async reSubmitClaim(payload) {
      return userApi.reSubmitClaim(payload);
    },

    /**
     * Re Submit Claim
     */ async UpdatePaymentInfo(payload) {
      return userApi.UpdatePaymentInfo(payload);
    },

    /**
     * Downlload file
     */
    async download(payload) {
      return userApi.download(payload);
    },

    /**
     * Download EOB file
     */
    async downloadEOB(claim_id) {
      return userApi.downloadEOB(claim_id);
    },

    /**
     * deleteBank details
     */
    async deleteBank(bankId) {
      return userApi.deleteBank(bankId);
    },

    /**
     * deleteClaim details
     */
    async deleteClaim(claimId) {
      return userApi.deleteClaim(claimId);
    },
  }),

  /**
   * Reducers
   */
  reducers: {
    /**
     * Replace list in store
     * @param {obj} state
     * @param {obj} payload
     */
    replace(state, payload) {
      return { ...state, phoneNumber: payload.phone_number, userID: payload.user_id };
    },
  },
};
