import axios from 'axios';
// import AsyncStorage from '@react-native-community/async-storage';
import Config from '../constants/config';
// import { Actions } from 'react-native-router-flux';
// import { Toast } from 'native-base';

export default class BaseApi {
  static request = null;

  static authenticatedRequest = null;

  static blobRequest = null;

  static isRefreshing = false;

  refreshSubscribers = [];

  _getInstance = (sendToken, isMultipart = false, blob = false) => {
    const request = axios.create({
      baseURL: Config.API_URL,
      responseType: blob ? 'blob' : 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': isMultipart
          ? 'multipart/form-data; charset=utf-8;'
          : 'application/x-www-form-urlencoded; charset=UTF-8',
        'Content-Security-Policy': "frame-ancestors 'self'",
      },
    });

    if (sendToken) {
      request.interceptors.request.use(
        async (config) => {
          const token = await localStorage.getItem('token');
          const newRequest = config;
          if (token) {
            newRequest.headers.Authorization = `Bearer ${token}`;
          }
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (timezone) {
            newRequest.headers.timezone = timezone;
          }
          return newRequest;
        },
        (error) => Promise.reject(error),
      );
    }
    return request;
  };

  publicApi() {
    if (this.request != null) {
      return this.request;
    }

    this.request = this._getInstance(false);
    return this.request;
  }

  authenticatedApi(isMultipart = false, blob = false) {
    if (this.authenticatedRequest != null && !blob) {
      return this.authenticatedRequest;
    }

    this.authenticatedRequest = this._getInstance(true, isMultipart, blob);

    this.authenticatedRequest.interceptors.response.use(undefined, (err) => {
      const {
        config,
        response: { status },
      } = err;
      const originalRequest = config;

      if (status === 401) {
        console.log('Unauthorized ', status);
        console.log('isRefreshing ', BaseApi.isRefreshing);
        if (!BaseApi.isRefreshing) {
          console.log('is Refreshing');
          BaseApi.isRefreshing = true;
          this.refreshToken()
            .then((response) => {
              console.log('refresh token response ', response);
              const { token } = response;
              this.onRefreshed(token);
              this.refreshSubscribers = [];
            })
            .catch(async (error) => {
              BaseApi.isRefreshing = false;
              console.log(error.response.status);
              if (error.response.status === 401) {
                await localStorage.setItem('token', '');
                // Actions.login();
                // Toast.show({ text: 'Your session has expired. Please login to continue', duration: 1000 });
              }
              console.log('promise rejection', error.response.status);
              this.onRefreshFailed();
              this.refreshSubscribers = [];
            });
        }
        const requestSubscribers = new Promise((resolve) => {
          this.subscribeTokenRefresh((token) => {
            if (token) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            } else {
              resolve(axios(originalRequest));
            }
          });
        });
        return requestSubscribers;
      }
      return Promise.reject(err);
    });

    return this.authenticatedRequest;
  }

  blobApi() {
    if (this.blobRequest != null) {
      return this.blobRequest;
    }

    this.blobRequest = this._getInstance(true, false, true);

    this.blobRequest.interceptors.response.use(undefined, (err) => {
      const {
        config,
        response: { status },
      } = err;
      const originalRequest = config;

      if (status === 401) {
        console.log('Unauthorized ', status);
        console.log('isRefreshing ', BaseApi.isRefreshing);
        if (!BaseApi.isRefreshing) {
          console.log('is Refreshing');
          BaseApi.isRefreshing = true;
          this.refreshToken()
            .then((response) => {
              console.log('refresh token response ', response);
              const { token } = response;
              this.onRefreshed(token);
              this.refreshSubscribers = [];
            })
            .catch(async (error) => {
              BaseApi.isRefreshing = false;
              console.log(error.response.status);
              if (error.response.status === 401) {
                await localStorage.setItem('token', '');
                // Actions.login();
                // Toast.show({ text: 'Your session has expired. Please login to continue', duration: 1000 });
              }
              console.log('promise rejection', error.response.status);
              this.onRefreshFailed();
              this.refreshSubscribers = [];
            });
        }
        const requestSubscribers = new Promise((resolve) => {
          this.subscribeTokenRefresh((token) => {
            if (token) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            } else {
              resolve(axios(originalRequest));
            }
          });
        });
        return requestSubscribers;
      }
      return Promise.reject(err);
    });

    return this.blobRequest;
  }

  subscribeTokenRefresh(cb) {
    this.refreshSubscribers.push(cb);
  }

  onRefreshed(token) {
    BaseApi.isRefreshing = false;
    this.refreshSubscribers.map((cb) => cb(token));
  }

  onRefreshFailed() {
    BaseApi.isRefreshing = false;
    this.refreshSubscribers.map((cb) => cb(null));
  }

  refreshToken() {
    return this._getInstance(true)
      .post('refresh-token')
      .then(
        (res) => {
          if (res.data.token) {
            const { token } = res.data;
            localStorage.setItem('token', token);
          }
          return res.data;
        },
        (error) => {
          console.log('Error refreshing token', error);
          throw error;
        },
      );
  }
}
