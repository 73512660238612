import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Label } from 'reactstrap';
import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Title = ({ policyNumber, certificateNumber, expiryDate, children }) => (
  <div className="policy-details-header d-flex justify-content-between">
    <div>
      <div className="d-flex">
        <Col className="policy-info">
          <Label>Policy Number</Label>
          <h5>{policyNumber}</h5>
        </Col>
        <Col className="policy-info">
          <Label>Certificate Number</Label>
          <h5>{certificateNumber}</h5>
        </Col>
        <Col className="policy-info">
          <Label>Expiry Date</Label>
          <h5>{expiryDate ? moment(expiryDate).format('MMM DD, Y') : ''}</h5>
        </Col>
      </div>
    </div>
    <div className="text-right pt-xl-0 pt-3 btn-wrap xl-5">
      {children}
    </div>
  </div>
);

Title.propTypes = {
  policyNumber: PropTypes.number,
  certificateNumber: PropTypes.string,
  expiryDate: PropTypes.string,
  children: PropTypes.element,
};

Title.defaultProps = {
  policyNumber: null,
  certificateNumber: '',
  expiryDate: '',
  children: null,
};

export default withRouter(Title);
