import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import Layout from '../components/PolicyDetails/Services/TransactionHistory/TransactionHistory';
import Alert from '../components/UI/Alert';

class TransactionHistory extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  componentDidMount() {
    this.getClaimList();
    this.getPreApprovalList();
  }

  /**
   * get Claim list
   */
  getClaimList = async (filters = null) => {
    console.log('CLAIM', filters);
    const { getClaims, policyData } = this.props;
    try {
      this.setState((prev) => ({ ...prev, loading: true }));
      const { success, data } = await getClaims([policyData?.id, { ...filters, completed: true }]);
      if (success) {
        this.setState((prev) => ({ ...prev, loading: false, claims: data }));
      }
      return false;
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loading: false }));
    }
    return [];
  };

  /**
   * Get all Pre approvals
   */
  getPreApprovalList = async (filters) => {
    console.log('PRE APPROVAL', filters);
    const { getPreApproval, policyData } = this.props;
    try {
      this.setState((prev) => ({ ...prev, loading: true }));
      const { success, data } = await getPreApproval([policyData?.id, { ...filters, completed: true }]);
      if (success) {
        this.setState((prev) => ({ ...prev, loading: false, preApprovals: data }));
      }
      return false;
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loading: false }));
    }
    return [];
  };

  /**
   * get currency list
   */
  downloadEOB = async (claim_id) => {
    const { downloadEOB } = this.props;
    toast.promise(downloadEOB(claim_id),
      {
        loading: (
          <Alert message="Please wait while the EOB is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type === 'application/pdf') {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = `EOB_${claim_id}.pdf`;
            link.click();
            return (
              <Alert message="EOB has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Medical Card'} />
          );
        },
        error: (
          <Alert message="Unable to download EOB" />
        ),
      });
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, policyData, dependents } = this.props;
    const { error, loading, success, claims, preApprovals } = this.state;

    return (
      <Layout
        downloadEOB={this.downloadEOB}
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        claims={claims}
        preApprovals={preApprovals}
        policyData={policyData}
        getClaims={this.getClaimList}
        getPreApprovals={this.getPreApprovalList}
        dependents={dependents}
      />
    );
  };
}

TransactionHistory.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  policyData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  getClaims: PropTypes.func.isRequired,
  getPreApproval: PropTypes.func.isRequired,
  downloadEOB: PropTypes.func.isRequired,
  dependents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  policyData: state.policy.currentPolicy || {},
  dependents: state.member.dependents,
});

const mapDispatchToProps = (dispatch) => ({
  getClaims: dispatch.claim.getClaims,
  getPreApproval: dispatch.pre_approval.getPreApprovals,
  downloadEOB: dispatch.claim.downloadEOB,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
