import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row, Container, Form, FormGroup, Input, Label, Button, List } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Template from '../../Templates/Home';
import Title from '../../PolicyDetails/Title';
import ClaimMessage from '../ClaimMessage';
import { errorMessages } from '../../../constants/messages';
import AddBankDetails from '../AddClaim/AddBankDetails';
import BankDetailsCard from '../AddClaim/BankDetailsCard';
// import config from '../../../constants/config';
import InfoLabel from '../../PolicyDetails/InfoLabel';

const UpdatePaymentInfo = ({
  defaultData,
  onConfirm,
  policyData,
  bankList,
  currencyList,
  countryList,
  addBankAccount,
  deleteBank,
  loading,
  bankSelected,
  success,
  memsSuccess,
  ucrn,
  draft,
}) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Claims', to: '/claims' },
    { label: 'Update Payment Info', active: true },
  ];

  const [showBanks, setSetShowBanks] = useState(true);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [addBankDetails, setAddBankDetails] = useState(false);
  const toggle = () => {
    setAddBankDetails(!addBankDetails);
  };

  /* eslint-disable */
  const handlePaymentMethod = (e) => {
    if (e.target.value === '0') {
      setSetShowBanks(true);
    } else {
      setSetShowBanks(false);
    }
    setValue('payment_method', e.target.value);
  };

  return (
    <Template pageTitle="Edit Claim" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section color="light" className="policy-details-content metLife-gray">
          <div className="container-fluid max-container">
            {!success ? (
              <Row>
                <Col className="">
                  <div className="card policy-details-tab">
                    <Row className="justify-content-between mt-3">
                      <Col md="5">
                        <Label className="f-mlc-m display-3 tab-title">
                          {`Claim #${defaultData?.ucrn_no ? `UCRN${defaultData?.ucrn_no}` : null || defaultData?.id}`}
                        </Label>
                      </Col>
                    </Row>
                    <hr />
                    <Fragment>
                      <Form onSubmit={handleSubmit((values) => onConfirm(values, true))} >
                        <FormGroup>
                          <div className="grid grid-2 info-list-wrapper">
                            <List type="unstyled" className="info-list">
                              <li>
                                <InfoLabel title="Policy Number" name={defaultData?.policy?.policy_number} />
                              </li>
                              <li>
                                <InfoLabel title="Insured Name" name={defaultData?.insured_name} />
                              </li>
                            </List>
                            <List type="unstyled" className="info-list">
                              <li>
                                <InfoLabel title="Claimed Amount" name={defaultData?.claimed_amount} />
                              </li>
                              <li>
                                <InfoLabel title="Payment Mode" name={defaultData?.payment_mode} />
                              </li>
                            </List>
                          </div>
                          <Input
                            name="claim_id"
                            type="hidden"
                            id="claim_id"
                            value={defaultData?.id}
                            innerRef={register()}
                          />
                          <Input
                            name="country_id"
                            type="hidden"
                            id="country_id"
                            value={defaultData?.country_id}
                            innerRef={register()}
                          />

                          <h3 className="mt-5">How would you like to receive your reimbursement?</h3>
                          <p>I would like to receive my medical claim(s) reimbursement via: </p>
                          {showBanks ? (
                            <div>
                              <Row>
                                <Col>
                                  <h3>Bank Details</h3>
                                </Col>
                                <Col className="text-right text-primary font-weight-bold">
                                  <a onClick={toggle}>+ Add Bank Details</a>
                                </Col>
                              </Row>
                              <Row>
                                <p className="mt-1 ml-3">
                                  (Note: If the country of Bank you choose is United Arab Emirates, Qatar or Kuwait, please choose a bank account having IBAN number.)
                                </p>
                              </Row>
                              <Row>
                                {policyData?.member_details?.bank_accounts.map((x) => (
                                  <BankDetailsCard
                                    register={register}
                                    setValue={setValue}
                                    key={x.id}
                                    data={x}
                                    defaultChecked={
                                      defaultData?.bank_id
                                        ? x?.id === defaultData?.bank_id
                                        : x?.id === policyData?.member_details?.bank_id
                                    }
                                    deleteBank={deleteBank}
                                  />
                                ))}
                                {errors.bank_id && <p className="invalid-feedback">Please Choose a bank</p>}
                              </Row>
                              {!bankSelected && (
                                <Col className="text-right">
                                  <p style={{ color: 'red' }}> Please choose a bank account</p>
                                </Col>
                              )}
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <FormGroup className="form-label-group">
                                  <Input
                                    type="payment_to"
                                    readOnly
                                    defaultValue={policyData?.member_details?.member_name}
                                    name="payment_to"
                                    id="payment_to"
                                    placeholder="payment_to address"
                                    disabled={loading}
                                    invalid={!!errors.payment_to}
                                    innerRef={register({ required: errorMessages.missingData })}
                                    onChange={(e) => setValue('payment_to', e.target.value)}
                                  />
                                  <Label for="payment_to">Paid To</Label>
                                  {errors.dependent && <p className="invalid-feedback">{errors.email.message}</p>}
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              {/* <div className="pl-4">
                                <Input
                                  type="checkbox"
                                  name="declaration"
                                  id="declaration"
                                  disabled={loading}
                                  invalid={!!errors.declaration}
                                  innerRef={register({ required: errorMessages.missingData })}
                                  onChange={(e) => setValue('declaration', e.target.value)}
                                />
                                <Label for="declaration">
                                  I have read and accept all the
                                  <a
                                    href={`${config.baseURL}/claim-submission-disclaimer/AE`}
                                    className="btn-link"
                                    target="blank"
                                  >
                                    {' '}
                                    Terms & conditions
                                  </a>
                                </Label>
                                {errors.declaration && (
                                  <p className="invalid-feedback">Please check this to continue</p>
                                )}
                              </div> */}
                            </Col>
                            <Col className="text-right">
                              <Button color="primary" disabled={loading}>
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                      <AddBankDetails
                        toggle={toggle}
                        isOpen={addBankDetails}
                        bankList={bankList}
                        currencyList={currencyList}
                        countryList={countryList}
                        onConfirm={addBankAccount}
                        loading={loading}
                        user={policyData?.member_details}
                      />
                    </Fragment>
                  </div>
                </Col>
              </Row>
            ) : (
              <ClaimMessage memsSuccess={memsSuccess} ucrn={ucrn} draft={draft} />
            )}
          </div>
        </section>
      </>
    </Template>
  );
};

UpdatePaymentInfo.propTypes = {
  defaultData: PropTypes.shape({
    id: PropTypes.number,
    country_id: PropTypes.number,
  }).isRequired,
  policyData: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  bankList: PropTypes.arrayOf(PropTypes.shape({})),
  currencyList: PropTypes.arrayOf(PropTypes.shape({})),
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  addBankAccount: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  deleteBank: PropTypes.func.isRequired,
  bankSelected: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  memsSuccess: PropTypes.bool,
  ucrn: PropTypes.string,
  draft: PropTypes.bool,
  // message: PropTypes.string,
  mandatoryFilesUploaded: PropTypes.shape({}).isRequired,
};

UpdatePaymentInfo.defaultProps = {
  bankList: [],
  currencyList: [],
  countryList: [],
  loading: false,
  success: false,
  memsSuccess: false,
  ucrn: '',
  draft: false,
  // message: '',
};

export default withRouter(UpdatePaymentInfo);
