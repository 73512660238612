import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Label, Button, Form, FormGroup, Input, Alert, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Title from '../Title';
import Template from '../../Templates/Home';
import { errorMessages } from '../../../constants/messages';

const AddBeneficiary = ({ error, loading, success, onFormSubmit, defaultValues }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  return (
    <Template pageTitle="Policy Details">
      <Container>
        <Title />
        <Row>
          <Label>Add Beneficiary</Label>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              {!!success && <Alert color="success">{success}</Alert>}
              {!!error && <Alert color="danger">{error}</Alert>}
              {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}
              <Row>
                <Col>
                  <FormGroup className="mt-3 form-label-group">
                    <Label for="beneficiary_name" className="required">
                      Beneficiary Name
                    </Label>
                    <Input
                      type="text"
                      name="beneficiary_name"
                      id="beneficiary_name"
                      placeholder="Beneficiary Name"
                      disabled={loading}
                      invalid={!!errors.beneficiary_name}
                      innerRef={register({ required: errorMessages.missingEmail })}
                      onChange={(e) => setValue('beneficiary_name', e.target.value)}
                    />
                    {errors.beneficiary_name && (
                      <p className="invalid-feedback">{errors.beneficiary_name.message}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mt-3 form-label-group">
                    <Label for="beneficiary_name" className="required">
                      Beneficiary Relation
                    </Label>
                    <Input
                      type="text"
                      name="beneficiary_relation"
                      id="beneficiary_relation"
                      placeholder="Beneficiary Relation"
                      disabled={loading}
                      invalid={!!errors.beneficiary_name}
                      innerRef={register({ required: errorMessages.missingEmail })}
                      onChange={(e) => setValue('beneficiary_relation', e.target.value)}
                    />
                    {errors.beneficiary_relation && (
                      <p className="invalid-feedback">{errors.beneficiary_relation.message}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mt-3 form-label-group">
                    <Label for="beneficiary_eff_date" className="required">
                      Beneficiary EFF.Date
                    </Label>
                    <Input
                      type="text"
                      name="beneficiary_eff_date"
                      id="beneficiary_eff_date"
                      placeholder="Beneficiary EFF.Date"
                      disabled={loading}
                      invalid={!!errors.beneficiary_eff_date}
                      innerRef={register({ required: errorMessages.missingEmail })}
                      onChange={(e) => setValue('beneficiary_eff_date', e.target.value)}
                    />
                    {errors.beneficiary_eff_date && (
                      <p className="invalid-feedback">{errors.beneficiary_eff_date.message}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mt-3">
                    <Label for="beneficiary_dob" className="required">
                      Beneficiary DOB
                    </Label>
                    <Input
                      type="date"
                      name="beneficiary_dob"
                      id="beneficiary_dob"
                      placeholder="dd/mm/yyyy"
                      className="form-control placeholder"
                      disabled={loading}
                      invalid={!!errors.beneficiary_dob}
                      innerRef={register({ required: errorMessages.missingEmail })}
                      onChange={(e) => {
                        setValue('beneficiary_dob', e.target.value);
                      }}
                      onClick={(e) => {
                        e.target.showPicker();
                        e.target.classList.add('active');
                      }}
                    />
                    {errors.beneficiary_dob && (
                      <p className="invalid-feedback">{errors.beneficiary_dob.message}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" disabled={loading} size="lg">
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Template>
  );
};

AddBeneficiary.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.string,
  defaultValues: PropTypes.shape({
    beneficiary_name: PropTypes.string,
    beneficiary_relation: PropTypes.string,
    beneficiary_eff_date: PropTypes.string,
    beneficiary_dob: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

AddBeneficiary.defaultProps = {
  error: null,
  success: null,
  loading: false,
  defaultValues: {},
};

export default withRouter(AddBeneficiary);
