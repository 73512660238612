import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Layout from '../components/PolicyDetails/PolicyDetails';
import Alert from '../components/UI/Alert';

class PolicyDetails extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, downloading: false };
  }

  componentDidMount() {
    // const { getPolicyDetails, currentPolicy } = this.props;
    // if (currentPolicy) {
    //   getPolicyDetails(currentPolicy);
    // }

    this.getMemberCoverageDetails();
  }

  generateECard = async (id) => {
    const { generateECard, currentPolicy } = this.props;

    this.setState((state) => ({ ...state, ecardDownload: true }));
    // const pdf = await generateECard({ id, policyId: currentPolicy?.id });
    toast.promise(generateECard({ id, policyId: currentPolicy?.id }),
      {
        loading: (
          <Alert message="Please wait while the Medical Card is downloading" isOpen />
        ),
        success: (pdf) => {
          console.log(typeof pdf.type);
          if (pdf?.type === 'application/pdf') {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = 'MedicalCard.pdf';
            link.click();
            return (
              <Alert message="Medical Card has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Medical Card'} />
          );
        },
        error: (
          <div className="custom-alert alert alert-danger alert-dismissible">
            <span className="alert-icon">!</span>
            <h3>Error:</h3>
            Unable to download Medical Card
            <button type="button" className="close">×</button>
          </div>
        ),
      });
  };

  downloadTOB = async (dependent_id = null) => {
    const { downloadTOB, currentPolicy } = this.props;
    // toast('Please wait while the Table of benefits Card is downloading" />, {
    //   duration: 3000,
    // });
    // const pdf = await downloadTOB({ policy_id: currentPolicy?.id, dependent_id });
    toast.promise(downloadTOB({ policy_id: currentPolicy?.id, dependent_id }), {
      loading: (
        <Alert message="Please wait while the Table of benefits Card is downloading" isOpen />
      ),
      success: (pdf) => {
        console.log(typeof pdf.type);
        if (pdf?.type === 'application/pdf') {
          // const file = new Blob([...pdf], { type: 'application/pdf' });
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          this.setState((state) => ({ ...state, ecardDownload: false }));
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(pdf);
          link.download = 'TableOfBenefits.pdf';
          link.click();
          return (
            <Alert message="Table Of Benefits has downloaded" />
          );
        }
        this.setState((state) => ({ ...state, ecardDownload: false }));
        return (
          <Alert message={pdf?.message || 'Unable to download Table Of Benefits'} />
        );
      },
      error: (
        <div className="custom-alert alert alert-danger alert-dismissible">
          <span className="alert-icon">!</span>
          <h3>Error:</h3>
          Unable to download Medical Card
          <button type="button" className="close">×</button>
        </div>
      ),
    });
    console.log(typeof pdf);
  };

  getMemberCoverageDetails = async () => {
    const { currentPolicy, getMemberCoverageDetails } = this.props;
    const { data } = await getMemberCoverageDetails(currentPolicy?.id);
    this.setState((pre) => ({ ...pre, memberCoverageDetails: data }));
  };

  /**
   * Render
   */
  render = () => {
    const { currentPolicy } = this.props;
    const { error, loading, success, memberCoverageDetails } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        generateECard={this.generateECard}
        downloadTOB={this.downloadTOB}
        data={currentPolicy}
        memberCoverageDetails={memberCoverageDetails}
      />
    );
  };
}

PolicyDetails.propTypes = {
  currentPolicy: PropTypes.shape({
    id: PropTypes.number,
    policyNumber: PropTypes.string,
  }).isRequired,
  generateECard: PropTypes.func.isRequired,
  downloadTOB: PropTypes.func.isRequired,
  getMemberCoverageDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getPolicyDetails: dispatch.policy.getPolicyDetails,
  generateECard: dispatch.policy.generateECard,
  downloadTOB: dispatch.policy.downloadTOB,
  getMemberCoverageDetails: dispatch.policy.getMemberCoverageDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDetails);
