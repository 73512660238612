import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/SetNewPassword';

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, data: {} };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data, password_reset) => {
    console.log(' set new password');
    const { history } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      // const res = await onFormSubmit(data);
      this.setState({
        success: null,
        error: null,
        loading: false,
        // message: 'Password has been updated',
      });
      setTimeout(() => {
        history.push({
          pathname: '/addSecurityQuestion',
          state: { ...data, password_reset },
        });
      }, 0);
    } catch (error) {
      this.setState({ loading: false, success: null, error: true, message: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput } = this.props;
    const { error, loading, success, message, data } = this.state;

    console.log(data);

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        data={data}
        message={message}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

PasswordReset.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // onFormSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.member.setNewPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
