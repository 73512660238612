import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Spinner } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { Helmet } from 'react-helmet';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import mailLogo from '../assets/images/mail.png';
import Footer from './UI/Footer';

const VerifyOTP = ({ error, loading, success, message, onFormSubmit, defaultValues, resendOTP, verified }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const [showResend, setShowResend] = useState(false);

  const [otp, setOtp] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setShowResend(true);
    }, 60000);
  }, []);
  const history = useHistory();

  return (
    <Container className="login full-width-page" tag="section">
      <Helmet>
        <title>Verify OTP</title>
      </Helmet>
      <Row className="text-center align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          {verified ? (
            <div className="msg-box" style={{ maxHeight: 'initial', height: 'auto' }}>
              <img className="logo" src={mailLogo} alt="" style={{ width: '80px', marginBottom: '10px' }} />
              <h2 style={{ marginBottom: 0 }}>50% Completed</h2>
              <h3>Check your inbox</h3>
              <h3 style={{ fontWeight: '400' }}>{message}</h3>
              <Link to="/">
                <h3>Login</h3>
              </Link>
            </div>
          ) : (
            <>
              <h1>Enter Validation Code</h1>
              <h3>
                If account is associated with
                <span> </span>
                <span>{defaultValues?.first_name}</span>
                <span> </span>
                <span>{defaultValues?.last_name}</span>
                <span>, </span>
                you will receive a validation code.
                <span>{defaultValues.masked_phone_number || defaultValues.phone_number}</span>
              </h3>
              <h3>The code will be valid only for 10 minutes</h3>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                {!!success && <Alert color="success">{message}</Alert>}
                {!!error && <Alert color="danger">{message}</Alert>}
                {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

                <Input
                  type="hidden"
                  name="phone_number"
                  id="phone_number"
                  innerRef={register({ required: errorMessages.incorrectOTP })}
                />

                <Input
                  type="hidden"
                  name="user_id"
                  id="user_id"
                  innerRef={register({ required: errorMessages.incorrectOTP })}
                />
                <FormGroup className="mt-3 form-label-group">
                  {/* <Input
                    type="number"
                    name="otp"
                    id="otp"
                    placeholder="Enter OTP Code:"
                    disabled={loading}
                    invalid={!!errors.otp}
                    innerRef={register({ required: errorMessages.incorrectOTP })}
                    onChange={(e) => setValue('otp', e.target.value)}
                  /> */}

                  <Input
                    type="hidden"
                    name="otp"
                    id="otp"
                    innerRef={register({ required: errorMessages.incorrectOTP })}
                    value={otp}
                  />

                  <OtpInput
                    value={otp}
                    inputStyle={{ width: '50px', height: '50px', margin: '2px', fontSize: '30px' }}
                    containerStyle={{ justifyContent: 'center' }}
                    onChange={(value) => {
                      setValue('otp', value);
                      setOtp(value);
                    }}
                    shouldAutoFocus
                    isInputNum
                    numInputs={6}
                  />

                  {/* <Label>Enter OTP Code</Label> */}
                  {errors.otp && <p className="invalid-feedback">{errors.otp.message}</p>}
                  {!!showResend && (
                    <a
                      className="btn-link d-block text-right pt-2 pr-1"
                      onClick={() => {
                        resendOTP();
                        setShowResend(false);
                        setTimeout(() => {
                          setShowResend(true);
                        }, 60000);
                      }}
                    >
                      Resend Validation Code
                    </a>
                  )}
                </FormGroup>
                <Button color="outline-primary" className="mr-3 px-5" onClick={() => history.push('/login')}>
                  Cancel
                </Button>
                <Button color="primary" className="px-5" disabled={loading} size="lg">
                  {loading ?
                    (
                      <>
                        <Spinner
                          size="sm"
                          color="light"
                        />
                        <span>&nbsp;Loading</span>
                      </>
                    ) : 'Continue'}
                </Button>
              </Form>
              <div className="bottom-links justify-content-center my-5 text-center">
                <Link to="/verifyAccount">Skip the OTP verification</Link>
              </div>
            </>
          )}
          For Additional Assistance, Please click here to
          <div className="bottom-links justify-content-center text-center">
            <Link to="/contactUs">contact us</Link>
          </div>
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

VerifyOTP.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  defaultValues: PropTypes.shape({
    otp: PropTypes.string,
    phone_number: PropTypes.string,
    masked_phone_number: PropTypes.string,
    user_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  resendOTP: PropTypes.func.isRequired,
  verified: PropTypes.bool,
};

VerifyOTP.defaultProps = {
  error: null,
  success: null,
  message: null,
  loading: false,
  defaultValues: {},
  verified: false,
};

export default withRouter(VerifyOTP);
