import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import Alert from '../UI/Alert';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';

const LoginCredentials = ({ loading, onFormSubmit, defaultValues, data, height }) => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm({ defaultValues });
  const [button, setButton] = useState(false);

  const submit = async (profileData) => {
    const res = await onFormSubmit(profileData);
    if (res?.success) {
      setButton(false);
    }
    toast.custom(<Alert message={res?.message} />);

    // await toast.promise(onFormSubmit(profileData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     if (e?.success) {
    //       setButton(false);
    //     }
    //     toast.custom(<Alert message="e?.message} />, {
    //       duration: 3000,
    //     });
    //   },
    //   error: (e) => {
    //     setButton(false);
    //     toast.custom(<Alert message="e?.message} />, {
    //       duration: 3000,
    //     });
    //   },
    // });
  };

  return (
    <Card name="loginCredentials" className="profile-login-credential" style={{ marginBottom: height }}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Login Credentials</Label>
          </div>
          <div className="text-right">
            {!button ? (
              <a
                className="btn-link edit"
                onClick={() => {
                  if (button) {
                    setButton(false);
                  } else {
                    setButton(true);
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                    <rect
                      id="Rectangle_95"
                      data-name="Rectangle 95"
                      width="3.4"
                      height="4.8"
                      transform="translate(16.195 4.391) rotate(-45)"
                      fill="#0090da"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                      fill="#007abc"
                    />
                  </g>
                </svg>
                Edit
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr />
        {button ? (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <div className="pl-3 user-id">
                    <InfoLabel title="User ID" name={data?.email} />
                  </div>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="password"
                        name="old_password"
                        autoComplete="off"
                        id="old_password"
                        placeholder="Old Password"
                        disabled={loading}
                        invalid={!!errors.old_password}
                        innerRef={register({ required: errorMessages.missingOldPassword })}
                        onChange={(e) => setValue('old_password', e.target.value)}
                      />
                      <Label for="">Old Password</Label>
                      {errors.old_password && <p className="invalid-feedback">{errors.old_password.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="password"
                        autoComplete="off"
                        name="password"
                        id="password"
                        placeholder="New Password"
                        disabled={loading}
                        invalid={!!errors.password}
                        innerRef={register({
                          required: errorMessages.missingPassword,
                          pattern: {
                            value: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                            message:
                              'Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one number',
                          },
                        })}
                        onChange={(e) => setValue('password', e.target.value)}
                      />
                      <Label for="">New Password</Label>
                      {errors.password ? (
                        <p className="invalid-feedback">{errors.password.message}</p>
                      ) : (
                        <p style={{ fontSize: '12px', textAlign: 'left', padding: '2px 8px' }}>
                          Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least
                          one number
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="password"
                        name="con_password"
                        autoComplete="off"
                        id="con_password"
                        placeholder="Confirm Password"
                        disabled={loading}
                        invalid={!!errors.con_password}
                        innerRef={register({
                          required: true,
                          validate: (val) => {
                            const { password } = getValues();
                            return password === val || errorMessages.passwordsDontMatch;
                          },
                        })}
                        onChange={(e) => setValue('con_password', e.target.value)}
                      />
                      <Label for="">Confirm Password</Label>
                      {errors.con_password && <p className="invalid-feedback">{errors.con_password.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ?
                        (
                          <>
                            <Spinner
                              size="sm"
                              color="light"
                            />
                            <span>&nbsp;Loading</span>
                          </>
                        ) : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Username" name={data?.email} />
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Password" name="**********" />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

LoginCredentials.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  onFormSubmit: PropTypes.func.isRequired,
  height: PropTypes.string,
};

LoginCredentials.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
  height: null,
};

export default withRouter(LoginCredentials);
