import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Template from '../../Templates/Home';
import Title from '../Title';
import ListCard from './ListCard';

const Services = ({ serviceList, policyData }) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', active: true },
  ];
  return (
    <Template pageTitle="Services" breadcrumb={breadcrumb}>
      <>
        <Container className="max-container">
          <Title
            policyNumber={policyData.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData.expiryDate}
          >
            <Link
              to={{
                pathname: '/home/searchProvider',
              }}
              className="btn btn-outline-primary mr-3"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
                <path
                  id="Icon_Search"
                  data-name="Icon/Search"
                  d="M-1.713,16h0L0,14.32-4.6,9.813a6.062,6.062,0,0,0,.51-6.44A6.292,6.292,0,0,0-9.7,0a6.306,6.306,0,0,0-5.945,4.139,6.123,6.123,0,0,0,2.007,6.877,6.4,6.4,0,0,0,3.943,1.359,6.385,6.385,0,0,0,3.333-.936L-1.713,16ZM-9.7,2.376A3.854,3.854,0,0,1-5.813,6.188,3.854,3.854,0,0,1-9.7,10a3.858,3.858,0,0,1-3.884-3.812A3.853,3.853,0,0,1-9.7,2.376Z"
                  transform="translate(15.999 0)"
                  fill="#007abc"
                />
              </svg>
              Search Provider
            </Link>
          </Title>
        </Container>
        <section className="metLife-gray">
          <Container fluid className="max-container">
            <Row>
              <Col className="grid grid-2 service-list mb-4">
                {serviceList.map((item) => (
                  <Link to={{ pathname: item.url, state: { policyData } }} key={item.id}>
                    <ListCard data={item} policyData={policyData} />
                  </Link>
                ))}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </Template>
  );
};

Services.propTypes = {
  serviceList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.shape({}),
      url: PropTypes.string,
      policyData: PropTypes.shape({}),
    }),
  ),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
};

Services.defaultProps = {
  serviceList: [],
  policyData: {},
};

export default withRouter(Services);
