import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, List } from 'reactstrap';
import toast from 'react-hot-toast';
import Alert from '../../UI/Alert';

const CustomFileInput = ({ onFileSelect, type, label, defaultData, onFileRemoved }) => {
  const file = useRef();
  const [files, setFiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState(defaultData);

  const checkValid = (filesObject) => {
    let valid = true;
    Object.values(filesObject).every((singlefile) => {
      if (singlefile?.size > 8388608) {
        toast.custom(<Alert message="'Documents have to be less than 8MB." />);
        valid = false;
        return false;
      }
      return true;
    });
    return valid;
  };

  const handleFileRemove = (index) => {
    const dt = new DataTransfer();
    Object.keys(files).forEach((key) => {
      if (index !== key) dt.items.add(files[key]);
    });
    setFiles(dt.files);
    onFileSelect(dt.files, type);
  };

  const handleAddToDelete = (id) => {
    const newList = list?.filter((item) => item?.id !== id);
    setList(newList);
    onFileRemoved(id);
  };

  const handleFileChange = (fileList) => {
    const dt = new DataTransfer();

    // EXISTING FILES
    Object.keys(files).forEach((key) => {
      dt.items.add(files[key]);
    });

    // NEWLY SELECTED FILES
    Object.keys(fileList).forEach((key) => {
      dt.items.add(fileList[key]);
    });

    if (checkValid(dt.files)) {
      setFiles(dt.files);
      onFileSelect(dt.files, type);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (files.length || list?.length) {
            setIsOpen(true);
          } else if (file) {
            file.current.click();
          }
        }}
        className="p-4 border shadow-none d-flex border-radius mb-4 align-items-center pointer"
      >
        <FileIcon fill={files.length || list?.length ? '#007abc' : '#808080'} />
        <h4 className="mb-0 ml-2">{label}</h4>
        <input
          type="file"
          id={`file${1 + 1}`}
          name={`file${1 + 1}`}
          ref={file}
          accept=".jpg, .jpeg, .tif, .bmp, .png, .doc, .docx, .txt, .pdf"
          style={{ display: 'none' }}
          onChange={(e) => handleFileChange(e.target.files)}
          multiple
        />
        <FontAwesomeIcon className="ml-auto" icon={faUpload} />
      </div>

      <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} style={{ width: '80vw' }}>
        <ModalHeader>{label}</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h3>Selected Files</h3>
            <Button type="button" size="sm" color="primary" onClick={() => file && file.current.click()}>
              + Add Files
            </Button>
          </div>
          <List type="unstyled" className="selected-files">
            {list?.map((item) => (
              <li className="my-3" key={item.id}>
                <Card>
                  <CardBody className="d-inline-flex justify-content-between">
                    <p>{item.file_name}</p>
                    {!item.id ? (
                      <Button color="danger" type="button" onClick={() => handleAddToDelete(item.id)} disabled>
                        Remove
                      </Button>
                    ) : null}
                  </CardBody>
                </Card>
              </li>
            ))}
            {Object.keys(files).map((key) => (
              <li className="my-3" key={key}>
                <Card>
                  <CardBody className="d-inline-flex justify-content-between">
                    <p>{files[key].name}</p>
                    <Button color="danger" type="button" onClick={() => handleFileRemove(key)}>
                      Remove
                    </Button>
                  </CardBody>
                </Card>
              </li>
            ))}
          </List>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="outline-primary" onClick={() => setIsOpen(!isOpen)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const FileIcon = ({ fill }) => (
  <svg
    id="Icon_Folder"
    data-name="Icon/Folder"
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <rect id="Mask" width="31" height="31" fill="none" />
    <path
      id="Icon_Folder-2"
      data-name="Icon/Folder"
      d="M2,25.222a1.294,1.294,0,0,0,1.35,1.389A1.294,1.294,0,0,0,4.7,25.222V8.556H26.3V5.778H10.774L9.019,3H2ZM6.049,9.944V26.333A1.666,1.666,0,0,1,4.43,28H27.376A1.622,1.622,0,0,0,29,26.194V9.944ZM12.124,20.5a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,12.124,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,17.523,20.5Zm5.4,0a1.528,1.528,0,1,1,1.485-1.528A1.52,1.52,0,0,1,22.922,20.5Z"
      fill={fill}
    />
  </svg>
);

CustomFileInput.propTypes = {
  type: PropTypes.string.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      file_name: PropTypes.string.isRequired,
    }),
  ),
  onFileRemoved: PropTypes.func.isRequired,
};

CustomFileInput.defaultProps = {
  defaultData: [],
};

FileIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default withRouter(CustomFileInput);
