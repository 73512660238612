import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/ComplaintDetails';

class ComplaintDetails extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({ data: location?.state?.data });
  }

  downloadFile = async (filepath) => {
    const { download } = this.props;
    toast.promise(download({ file_path: filepath }),
      {
        loading: (
          <Alert message="Please wait while the file is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type) {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = '_blank';
            link.click();
            return (
              <Alert message="File has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download file'} />
          );
        },
        error: (
          <Alert message="Unable to download file" />
        ),
      });
  };

  /**
   * Render
   */
  render = () => {
    // const { userInput } = this.props;
    const { data, loading } = this.state;
    const { currentPolicy } = this.props;
    return <Layout data={data} policyData={currentPolicy} loading={loading} download={this.downloadFile} />;
  };
}

ComplaintDetails.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  download: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  download: dispatch.claim.download,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintDetails);
