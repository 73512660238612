import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import InfoLabel from '../InfoLabel';

const ViewBenefits = ({ data, toggle, isOpen, titleName }) => {
  const [active, setActive] = useState(0);
  return (
    <>
      <Modal size="lg" isOpen={isOpen} toggle={toggle} style={{ height: '100%' }}>
        <ModalHeader toggle={toggle}>Coverage Benefits</ModalHeader>
        <ModalBody>
          <Row className="justify-content-between mt-3 row">
            <Col md="12">
              <div className="p-4 shadow-none mb-4 rounded-0">
                <h3 className="f-mlc-m sub-title">{titleName}</h3>
              </div>
            </Col>
            <Col>
              <div className="policy-details-tab mt-3">
                <Nav className="w-100" tabs>
                  {data?.map((element, index) => (
                    <NavItem key={element?.benefit_name}>
                      <NavLink
                        className={active === index ? 'active' : ''}
                        onClick={() => {
                          setActive(index);
                        }}
                      >
                        {element?.benefit_name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={active} className="py-4 w-100">
                  {data?.map((element, index) => (
                    <TabPane tabId={index} key={element?.benefit_name}>
                      <InfoLabel title="Annual Coverage Amount" name={element?.annual_coverage_amount} />
                      <Row>
                        <Col>
                          <h4 className="f-mlc-m sub-title">{element?.benefit_name}</h4>
                          <div className="grid grid-1 info-list-wrapper w-100">
                            <ul className="info-list list-unstyled w-100">
                              <li>
                                <InfoLabel title="Co-insurance(%)" name={element?.coinsurance || ''} />
                              </li>
                              <li>
                                <InfoLabel title="Available Limit" name={element?.available_limit || ''} />
                              </li>
                              <li>
                                <InfoLabel title="Co-pay" name={element?.copay || ''} />
                              </li>
                              <li>
                                <InfoLabel title="Deductible" name={element?.deductible || ''} />
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="outline-primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ViewBenefits.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      access: string,
      annual_coverage_amount: string,
      available_limit: string,
      benefit_name: string,
      coinsurance: string,
      copay: string,
      deductible: string,
    }),
  ),
  titleName: string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ViewBenefits.defaultProps = {
  data: null,
  isOpen: false,
};

export default withRouter(ViewBenefits);
