import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Container,
  Row,
  List,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Template from '../../Templates/Home';
import Title from '../../PolicyDetails/Title';
import InfoLabel from '../../PolicyDetails/InfoLabel';
import Loading from '../../UI/Loading';

const PreApprovals = ({ data, policyData, loading, breadcrumb, download, showResubmit }) => {
  const [active, setActive] = useState('documents');
  const date = new Date();
  const formattedDate = date
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    .replace(/\//g, '-');

  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Pending';
      case 'Approved':
        return 'Approved';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Approved';
      case 'Suspended':
        return 'Suspended';
      default:
        return 'In Progress';
    }
  };

  // const currencyByCountryId = (id) => {
  //   switch (id) {
  //     case 234:
  //       return 'AED';
  //     case 18:
  //       return 'BHD';
  //     case 166:
  //       return 'OMR';
  //     default:
  //       return '';
  //   }
  // };

  console.log(data);

  /* eslint-disable */
  // const numberFormat = (value, currency) =>
  //   new Intl.NumberFormat('en-IN', {
  //     style: 'currency',
  //     currency,
  //   }).format(value);

  return (
    <Template pageTitle="PreApproval details" breadcrumb={breadcrumb}>
      <Fragment>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData.expiryDate}
          >
            {(getStatusString(data?.status) === 'Pending' ||
              getStatusString(data?.status) === 'Rejected' ||
              getStatusString(data?.status) === 'Partially Approved' ||
              getStatusString(data?.status) === 'Suspended') &&
            showResubmit ? (
              <>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1 text-danger" />
                <small className="mr-2">Action Required</small>
                <Button
                  color="primary"
                  tag={Link}
                  to={{
                    pathname: '/reSubmitPreApproval',
                    state: {
                      data,
                    },
                  }}
                >
                  Re-Submit
                </Button>
              </>
            ) : null}
          </Title>
        </Container>

        <section className="metLife-gray">
          <Container fluid className="max-container">
            {loading ? (
              <Loading />
            ) : (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label className="f-mlc-m display-3 tab-title">
                        {`PreApproval #${data?.reference_number || data?.id}`}
                        <span className="badge badge-secondary ml-sm-3">
                          <small>{getStatusString(data?.status)}</small>
                        </span>
                      </Label>
                    </Col>
                    <Col className="text-right">
                      {getStatusString(data?.status) === 'Draft' || data?.approval_submission_date === formattedDate ? (
                        <Link
                          to={{
                            pathname: '/editPreApproval',
                            state: {
                              data,
                            },
                          }}
                        >
                          <svg
                            id="Icon_PencilEdit"
                            data-name="Icon/PencilEdit"
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                          >
                            <rect id="Mask" width="24" height="24" fill="none" />
                            <g id="Icon_PencilEdit-2" data-name="Icon/PencilEdit" transform="translate(-0.5 0.514)">
                              <rect
                                id="Rectangle_95"
                                data-name="Rectangle 95"
                                width="3.4"
                                height="4.8"
                                transform="translate(16.195 4.391) rotate(-45)"
                                fill="#0090da"
                              />
                              <path
                                id="Path_3"
                                data-name="Path 3"
                                d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                                fill="#007abc"
                              />
                            </g>
                          </svg>
                          <b>Edit</b>
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="search-provider-results">
                    <Col>
                      <div className="grid grid-2 info-list-wrapper">
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Policy Number" name={data?.policy?.ext_policy_number} />
                          </li>
                          <li>
                            <InfoLabel title="Provider Name" name={data?.provider_name} />
                          </li>
                          {/* <li>
                            <InfoLabel title="Insured Id " name={data?.insured_id} />
                          </li> */}
                          {/* <li>
                            <InfoLabel title="Dependent Code" name={data?.dependant_code} />
                          </li>
                          <li>
                            <InfoLabel title="Physician Name" name={data?.physician_name} />
                          </li> */}
                          <li>
                            <InfoLabel title="Expected Admission Date" name={data?.expected_admission_date} />
                          </li>
                          {/* <li>
                            <InfoLabel title="Approval Scenario" name={data?.approval_scenario} />
                          </li> */}
                          {/* <li>
                            <InfoLabel
                              title="Patient Name"
                              name={data?.dependent?.member_name || data?.user?.member_name}
                            />
                          </li> */}
                          <li>
                            <InfoLabel
                              title="Claimed Amount"
                              name={data?.claimed_amount_format ? data?.claimed_amount_format : ''}
                            />
                          </li>
                          <li>
                            <InfoLabel
                              title="Approved Amount"
                              name={data?.approved_amount_format ? data?.approved_amount_format : ''}
                            />
                          </li>
                          <li>
                            <span>Additional Info: </span>
                            <span>{data?.additional_info}</span>
                          </li>
                        </List>
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Certificate Number" name={policyData?.certificateNumber} />
                          </li>
                          <li>
                            <InfoLabel title="Insured Name" name={data?.insured_name} />
                          </li>
                          {/* <li>
                            <InfoLabel title="Hospital Name" name={data?.hospital_name} />
                          </li> */}
                          <li>
                            <InfoLabel
                              title="Approval Submission Date"
                              name={moment(data?.created_at).local().format('DD-MM-YYYY')}
                            />
                          </li>
                          {/* <li>
                            <InfoLabel title="Expected Number of days" name={data?.expected_number_of_days} />
                          </li> */}
                          {/* <li>
                            <InfoLabel title="Diagnosis" name={data?.diagnosis} />
                          </li> */}
                          {/* <li>
                            <InfoLabel title="Provider Name" name={data?.provider_name} />
                          </li> */}
                          <li>
                            <InfoLabel
                              title="Denied Amount"
                              name={data?.denied_amount_format ? data?.denied_amount_format : ''}
                            />
                          </li>
                        </List>
                      </div>
                      <span>Comments: </span>
                      <span>{data?.comments}</span>
                      <Nav className="w-100 tab-default mt-5 p-0 mb-3" tabs>
                        {/* <NavItem>
                          <NavLink
                            className={active === 'service' ? 'active' : ''}
                            onClick={() => {
                              setActive('service');
                            }}
                          >
                            Service List
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={active === 'documents' ? 'active' : ''}
                            onClick={() => {
                              setActive('documents');
                            }}
                          >
                            Documents
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={active}>
                        {/* <TabPane tabId="service">
                          <div className="grid grid-2 info-list-wrapper">
                            <List type="unstyled" className="info-list">
                              <h3 className="f-mlc-m">Specialist OP Initial Visit/Consultation</h3>
                              <li>
                                <InfoLabel title="Unit" name={data?.date_of_service} />
                              </li>
                            </List>
                            <List type="unstyled" className="info-list">
                              <h3 className="f-mlc-m">Specialist OP Initial Visit/Consultation</h3>
                              <li>
                                <InfoLabel title="Unit" name={data?.unit} />
                              </li>
                            </List>
                          </div>
                        </TabPane> */}
                        <TabPane tabId="documents" activeTab={active}>
                          <Row>
                            {data?.documents?.map((doc) => (
                              <Col key={doc?.id} md={6} lg={6} className="mb-3">
                                <Card className="shadow-none border">
                                  <CardBody>
                                    <div className="align-items-center row">
                                      <span className="s-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="38"
                                          height="38"
                                          viewBox="0 0 38 38"
                                        >
                                          <g id="Icon_Download" data-name="Icon/Download" transform="translate(0 -0.3)">
                                            <rect
                                              id="Mask"
                                              width="38"
                                              height="38"
                                              transform="translate(0 0.3)"
                                              fill="none"
                                            />
                                            <path
                                              id="Bottom"
                                              d="M11.71,0h-8.3V.739A3.486,3.486,0,0,1,.065,4.357H15.448A3.573,3.573,0,0,0,18.972.739V0Z"
                                              transform="translate(16.175 28.149)"
                                              fill="#007abc"
                                            />
                                            <path
                                              id="Middle"
                                              d="M3.522,3.486A3.34,3.34,0,0,0,.235,0H17.476a4.81,4.81,0,0,1,4.836,4.784l.08,10.134v3.759H10.763v1.075a3.554,3.554,0,0,1-3.519,3.486h-.2a3.5,3.5,0,0,1-3.523-3.486v-3.4"
                                              transform="translate(6.945 6.364)"
                                              fill="#007abc"
                                            />
                                            <path
                                              id="Arrow"
                                              d="M0,4.353H3.626V0H5.081V4.353H8.715L4.353,8.713Z"
                                              transform="translate(15.544 12.174)"
                                              fill="#fff"
                                              stroke="rgba(0,0,0,0)"
                                              strokeMiterlimit="10"
                                              strokeWidth="1"
                                            />
                                            <path
                                              id="Corner"
                                              d="M2.614,0A2.685,2.685,0,0,0,0,2.723v.762H5.229V2.723A2.684,2.684,0,0,0,2.614,0"
                                              transform="translate(3.195 8.688)"
                                              fill="#007abc"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                      <Col>
                                        <Label>{}</Label>
                                        <a onClick={() => download(doc?.filepath)}>{doc?.file_name}</a>
                                        {doc?.description ? <p>{doc?.description}</p> : ''}
                                      </Col>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Container>
        </section>
      </Fragment>
    </Template>
  );
};

PreApprovals.propTypes = {
  showResubmit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    mednext_id: PropTypes.string,
    reference_number: PropTypes.string,
    claimed_amount: PropTypes.string,
    claimed_amount_format: PropTypes.string,
    status: PropTypes.string,
    denied_amount: PropTypes.string,
    denied_amount_format: PropTypes.string,
    approved_amount: PropTypes.string,
    approved_amount_format: PropTypes.string,
    payment_reference: PropTypes.string,
    certificate_number: PropTypes.string,
    last_claim_service_date: PropTypes.string,
    paid_date: PropTypes.string,
    payment_mode: PropTypes.string,
    currency: PropTypes.string,
    additional_info: PropTypes.string,
    date_of_service: PropTypes.string,
    paid_amount: PropTypes.string,
    unit: PropTypes.string,
    derived_amount: PropTypes.string,
    patient_share: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        file_path: PropTypes.string,
        description: PropTypes.string,
        file_name: PropTypes.string,
        filepath: PropTypes.string,
      }),
    ),
    policy: PropTypes.shape({
      ext_policy_number: PropTypes.number,
      holder_name: PropTypes.string,
    }),
    dependent: PropTypes.shape({
      member_name: PropTypes.string,
    }),
    user: PropTypes.shape({
      member_name: PropTypes.string,
    }),
    insured_id: PropTypes.number,
    dependant_code: PropTypes.string,
    physician_name: PropTypes.string,
    expected_admission_date: PropTypes.string,
    approval_scenario: PropTypes.string,
    insured_name: PropTypes.string,
    hospital_name: PropTypes.string,
    created_at: PropTypes.string,
    approval_submission_date: PropTypes.string,
    expected_number_of_days: PropTypes.string,
    diagnosis: PropTypes.string,
    provider_name: PropTypes.string,
    comments: PropTypes.string,
  }),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
  loading: PropTypes.bool,
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
  download: PropTypes.func.isRequired,
};

PreApprovals.defaultProps = {
  data: {},
  policyData: {},
  loading: false,
  breadcrumb: [],
};

export default withRouter(PreApprovals);
