import React, { Component } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/Claims/Claims';
import Alert from '../components/UI/Alert';

class Claims extends Component {
  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    this.getClaimList();
  }

  /**
   * get Claim list
   */
  getClaimList = async (filters = null) => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      .replace(/\//g, '-');
    const { getClaims, currentPolicy } = this.props;
    try {
      this.setState((prev) => ({ ...prev, loading: true }));
      // FOR SUBMITTED STATUS
      let tempFilters = filters;
      if (filters) {
        if (filters?.status === '-1') tempFilters = { ...filters, status: 0 };
      }

      const { success, data } = await getClaims([currentPolicy?.id, tempFilters]);
      if (success) {
        let filtered = {};
        // Since the 'submitted' claims goes under Pending records
        if (data?.Pending) {
          filtered = data?.Pending?.reduce(
            (acc, item) => {
              const { Pending, Submitted } = acc;
              if (item?.submission_date === formattedDate) {
                acc.Submitted = [...Submitted, { ...item, status: 'Submitted' }];
              } else {
                acc.Pending = [...Pending, item];
              }
              return acc;
            },
            { Pending: [], Submitted: [] },
          );
        }

        const final = { ...data, ...filtered };
        // DELETE EMPTY ITEMS
        Object.keys(final)?.forEach((key) => {
          if (!final[key]?.length) delete final[key];
        });

        if (filters?.status) {
          if (filters?.status === '-1') {
            delete final?.Pending;
          } else {
            delete final?.Submitted;
            console.log('Submitted Deleted', filters);
          }
        }
        this.setState((prev) => ({ ...prev, loading: false, data: final }));
      }
      return false;
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loading: false }));
    }
    return [];
  };

  /**
   * get currency list
   * @deprecated
   * NOT USING
   */
  getClaimDetails = async (id) => {
    const { getClaimDetails } = this.props;
    try {
      const { success, data } = await getClaimDetails(id);
      if (success) {
        this.setState((prev) => ({ ...prev, currentClaim: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  downloadEOB = async (claim_id) => {
    const { downloadEOB } = this.props;
    toast.promise(downloadEOB(claim_id),
      {
        loading: (
          <Alert message="Please wait while the EOB is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type === 'application/pdf') {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = `EOB_${claim_id}.pdf`;
            link.click();
            return (
              <Alert message="EOB has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Medical Card'} />
          );
        },
        error: (
          <Alert message="Unable to download EOB" />
        ),
      });
  };

  /**
   * get currency list
   */
  deleteClaim = async (claimId) => {
    const { deleteClaim } = this.props;
    try {
      const { success } = await deleteClaim(claimId);
      console.log(success);
      if (success) {
        this.getClaimList();
        toast.custom(<Alert message="Claim deleted successfully." />, {
          duration: 3000,
        });
      } else {
        toast.custom(<Alert message="Failed to delete your claim." />, {
          duration: 3000,
        });
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  /**
   * get currency list
   */
  deleteClaim = async (claimId) => {
    const { deleteClaim } = this.props;
    try {
      const { success } = await deleteClaim(claimId);
      console.log(success);
      if (success) {
        this.getClaimList();
        toast.custom(<Alert message="Claim deleted successfully." />, {
          duration: 3000,
        });
      } else {
        toast.custom(<Alert message="Failed to delete your claim." />, {
          duration: 3000,
        });
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  /**
   * Render
   */
  render = () => {
    const { data, loading } = this.state;
    const { currentPolicy } = this.props;
    return (
      <Layout
        data={data}
        policyData={currentPolicy}
        downloadEOB={this.downloadEOB}
        deleteClaim={this.deleteClaim}
        loading={loading}
        getClaimList={this.getClaimList}
      />
    );
  };
}

Claims.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  getClaims: PropTypes.func.isRequired,
  getClaimDetails: PropTypes.func.isRequired,
  downloadEOB: PropTypes.func.isRequired,
  deleteClaim: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  getClaims: dispatch.claim.getClaims,
  getClaimDetails: dispatch.claim.getClaimDetails,
  downloadEOB: dispatch.claim.downloadEOB,
  deleteClaim: dispatch.claim.deleteClaim,
});

export default connect(mapStateToProps, mapDispatchToProps)(Claims);
