import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { errorMessages } from '../../../constants/messages';
import CustomFileInput from './CustomFileInput';
import TermsAndConditions from '../../TermsAndConditions';
import config from '../../../constants/config';

const StepTwo = ({
  loading,
  onConfirm,
  currencyList,
  countryList,
  onFileSelected,
  btnClick,
  mandatoryFilesUploaded,
}) => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const handleSetPayload = async (formdata, draft = false) => {
    setIsDraft(draft);
    if (draft) {
      onConfirm({ ...formdata });
    } else {
      onConfirm({ ...formdata }, 'confirm');
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const date = moment(new Date()).format('YYYY-MM-DD');
  const minDate = moment(new Date()).subtract(2, 'years').format('YYYY-MM-DD');

  return (
    <Fragment>
      <Row className="justify-content-between mt-3">
        <Col md="5">
          <Label className="f-mlc-m display-3 tab-title">Claim details</Label>
        </Col>
      </Row>
      <hr />
      <Form onSubmit={handleSubmit((values) => handleSetPayload(values))} className="mt-3">
        <FormGroup>
          <Row className="justify-content-between mt-3">
            <Col md="6">
              <Input
                type="select"
                name="claim_type"
                id="claim_type"
                disabled={loading}
                invalid={!!errors.claim_type}
                defaultValue="1"
                innerRef={register({ required: errorMessages.missingData })}
                onChange={(e) => setValue('claim_type', e.target.value)}
              >
                <option value="1">In-Patient</option>
                <option value="2">Out-Patient</option>
                <option value="3">Both</option>
              </Input>
              {errors.claim_type && <p className="invalid-feedback">{errors.claim_type.message}</p>}
            </Col>
          </Row>
          <p className="mt-3">
            (Note: If you were admitted to hospital but the stay was less than 12 hours, such cases are considered as
            'Day Care OutPatient')
          </p>
          <h3 className="mt-5">Claim Details</h3>
          <p>
            (Note: If the Country you would like to select is not in the list, It is a 'Sanctioned Country' and your
            claim cannot be submitted online. Please send your original documents along with your Claim from the address
            listed in the Contact Us section)
          </p>
          <Row className="d-flex">
            <Col>
              <div className="form-label-group form-group mb-0">
                <Input
                  type="select"
                  name="country_id"
                  id="country_id"
                  disabled={loading}
                  invalid={!!errors.country_id}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('country_id', e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Country
                  </option>
                  {countryList?.map((country) => (
                    <option key={country.short_name} value={country.id}>
                      {country.short_name}
                    </option>
                  ))}
                </Input>
                <Label for="country">Country of Treatment</Label>
                {errors.country && <p className="invalid-feedback">{errors.country.message}</p>}
              </div>
            </Col>
            <Col>
              <div className="form-label-group form-group mb-4">
                <Input
                  type="select"
                  name="currency_id"
                  id="currency_id"
                  disabled={loading}
                  invalid={!!errors.currency_id}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('currency_id', e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Claim Currency
                  </option>
                  {currencyList?.map((currency) => (
                    <option key={currency.currency} value={currency.id}>
                      {currency.name}
                    </option>
                  ))}
                </Input>
                <Label for="currency_id">Claim Currency</Label>
                {errors.currency && <p className="invalid-feedback">{errors.currency.message}</p>}
              </div>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col>
              <div className="form-label-group form-group mb-4">
                <NumericFormat
                  type="text"
                  name="claimed_amount"
                  id="claimed_amount"
                  customInput={(props) => <Input {...props} />}
                  allowLeadingZeros
                  thousandSeparator=","
                  placeholder="Total claim amount"
                  className="form-control"
                  disabled={loading}
                  invalid={!!errors.claimed_amount}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('claimed_amount', e.target.value)}
                />
                <Label for="claimed_amount">Total Claim Amount</Label>
                {errors.claimed_amount && <p className="invalid-feedback">{errors.claimed_amount.message}</p>}
              </div>
            </Col>
            <Col>
              <div className="form-label-group form-group mb-4">
                <Input
                  type="text"
                  name="claim_date"
                  id="claim_date"
                  placeholder="Treatment Start Date"
                  disabled={loading}
                  min={minDate}
                  max={date}
                  onFocus={(e) => {
                    e.target.type = 'date';
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) e.target.type = 'text';
                  }}
                  invalid={!!errors.claim_date}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('claim_date', e.target.value)}
                />
                <Label for="total_claim_amount">Treatment Start Date</Label>
                {errors.claim_date && <p className="invalid-feedback">{errors.claim_date.message}</p>}
              </div>
            </Col>
          </Row>
          <h3 className="mt-5">Documents</h3>
          <p>
            Please upload at least one of the following mandatory documents
            that are required to proceed with the claim submission:
          </p>
          <p className="mt-3">
            Maximum file size allowed is 8MB/Document. Allowed file types .jpg, .jpeg, .tif, .bmp, .png, .doc, .docx,
            .txt, .pdf.
          </p>

          <Row>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                label="Metlife claim form with physician notes/medical report"
                type="physician_notes"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                type="hospital_receipts"
                label="Medical Provider Receipt"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                label="Discharge report"
                onFileSelect={onFileSelected}
                type="discharge_report"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                label="Proof of payment"
                type="proof_of_payment"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                onFileSelect={onFileSelected}
                label="Results of lab tests, radiology"
                type="lab_tests"
                btnClick={btnClick}
                mandatoryFilesUploaded={mandatoryFilesUploaded}
              />
            </Col>
            <Col md={6}>
              <CustomFileInput
                label="Other supporting documents"
                onFileSelect={onFileSelected}
                type="other_supporting_docs"
              />
            </Col>
            <Col md={6}>
              <CustomFileInput onFileSelect={onFileSelected} label="Translated documents" type="translated_docs" />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pl-4">
                <Input
                  type="checkbox"
                  name="declaration"
                  id="declaration"
                  disabled={loading}
                  invalid={!!errors.declaration}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('declaration', e.target.value)}
                />
                <Label for="declaration">
                  I have read and accept all the
                  <a
                    href={`${config.baseURL}/claim-submission-disclaimer/AE`}
                    className="btn-link"
                    target="blank"
                  >
                    {' '}
                    Terms & conditions
                  </a>
                </Label>
                {errors.declaration && <p className="invalid-feedback">Please check this to continue</p>}
              </div>
            </Col>
            <Col className="text-right">
              <Button
                type="button"
                color="outline-primary"
                className="mr-3"
                disabled={loading}
                onClick={() => handleSetPayload(getValues(), true)}
              >
                {isDraft && loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="#007ABC"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'SAVE AS DRAFT'}
              </Button>
              <Button color="primary" disabled={loading}>
                {!isDraft && loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'SUBMIT'}
              </Button>
            </Col>
            <TermsAndConditions isOpen={isOpen} toggle={toggle} />
          </Row>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

StepTwo.propTypes = {
  // data: shape({
  //   claim_types: arrayOf(
  //     shape({
  //       type: PropTypes.string,
  //       id: PropTypes.number,
  //       code: PropTypes.string,
  //     }),
  //   ),
  //   currencies: arrayOf(
  //     shape({
  //       name: PropTypes.string,
  //       id: PropTypes.number,
  //     }),
  //   ),
  //   countries: arrayOf(
  //     shape({
  //       name: PropTypes.string,
  //       id: PropTypes.number,
  //     }),
  //   ),
  // }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  onFileSelected: PropTypes.func.isRequired,
  btnClick: PropTypes.bool.isRequired,
  mandatoryFilesUploaded: PropTypes.shape({
    // physician_notes: PropTypes.bool.isRequired,
    // hospital_receipts: PropTypes.bool.isRequired,
  }).isRequired,
};

StepTwo.defaultProps = {
  loading: false,
};

export default withRouter(StepTwo);
