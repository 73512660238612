import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Alert, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';

const ForgotPassword = ({ error, loading, success, message, onFormSubmit, defaultValues }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });

  return (
    <Container className="forgot-password full-width-page">
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <Row className="text-center h-100 align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          <h1>Forgot Password</h1>
          {!success || !!error || !!loading ? (
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              {!success && !!message && <Alert color="danger">{message}</Alert>}
              {!!success && !!message && <Alert color="success">{message}</Alert>}
              {!!error && <Alert color="danger">{error}</Alert>}
              {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

              <FormGroup className="mt-3 form-label-group">
                {/* <Label for="username">Email</Label> */}
                <Input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Username or Email"
                  disabled={loading}
                  invalid={!!errors.username}
                  innerRef={register({
                    required: errorMessages.invalidEmail,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: errorMessages.invalidEmail,
                    },
                  })}
                  onChange={(e) => setValue('username', e.target.value)}
                />
                <Label>Username or Email</Label>
                {errors.username && <p className="invalid-feedback">{errors.username.message}</p>}
              </FormGroup>

              <Button color="primary" disabled={loading} style={{ width: '100%' }}>
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
              </Button>
            </Form>
          ) : (
            <Row className="align-items-center">
              {/* <Label className="msg-box">{message}</Label> */}
              <Label className="msg-box f-mlc-m">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 50 50"
                  version="1.1"
                  viewBox="0 0 50 50"
                  xmlSpace="preserve"
                  className="mb-4"
                >
                  <circle cx="25" cy="25" r="25" fill="#99D538" />
                  <path
                    fill="none"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    d="M38 15L22 33 12 25"
                  />
                </svg>
                {message}
                <small>The link will expire in 24 hours</small>
              </Label>
            </Row>
          )}
          <div className="bottom-links justify-content-center  mt-5">
            Back to
            <Link to="/login" className="text-primary">
              {' '}
              Log in
            </Link>
          </div>
          <div className="bottom-links justify-content-center  mt-3">
            <Link to="/faq" className="text-primary">
              Hi, Need Any Help?
            </Link>
          </div>
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

ForgotPassword.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  defaultValues: PropTypes.shape({
    username: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

ForgotPassword.defaultProps = {
  error: null,
  success: null,
  message: '',
  loading: false,
  defaultValues: {},
};

export default withRouter(ForgotPassword);
