import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import InputField, { isValidPhoneNumber } from 'react-phone-number-input/input';
import toast from 'react-hot-toast';
import Alert from '../UI/Alert';
import InfoLabel from '../PolicyDetails/InfoLabel';
import { errorMessages } from '../../constants/messages';

const PersonalDetails = ({ loading, defaultValues, users, getUserProfile, onFormSubmit, photoUpload, data }) => {
  const { register, handleSubmit, errors, setValue, reset } = useForm({ defaultValues });
  const [button, setButton] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [num, setNum] = useState(null);
  const [click, setClick] = useState(false);

  const [filterUsers, setFilterUsers] = useState();

  const submit = async (profileData) => {
    setClick(true);
    if (num) {
      if (isValidPhoneNumber(num)) {
        const tempData = profileData;
        tempData.phone_number = num;

        const res = await onFormSubmit(tempData);
        if (res?.success) {
          setButton(false);
        }
        toast.custom(<Alert message={res?.message} />);
      }
    }
  };

  useEffect(() => {
    // console.log(data?.email, users);
    setNum(data?.phone_number ? `+${data?.phone_number.replace('+', '')}` : '');
    setFilterUsers(users?.length ? users?.filter((e) => e?.card_number !== data?.card_number && e?.policy_status) : []);
  }, [users, data]);

  // setTimeout(() => {
  //   setFilterUsers(users?.length ? users?.filter((e) => e?.email !== data?.email) : []);
  // });

  const file = useRef();
  const buttonClick = async (id, type) => {
    const formData = new FormData();
    formData.append('file', file?.current?.files[0]);
    if (!type) {
      formData.append('dependent_id', id);
    }
    photoUpload(formData);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Card name="personalDetails">
      <CardBody className="profile-header-sec">
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Personal Details</Label>
          </div>
          <div className="d-flex justify-content-end align-items-center ">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="personal-details-dropdown">
              <DropdownToggle>
                <div className="profile">
                  <div className="align-items-center media">
                    <Row className="align-items-center m-0">
                      <div>
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            alt=""
                            src={data?.image_path || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                            width="50px"
                            height="auto"
                          />
                        </span>
                      </div>
                      <div className="pl-3 text-left">
                        <Label>Select Insured Name</Label>
                        <div className="d-flex">
                          <a className="">{data?.first_name}</a>
                          <svg
                            className="ml-3 mt-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.009"
                            height="6.628"
                            viewBox="0 0 11.009 6.628"
                          >
                            <g id="left_arrow" transform="translate(1.414 5.628) rotate(-90)" opacity="0.55">
                              <path
                                id="left_arrow-2"
                                data-name="left_arrow"
                                d="M0,8.181,4.214,4.09,0,0"
                                transform="translate(4.214 8.181) rotate(180)"
                                fill="none"
                                stroke="#333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu>
                {filterUsers ? (
                  filterUsers?.map((element) => (
                    <DropdownItem
                      value={element?.id}
                      key={element?.email}
                      onClick={() => {
                        setButton(false);
                        if (!element?.type || element?.type === 'Member') {
                          getUserProfile();
                        } else {
                          getUserProfile(element?.id);
                        }
                      }}
                    >
                      <div className="profile">
                        <div className="align-items-center media">
                          <Row>
                            <Col>
                              <span className="avatar avatar-sm rounded-circle">
                                <img
                                  alt=""
                                  src={
                                    element?.image_path ||
                                    'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'
                                  }
                                  width="50px"
                                  height="auto"
                                />
                              </span>
                            </Col>
                            <Col className="p-0">
                              <a className="">{element?.member_name}</a>
                              <Label>{element?.type}</Label>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </DropdownItem>
                  ))
                ) : (
                  <DropdownItem>No Dependents</DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            {!button ? (
              <a
                className="btn-link edit"
                onClick={() => {
                  if (button) {
                    setButton(false);
                  } else {
                    setButton(true);
                    reset(data);
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                    <rect
                      id="Rectangle_95"
                      data-name="Rectangle 95"
                      width="3.4"
                      height="4.8"
                      transform="translate(16.195 4.391) rotate(-45)"
                      fill="#0090da"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                      fill="#007abc"
                    />
                  </g>
                </svg>
                Edit
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr />
        {button ? (
          <Row>
            <Col md="12" className="mb-3">
              <div className="photo mb-3">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    src={data?.image_path || 'http://static.thenounproject.com/png/396915-200.png'}
                    alt="user"
                    className="rounded-circle"
                  />
                </span>
                <input
                  type="file"
                  id={`file${data?.id}`}
                  name={`file${data?.id}`}
                  ref={file}
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={() => buttonClick(data?.id, data?.is_principle_user)}
                />
                <Button onClick={() => file.current && file.current.click()} color="primary" className="rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                    <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                      <rect
                        id="Rectangle_95"
                        data-name="Rectangle 95"
                        width="3.4"
                        height="4.8"
                        transform="translate(16.195 4.391) rotate(-45)"
                        fill="#fff"
                      />
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </Button>
              </div>
            </Col>

            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="First Name"
                        disabled
                        invalid={!!errors.first_name}
                        innerRef={register({ required: errorMessages.missingfirstName })}
                        onChange={(e) => setValue('first_name', e.target.value)}
                      />
                      <Label for="email">First Name</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="middle_name"
                        id="middle_name"
                        placeholder="Middle Name"
                        disabled
                        invalid={!!errors.middle_name}
                        innerRef={register({ required: errorMessages.missingmiddleName })}
                        onChange={(e) => setValue('middle_name', e.target.value)}
                      />
                      <Label for="email">Middle Name</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Last Name"
                        disabled
                        invalid={!!errors.last_name}
                        innerRef={register({ required: errorMessages.missinglastName })}
                        onChange={(e) => setValue('last_name', e.target.value)}
                      />
                      <Label for="email">Last Name</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        disabled
                        invalid={!!errors.email}
                        innerRef={register()}
                      // onChange={(e) => setValue('email', e.target.value)}
                      />
                      <Label for="email">Email</Label>
                      {/* {errors.email && <p className="invalid-feedback">{errors.email.message}</p>} */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormGroup className="form-label-group">
                      <InputField
                        type="text"
                        // readOnly={!editContact}
                        name="phone_number"
                        id="phone_number"
                        placeholder="Phone Number"
                        international
                        withCountryCallingCode
                        className={`form-control ${click ? (num ? (!isValidPhoneNumber(num) ? 'is-invalid' : '') : 'is-invalid') : ''
                          }`}
                        disabled={loading}
                        // invalid={click && num ? !isValidPhoneNumber(num) : true}
                        value={num}
                        onChange={(e) => {
                          setNum(e);
                          setValue('phone_number', e);
                        }}
                      />
                      <Label for="phone_number">Phone Number</Label>
                      <p className="invalid-feedback">
                        {`${click
                          ? num
                            ? isValidPhoneNumber(num)
                              ? ''
                              : 'Invalid phone number'
                            : 'Phone number required'
                          : ''
                          }`}
                      </p>
                      <p className="phone-info">
                        Please enter your phone number prefixed with the country dialing code, without appending any
                        zeroes
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-label-group">
                      {/* <Label for="email">Email</Label> */}
                      <Input
                        type="text"
                        name="certificate_number"
                        id="certificate_number"
                        placeholder="Certificate Number"
                        disabled
                        invalid={!!errors.certificate_number}
                        innerRef={register({ required: errorMessages.missingCertificateNumber })}
                        onChange={(e) => setValue('certificate_number', e.target.value)}
                      />
                      <Label for="email">Certificate Number</Label>
                      {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    {' '}
                    <Button
                      style={{ width: '130px' }}
                      className="mr-3"
                      color="outline-primary"
                      onClick={() => {
                        if (button) {
                          setButton(false);
                        } else {
                          setButton(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" style={{ width: '130px' }} disabled={loading}>
                      {loading ?
                        (
                          <>
                            <Spinner
                              size="sm"
                              color="light"
                            />
                            <span>&nbsp;Loading</span>
                          </>
                        ) : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <div className="grid grid-2 info-list-wrapper">
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="First Name" name={data?.first_name || ''} />
              </li>
              <li>
                <InfoLabel title="Last Name" name={data?.last_name || ''} />
              </li>
              <li>
                <InfoLabel title="Mobile Number" name={data?.phone_number || ''} />
              </li>
            </ul>
            <ul className="info-list list-unstyled">
              <li>
                <InfoLabel title="Middle Name" name={data?.middle_name || ''} />
              </li>
              <li>
                <InfoLabel title="Email ID" name={data?.email || ''} />
              </li>
              <li>
                <InfoLabel title="Certificate Number" name={data?.info?.certificate_number || ''} />
              </li>
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

PersonalDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    image_path: PropTypes.string,
    card_number: PropTypes.string,
    info: PropTypes.shape({
      certificate_number: PropTypes.string,
      residing_country: PropTypes.string,
      residing_location: PropTypes.string,
      residing_emirates: PropTypes.string,
      work_location: PropTypes.string,
      work_emirates: PropTypes.string,
    }),
    is_principle_user: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    certificate_number: PropTypes.string,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onFormSubmit: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  photoUpload: PropTypes.func.isRequired,
};

PersonalDetails.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
  users: [],
};

export default withRouter(PersonalDetails);
