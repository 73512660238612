import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Label, Row } from 'reactstrap';

const PhotoUploadCard = ({ item, photoUpload }) => {
  const file = useRef();
  const [photoUploadData, setPhotoUploadData] = useState(item);
  const buttonClick = async (id, type) => {
    const formData = new FormData();
    formData.append('file', file.current.files[0], 'image.*');
    if (type === 'Dependent') {
      formData.append('dependent_id', id);
    }
    const { data, success } = await photoUpload(formData);
    if (success) {
      setPhotoUploadData({ ...item, image_path: data.image_path });
    }
  };

  return (
    <Card className="member-photo-upload shadow-none border">
      <CardBody>
        <Row>
          <div className="photo">
            <span className="avatar avatar-sm rounded-circle pointer" onClick={() => file.current && file.current.click()}>
              <img
                src={photoUploadData.image_path || 'http://static.thenounproject.com/png/396915-200.png'}
                alt="user"
              />
            </span>
            <input
              type="file"
              id={`file${photoUploadData.id}`}
              name={`file${photoUploadData.id}`}
              ref={file}
              accept="image/*"
              style={{ display: 'none' }}
              onChange={() => buttonClick(photoUploadData.id, photoUploadData.type)}
            />
            <Button onClick={() => file.current && file.current.click()} color="primary" className="rounded-circle">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                  <rect
                    id="Rectangle_95"
                    data-name="Rectangle 95"
                    width="3.4"
                    height="4.8"
                    transform="translate(16.195 4.391) rotate(-45)"
                    fill="#fff"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Button>
          </div>
          <div className="mem-details">
            <h3>{item?.member_name}</h3>
            <div>
              <Label>
                Relationship:
                <b>{item?.type}</b>
              </Label>
            </div>
            <div>
              {' '}
              <Label>
                Dependant Code:
                <b> Member</b>
              </Label>
            </div>
            <div>
              <Label>
                Certificate Number:
                <b> Member</b>
              </Label>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

PhotoUploadCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    member_name: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    image_path: PropTypes.string,
  }),
  photoUpload: PropTypes.func.isRequired,
};

PhotoUploadCard.defaultProps = {
  item: {},
};

export default withRouter(PhotoUploadCard);
