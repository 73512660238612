import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/ProviderPreApproval/ProviderPreApprovalDetails';

class ProviderPreApprovalDetails extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({ data: location?.state?.data });
  }

  /**
   * Render
   */
  render = () => {
    // const { userInput } = this.props;
    const { data, loading } = this.state;
    const { currentPolicy } = this.props;
    return <Layout data={data} policyData={currentPolicy} loading={loading} />;
  };
}

ProviderPreApprovalDetails.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderPreApprovalDetails);
