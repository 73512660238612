import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, NavbarToggler, Collapse, Nav, NavItem, NavLink, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/myMetlife-logo.png';
import { hasAuthToken } from '../../lib/jwt';

const Topbar = ({ user }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  return (
    <header className="shadow-sm bg-white">
      <Container fluid className="max-container">
        <Navbar light className="navbar" expand="md">
          <Button color="info" className="sidebar-toggle d-none">
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
          <Link to="/home">
            <img className="logo pointer" src={logo} alt="" />
          </Link>
          <NavbarToggler onClick={toggleTopbar} />
          {hasAuthToken() ? (
            <Collapse isOpen={topbarIsOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} to="/home">
                    DASHBOARD
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/profile">
                    PROFILE
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/contactUs">
                    CONTACT US
                  </NavLink>
                </NavItem>
                <NavItem>
                  <div className="profile">
                    <div className="align-items-center media">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt=""
                          src={user?.image_path || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        />
                      </span>
                      <div className="ml-2 profile-info">
                        {/* <span className="mb-0 text-sm font-weight-bold">Aflq Setu</span> */}
                        <Link className="text-primary" to="/" onClick={() => window.deleteXimaWebChat()}>
                          LOGOUT
                        </Link>
                      </div>
                    </div>
                  </div>
                </NavItem>
              </Nav>
            </Collapse>
          ) : (
            <Collapse isOpen={topbarIsOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="text-primary" tag={Link} to="/profile">
                    LOGIN
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          )}
        </Navbar>
      </Container>
    </header>
  );
};

Topbar.propTypes = {
  user: PropTypes.shape({
    image_path: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.member.user || {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
