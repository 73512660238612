import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';
// import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
// import MobileTabBar from '../UI/MobileTabBar';
import Footer from '../UI/Footer';
// import SideBar from '../UI/SideBar';
import Content from '../UI/Content';

import './Home.scss';

const Template = ({ pageTitle, children, breadcrumb }) => {
  const timeout = 60000 * 30;
  const [sidebarIsOpen, setSidebarOpen] = useState(true);

  const history = useHistory();

  const handleOnIdle = () => {
    window.deleteXimaWebChat();
    history.push('/');
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="App wrapper">
        {/* <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} /> */}
        {/* <Toaster /> */}
        <Content toggle={toggleSidebar} isOpen={sidebarIsOpen} pageTitle={pageTitle} breadcrumb={breadcrumb}>
          {children}
        </Content>
        <Footer />
      </div>
      {/* <MobileTabBar /> */}
    </React.Fragment>
  );
};

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
};

Template.defaultProps = {
  pageTitle: 'MetLife',
  breadcrumb: [],
};

export default Template;
