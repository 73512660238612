import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Form, Label, Row, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import Alert from '../UI/Alert';
// import BankCard from './BankCard';
import AddBankDetails from '../Claims/AddClaim/AddBankDetails';
import BankDetailsCard from '../Claims/AddClaim/BankDetailsCard';

const RIClaims = ({
  loading,
  onFormSubmit,
  data,
  addBank,
  getBankList,
  getCurrencyList,
  deleteBank,
  getCountryList,
  bankDeleteLoading,
}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [button, setButton] = useState(false);
  const [addBankDetails, setAddBankDetails] = useState(false);
  const [bankData, setBankData] = useState({});

  const submit = async (profileData) => {
    const res = await onFormSubmit(profileData);
    if (res?.success) {
      setButton(false);
    }
    toast.custom(<Alert message={res?.message} />);

    // await toast.promise(onFormSubmit(profileData), {
    //   loading: 'Saving...',
    //   success: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    //   error: (e) => {
    //     setButton(false);
    //     return <b>{e?.message}</b>;
    //   },
    // });
  };

  // console.log(bankId);
  useEffect(async () => {
    await data?.bank_accounts?.forEach((e) => {
      if (e?.id === data?.bank_id) {
        setBankData(e);
      }
    });
    // setBankId(data?.bank_accounts.map((e) => e.account_no === RI[0]?.account_number)[0]?.bank_id || '1');
  }, []);

  setTimeout(async () => {
    await data?.bank_accounts?.forEach((e) => {
      if (e?.id === data?.bank_id) {
        setBankData(e);
        // console.log(e);
      }
    });
  });

  const toggle = () => {
    setAddBankDetails(!addBankDetails);
  };

  return (
    <Card name="RIClaims">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <Label className="f-mlc-m display-3 tab-title">Reimbursement Claims</Label>
          </div>
          <div className="text-right">
            <a
              className="btn-link edit"
              onClick={() => {
                if (button) {
                  toggle();
                } else {
                  setButton(true);
                }
              }}
            >
              {!button ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="Icon_PencilEdit" data-name="Icon/PencilEdit" transform="translate(-3 -1.987)">
                    <rect
                      id="Rectangle_95"
                      data-name="Rectangle 95"
                      width="3.4"
                      height="4.8"
                      transform="translate(16.195 4.391) rotate(-45)"
                      fill="#0090da"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                      fill="#007abc"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  id="Icon_Plus"
                  data-name="Icon/Plus"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                >
                  <path id="Icon_Plus-2" data-name="Icon/Plus" d="M12,5H7V0H5V5H0V7H5v5H7V7h5V5Z" fill="#007abc" />
                </svg>
              )}
              {button ? 'Add Bank Account' : 'Edit'}
            </a>
          </div>
        </div>
        <hr />
        <Row>
          <Col>
            {button ? (
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit(submit)}>
                    <Row>
                      <Col>
                        <Label>I would like to receive my medical claim(s) reimbursement via:</Label>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <span className="pl-4 mr-3">
                          <Label className="pointer">
                            <Input
                              type="radio"
                              id="payment_method"
                              name="payment_method"
                              radioGroup="payment_method"
                              value="0"
                              defaultChecked={data?.payment_method === 0}
                              innerRef={register({ required: errorMessages.missingPaymentMethod })}
                              onChange={(e) => setValue('payment_method', e.target.value)}
                            />
                            Wire Transfer
                          </Label>
                        </span>
                        <span className="pl-5">
                          <Label className="pointer">
                            <Input
                              type="radio"
                              id="payment_method"
                              name="payment_method"
                              radioGroup="payment_method"
                              value="1"
                              defaultChecked={data?.payment_method === 1}
                              innerRef={register({ required: errorMessages.missingPaymentMethod })}
                              onChange={(e) => setValue('payment_method', e.target.value)}
                            />
                            Cheque
                          </Label>
                        </span>
                      </Col>
                    </Row> */}
                    <Label>Bank Details</Label>
                    <Row>
                      {data?.bank_accounts.map((e) => (
                        // <BankCard data={e} bankId={bankData?.id} register={register} key={e.account_no} />

                        <BankDetailsCard
                          register={register}
                          setValue={setValue}
                          key={e.id}
                          data={e}
                          defaultChecked={e?.id?.toString() === data?.bank_id?.toString()}
                          deleteBank={deleteBank}
                          loading={bankDeleteLoading}
                        />
                      ))}
                    </Row>
                    <hr />
                    <Row>
                      <Col className="text-center">
                        <Button
                          className="mr-3 px-5"
                          color="outline-primary"
                          onClick={() => {
                            setButton(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button className="px-5" color="primary" disabled={loading}>
                          {loading ?
                            (
                              <>
                                <Spinner
                                  size="sm"
                                  color="light"
                                />
                                <span>&nbsp;Loading</span>
                              </>
                            ) : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md="12">
                  <Label>I would like to receive my medical claim(s) reimbursement via:</Label>
                  <br />
                  {/* <Label>{data?.payment_method === 1 ? 'Via Cheque' : 'Via Wire Transer'}</Label>
                  <br /> */}
                  <h6 className="mb-3 mt-2">Bank Details</h6>
                </Col>
                <Col md="6">
                  <Card className="shadow-none border px-3">
                    <CardBody>
                      <Row>
                        <h5>{bankData?.bank_name}</h5>
                      </Row>
                      {
                        bankData?.account_no ? (
                          <Row>
                            <Label className="mb-0">
                              Acc No:&nbsp;
                              {bankData?.account_no}
                            </Label>
                          </Row>
                        ) : (
                          <Row>
                            <Label className="mb-0">
                              IBAN:&nbsp;
                              {bankData?.iban_no}
                            </Label>
                          </Row>
                        )
                      }
                      <Row>
                        <Label className="mb-0">
                          Acc Holder Name:&nbsp;
                          {bankData?.payable_to}
                        </Label>
                      </Row>
                      <div className="row d-block">
                        <hr />
                      </div>
                      <Row>
                        <h6 className="mb-0">Visa...1234</h6>
                      </Row>
                      <Row>
                        <Label className="mb-0">
                          Scheduled to pay:&nbsp;
                          {bankData?.payable_to}
                        </Label>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <AddBankDetails
              toggle={toggle}
              isOpen={addBankDetails}
              onConfirm={addBank}
              user={data}
              bankList={getBankList}
              currencyList={getCurrencyList}
              countryList={getCountryList}
              loading={loading}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

RIClaims.propTypes = {
  data: PropTypes.shape({
    reimbursement_claims: PropTypes.arrayOf(PropTypes.shape({})),
    bank_accounts: PropTypes.arrayOf(PropTypes.shape({})),
    bank_id: PropTypes.number,
    payment_method: PropTypes.number,
  }),
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  onFormSubmit: PropTypes.func.isRequired,
  addBank: PropTypes.func.isRequired,
  getBankList: PropTypes.arrayOf(PropTypes.shape({})),
  getCountryList: PropTypes.arrayOf(PropTypes.shape({})),
  deleteBank: PropTypes.func.isRequired,
  getCurrencyList: PropTypes.arrayOf(PropTypes.shape({})),
  bankDeleteLoading: PropTypes.bool,
};

RIClaims.defaultProps = {
  data: {},
  loading: false,
  defaultValues: {},
  getBankList: [],
  getCountryList: [],
  getCurrencyList: [],
  bankDeleteLoading: false,
};

export default withRouter(RIClaims);
