import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Label, Row, CardFooter, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const ProviderCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  const getSpeciality = (array) => {
    let x = '';
    array?.forEach((element) => {
      x += element?.speciality?.name;
      x += ', ';
    });
    return x;
  };
  return (
    <Card className="">
      <CardBody onClick={() => setOpen(false)}>
        <Row>
          <Col className="provider-card-header">
            <h6>{data?.name}</h6>
            <span className="py-1 d-block">
              {data?.specialities?.length ? getSpeciality(data?.specialities).substring(0, 55) : null}
              {getSpeciality(data?.specialities)?.length > 55 ? (
                <>
                  <a id={`pop${data?.id}`} className="text-link" onClick={() => setOpen(!open)}>
                    &nbsp;More
                  </a>
                  <Popover isOpen={open} target={`pop${data?.id}`} toggle={() => setOpen(!open)}>
                    <PopoverHeader>Specialities</PopoverHeader>
                    <PopoverBody>{getSpeciality(data?.specialities)}</PopoverBody>
                  </Popover>
                </>
              ) : null}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex col-12 col-sm-6">
            <Row>
              <Col md="12 d-flex provider-location">
                <svg
                  className="mr-3"
                  id="icon_FindAgent"
                  data-name="icon/FindAgent"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.567"
                  height="27.793"
                  viewBox="0 0 23.567 27.793"
                >
                  <path
                    id="Shape"
                    d="M11.8,27.793h0L3.372,19.653a11.511,11.511,0,0,1,0-16.281,12.177,12.177,0,0,1,16.823,0,11.51,11.51,0,0,1,0,16.281L11.8,27.792Zm0-22.583a6.08,6.08,0,1,0,6.08,6.08A6.087,6.087,0,0,0,11.8,5.211Z"
                    fill="#007abc"
                  />
                  <circle id="Oval" cx="1.75" cy="1.75" r="1.75" transform="translate(10.033 9.231)" fill="#007abc" />
                </svg>
                <div className="">
                  <p className="mb-1">{data.address}</p>
                  {data?.distance && (
                    <span className="mt-2 badge badge-success mb-2">
                      {data?.distance}
                      <span> km</span>
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col className="text-center payment-percent col-12 col-sm-6">
            <p>
              Co-payment
              <span className="ml-3 badge badge-light">14%</span>
            </p>
            <p>
              Co-Insurance
              <span className="ml-3 badge badge-light">10%</span>
            </p>
          </Col> */}
        </Row>
      </CardBody>
      <CardFooter>
        <Row>
          <Col className="border-right">
            <Label>
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="22.982"
                height="22.629"
                viewBox="0 0 22.982 22.629"
              >
                <path
                  id="_Phone_Icon_Color"
                  data-name="🎨 Phone Icon Color"
                  d="M22.982,17.871a7.058,7.058,0,0,1-.506,2.234,4.281,4.281,0,0,1-1.991,1.7,6.313,6.313,0,0,1-3.036.82,12.729,12.729,0,0,1-4.277-1.109,15.365,15.365,0,0,1-2.856-1.334,27.347,27.347,0,0,1-7.835-7.714A14.921,14.921,0,0,1,1.126,9.659,12.229,12.229,0,0,1,0,5.448,6.07,6.07,0,0,1,.832,2.459,4.255,4.255,0,0,1,2.563.5,7.359,7.359,0,0,1,4.831,0a.845.845,0,0,1,.343.048c.343.113.7.9.865,1.221.522.916,1.028,1.848,1.567,2.748.261.418.751.932.751,1.43,0,.98-2.954,2.411-2.954,3.279a3.43,3.43,0,0,0,.637,1.382,16.119,16.119,0,0,0,6.676,6.573,3.535,3.535,0,0,0,1.4.627c.881,0,2.334-2.909,3.33-2.909.506,0,1.028.482,1.453.739.914.53,1.861,1.029,2.791,1.543.326.161,1.126.514,1.241.852A.809.809,0,0,1,22.982,17.871Z"
                  fill="#007abc"
                />
              </svg>
              <b>{data.phone}</b>
            </Label>
          </Col>
          <Col className="text-center font-weight-bold">
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className="mr-3">
                <path
                  id="_Map_Icon_Color"
                  data-name="🎨 Map Icon Color"
                  d="M.644,22h0a.627.627,0,0,1-.454-.191A.592.592,0,0,1,0,21.384V2.933a.533.533,0,0,1,.147-.366.76.76,0,0,1,.322-.22L7.333,0l7.333,2.581L21.208.058,21.384,0a.592.592,0,0,1,.426.191A.6.6,0,0,1,22,.616v18.45a.534.534,0,0,1-.147.368.759.759,0,0,1-.322.219L14.667,22,7.333,19.419.645,22ZM7.333,2.464V16.955l7.333,2.581V5.045Z"
                  fill="#007abc"
                />
              </svg>
              Show On Map
            </a>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

ProviderCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    distance: PropTypes.string,
    specialities: PropTypes.arrayOf(
      PropTypes.shape({
        speciality: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }),
};

ProviderCard.defaultProps = {
  data: {},
};

export default withRouter(ProviderCard);
