import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import IBAN from 'iban';
import { useForm } from 'react-hook-form';
import { errorMessages } from '../../../constants/messages';

const AddBankDetails = ({ toggle, isOpen, onConfirm, loading, bankList, currencyList, countryList, user }) => {
  const { register, handleSubmit, errors, setValue, reset, getValues } = useForm();
  const [noBankListed, setNoBankListed] = useState(false);
  const [numberType, setNumberType] = useState(1);
  const [ifsc, setIfsc] = useState(false);
  const [ifscOrAba, setIfscOrAba] = useState('IFSC Code');
  const [bankListData, setBankListData] = useState([]);
  const [isIBANStrictCountry, setIsIBANStrictCountry] = useState(['234', '118', '179'].includes(getValues('country_id')));
  const radioRef = useRef('number_type');
  const [isValidIBAN, setIsValidIBAN] = useState(true);

  const onSelectBank = (e) => {
    if (e.target.value === '0' || e.target.value === '-1' || e.target.value === '-2') {
      setNoBankListed(true);
      setValue('bank_name', '');
    } else {
      setNoBankListed(false);
      const bank = bankList.find((obj) => obj.BankAutoId === parseInt(e.target.value, 10));
      setValue('bank_name', bank?.Title);
      setValue('bank_id', e.target.value);
      setValue('swift', bank?.SwiftCode);
    }
  };

  const onIBANChanged = (value) => {
    setIsValidIBAN(IBAN.isValid(value));
    if (IBAN.isValid(value)) {
      setValue('iban_no', value);
      setNumberType(1);
    }
  };

  const onCheckNumberType = (value) => {
    setNumberType(value);
    setValue('number_type', value);
  };

  useEffect(() => {
    setBankListData(bankList);
  }, [bankList]);

  useEffect(() => {
    setIsValidIBAN(true);
    if (isOpen) {
      setIsIBANStrictCountry(false);
      setIfsc(false);
      onCheckNumberType(1);
      setIfsc(false);
      reset();
    }
  }, [isOpen]);

  const onSelectCountry = (e) => {
    const bank = bankList.filter((obj) => obj.country_id === parseInt(e.target.value, 10) ||
      obj.country_id === null ||
      obj.country_id === 0);
    setBankListData(bank);
    setValue('swift', null);
    // const x = countryList.find((obj) => String(obj.id) === String(e.target.value)) || {};
    setValue('country_id', e.target.value);
    // setValue('bank_country', x?.short_name);
    setValue('bank_country', e.target.value);

    // For Qatar and Kuwait IBAN only
    if (['234', '118', '179'].includes(e.target.value)) {
      setIsIBANStrictCountry(true);
      onCheckNumberType(1);
      radioRef.current.checked = true;
    } else {
      setIsIBANStrictCountry(false);
    }
    // -------------------------------

    if (e.target.value === '102') {
      console.log(e.target.value);
      setIfsc(true);
      setIfscOrAba('IFSC Code');
    } else if (e.target.value === '236') {
      setIfsc(true);
      setIfscOrAba('ABA Code');
    } else {
      setIfsc(false);
    }
  };
  const onSelectCurrency = (e) => {
    const x = currencyList.find((obj) => String(obj.id) === String(e.target.value)) || {};
    setValue('pay_currency_id', e.target.value);
    setValue('pay_currency', x?.currency);
  };

  const onConfirmSubmission = async (e) => {
    const payload = { ...e, number_type: numberType };
    const success = await onConfirm(payload);
    if (success) {
      reset();
      toggle();
    }
  };

  const handleCustomSubmit = () => {
    // Your custom logic to determine whether to submit or not

    if ((numberType === 1 && isValidIBAN) || numberType === 2) {
      // If the condition is met, manually trigger form submission
      handleSubmit(onConfirmSubmission)();
    }
  };

  return (
    <div>
      <Modal size="lg" isOpen={isOpen} toggle={toggle} style={{ width: '80vw' }}>
        <Form onSubmit={handleSubmit(onConfirmSubmission)} className="">
          <ModalHeader toggle={toggle}>Add Bank details</ModalHeader>
          <ModalBody className="pt-4 mt-3">
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup className="form-label-group">
                  <Input
                    type="select"
                    name="country_id"
                    id="country_id"
                    placeholder="Country"
                    disabled={loading}
                    invalid={!!errors.country_id}
                    innerRef={register('country_id', { required: errorMessages.missingData })}
                    onChange={onSelectCountry}
                  >
                    <option key="0">---select country---</option>
                    {countryList?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.short_name}
                      </option>
                    ))}
                  </Input>

                  <Input
                    type="hidden"
                    name="bank_country"
                    id="bank_country"
                    placeholder="Country"
                    disabled={loading}
                    invalid={!!errors.bank_country}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('bank_country', e.target.value)}
                  />
                  <Label>Country</Label>
                </FormGroup>
              </Col>
              <Col className="col-md-6 col-12">
                <FormGroup className="form-label-group">
                  <Input
                    type="select"
                    name="bank_id"
                    id="bank_id"
                    placeholder="Bank Name"
                    disabled={loading}
                    invalid={!!errors.bank_name}
                    innerRef={register('bank_id')}
                    onChange={onSelectBank}
                  >
                    {bankListData?.map((item) => (
                      <option key={item?.BankAutoId} value={item?.BankAutoId}>
                        {item?.Title}
                      </option>
                    ))}
                  </Input>
                  <Label>Bank Name</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup className={noBankListed ? 'form-label-group' : 'd-none'}>
                  <Input
                    type={noBankListed ? 'text' : 'hidden'}
                    name="bank_name"
                    id="bank_name"
                    placeholder="Enter youe bank name"
                    disabled={loading}
                    invalid={!!errors.bank_name}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('bank_name', e.target.value)}
                  />
                  <Label>Enter your bank name</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup className="form-label-group">
                  <Input
                    type="text"
                    name="swift"
                    id="swift"
                    placeholder="Swift Code"
                    disabled={loading}
                    invalid={!!errors.swift}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('swift', e.target.value)}
                  />
                  <Label>Swift Code</Label>
                </FormGroup>
              </Col>
              {ifsc ? (
                <Col className="col-md-6 col-12">
                  <FormGroup className="form-label-group">
                    <Input
                      type="text"
                      name="ifsc_or_aba"
                      id="ifsc_or_aba"
                      placeholder="IFSC/ABA Code"
                      disabled={loading}
                      invalid={!!errors.ifsc_or_aba}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => setValue('ifsc_or_aba', e.target.value)}
                    />
                    <Label>{ifscOrAba}</Label>
                  </FormGroup>
                </Col>
              ) : (
                <Col className="col-md-6 col-12">
                  <FormGroup className="form-label-group">
                    <Input
                      className="custom-select"
                      type="select"
                      name="pay_currency_id"
                      id="pay_currency_id"
                      placeholder="Payment currency"
                      disabled={loading}
                      invalid={!!errors.pay_currency_id}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={onSelectCurrency}
                    >
                      <option key="0" value="">
                        ---Select currency---
                      </option>
                      {currencyList?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="hidden"
                      name="pay_currency"
                      id="pay_currency"
                      placeholder="Payment currency"
                      disabled={loading}
                      invalid={!!errors.pay_currency}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => setValue('pay_currency', e.target.value)}
                    />
                    <Label>Payment currency</Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup className="form-label-group">
                  <Input
                    type="text"
                    name="payable_to"
                    id="payable_to"
                    placeholder="Account Holder Name"
                    defaultValue={user?.member_name}
                    disabled
                    invalid={!!errors.payable_to}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('payable_to', e.target.value)}
                  />
                  <Label>Account Holder Name</Label>
                </FormGroup>
              </Col>
              <Col className="col-md-6 col-12">
                {ifsc ? (
                  <FormGroup className="form-label-group">
                    <Input
                      className="custom-select"
                      type="select"
                      name="pay_currency_id"
                      id="pay_currency_id"
                      placeholder="Payment currency"
                      disabled={loading}
                      invalid={!!errors.pay_currency_id}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={onSelectCurrency}
                    >
                      <option key="0">---Select currency---</option>
                      {currencyList?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.currency}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="hidden"
                      name="pay_currency"
                      id="pay_currency"
                      placeholder="Payment currency"
                      disabled={loading}
                      invalid={!!errors.pay_currency}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => setValue('pay_currency', e.target.value)}
                    />
                    <Label>Payment currency</Label>
                  </FormGroup>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex mb-3">
                <FormGroup check className="mr-4">
                  <Label check>
                    <Input
                      type="radio"
                      name="number_type"
                      value="1"
                      defaultChecked={numberType === 1}
                      disabled={loading}
                      // invalid={!!errors.number_type}
                      innerRef={(e) => {
                        radioRef.current = e;
                        // register({ required: errorMessages.missingData });
                      }}
                      onChange={() => onCheckNumberType(1)}
                    />
                    IBAN
                  </Label>
                </FormGroup>
                {!isIBANStrictCountry ?
                  (
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="number_type"
                          value="2"
                          defaultChecked={numberType === 2}
                          disabled={loading}
                          // invalid={!!errors.number_type}
                          // innerRef={register({ required: errorMessages.missingData })}
                          onChange={() => onCheckNumberType(2)}
                        />
                        Account Number
                      </Label>
                    </FormGroup>
                  ) : null}
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                {numberType === 1 ? (
                  <FormGroup className="form-label-group">
                    <Input
                      type="text"
                      name="iban_no"
                      id="iban_no"
                      placeholder="Enter IBAN"
                      disabled={loading}
                      invalid={!!errors.iban_no || !isValidIBAN}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => onIBANChanged(e.target.value)}
                    />
                    <Label>Enter IBAN</Label>
                    {!isValidIBAN ? (<p style={{ fontSize: '15px', color: 'red' }}>Invalid IBAN</p>) : null}
                  </FormGroup>
                ) : (
                  <FormGroup className="form-label-group">
                    <Input
                      type="text"
                      name="account_no"
                      id="account_no"
                      placeholder="Enter Account Number"
                      disabled={loading}
                      invalid={!!errors.account_no}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => setValue('account_no', e.target.value)}
                    />
                    <Label>Enter Account Number</Label>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="note">
                <p className="alert alert-dark mb-3">
                  Note: Please ensure that the bank details you provide will be used for reimbursement of your claims.
                  Therefore please ensure that the details provided are accurate and up-to-date.
                  <br />
                  <br />
                  Note: If your bank is not listed you can select international bank option
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="outline-primary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleCustomSubmit} disabled={loading}>
              {loading ?
                (
                  <>
                    <Spinner
                      size="sm"
                      color="light"
                    />
                    <span>&nbsp;Loading</span>
                  </>
                ) : 'Confirm'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

AddBankDetails.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  bankList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    member_name: PropTypes.string,
  }).isRequired,
};

AddBankDetails.defaultProps = {
  isOpen: false,
  loading: false,
};

export default withRouter(AddBankDetails);
