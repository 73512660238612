import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';
import PasswordExpiredAlert from './UI/PasswordExpiredAlert';

const LoginForm = ({
  error,
  loading,
  success,
  message,
  onFormSubmit,
  defaultValues,
  isOpenAlert,
  member_name,
  token,
  email,
  loginOTPData,
  me,
  guideVideoURL,
  thumbnail,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const [passwordView, setPasswordView] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  return (
    <div className="bg-g">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container className="login full-width-page">
        <Row className="text-center align-content-center">
          <Col className="text-center login-left" md="5">
            <img className="logo" src={logo} alt="" />
            <h1>Sign into your account</h1>
            <h3>Enter your username and password</h3>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              {!!success && <Alert color="success">{message}</Alert>}
              {!!error && <Alert color="danger">{message}</Alert>}
              {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

              <FormGroup className="form-label-group">
                {/* <Label for="email">Email</Label> */}
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Username or Email"
                  disabled={loading}
                  invalid={!!errors.email}
                  innerRef={register({ required: errorMessages.missingEmail })}
                  onChange={(e) => setValue('email', e.target.value)}
                />
                <Label>Username or Email</Label>
                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                {/* <Label for="password">Password</Label> */}
                <Input
                  type={passwordView ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Password"
                  autoComplete="off"
                  disabled={loading}
                  invalid={!!errors.password}
                  innerRef={register({ required: errorMessages.missingPassword })}
                  onChange={(e) => setValue('password', e.target.value)}
                />
                <span
                  className={passwordView ? 'show-password-icon opened' : 'show-password-icon'}
                  onClick={() => setPasswordView(!passwordView)}
                />
                <Label>Password</Label>
                {errors.password && <p className="invalid-feedback">{errors.password.message}</p>}
              </FormGroup>

              <Button color="primary" disabled={loading} style={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* {loading ? 'Loading' : 'Login'} */}
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Login'}
              </Button>
            </Form>
            <div className="bottom-links justify-content-around mt-5 text-primary">
              <Link className="mb-sm-0 mb-2" to="/forgotPassword">
                Forgot Password
              </Link>
              <span className="d-none d-sm-block">&#9679;</span>
              <Link className="mb-sm-0 mb-2" to="/register">
                Register Now
              </Link>
              <span className="d-none d-sm-block">&#9679;</span>
              <Link to="/recoverUsername">Trouble logging in?</Link>
            </div>
          </Col>
          <Col className="text-center w-100 login-right text-white p-5">
            <h2 className="">Introducing myMetLife Plus</h2>
            <p className="">
              myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
              your account, read the latest on health, fitness, and financial wellness, and access customer service-
              anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
              out-of- pocket expenses. Now, that's something to smile about.
            </p>
            {guideVideoURL ?
              (
                <div className="video-container">
                  <video id="guide" ref={videoRef} width="100%" poster={thumbnail} controls={isPlaying}>
                    <source src={guideVideoURL} />
                    <track kind="captions" />
                  </video>
                  {!isPlaying && (
                    <div className="player" onClick={handlePlay}><span /></div>
                  )}
                </div>
              ) : null}
          </Col>
        </Row>
      </Container>
      <Footer />

      <PasswordExpiredAlert
        isOpenAlert={isOpenAlert}
        member_name={member_name}
        token={token}
        email={email}
        loginOTPData={loginOTPData}
        me={me}
        login={handleSubmit(onFormSubmit)}
      />
    </div>
  );
};

LoginForm.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool,
  isOpenAlert: PropTypes.bool,
  member_name: PropTypes.string,
  token: PropTypes.string,
  email: PropTypes.string,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  guideVideoURL: PropTypes.string,
  thumbnail: PropTypes.string,
  loginOTPData: PropTypes.shape({}),
  me: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  message: null,
  error: null,
  success: null,
  isOpenAlert: null,
  member_name: null,
  token: null,
  email: null,
  loading: false,
  defaultValues: {},
  guideVideoURL: null,
  thumbnail: null,
  loginOTPData: {},
};

export default withRouter(LoginForm);
