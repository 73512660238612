import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useLocation, withRouter } from 'react-router-dom';
import InputField, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { errorMessages } from '../constants/messages';
import Template from './Templates/Home';
import CustomFileInput from './PreApprovals/AddPreApprovals/CustomFileInput';
import { hasAuthToken } from '../lib/jwt';

const ComplaintForm = ({ error, loading, message, onFormSubmit, defaultValues, onFileSelected, authUser }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });
  const [contactPref, setContactPref] = useState(null);
  const [existCase, setExistCase] = useState(0);
  const [num, setNum] = useState(null);
  const [click, setClick] = useState(false);

  const location = useLocation();
  console.log(location);

  // const history = useHistory();

  const handleContactPreferenceChange = (value) => {
    setContactPref(value);
    setValue('contact_preference', value);
    if (hasAuthToken()) {
      setTimeout(() => {
        setValue('name', authUser?.member_name);
        setValue('phone_number', `+${authUser?.phone_number}`);
        setValue('email', authUser?.email);
        setValue('certificate_number', authUser?.certificate_number);
        setValue('policy_number', authUser?.policy_number);
        setNum(authUser?.phone_number ? `+${authUser?.phone_number.replace('+', '')}` : '');
      }, 100);
    }
  };
  useEffect(() => {
    setContactPref(null);
    setValue('contact_preference', null);
    console.log('useeffect');
  }, [defaultValues]);

  const breadcrumb = [
    { label: 'Contact Us', to: '/contactUs' },
    { label: 'Complaints', active: true },
  ];
  return (
    <Template pageTitle="Complaints" breadcrumb={breadcrumb}>
      <div className="bg-g">
        <Container fluid className="full-width-page complaint-page max-container">
          <Row className="align-content-center">
            <Col className="m-auto col-md-12 col-xl-10">
              <div className="text-center mb-5 border-bottom pb-4">
                <div className="pt-4">
                  <h2 className="f-ns-m">
                    Help us to improve our products and services, share your experience with us
                  </h2>
                  {/* <p>Help us to improve our products and services, share your experience with us</p> */}
                </div>
              </div>
              <Form
                onSubmit={handleSubmit((formData) => {
                  setClick(true);
                  if (num ? isValidPhoneNumber(num) : false) {
                    const tempData = formData;
                    tempData.phone_number = num;
                    onFormSubmit(tempData);
                  }
                })}
              >
                {/* {!!success && <Alert color="success">{message}</Alert>} */}
                {!!error && <Alert color="danger">{message}</Alert>}
                {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormGroup className="form-label-group">
                      <Input
                        type="select"
                        name="contact_preference"
                        id="contact_preference"
                        placeholder="Contact Preference"
                        invalid={!!errors.contact_preference}
                        innerRef={register({ required: errorMessages.missingData })}
                        onChange={(e) => handleContactPreferenceChange(e.target.value)}
                      >
                        <option hidden value="">
                          Contact Preference
                        </option>
                        <option value="1">Email me</option>
                        <option value="2">Call me</option>
                      </Input>
                      <Label for="contact_preference">Contact Preference</Label>
                      {errors.contact_preference && (
                        <p className="invalid-feedback">{errors.contact_preference.message}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col> </Col>
                </Row>
                {contactPref ? (
                  <div>
                    <Row>
                      <Col className="col-12 col-sm-6">
                        <FormGroup className="form-label-group">
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                            invalid={!!errors.name}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('name', e.target.value)}
                          />
                          <Label for="name">Name</Label>
                          {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                        </FormGroup>
                      </Col>
                      {/* <Col className="col-12 col-sm-6">
                        <FormGroup className="form-label-group">
                          <Input
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Last Name"
                            invalid={!!errors.last_name}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('last_name', e.target.value)}
                          />
                          <Label for="last_name">Last Name</Label>
                          {errors.last_name && <p className="invalid-feedback">{errors.last_name.message}</p>}
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col className="col-12 col-sm-6">
                        <FormGroup className="form-label-group">
                          {/* <Label for="email">Email</Label> */}
                          {/* <Input
                              type="text"
                              name="phone_number"
                              id="phone_number"
                              placeholder="Phone Number"
                              invalid={!!errors.phone_number}
                              innerRef={register({ required: errorMessages.missingData })}
                              onChange={(e) => setValue('phone_number', e.target.value)}
                            /> */}
                          <InputField
                            type="text"
                            // readOnly={!editContact}
                            name="phone_number"
                            id="phone_number"
                            placeholder="Phone Number"
                            international
                            withCountryCallingCode
                            className={`form-control ${click ? (num ? (!isValidPhoneNumber(num) ? 'is-invalid' : '') : 'is-invalid') : ''
                              }`}
                            disabled={loading}
                            // invalid={click && num ? !isValidPhoneNumber(num) : true}
                            value={num}
                            onChange={(e) => {
                              setNum(e);
                              setValue('phone_number', e);
                            }}
                          />
                          <Label for="phone_number">Phone Number</Label>
                          <p className="invalid-feedback">
                            {`${click
                                ? num
                                  ? isValidPhoneNumber(num)
                                    ? ''
                                    : 'Invalid phone number'
                                  : 'Phone number required'
                                : ''
                              }`}
                          </p>
                          <p className="phone-info">
                            Please enter your phone number prefixed with the country dialing code, without appending any
                            zeroes
                          </p>
                        </FormGroup>
                      </Col>
                      <Col className="col-12 col-sm-6">
                        <FormGroup className="form-label-group">
                          {/* <Label for="email">Email</Label> */}
                          <Input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email"
                            invalid={!!errors.email}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('email', e.target.value)}
                          />
                          <Label for="email">Email</Label>
                          {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {contactPref === '2' ? (
                        <Col>
                          <FormGroup className="form-label-group">
                            <Input
                              type="select"
                              name="time_to_call"
                              id="time_to_call"
                              placeholder="Best Time to call"
                              invalid={!!errors.time_to_call}
                              defaultValue="1"
                              innerRef={register({ required: false })}
                              onChange={(e) => setValue('time_to_call', e.target.value)}
                            >
                              <option value="1">9am to 10</option>
                              <option value="2">11am to 12pm</option>
                              <option value="3">1pm to 2pm</option>
                              <option value="4">2pm to 3pm</option>
                            </Input>
                            <Label for="time_to_call">Best Time to call</Label>
                            {errors.time_to_call && <p className="invalid-feedback">{errors.time_to_call.message}</p>}
                          </FormGroup>
                        </Col>
                      ) : null}
                      <Col md="6">
                        <FormGroup className="form-label-group">
                          <Input
                            type="select"
                            name="product_type"
                            id="product_type"
                            placeholder="Product Type"
                            invalid={!!errors.product_type}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('product_type', e.target.value)}
                            defaultValue="1"
                          >
                            <option value="1">Insurance Provided By your Employer</option>
                          </Input>
                          <Label for="product_type">Product Type</Label>
                          {errors.product_type && <p className="invalid-feedback">{errors.product_type.message}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-md-6">
                        <h4>Is your query related to an existing case?</h4>
                        <FormGroup className="form-group">
                          <Label check className="ml-5">
                            <Input
                              type="radio"
                              name="related_to_existing_case"
                              value="1"
                              disabled={loading}
                              invalid={!!errors.related_to_existing_case}
                              innerRef={register({ required: errorMessages.missingData })}
                              onChange={() => setExistCase(1)}
                            />
                            Yes
                          </Label>
                          <Label check className="ml-5">
                            <Input
                              type="radio"
                              name="related_to_existing_case"
                              value="0"
                              disabled={loading}
                              defaultChecked
                              invalid={!!errors.related_to_existing_case}
                              innerRef={register({ required: errorMessages.missingData })}
                              onChange={() => setExistCase(0)}
                            />
                            No
                          </Label>
                          {errors.related_to_existing_case && (
                            <p className="invalid-feedback">{errors.related_to_existing_case.message}</p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col> </Col>
                    </Row>
                    {existCase ? (
                      <Row>
                        <Col className="col-12 col-md-6">
                          <FormGroup className="form-label-group">
                            <Input
                              type="text"
                              name="reference_complaint_id"
                              id="reference_complaint_id"
                              placeholder="Reference Number of previous ticket  "
                              invalid={!!errors.reference_complaint_id}
                              innerRef={register({ required: errorMessages.missingData })}
                              onChange={(e) => setValue('reference_complaint_id', e.target.value)}
                            />
                            <Label for="reference_complaint_id">Reference Number of previous ticket </Label>
                            {errors.reference_complaint_id && (
                              <p className="invalid-feedback">{errors.reference_complaint_id.message}</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col> </Col>
                      </Row>
                    ) : null}
                    {/* <Row>
                      <Col className="col-12 col-md-6">
                        <FormGroup className="form-label-group">
                          <Input
                            type="date"
                            name="dob"
                            id="dob"
                            placeholder="Date of Birth"
                            invalid={!!errors.dob}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('dob', e.target.value)}
                          />
                          <Label for="dob">Date of birth</Label>
                          {errors.dob && <p className="invalid-feedback">{errors.dob.message}</p>}
                        </FormGroup>
                      </Col>
                      <Col> </Col>
                    </Row> */}
                    {/* <Row>
                      <Col className="col-12 col-md-6">
                        <FormGroup className="form-label-group">
                          <Input
                            type="text"
                            name="policy_number"
                            id="policy_number"
                            placeholder="Policy Number"
                            invalid={!!errors.policy_number}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('policy_number', e.target.value)}
                          />
                          <Label for="policy_number">Policy Number</Label>
                          {errors.policy_number && <p className="invalid-feedback">{errors.policy_number.message}</p>}
                        </FormGroup>
                      </Col>
                      <Col className="col-12 col-md-6">
                        <FormGroup className="form-label-group">
                          <Label for="email">Email</Label>
                          <Input
                            type="text"
                            name="certificate_number"
                            id="certificate_number"
                            placeholder="Certificate Number"
                            invalid={!!errors.certificate_number}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('certificate_number', e.target.value)}
                          />
                          <Label for="certificate_number">Certificate Number</Label>
                          {errors.certificate_number && (
                            <p className="invalid-feedback">{errors.certificate_number.message}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col className="col-12 col-md-6">
                        <FormGroup className="form-label-group">
                          {/* <Label for="email">Email</Label> */}
                          <Input
                            type="select"
                            name="complaint_related_to"
                            id="complaint_related_to"
                            placeholder="First Name"
                            invalid={!!errors.complaint_related_to}
                            innerRef={register({ required: errorMessages.missingData })}
                            onChange={(e) => setValue('complaint_related_to', e.target.value)}
                            defaultValue="1"
                          >
                            <option value="1">Mobile App</option>
                            <option value="2">MetLife Online Account</option>
                            <option value="3">Network</option>
                            <option value="4">Policy Benefits</option>
                            <option value="5">Claims</option>
                            <option value="6">Certificate</option>
                            <option value="7">Check Dispatch details</option>
                            <option value="8">Approval</option>
                            <option value="9">Others</option>
                          </Input>
                          <Label for="complaint_related_to">My Complaint is related to </Label>
                          {errors.complaint_related_to && (
                            <p className="invalid-feedback">{errors.complaint_related_to.message}</p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col> </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="form-label-group">
                          <Input
                            type="textarea"
                            name="message"
                            id="message"
                            className="form-control"
                            innerRef={register()}
                            onChange={(e) => setValue('message', e.target.value)}
                          />
                          <Label for="complaint_related_to">Message </Label>
                          {errors.message && <p className="invalid-feedback">{errors.message.message}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <small className="mb-3 d-block">File attachments if any</small>
                        <CustomFileInput onFileSelect={onFileSelected} type="docs" label="File Attachments" />
                        <small>
                          *Maximum file size allowed is 8MB/Document. Allowed file types .jpg, .jpeg, .tiff, .tif, .gif,
                          .bmp, .pdf, .doc, .docx.
                        </small>
                      </Col>
                    </Row>
                    <br />
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ?
                        (
                          <>
                            <Spinner
                              size="sm"
                              color="light"
                            />
                            <span>&nbsp;Loading</span>
                          </>
                        ) : 'Submit'}
                    </Button>
                  </div>
                ) : null}
              </Form>
            </Col>
            <Col md="10" className="m-auto complaints-content">
              <h2 className="mt-5 f-ns-b">What happens next?</h2>
              <p>After you get in touch, we will:</p>
              <ul>
                <li> Acknowledge your complaint via email or call and share a complaints reference number </li>
                <li>Provide you with an update on your case investigation within two (2) working days:</li>
                <li>Deliver a suitable resolution within five (5) working days from receipt of your complaint.</li>
              </ul>
              <p>
                We are committed to providing our customers and partners with products and services of the highest
                standards.
              </p>
              <p>
                If you feel our response did not completely satisfy your request, you may notify our appeals committee
                to re-assess the complaint within 3 months of the date of the intial resolution by emailing
                <a href="mailto:Complaints.Gulf@metlife.metnotices.com"> Complaints.Gulf@metlife.metnotices.com</a>
              </p>
              <p> One of our representatives will respond to you within five working days.</p>
              {/* <p>Click here to download our complaints process and flow chart.</p> */}
              <p>
                <b> If you are dissatisfied with our final response </b>
                you may refer your complaint to the Insurance Regulator.
              </p>
              <p>
                You may do so by sending details of your complaint, stating the MetLife Complaint Reference Number, to
                the relevant Regulator:
                <br />
                <br />
                <b> For Dubai Health Insurance complaints. </b>
                you can contact the Dubai Health Authority (DHA) using the
                <a href="http://ipromes.eclaimlink.ae/" target="_blank" rel="noreferrer"> online complaint form </a>
                the details below:
                <br />
                <br />
                E-Mail:
                <a href="mailto:wasselsotak@dha.gov.ae"> wasselsotak@dha.gov.ae</a>
                <br />
                <br />
                Toll Free (24/7): 800342 (800 DHA)
                <br />
                <br />
                <b> For Abu Dhabi Health Insurance complaints</b>
                , you can contact the Health Authority of Abu Dhabi (HAAD) using the
                <a href="https://www.haad.ae/haad" target="_blank" rel="noreferrer"> online complaint form </a>
                or the
                details below:
                <br />
                <br />
                Email:
                <a href="mailto:contact@abudhabi.ae"> contact@abudhabi.ae</a>
                <br />
                <br />
                Telephone: +971 2449 3333 or Local Toll Free Number: 800 555
                <br />
                <br />
                <b>For all other UAE Insurance Policies</b>
                , you can contact the Insurance Authority using the
                <a href="https://smartservices.ia.gov.ae/ecomplaint/ecomplaint/complaint" target="_blank" rel="noreferrer"> online complaint form </a>
                or the details bellow:
                <br />
                <br />
                Email:
                <a href="mailto:contactus@ia.gov.ae"> contactus@ia.gov.ae</a>
                <br />
                <br />
                Telephone: +971 2499 0111
                <br />
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Template>
  );
};

ComplaintForm.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  authUser: PropTypes.shape({
    member_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    policy_number: PropTypes.string,
    certificate_number: PropTypes.string,
  }).isRequired,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
};

ComplaintForm.defaultProps = {
  message: null,
  error: null,
  loading: false,
  defaultValues: {},
};

export default withRouter(ComplaintForm);
