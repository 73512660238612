import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import Template from './Templates/Home';

const About = () => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'About Us', active: true },
  ];

  return (
    <Template pageTitle="About Us" breadcrumb={breadcrumb}>
      <Container fluid className="max-container">
        <h4 className="f-mlc-m policy-main-title mt-2">myMetLife</h4>
        <p className="text-justify f-18 ">
          <b>Navigating life together. </b>
          We’ve been in the region for over 65 years,
          finding smart financial solutions to life’s everyday needs.
          Our experience means we’ve watched the world change, and we’ve adapted to it,
          but our promise remains –offer guidance to help you meet your goals and navigate life’s twists and turns.
          Underpinning our success is our commitment to always improving our offer and the dedication
          to understanding the future you want to create - one that’s just right for you, your family
          or your business, today and beyond.
        </p>
        <p className="text-justify  f-18 mb-5">
          We’re headquartered in Dubai, UAE and we support businesses in the UAE,
          Bahrain, Kuwait, Oman and Qatar.
          Insurance Company (MetLife) American Life Insurance Company (MetLife) is a branch of
          a foreign insurance company duly established and operating
          in the UAE duly registered with the UAE Insurance Authority under registration number (34).
        </p>
      </Container>
    </Template>
  );
};

export default withRouter(About);
