import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Template from '../../../Templates/Home';
import Title from '../../Title';
import PhotoUploadCard from './PhotoUploadCard';
import Loading from '../../../UI/Loading';

const MemberPhotoUpload = ({ data, policyData, photoUpload, loading }) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Member Photo Upload', active: true },
  ];
  return (
    <Template pageTitle="Member Photo Upload" breadcrumb={breadcrumb}>
      <>
        {' '}
        <Container className="max-container">
          <Title
            policyNumber={policyData.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData.expiryDate}
          />
        </Container>
        <section className="metLife-gray">
          <Container className="max-container">
            <Row>
              {data?.length ? (
                data.map((item) => (
                  <Col md="6" key={item.id}>
                    <PhotoUploadCard item={item} key={item.id} photoUpload={photoUpload} />
                  </Col>
                ))
              ) : loading ? (
                <Col>
                  <Loading />
                </Col>
              ) : null}
            </Row>
          </Container>
        </section>
      </>
    </Template>
  );
};

MemberPhotoUpload.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  photoUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

MemberPhotoUpload.defaultProps = {
  data: [],
  loading: false,
};

export default withRouter(MemberPhotoUpload);
