import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/PolicyDetails/Services/Services';

class Services extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    console.log('Beneficiary');
    const { onFormSubmit } = this.props;

    this.setState({ success: null, error: null, loading: true });

    try {
      const success = await onFormSubmit(data);
      this.setState({ success, error: null, loading: false });
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, currentPolicy } = this.props;
    const { error, loading, success } = this.state;

    const serviceList = [
      // {
      //   id: 1,
      //   name: 'Memeber Document Upload',
      //   icon: faInfoCircle,
      //   url: '',
      // },
      {
        id: 2,
        name: 'Member Photo Upload',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="29.333" height="22" viewBox="0 0 29.333 22">
            <path
              id="Path_1654"
              data-name="Path 1654"
              d="M31.5,11.542H27.34a1.24,1.24,0,0,1-.917-.412C24.254,8.7,23.437,7.875,22.551,7.875H16.019c-.894,0-1.772.825-3.949,3.262a1.216,1.216,0,0,1-.909.4h-.313v-.611a.613.613,0,0,0-.611-.611H8.251a.613.613,0,0,0-.611.611v.611H7.067A2.477,2.477,0,0,0,4.5,13.887V27.331a2.644,2.644,0,0,0,2.559,2.544H31.5a2.449,2.449,0,0,0,2.33-2.544V13.887A2.277,2.277,0,0,0,31.5,11.542ZM19.472,26.85a6.531,6.531,0,1,1,6.218-6.218A6.537,6.537,0,0,1,19.472,26.85ZM26.5,15.972a.993.993,0,1,1,.993-.993A.991.991,0,0,1,26.5,15.972Z"
              transform="translate(-4.5 -7.875)"
              fill="#007abc"
            />
          </svg>
        ),
        url: '/home/memberPhotoUpload',
        currentPolicy,
        subtitle: 'View/edit member photos.',
      },
      {
        id: 3,
        name: 'Preapprovals',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21">
            <path
              id="Icon_PolicyNote"
              data-name="Icon/PolicyNote"
              d="M19.8,17.824V3H4V19.8a2.783,2.783,0,0,0,2.673,2.965h.243a2.875,2.875,0,0,0,2.916-2.841v-2.1ZM7.646,6.706h8.505V8.435H7.646Zm0,6.176V11.153h8.505v1.729Zm18.226,7.412H13.722v.618a3.252,3.252,0,0,1-1.7,2.594H11.9A1.547,1.547,0,0,1,10.684,24H23.078a2.31,2.31,0,0,0,1.458-.494,3.021,3.021,0,0,0,1.458-2.594v-.618"
              transform="translate(-3.994 -3)"
              fill="#007abc"
            />
          </svg>
        ),
        currentPolicy,
        url: '/preApprovals',
        subtitle: 'View/Add pre-approvals.',
      },
      {
        id: 5,
        name: 'Download Form',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="27.858" height="25.4" viewBox="0 0 27.858 25.4">
            <path
              id="Path"
              d="M22.942,25.4H3.776A3.852,3.852,0,0,1,0,21.476V0H20.032V21.476C20.032,23.643,20.856,25.4,22.942,25.4Z"
              transform="translate(0)"
              fill="#0090da"
            />
            <path
              id="Path-2"
              data-name="Path"
              d="M4.585,2.529V0H27.858V2.529a5.026,5.026,0,0,1-1.35,3.434,4.43,4.43,0,0,1-3.235,1.412H0A4.43,4.43,0,0,0,3.235,5.962,5.026,5.026,0,0,0,4.585,2.529Z"
              transform="translate(0 18.026)"
              fill="#0061a0"
            />
            <path
              id="Path-3"
              data-name="Path"
              d="M0,4.917H4.916V0H6.555V4.917H11.47L5.736,10.652Z"
              transform="translate(4.631 5.23)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </svg>
        ),
        url: '/home/downloadForm',
        currentPolicy,
        subtitle: 'View/download form.',
      },
      {
        id: 8,
        name: 'Videos and Manuals',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18.667" viewBox="0 0 28 18.667">
            <path
              id="Icon_awesome-video"
              data-name="Icon awesome-video"
              d="M16.343,4.5H2.324A2.324,2.324,0,0,0,0,6.824V20.843a2.324,2.324,0,0,0,2.324,2.324H16.343a2.324,2.324,0,0,0,2.324-2.324V6.824A2.324,2.324,0,0,0,16.343,4.5ZM25.55,6.333l-5.328,3.675v7.651l5.328,3.67A1.559,1.559,0,0,0,28,20.075V7.587A1.559,1.559,0,0,0,25.55,6.333Z"
              transform="translate(0 -4.5)"
              fill="#007abc"
            />
          </svg>
        ),
        url: '/home/videoAndManuals',
        currentPolicy,
        subtitle: 'View videos and manuals',
      },
      // {
      //   id: 7,
      //   name: 'Profile Management',
      //   icon: (
      //     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22">
      //       <path
      //         id="Icon_Update"
      //         data-name="Icon/Update"
      /* eslint-disable */
      //         d="M8,4a4.7,4.7,0,0,1,4.455,4.813A4.7,4.7,0,0,1,8,13.625,4.7,4.7,0,0,1,3.545,8.813,4.7,4.7,0,0,1,8,4ZM1,26H15a64.225,64.225,0,0,0-2.036-11H3.036A64.225,64.225,0,0,0,1,26ZM22.636,12.25a6.57,6.57,0,0,0-6.364,6.875V26h6.364A6.57,6.57,0,0,0,29,19.125,6.57,6.57,0,0,0,22.636,12.25ZM21.491,23.112l-2.673-2.887,1.018-1.1,1.782,1.925,3.945-4.263.891.963Z"
      //         transform="translate(-1 -4)"
      //         fill="#007abc"
      //       />
      //     </svg>
      //   ),
      //   url: '/profile',
      //   currentPolicy,
      //   subtitle: 'View/edit profile details.',
      // },
      {
        id: 4,
        name: 'Certificates',
        icon: (
          <svg
            id="hr_Give-Recognition__120p"
            data-name="hr_Give-Recognition_≤120p"
            width="29"
            height="29"
            viewBox="0 0 29 29"
          >
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_4027" data-name="Rectangle 4027" width="29" height="29" fill="none" />
              </clipPath>
            </defs>
            <g id="Group_31343" data-name="Group 31343" transform="translate(4.122 0.972)">
              <path
                id="Path_4075"
                data-name="Path 4075"
                d="M70.042,73.012l-3.191.077L65,75.69h0L59.64,65.6l5.046-2.678Z"
                transform="translate(-49.411 -48.752)"
                fill="#0061a0"
              />
              <path
                id="Path_4076"
                data-name="Path 4076"
                d="M17.13,73.012l3.191.077,1.853,2.6h0L27.532,65.6,22.484,62.92Z"
                transform="translate(-17.13 -48.752)"
                fill="#0061a0"
              />
              <path
                id="Path_4077"
                data-name="Path 4077"
                d="M39.333,13.679c0,1.2-1.619,2.069-2.06,3.114-.457,1.083.031,2.835-.792,3.648s-2.57.3-3.658.746c-1.049.428-1.925,2.028-3.128,2.021s-2.077-1.612-3.121-2.062c-1.083-.457-2.835.031-3.648-.792s-.3-2.57-.746-3.658c-.428-1.049-2.028-1.925-2.021-3.128s1.619-2.069,2.06-3.114c.457-1.083-.031-2.835.792-3.648s2.57-.3,3.658-.746c1.049-.428,1.925-2.028,3.128-2.021s2.069,1.619,3.114,2.06c1.083.457,2.835-.031,3.648.792s.3,2.57.746,3.658c.428,1.049,2.028,1.925,2.021,3.128"
                transform="translate(-19.431 -4.04)"
                fill="#0090da"
              />
              <path
                id="Path_4078"
                data-name="Path 4078"
                d="M46.606,24.256A6.213,6.213,0,1,1,40.432,18a6.213,6.213,0,0,1,6.174,6.256"
                transform="translate(-30.078 -14.64)"
                fill="#0061a0"
              />
            </g>
            <g id="_120p_Dimensions" data-name="120p Dimensions">
              <g id="Group_9477" data-name="Group 9477" clipPath="url(#clip-path)">
                <g id="Group_9476" data-name="Group 9476" clipPath="url(#clip-path)">
                  <g id="Group_9475" data-name="Group 9475" clipPath="url(#clip-path)">
                    <g id="Group_9474" data-name="Group 9474" opacity="0">
                      <g id="Group_9473" data-name="Group 9473" clipPath="url(#clip-path)">
                        <rect id="Rectangle_4023" data-name="Rectangle 4023" width="29" height="29" fill="#fff" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <rect id="Rectangle_4028" data-name="Rectangle 4028" width="29" height="29" fill="#fff" opacity="0" />
          </svg>
        ),
        url: '/home/certificates',
        currentPolicy,
        subtitle: 'View/download certificates.',
      },
      // {
      //   id: 6,
      //   name: 'Spouse Registration',
      //   icon:null,
      //   url: '',
      //   currentPolicy,
      //   subtitle: 'Register spouse.',
      // },
      // {
      //   id: 10,
      //   name: 'Video Tutorials',
      //   icon: (
      //     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18.667" viewBox="0 0 28 18.667">
      //       <path
      //         id="Icon_awesome-video"
      //         data-name="Icon awesome-video"
      //         d="M16.343,4.5H2.324A2.324,2.324,0,0,0,0,6.824V20.843a2.324,2.324,0,0,0,2.324,2.324H16.343a2.324,2.324,0,0,0,2.324-2.324V6.824A2.324,2.324,0,0,0,16.343,4.5ZM25.55,6.333l-5.328,3.675v7.651l5.328,3.67A1.559,1.559,0,0,0,28,20.075V7.587A1.559,1.559,0,0,0,25.55,6.333Z"
      //         transform="translate(0 -4.5)"
      //         fill="#007abc"
      //       />
      //     </svg>
      //   ),
      //   url: '/videoTutorials',
      //   currentPolicy,
      //   subtitle: 'View videos and manuals',
      // },
      {
        id: 9,
        name: 'Transaction History',
        icon: (
          <svg
            id="Additional_Programs_24"
            data-name="Additional Programs &gt;24"
            xmlns="http://www.w3.org/2000/svg"
            width="19.369"
            height="29.054"
            viewBox="0 0 19.369 29.054"
          >
            <rect
              id="Rectangle_3898"
              data-name="Rectangle 3898"
              width="19.369"
              height="25.825"
              rx="1.5"
              transform="translate(0 3.229)"
              fill="#0061a0"
            />
            <path
              id="Path_3984"
              data-name="Path 3984"
              d="M55.349,14.824a.808.808,0,1,1,.808-.805.808.808,0,0,1-.808.805m0-2.824a2.019,2.019,0,1,0,2.019,2.019A2.019,2.019,0,0,0,55.349,12"
              transform="translate(-45.664 -12)"
              fill="#0061a0"
            />
            <rect
              id="Rectangle_3899"
              data-name="Rectangle 3899"
              width="16.14"
              height="21.793"
              transform="translate(1.613 4.842)"
              fill="#0090da"
            />
            <path
              id="Path_3985"
              data-name="Path 3985"
              d="M45.528,24h-.805l-4.951,1.637a1.619,1.619,0,0,0-1.1,1.531v.463H51.581v-.463a1.619,1.619,0,0,0-1.1-1.531Z"
              transform="translate(-35.441 -20.368)"
              fill="#0061a0"
            />
            <circle
              id="Ellipse_1178"
              data-name="Ellipse 1178"
              cx="1.195"
              cy="1.195"
              r="1.195"
              transform="translate(4.594 14.923)"
              fill="#fff"
            />
            <circle
              id="Ellipse_1179"
              data-name="Ellipse 1179"
              cx="1.195"
              cy="1.195"
              r="1.195"
              transform="translate(8.489 14.923)"
              fill="#fff"
            />
            <circle
              id="Ellipse_1180"
              data-name="Ellipse 1180"
              cx="1.195"
              cy="1.195"
              r="1.195"
              transform="translate(12.384 14.923)"
              fill="#fff"
            />
          </svg>
        ),
        url: '/home/transactionHistory',
        currentPolicy,
        subtitle: 'View previous transactions',
      },
      {
        id: 10,
        name: 'Provider PreApproval',
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21">
            <path
              id="Icon_PolicyNote"
              data-name="Icon/PolicyNote"
              d="M19.8,17.824V3H4V19.8a2.783,2.783,0,0,0,2.673,2.965h.243a2.875,2.875,0,0,0,2.916-2.841v-2.1ZM7.646,6.706h8.505V8.435H7.646Zm0,6.176V11.153h8.505v1.729Zm18.226,7.412H13.722v.618a3.252,3.252,0,0,1-1.7,2.594H11.9A1.547,1.547,0,0,1,10.684,24H23.078a2.31,2.31,0,0,0,1.458-.494,3.021,3.021,0,0,0,1.458-2.594v-.618"
              transform="translate(-3.994 -3)"
              fill="#007abc"
            />
          </svg>
        ),
        url: '/home/providerPreApproval',
        currentPolicy,
        subtitle: 'View provider preapproval',
      },
    ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        serviceList={serviceList}
        policyData={currentPolicy}
      />
    );
  };
}

Services.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  currentPolicy: PropTypes.shape({}).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
  state: {},
});

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: dispatch.articles.save,
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
