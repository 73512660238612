import React, { useEffect, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, CustomInput, Spinner } from 'reactstrap';
import Template from './Templates/Home';

const Newsletter = ({ error, loading, success, message, data, onFormSubmit, defaultValues }) => {
  const { register, handleSubmit, setValue, reset } = useForm({ defaultValues });
  const [children, setChildren] = useState([]);

  useEffect(async () => {
    console.log(data?.children_below_eighteen?.length);
    reset({
      health: data?.interests?.includes(1),
      financial_wellness: data?.interests?.includes(2),
      family_and_community: data?.interests?.includes(3),
      childrens_education: data?.childrens_education,
      retirement: data?.retirement,
      property: data?.property,
      general_savings: data?.general_savings,
      children_count: data?.children_count,
    });
    const arr = [];
    for (let i = 0; i < data?.children_count; i += 1) {
      arr.push(`child${i}`);
    }
    await setChildren(arr);
  }, [data]);
  return (
    <Template pageTitle="Update my preferences">
      <div className="bg-g">
        <Container fluid className="full-width-page complaint-page max-container">
          <Row className="align-content-center">
            <Col className="m-auto col-md-12 col-xl-10">
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                {!!success && <Alert color="success">{message}</Alert>}
                {!!error && <Alert color="danger">{message}</Alert>}
                {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}
                <div>
                  <Row>
                    <Col className="col-12 col-md-6">
                      <h4 className="mb-4">My Interests</h4>
                      <FormGroup>
                        <Label for="exampleCheckbox">
                          MetLife would like to provide you with personalized information. Please select your preferred
                          interests:
                        </Label>
                        <div>
                          <CustomInput
                            type="checkbox"
                            id="exampleCustomCheckbox"
                            name="health"
                            label="Health"
                            innerRef={register()}
                          />
                          <CustomInput
                            type="checkbox"
                            id="exampleCustomCheckbox2"
                            name="financial_wellness"
                            label="Financial wellness"
                            innerRef={register()}
                          />
                          <CustomInput
                            type="checkbox"
                            id="exampleCustomCheckbox3"
                            name="family_and_community"
                            label="Family and community"
                            innerRef={register()}
                          />
                        </div>
                      </FormGroup>
                      <h4 className="mb-4">My Communication Preferences</h4>
                      <Label for="exampleCheckbox">
                        MetLife can send you information and offers based on your selected interests via E-MAIL and SMS.
                        Please choose your preferred option:
                      </Label>
                      <Label>Email</Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="send_email"
                            value="1"
                            defaultChecked={data?.send_email === 1}
                            innerRef={register()}
                            onInput={(e) => setValue('send_email', e.target.value)}
                          />
                          Yes, I agree to receiving newsletters and offers
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="send_email"
                            value="0"
                            defaultChecked={data?.send_email === 0}
                            innerRef={register()}
                            onInput={(e) => setValue('send_email', e.target.value)}
                          />
                          No, I do not want to receive newsletters and offers
                        </Label>
                      </FormGroup>
                      <Label className="mt-3">SMS</Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="send_sms"
                            value="1"
                            defaultChecked={data?.send_sms === 1}
                            innerRef={register()}
                            onInput={(e) => setValue('send_sms', e.target.value)}
                          />
                          Yes, I agree to receiving messages with information and offers
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="send_sms"
                            value="0"
                            defaultChecked={data?.send_sms === 0}
                            innerRef={register()}
                            onInput={(e) => setValue('send_sms', e.target.value)}
                          />
                          No, I do not want to receive messages with information and offers
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-sm-6">
                    <h4 className="mb-4">Customer Information</h4>
                      <Label for="exampleCheckbox">
                        let us know more about you so we can help archeive your goals
                      </Label>
                      <Label>Are you married?</Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="married"
                            value="1"
                            defaultChecked={data?.married === 1}
                            innerRef={register()}
                            onInput={(e) => setValue('married', e.target.value)}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="married"
                            value="0"
                            defaultChecked={data?.married === 0}
                            innerRef={register()}
                            onInput={(e) => setValue('married', e.target.value)}
                          />
                          No
                        </Label>
                      </FormGroup>
                      <h4 className="my-4">Saving goals</h4>
                      <Label className=""> How much do you think you need?</Label>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="number"
                          name="childrens_education"
                          id="childrens_education"
                          placeholder="Children's Education"
                          innerRef={register()}
                          onChange={(e) => setValue('childrens_education', e.target.value)}
                        />
                        <Label for="childrens_education">Children's Education</Label>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="number"
                          name="retirement"
                          id="retirement"
                          placeholder="Retirement"
                          innerRef={register()}
                          onChange={(e) => setValue('retirement', e.target.value)}
                        />
                        <Label for="retirement">Retirement</Label>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="number"
                          name="property"
                          id="property"
                          placeholder="Property"
                          innerRef={register()}
                          onChange={(e) => setValue('property', e.target.value)}
                        />
                        <Label for="property">Property</Label>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        {/* <Label for="email">Email</Label> */}
                        <Input
                          type="number"
                          name="general_savings"
                          id="general_savings"
                          placeholder="general_savings"
                          innerRef={register()}
                          onChange={(e) => setValue('general_savings', e.target.value)}
                        />
                        <Label for="general_savings">General Savings</Label>
                      </FormGroup>
                      <Label>How many children do you have below 18 years old?</Label>
                      <FormGroup className="form-label-group">
                        <Input
                          type="number"
                          name="children_count"
                          id="children_count"
                          placeholder="Children Below Eighteen"
                          innerRef={register()}
                          onChange={(e) => {
                            setValue('children_count', e.target.value);
                            const arr = [];
                            for (let i = 0; i < parseInt(e.target.value ?? 0, 10); i += 1) {
                              arr.push(`child${i}`);
                            }
                            setChildren(arr);
                          }}
                        />
                        <Label for="children_below_eighteen" />
                      </FormGroup>
                      {children?.length ? <Label>Which year were your children born?</Label> : ''}
                      {children?.map((item, index) => (
                        <FormGroup className="form-label-group" key={item}>
                          <Input
                            type="number"
                            placeholder="Child"
                            id={item}
                            name={item}
                            innerRef={register()}
                            onChange={(e) => setValue(item, e.target.value)}
                            defaultValue={data?.children_below_eighteen[index]}
                          />
                          <Label>Child</Label>
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                  <br />
                  <Button type="submit" color="primary" disabled={loading} className="mb-3">
                    {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </Template>
  );
};

Newsletter.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool,
  data: PropTypes.shape({
    interests: PropTypes.arrayOf(number),
    send_email: PropTypes.number,
    send_sms: PropTypes.number,
    married: PropTypes.number,
    childrens_education: PropTypes.string,
    retirement: PropTypes.string,
    property: PropTypes.string,
    general_savings: PropTypes.string,
    children_below_eighteen: PropTypes.arrayOf(PropTypes.string),
    children_count: PropTypes.number,
  }),
  defaultValues: PropTypes.shape({
    interests: PropTypes.arrayOf(number),
    send_email: PropTypes.number,
    send_sms: PropTypes.number,
    married: PropTypes.number,
    childrens_education: PropTypes.string,
    retirement: PropTypes.string,
    property: PropTypes.string,
    general_savings: PropTypes.string,
    children_below_eighteen: PropTypes.arrayOf(PropTypes.string),
    children_count: PropTypes.number,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

Newsletter.defaultProps = {
  message: null,
  error: null,
  success: null,
  data: [],
  loading: false,
  defaultValues: {},
};

export default withRouter(Newsletter);
