import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/ComplaintList';

class complaintList extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false, complaintListData: null };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { getComplaints } = this.props;
    const { success, data } = await getComplaints();
    if (success) {
      this.setState({ complaintListData: data });
    }
    this.setState({ loading: false });
  }

  /**
   * Render
   */
  render = () => {
    const { error, loading, success, message, complaintListData } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        message={message}
        complaintListData={complaintListData}
      />
    );
  };
}

complaintList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getComplaints: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  logOut: dispatch.member.logOut,
  me: dispatch.member.me,
  getComplaints: dispatch.member.getComplaints,
});

export default connect(mapStateToProps, mapDispatchToProps)(complaintList);
