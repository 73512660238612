import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  List,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Template from '../Templates/Home';
import Title from '../PolicyDetails/Title';
import InfoLabel from '../PolicyDetails/InfoLabel';
import DocumentItem from './DocumentItem';
import Loading from '../UI/Loading';

const ClaimDetails = ({ data, policyData, breadcrumb, download, loading, downloadEOB, showResubmit }) => {
  const [active, setActive] = useState('service');
  const getStatusString = (status) => {
    switch (status) {
      case 'Pending':
        return 'In Progress';
      case 'Submitted':
        return 'Submitted';
      case 'Rejected':
        return 'Rejected';
      case 'On Hold':
        return 'Suspended';
      case 'Approved':
        return 'Paid';
      case 'Drafted':
        return 'Draft';
      case 'Re Submitted':
        return 'Re-Submitted';
      case 'Partially Approved':
        return 'Partially Paid';
      case 'Payment Failed':
        return 'Payment Failed';
      case 'Payment Pending':
        return 'Payment Pending';
      default:
        return 'In Progress';
    }
  };

  const currencyByCountryId = (id) => {
    switch (id) {
      case 234:
        return 'AED';
      case 18:
        return 'BHD';
      case 166:
        return 'OMR';
      default:
        return '';
    }
  };

  /* eslint-disable */
  const numberFormat = (value, currency) => {
    if (currency) {
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: currency ?? currencyByCountryId(data?.country_id),
      }).format(value);
    }
  };

  return (
    <Template pageTitle="Claim Details" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={policyData.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData.expiryDate}
          >
            {(data?.status === 'On Hold' || data?.status === 'Rejected' || data?.status === 'Partially Approved') &&
            showResubmit &&
            data?.can_resubmit ? (
              <>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1 text-danger" />
                <small className="mr-2">Action Required</small>
                {data?.status === 'Payment Failed' ? (
                  <Button
                    color="primary"
                    tag={Link}
                    to={{
                      pathname: '/updatePaymentInfo',
                      state: {
                        data,
                      },
                    }}
                  >
                    Update Payment Info
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    tag={Link}
                    to={{
                      pathname: '/reSubmitClaim',
                      state: {
                        data,
                      },
                    }}
                  >
                    Re-Submit
                  </Button>
                )}
              </>
            ) : null}
          </Title>
        </Container>
        <section className="metLife-gray">
          {data ? (
            <Container fluid className="max-container">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label className="f-mlc-m display-3 tab-title">
                        {`Claim #${data?.reference_number ? data?.reference_number : data?.id}`}
                        <span className="badge badge-secondary ml-3">
                          <small>{getStatusString(data?.status)}</small>
                        </span>
                      </Label>
                    </Col>
                    <Col className="text-right">
                      {getStatusString(data?.status) === 'Approved' ? (
                        <a
                          className="pointer"
                          onClick={() => {
                            downloadEOB(data?.id);
                          }}
                        >
                          <svg
                            id="Icon_Download"
                            data-name="Icon/Download"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <rect id="Mask" width="24" height="24" fill="none" />
                            <path
                              id="Bottom"
                              d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                              transform="translate(10.125 17.636)"
                              fill="#007abc"
                            />
                            <path
                              id="Middle"
                              d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                              transform="translate(4.347 4)"
                              fill="#007abc"
                            />
                            <path
                              id="Arrow"
                              d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                              transform="translate(9.729 7.637)"
                              fill="#fff"
                              stroke="rgba(0,0,0,0)"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                            />
                            <path
                              id="Corner"
                              d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                              transform="translate(2 5.455)"
                              fill="#007abc"
                            />
                          </svg>
                          <Label className="pointer">Download EOB</Label>
                        </a>
                      ) : null}
                      {(getStatusString(data?.status) === 'Draft' ||
                        (getStatusString(data?.status) === 'Submitted' && data?.submission_type != 2)) &&
                      showResubmit ? (
                        <Link
                          to={{
                            pathname: '/editClaim',
                            state: {
                              data,
                            },
                          }}
                        >
                          <svg
                            id="Icon_PencilEdit"
                            data-name="Icon/PencilEdit"
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                          >
                            <rect id="Mask" width="24" height="24" fill="none" />
                            <g id="Icon_PencilEdit-2" data-name="Icon/PencilEdit" transform="translate(-0.5 0.514)">
                              <rect
                                id="Rectangle_95"
                                data-name="Rectangle 95"
                                width="3.4"
                                height="4.8"
                                transform="translate(16.195 4.391) rotate(-45)"
                                fill="#0090da"
                              />
                              <path
                                id="Path_3"
                                data-name="Path 3"
                                d="M4.9,15.591,18.6,2,22,5.4,8.4,19.088Zm2,4.9-3.4-3.4-.5,3.9Z"
                                fill="#007abc"
                              />
                            </g>
                          </svg>
                          <b>Edit</b>
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="search-provider-results">
                    <Col>
                      <div className="grid grid-2 info-list-wrapper">
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Policy Number" name={data?.policy?.ext_policy_number} />
                          </li>
                          <li>
                            <InfoLabel title="Policy Holder Name " name={data?.policy?.holder_name} />
                          </li>
                          <li>
                            <InfoLabel title="Claimed Amount" name={data?.claimed_amount_format ?? ''} />
                          </li>
                          <li>
                            <InfoLabel title="Approved Amount" name={data?.approved_amount_format ?? ''} />
                          </li>
                          <li>
                            <InfoLabel title="Denied Amount" name={data?.denied_amount_format ?? ''} />
                          </li>
                          {/* <li>
                            <InfoLabel title="Payment Reference" name={data?.payment_reference} />
                          </li> */}
                          {getStatusString(data?.status) === 'Suspended' ||
                          getStatusString(data?.status) === 'Rejected' ||
                          getStatusString(data?.status) === 'Partially Paid' ||
                          getStatusString(data?.status) === 'Paid' ? (
                            <li>
                              <Col className="text-right">
                                <a
                                  className="pointer"
                                  onClick={() => {
                                    downloadEOB(data?.id);
                                  }}
                                >
                                  <svg
                                    id="Icon_Download"
                                    data-name="Icon/Download"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <rect id="Mask" width="24" height="24" fill="none" />
                                    <path
                                      id="Bottom"
                                      d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                                      transform="translate(10.125 17.636)"
                                      fill="#007abc"
                                    />
                                    <path
                                      id="Middle"
                                      d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                                      transform="translate(4.347 4)"
                                      fill="#007abc"
                                    />
                                    <path
                                      id="Arrow"
                                      d="M0,2.725H2.269V0H3.18V2.725H5.454L2.725,5.453Z"
                                      transform="translate(9.729 7.637)"
                                      fill="#fff"
                                      stroke="rgba(0,0,0,0)"
                                      strokeMiterlimit="10"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Corner"
                                      d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                                      transform="translate(2 5.455)"
                                      fill="#007abc"
                                    />
                                  </svg>
                                  <Label className="pointer">Download EOB</Label>
                                </a>
                              </Col>
                            </li>
                          ) : null}
                        </List>
                        <List type="unstyled" className="info-list">
                          <li>
                            <InfoLabel title="Certificate Number" name={data?.certificate_number} />
                          </li>
                          <li>
                            <InfoLabel
                              title="Insured Name"
                              name={data?.dependent?.member_name || data?.user?.member_name}
                            />
                          </li>
                          {/* <li>
                            <InfoLabel title="Last Claim Service Date" name={data?.last_claim_service_date} />
                          </li> */}
                          {/* <li>
                            <InfoLabel title="Paid Date" name={data?.paid_date} />
                          </li> */}
                          <li>
                            <InfoLabel title="Payment Mode" name={data?.payment_mode} />
                          </li>
                          <li>
                            <InfoLabel title="Paid Amount" name={data?.payable_format} />
                          </li>
                          <li>
                            <InfoLabel title="Remarks" name={data?.remarks} />
                          </li>
                        </List>
                      </div>
                      <Nav className="w-100 tab-default mt-5 p-0 mb-3" tabs>
                        <NavItem>
                          <NavLink
                            className={active === 'service' ? 'active' : ''}
                            onClick={() => {
                              setActive('service');
                            }}
                          >
                            Service List
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={active === 'documents' ? 'active' : ''}
                            onClick={() => {
                              setActive('documents');
                            }}
                          >
                            Documents
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={active}>
                        <TabPane tabId="service">
                          {data?.service_list?.length ? (
                            data?.service_list?.map((service) => (
                              <>
                                <h4 className="">{service?.Treatment_Description}</h4>
                                <div className="grid grid-2 info-list-wrapper mb-3">
                                  <List type="unstyled" className="info-list">
                                    <li>
                                      <InfoLabel title="Date of Service" name={service?.Treatment_Date_From} />
                                    </li>
                                    <li>
                                      <InfoLabel
                                        title="Approved Amount"
                                        name={service?.Considered_Amount_Format ?? ''}
                                      />
                                    </li>
                                    <li>
                                      <InfoLabel title="Claimed Amount" name={service?.Claimed_Amount_Format ?? ''} />
                                    </li>
                                    <li>
                                      <InfoLabel title="Paid Amount" name={service?.Payable_Amount_Format ?? ''} />
                                    </li>
                                    <li>
                                      <InfoLabel title="Remarks" name={service?.Remarks} />
                                    </li>
                                  </List>
                                  <List type="unstyled" className="info-list">
                                    <li>
                                      <InfoLabel title="Unit" name={service?.Units} />
                                    </li>
                                    <li>
                                      <InfoLabel title="Denied Amount" name={service?.Denied_Amount_Format ?? ''} />
                                    </li>
                                    <li>
                                      <InfoLabel title="Patient Share" name={service?.Patient_Share_Format} />
                                    </li>
                                  </List>
                                </div>
                              </>
                            ))
                          ) : (
                            <h4>No services found.</h4>
                          )}
                        </TabPane>
                        <TabPane tabId="documents">
                          <Row>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.physician_notes}
                                label="Metlife claim form with physician notes/medical report"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.hospital_receipts}
                                label="Medical Provider Receipt"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.discharge_report}
                                label="Discharge report"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.proof_of_payment}
                                label="Proof of payment"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.lab_tests}
                                label="Results of lab tests, radiology"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.other_supporting_docs}
                                label="Other supporting documents"
                              />
                            </Col>
                            <Col md={6}>
                              <DocumentItem
                                download={download}
                                attachment={data?.attachments?.translated_docs}
                                label="Translated documents"
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          ) : loading ? (
            <Container fluid className="max-container">
              <Loading />
            </Container>
          ) : null}
        </section>
      </>
    </Template>
  );
};
ClaimDetails.propTypes = {
  showResubmit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    claimed_amount: PropTypes.string,
    status: PropTypes.string,
    denied_amount: PropTypes.string,
    approved_amount: PropTypes.string,
    currency: PropTypes.string,
    payment_reference: PropTypes.string,
    certificate_number: PropTypes.string,
    insured_name: PropTypes.string,
    last_claim_service_date: PropTypes.string,
    paid_date: PropTypes.string,
    payment_mode: PropTypes.string,
    remarks: PropTypes.string,
    date_of_service: PropTypes.string,
    paid_amount: PropTypes.string,
    payable: PropTypes.string,
    unit: PropTypes.string,
    derived_amount: PropTypes.string,
    patient_share: PropTypes.string,
    submission_date: PropTypes.string,
    reference_number: PropTypes.string,
    attachments: PropTypes.shape({
      physician_notes: PropTypes.arrayOf(PropTypes.shape()),
      hospital_receipts: PropTypes.arrayOf(PropTypes.shape()),
      discharge_report: PropTypes.arrayOf(PropTypes.shape()),
      proof_of_payment: PropTypes.arrayOf(PropTypes.shape()),
      lab_tests: PropTypes.arrayOf(PropTypes.shape()),
      other_supporting_docs: PropTypes.arrayOf(PropTypes.shape()),
      translated_docs: PropTypes.arrayOf(PropTypes.shape()),
    }),
    service_list: PropTypes.arrayOf(
      PropTypes.shape({
        Treatment_Description: PropTypes.string,
        Treatment_Date_From: PropTypes.string,
        Treatment_Date_To: PropTypes.string,
        Units: PropTypes.string,
        Exchange_Rate: PropTypes.string,
        Claimed_Amount: PropTypes.string,
        Considered_Amount: PropTypes.string,
        Denied_Amount: PropTypes.string,
        Patient_Share: PropTypes.string,
        Payable_Amount: PropTypes.string,
        Remarks: PropTypes.string,
      }),
    ),
    policy: PropTypes.shape({
      ext_policy_number: PropTypes.number,
      holder_name: PropTypes.string,
    }),
    dependent: PropTypes.shape({
      member_name: PropTypes.string,
    }),
    user: PropTypes.shape({
      member_name: PropTypes.string,
    }),
  }),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
  download: PropTypes.func.isRequired,
  downloadEOB: PropTypes.func.isRequired,
};

ClaimDetails.defaultProps = {
  data: {},
  policyData: {},
  breadcrumb: [],
};

export default withRouter(ClaimDetails);
