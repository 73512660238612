import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import Layout from '../components/PreApprovals/PreApprovalsDetails/PreApprovalDetails';
import Alert from '../components/UI/Alert';

class PreApprovalDetails extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getPreApprovalDetails();
  }

  /**
   * Get Details
   */
  getPreApprovalDetails = async () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      .replace(/\//g, '-');
    const { viewDetails, location } = this.props;
    this.setState((prev) => ({ ...prev, loading: true }));
    try {
      if (location?.state?.preApprovalId) {
        const { success, data } = await viewDetails(location?.state?.preApprovalId);
        if (success) {
          if (data?.status === 'Pending' && data?.approval_submission_date === formattedDate) {
            data.status = 'Submitted';
          }
          this.setState((prev) => ({ ...prev, data, loading: false }));
        }
        return success;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
    this.setState((prev) => ({ ...prev, loading: false }));
    return false;
  };

  downloadFile = async (filepath) => {
    const { download } = this.props;
    toast.promise(download({ file_path: filepath }),
      {
        loading: (
          <Alert message="Please wait while the file is downloading" isOpen />
        ),
        success: (pdf) => {
          if (pdf?.type) {
            this.setState((state) => ({ ...state, ecardDownload: false }));
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdf);
            link.download = '_blank';
            link.click();
            return (
              <Alert message="File has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download file'} />
          );
        },
        error: (
          <Alert message="Unable to download file" />
        ),
      });
  };

  /**
   * Render
   */
  render = () => {
    const { currentPolicy, location } = this.props;
    const { data, loading } = this.state;
    const breadcrumb = location?.state?.breadcrumb || [
      { label: 'Dashboard', to: '/home' },
      { label: 'Policy Details', to: '/home/policyDetails' },
      { label: 'Services', to: '/home/services' },
      { label: 'Pre-Approvals', to: '/preApprovals' },
      { label: 'Pre-Approval details', active: true },
    ];

    return (
      <Layout
        data={data}
        policyData={currentPolicy}
        loading={loading}
        breadcrumb={breadcrumb}
        download={this.downloadFile}
        showResubmit={location?.state?.showResubmit}
      />
    );
  };
}

PreApprovalDetails.propTypes = {
  viewDetails: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      preApprovalId: PropTypes.number.isRequired,
      showResubmit: PropTypes.bool.isRequired,
      breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({}).isRequired,
  download: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: dispatch.pre_approval.getPreApprovalDetails,
  download: dispatch.pre_approval.download,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreApprovalDetails);
