import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/VerifyAccount';

class VerifyAccount extends Component {
  constructor() {
    super();
    this.state = { error: null, success: null, loading: false };
  }

  componentDidMount() {
    this.getCountryList();
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (data) => {
    const { verifyAccount, history } = this.props;
    console.log(data, 'VERIFY ACCOUNT');
    this.setState({ success: null, error: null, loading: true });
    console.log('verifyAccount');
    try {
      const { success, message } = await verifyAccount(data);
      if (success) {
        this.setState({
          error: null,
          loading: false,
        });
        history.push('/addPhoneNumber');
      } else {
        this.setState({ success, error: message, loading: false });
      }
    } catch (error) {
      this.setState({ loading: false, success: null, error: error.message });
    }
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        const x = data
          ?.sort((a, b) => a.nationality.localeCompare(b.nationality))
          ?.filter((item) => item?.nationality !== '—');
        this.setState((prev) => ({ ...prev, countryList: x }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, dependentFound } = this.props;
    const { error, loading, success, countryList } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        onFormSubmit={this.onFormSubmit}
        countryList={countryList}
        dependentFound={dependentFound}
      />
    );
  };
}

VerifyAccount.propTypes = {
  userInput: PropTypes.shape({
    user_id: PropTypes.number,
  }).isRequired,
  verifyAccount: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getCountryList: PropTypes.func.isRequired,
  dependentFound: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: { user_id: state.member.userID } || null,
  dependentFound: state.member.dependentFound || 0,
});

const mapDispatchToProps = (dispatch) => ({
  verifyAccount: dispatch.member.verifyAccount,
  getCountryList: dispatch.claim.getCountryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
