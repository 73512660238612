import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';

const RegisterForm = ({ error, loading, success, message, onFormSubmit, defaultValues }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });

  return (
    <div className="bg-g">
      <Helmet>
        <title>Register</title>
      </Helmet>
      <Container className="register full-width-page">
        <Row className="text-center h-100 align-content-center">
          <Col className="text-center login-left">
            <img className="logo" src={logo} alt="" />
            <h1>Registration</h1>
            <h3>Enter the below information to complete the registration</h3>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              {!!success && <Alert color="success">{message}</Alert>}
              {!!error && <Alert color="danger">{message}</Alert>}
              {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}

              <FormGroup className="mt-3 form-label-group">
                {/* <Label for="policy_number">MedNet ID Number</Label> */}
                <Input
                  type="text"
                  name="policy_number"
                  id="policy_number"
                  placeholder="Policy Number"
                  disabled={loading}
                  invalid={!!errors.policy_number}
                  innerRef={register({ required: errorMessages.invalidPolicyNumber })}
                  onChange={(e) => setValue('policy_number', e.target.value)}
                />
                <Label>Policy Number</Label>
                {errors.policy_number && <p className="invalid-feedback">{errors.policy_number.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="text"
                  name="certificate_number"
                  id="certificate_id"
                  placeholder="Certificate Number"
                  disabled={loading}
                  invalid={!!errors.certificate_number}
                  innerRef={register({ required: errorMessages.invalidCertificateNumber })}
                  onChange={(e) => setValue('certificate_number', e.target.value)}
                />
                <Label>Certificate Number</Label>
                {errors.certificate_number && <p className="invalid-feedback">{errors.certificate_number.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                {/* <Label for="dob">Date of Birth</Label> */}
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder="dd/mm/yyyy"
                  className="form-control placeholder"
                  disabled={loading}
                  invalid={!!errors.dob}
                  innerRef={register({ required: errorMessages.dobMissing })}
                  onChange={(e) => {
                    setValue('dob', e.target.value);
                  }}
                  onClick={(e) => {
                    e.target.showPicker();
                    e.target.classList.add('active');
                  }}
                  // onFocus={(e) => {
                  //   e.target.type = 'date';
                  // }}
                  // onFocus="(this.type='date')"
                  // onBlur={(e) => {
                  //   if (!e.target.value) e.target.type = 'text';
                  // }}
                  style={{ width: '100% !important' }}
                />
                <Label>Date Of Birth</Label>
                {errors.dob && <p className="invalid-feedback">{errors.dob.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                {/* <Label for="email">Email</Label> */}
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  disabled={loading}
                  invalid={!!errors.email}
                  innerRef={register({
                    required: errorMessages.invalidEmail,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: errorMessages.invalidEmail,
                    },
                  })}
                  onChange={(e) => setValue('email', e.target.value)}
                />
                <Label>Email</Label>
                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
              </FormGroup>

              <Button color="primary" disabled={loading} style={{ width: '100%' }}>
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Register'}
              </Button>
            </Form>
            <div className="bottom-links justify-content-center  mt-5">
              Already Registered?
              <Link to="/login" className="text-primary">
                {' '}
                Log in
              </Link>
            </div>
            <div className="bottom-links justify-content-center  mt-3">
              <Link to="/faq" className="text-primary">
                Hi, Need Any Help?
              </Link>
            </div>
          </Col>
          <Col className="text-center login-right text-white p-5">
            <h2 className="">Introducing myMetLife Plus</h2>
            <p className="">
              myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
              your account, read the latest on health, fitness, and financial wellness, and access customer service-
              anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
              out-of- pocket expenses. Now, that's something to smile about.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

RegisterForm.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  error: null,
  success: null,
  message: null,
  loading: false,
  defaultValues: {},
};

export default withRouter(RegisterForm);
