import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../components/ProviderPreApproval/ProviderPreApproval';

class ProviderPreApproval extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getProviderPreApproval();
  }

  /**
   * Get all Pre approvals
   */
  getProviderPreApproval = async (payload = {}) => {
    const { getProviderPreApproval } = this.props;
    this.setState({ loading: true });
    const { success, data } = await getProviderPreApproval(payload);
    try {
      if (success) {
        this.setState((prev) => ({ ...prev, loading: false, success, data }));
      } else {
        this.setState((prev) => ({ ...prev, loading: false, success }));
      }
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loading: false }));
    }
    return [];
  };

  /**
   * Render
   */
  render = () => {
    // const { userInput } = this.props;
    const { data, loading } = this.state;
    const { currentPolicy, user } = this.props;
    const users = [user, ...currentPolicy?.dependents];
    return (
      <Layout
        data={data}
        policyData={currentPolicy}
        loading={loading}
        users={users}
        user={user}
        getProviderPreApproval={this.getProviderPreApproval}
      />
    );
  };
}

ProviderPreApproval.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  getProviderPreApproval: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
  user: state.member.user,
});

const mapDispatchToProps = (dispatch) => ({
  getProviderPreApproval: dispatch.member.getProviderPreApproval,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderPreApproval);
