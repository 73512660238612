import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import Template from '../../../Templates/Home';
import Title from '../../Title';
import DownloadCard from '../DownloadCard';
import Loading from '../../../UI/Loading';

const DownloadForm = ({ data, loading, downloadForms, policyData }) => {
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home/policyDetails' },
    { label: 'Services', to: '/home/services' },
    { label: 'Download Form', active: true },
  ];
  return (
    <Template pageTitle="Download Form" breadcrumb={breadcrumb}>
      <>
        <Container>
          <Title
            policyNumber={policyData?.policyNumber}
            certificateNumber={policyData?.certificateNumber}
            expiryDate={policyData?.expiryDate}
          />
        </Container>
        <section className="metLife-gray">
          <Container fluid className="max-container">
            {!loading ? (
              data.length ? (
                data?.map((element) => (
                  <div className="card mb-4">
                    <div className="card-body">
                      <Label className="f-mlc-m display-3 tab-title">{element?.category}</Label>
                      <hr />
                      <Row>
                        <Col className="grid grid-2 gg-default service-list">
                          {element?.attachments.map((item) => (
                            <DownloadCard item={item} downloadForms={downloadForms} key={item?.id} />
                          ))}
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))
              ) : (
                <Col lg="12">
                  <div className="no-results mt-3">
                    <Card
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <CardBody className="pt-4">
                        <CardTitle tag="h5">No forms available</CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              )
            ) : (
              <Loading />
            )}
          </Container>
        </section>
      </>
    </Template>
  );
};

DownloadForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  policyData: PropTypes.shape({
    policyNumber: PropTypes.number,
    certificateNumber: PropTypes.string,
    expiryDate: PropTypes.string,
  }).isRequired,
  downloadForms: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

DownloadForm.defaultProps = {
  data: [],
  loading: false,
};

export default withRouter(DownloadForm);
