import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import icon from '../../assets/images/group-medical.png';
import dependentsIcon from '../../assets/images/dependents-icon.png';

const DashboardCard = ({ data, action, downloadTOB, generateECard }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="">
      {/* <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        I want to
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a className="dropdown-item" href="#">
            View Policy Details
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            View Claims
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Search Providers
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            View Services
          </a>
        </li>
      </ul>
    </div> */}
      <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle caret>I want to..</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => action('/home/policyDetails', data)}> View Policy Details</DropdownItem>
          <DropdownItem onClick={() => action('/claims', data)}> View Claims</DropdownItem>
          <DropdownItem onClick={() => action('/home/searchProvider', data)}>Search Providers</DropdownItem>
          <DropdownItem onClick={() => action('/home/services', data)}> View Services</DropdownItem>
          {data?.status && <DropdownItem onClick={() => generateECard(data?.id)}> Download Medical Card</DropdownItem>}
          {data?.tob_file_uploaded_at ? <DropdownItem onClick={() => downloadTOB(data)}> Download TOB</DropdownItem> : ''}
        </DropdownMenu>
      </Dropdown>
      <Card onClick={() => action('/home/policyDetails', data)} id="policyCard">
        <CardHeader>
          <div className="d-flex align-items-start">
            {/* eslint-disable */}
            <img className="icon" src={icon} alt="" />
            <div>
              <h3>{data.policy_type}</h3>
              <h6>
                {data?.status ? (
                  <span className="badge badge-success">Active</span>
                ) : (
                  <span className="badge badge-danger">Expired</span>
                )}
              </h6>
            </div>
            <div>
              <span className="policy-number">{`#${data?.ext_policy_number}`}</span>
            </div>
            <div className="border-left pl-3 ml-3">
              <a className="btn btn-outline-primary btn-add-claim " onClick={() => action('/addClaim', data)} id="claimBtn">
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <path id="Icon_Plus" data-name="Icon/Plus" d="M12,5H7V0H5V5H0V7H5v5H7V7h5V5Z" fill="#007ABC" />
                </svg>
                New Claim
              </a>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6" sm="12">
              <div className="dependents">
                <img className="icon" src={dependentsIcon} alt="" />
                <span>{`${data.total_dependents} Dependant(s)`}</span>
              </div>
            </Col>
            <Col className="policy-details" md="6" sm="12">
              <div>
                <h4>{data.member_name}</h4>
                <h6>INSURED NAME</h6>
              </div>
              <div className="">
                <h4>{data.expiry_date ? moment(data?.expiry_date).format('MMM DD, Y') : ''}</h4>
                <h6>EXPIRY DATE</h6>
              </div>
              <div className="">
                <h4>{data.employer_name}</h4>
                <h6>EMPLOYER NAME</h6>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* <Card onClick={() => action(data)}>
      <Row className="p-2">
        <Col>
          <CardTitle className="text-secondary">Policy Number</CardTitle>
          <CardSubtitle className="text-primary">{data.policy_number}</CardSubtitle>
        </Col>
        <Col>
          <CardTitle className="text-secondary">Insured Name</CardTitle>
          <CardSubtitle className="text-primary">{data.holder_name}</CardSubtitle>
        </Col>
      </Row>
      <Row className="p-2">
        <Col>
          <CardTitle className="text-secondary">Active Dependance</CardTitle>
          <CardSubtitle className="text-primary">{data.total_dependants}</CardSubtitle>
        </Col>
        <Col>
          <CardTitle className="text-secondary">Employer Name</CardTitle>
          <CardSubtitle className="text-primary">{`${data.first_name} ${data.last_name}`}</CardSubtitle>
        </Col>
      </Row>
      <hr />
      <Row className="p-2">
        <Col>
          <CardTitle className="text-secondary">
            Status
            <span className="text-primary">{data.status || 'Active'}</span>
          </CardTitle>
        </Col>
        <Col>
          <CardTitle className="text-secondary">
            Expiry Date
            <span className="text-primary">{data.expiry_date}</span>
          </CardTitle>
        </Col>
      </Row>
    </Card> */}
    </div>
  );
};

DashboardCard.propTypes = {
  data: PropTypes.shape({
    ext_policy_number: PropTypes.string,
    member_name: PropTypes.string,
    total_dependents: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    status: PropTypes.number,
    expiry_date: PropTypes.string,
    policy_type: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
    }),
    id: PropTypes.number,
    employer_name: PropTypes.string,
    tob_file_uploaded_at: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
  downloadTOB: PropTypes.func.isRequired,
  generateECard: PropTypes.func.isRequired,
};

DashboardCard.defaultProps = {
  data: {},
};

export default DashboardCard;
