import NodeRSA from 'node-rsa';

// Encrypt the password using the public key
const Encryption = {
  encrypt: (publicKeyData, payload) => {
    const publicKey = Buffer.from(publicKeyData, 'base64').toString();
    const key = new NodeRSA(publicKey);
    return key.encrypt(payload, 'base64');
  },
};

export default Encryption;
