import React, { useState } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Row, Nav, NavItem, NavLink, TabContent, TabPane, Col, Container } from 'reactstrap';
// import { Toaster } from 'react-hot-toast';
import Template from '../Templates/Home';
import MemberDetails from './MemberDetails/MemberDetails';
import DependentInfo from './DependentInfo/DependentInfo';
// import BeneficiaryInfo from './BeneficiaryInfo/BeneficiaryInfo';
// import Underwriting from './Underwriting/Underwriting';
import Title from './Title';
import MemberBenefits from './MemberBenefits/MemberBenefits';

const PolicyDetails = ({ generateECard, data, downloadTOB, memberCoverageDetails }) => {
  const [active, setActive] = useState('a');
  const breadcrumb = [
    { label: 'Dashboard', to: '/home' },
    { label: 'Policy Details', to: '/home', active: true },
  ];
  return (
    <Template pageTitle="Policy Details" breadcrumb={breadcrumb}>
      <>
        <Container fluid className="max-container">
          <Title
            policyNumber={data.policyNumber}
            certificateNumber={data?.certificateNumber}
            expiryDate={data.expiryDate}
          >
            <>
              <Link
                to={{
                  pathname: '/home/searchProvider',
                }}
                className="btn btn-outline-primary mr-3"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
                  <path
                    id="Icon_Search"
                    data-name="Icon/Search"
                    d="M-1.713,16h0L0,14.32-4.6,9.813a6.062,6.062,0,0,0,.51-6.44A6.292,6.292,0,0,0-9.7,0a6.306,6.306,0,0,0-5.945,4.139,6.123,6.123,0,0,0,2.007,6.877,6.4,6.4,0,0,0,3.943,1.359,6.385,6.385,0,0,0,3.333-.936L-1.713,16ZM-9.7,2.376A3.854,3.854,0,0,1-5.813,6.188,3.854,3.854,0,0,1-9.7,10a3.858,3.858,0,0,1-3.884-3.812A3.853,3.853,0,0,1-9.7,2.376Z"
                    transform="translate(15.999 0)"
                    fill="#007abc"
                  />
                </svg>
                Search Providers
              </Link>
              {window.location.pathname !== '/home/services' ? (
                <Link
                  to={{
                    pathname: '/home/services',
                  }}
                  className="btn btn-outline-primary mr-3"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.688" viewBox="0 0 16 16.688">
                    <path
                      id="icon_settings"
                      data-name="icon/settings"
                      d="M14.938,10.348a2.488,2.488,0,0,1-1.287-2.027,2.483,2.483,0,0,1,1.29-2.027c.448-.3.909-.575,1.372-.854L14.786,2.8c-.47.261-.939.522-1.423.76a2.487,2.487,0,0,1-2.4.1A2.491,2.491,0,0,1,9.85,1.534C9.815,1.026,9.807.513,9.8,0H6.75C6.74.513,6.731,1.026,6.7,1.534A2.489,2.489,0,0,1,5.586,3.667a2.487,2.487,0,0,1-2.4-.1c-.443-.219-.877-.458-1.308-.7L.352,5.511c.424.255.846.508,1.257.784A2.482,2.482,0,0,1,2.9,8.321a2.487,2.487,0,0,1-1.29,2.027c-.417.28-.847.538-1.279.8l1.525,2.644c.44-.246.879-.488,1.33-.709a2.485,2.485,0,0,1,2.4-.1A2.481,2.481,0,0,1,6.7,15.1c.034.527.044,1.057.052,1.584H9.8c.009-.522.018-1.057.052-1.584a2.484,2.484,0,0,1,1.112-2.13,2.486,2.486,0,0,1,2.4.1c.489.239.968.508,1.446.772l1.523-2.639C15.862,10.934,15.392,10.653,14.938,10.348Zm-6.607.083a2.086,2.086,0,1,1,2.086-2.086A2.086,2.086,0,0,1,8.331,10.432Z"
                      transform="translate(-0.33)"
                      fill="#117abc"
                    />
                  </svg>
                  Services
                </Link>
              ) : (
                ''
              )}
              <Link
                to={{
                  pathname: '/claims',
                }}
                className="btn btn-primary mr-3"
              >
                <svg
                  id="Icon_Quote"
                  data-name="Icon/Quote"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                >
                  <g id="Icon_Quote-2" data-name="Icon/Quote">
                    <path
                      id="Path"
                      d="M1.5,0A1.561,1.561,0,0,0,0,1.6V2H3V1.6A1.561,1.561,0,0,0,1.5,0"
                      transform="translate(0 1)"
                      fill="#007abc"
                    />
                    <path
                      id="Path-2"
                      data-name="Path"
                      d="M2,2.4V2.1A2.005,2.005,0,0,0,.1,0H11a3.1,3.1,0,0,1,3,2.5V15l-2-2.1L10,15,8,12.9,6,15,4,12.9,2,15Z"
                      transform="translate(2)"
                      fill="#fff"
                    />
                    <rect
                      id="Bar_2"
                      data-name="Bar 2"
                      width="6"
                      height="1.2"
                      transform="translate(7 6.8)"
                      fill="#007abc"
                    />
                    <rect
                      id="Bar_1"
                      data-name="Bar 1"
                      width="6"
                      height="1.2"
                      transform="translate(7 4)"
                      fill="#007abc"
                    />
                  </g>
                </svg>
                claims
              </Link>
            </>
          </Title>
        </Container>
        <section className="bg-light policy-details-content metLife-gray">
          <div className="container-fluid max-container">
            {/* <Title policyNumber={data.policyNumber} expiryDate={data.expiryDate} /> */}
            <Row>
              <Col className="">
                <div className="card policy-details-tab">
                  <Nav className="w-100" tabs>
                    <NavItem>
                      <NavLink
                        className={active === 'a' ? 'active' : ''}
                        onClick={() => {
                          setActive('a');
                        }}
                      >
                        Member Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={active === 'b' ? 'active' : ''}
                        onClick={() => {
                          setActive('b');
                        }}
                      >
                        Dependant Info
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={active === 'c' ? 'active' : ''}
                        onClick={() => {
                          setActive('c');
                        }}
                      >
                        Underwriting
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={active === 'd' ? 'active' : ''}
                        onClick={() => {
                          setActive('d');
                        }}
                      >
                        Beneficiary Info
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={active === 'e' ? 'active' : ''}
                        onClick={() => {
                          setActive('e');
                        }}
                      >
                        Member Coverages
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={active} className="py-4 w-100">
                    <TabPane tabId="a">
                      <Row>
                        {data?.member_details ? (
                          <MemberDetails
                            generateECard={generateECard}
                            data={data?.member_details}
                            downloadTOB={downloadTOB}
                            status={data?.status}
                          />
                        ) : (
                          'Loading..'
                        )}
                      </Row>
                    </TabPane>
                    <TabPane tabId="b">
                      <Row>
                        <DependentInfo
                          data={data?.dependents}
                          generateECard={generateECard}
                          downloadTOB={downloadTOB}
                          status={data?.status}
                        />
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="c">
                      <Row>
                        <Underwriting data={data?.underwriting} />
                      </Row>
                    </TabPane>
                    <TabPane tabId="d">
                      <Row>
                        <BeneficiaryInfo data={data?.beneficiary} policyNumber={data.policyNumber} />
                      </Row>
                    </TabPane> */}
                    <TabPane tabId="e">
                      <Row>
                        <MemberBenefits
                          data={memberCoverageDetails}
                          downloadTOB={downloadTOB}
                          tob_file_uploaded_at={data?.member_details?.tob_file_uploaded_at}
                        />
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </>
    </Template>
  );
};

PolicyDetails.propTypes = {
  data: PropTypes.shape({
    expiryDate: PropTypes.string,
    policyNumber: PropTypes.number,
    status: PropTypes.number,
    certificateNumber: PropTypes.string,
    member_details: PropTypes.shape({
      tob_file_uploaded_at: PropTypes.string,
    }),
    dependents: arrayOf(PropTypes.shape({})),
    underwriting: PropTypes.shape({}),
    beneficiary: PropTypes.shape({}),
  }),
  generateECard: PropTypes.func.isRequired,
  downloadTOB: PropTypes.func.isRequired,
  memberCoverageDetails: PropTypes.arrayOf(PropTypes.shape({})),
};

PolicyDetails.defaultProps = {
  data: {},
  memberCoverageDetails: [],
};

export default withRouter(PolicyDetails);
