import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withRouter, useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';
import Loading from './UI/Loading';

const PasswordReset = ({
  error,
  loading,
  success,
  message,
  data,
  passwordValidation,
  onFormSubmit,
  defaultValues,
  setEmail,
}) => {
  const { param } = useParams();
  const { email, token } = JSON.parse(Buffer.from(param, 'base64').toString('ascii'));

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: { ...defaultValues, email },
  });

  console.log(email, token);

  useEffect(() => {
    setEmail(param);
  }, [param]);

  const onSubmit = (formData) => {
    console.log('Password Reset');
    const postData = { ...formData, token };
    onFormSubmit(postData);
  };

  const transformContact = (messageText) => {
    const position = messageText.toLowerCase().search('contact');
    return position >= 0 ? (
      <span className="text-align-center mt-3">
        {messageText.substring(0, position)}
        <Link to="/contactUs">contact</Link>
        {messageText.substring(position + 7)}
      </span>
    ) : (
      messageText
    );
  };

  return (
    <Container className="forgot-password full-width-page">
      <Row className="text-center h-100 align-content-center">
        <Col className="text-center login-left">
          <img className="logo" src={logo} alt="" />
          <h1>Forgot Password</h1>
          {!loading ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              {!!success && message && <Alert color="success">{message}</Alert>}
              {!!error && message && <Alert color="danger">{transformContact(message)}</Alert>}
              {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}
              <FormGroup className="mt-3 form-label-group">
                <Input type="text" value={email} name="user" id="user" disabled />
                <Input
                  type="hidden"
                  name="email"
                  id="email"
                  placeholder="jane@doe.com"
                  invalid={!!errors.email}
                  innerRef={register({ required: errorMessages.missingEmail })}
                  onChange={(e) => setValue('email', e.target.value)}
                />
                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="select"
                  name="security_question"
                  id="security_question"
                  placeholder="security_question"
                  disabled={loading}
                  invalid={!!errors.security_question}
                  innerRef={register({ required: errorMessages.securityQuestion })}
                  onChange={(e) => setValue('security_question', e.target.value)}
                >
                  <option value="question" disabled>
                    Question
                  </option>
                  {data.data?.map((element) => (
                    <option value={element.id} key={element.id}>
                      {element.question}
                    </option>
                  ))}
                </Input>
                <Label>Security Question</Label>
                {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="text"
                  name="answer"
                  id="answer"
                  placeholder="Secret answer"
                  disabled={loading}
                  invalid={!!errors.answer}
                  innerRef={register({ required: errorMessages.answer })}
                  onChange={(e) => setValue('answer', e.target.value)}
                />
                <Label>Secret answer</Label>
                {errors.answer && <p className="invalid-feedback">{errors.answer.message}</p>}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  placeholder="New Password"
                  disabled={loading}
                  invalid={!!errors.password}
                  innerRef={register({
                    required: errorMessages.missingPassword,
                    pattern: {
                      value: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                      message:
                        'Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one number',
                    },
                  })}
                  onChange={(e) => setValue('password', e.target.value)}
                />
                <Label>New Password</Label>
                {errors.password ? (
                  <p className="invalid-feedback">{errors.password.message}</p>
                ) : (
                  <p style={{ fontSize: '12px', textAlign: 'left', padding: '2px 8px' }}>
                    Your new password must be 8-13 alphanumeric characters (A-Z, a-z, 0-9) long, contain at least one
                    number
                  </p>
                )}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="password"
                  name="conpassword"
                  id="conpassword"
                  autoComplete="off"
                  placeholder="Confirm password"
                  disabled={loading}
                  invalid={!!errors.conpassword || !!passwordValidation}
                  innerRef={register({
                    required: errorMessages.missingPassword,
                  })}
                  onChange={(e) => setValue('conpassword', e.target.value)}
                />
                <Label>Confirm Password</Label>
                {(errors.conpassword || !!passwordValidation) && (
                  <p className="invalid-feedback">{errors.conpassword?.message || passwordValidation}</p>
                )}
              </FormGroup>
              <Button color="primary" disabled={loading} style={{ width: '100%' }}>
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Submit'}
              </Button>
            </Form>
          ) : (
            <Loading />
          )}
        </Col>
        <Col className="text-center login-right text-white p-5">
          <h2 className="">Introducing myMetLife Plus</h2>
          <p className="">
            myMetLife Plus is a one-stop online customer service center with everything MetLife has to offer! Manage
            your account, read the latest on health, fitness, and financial wellness, and access customer service-
            anytime, anywhere from your computer, phone or tablet, providers - with plans designed to help manage
            out-of- pocket expenses. Now, that's something to smile about.
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

PasswordReset.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  passwordValidation: PropTypes.string,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  defaultValues: PropTypes.shape({}),
  onFormSubmit: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
};

PasswordReset.defaultProps = {
  error: false,
  success: false,
  message: '',
  passwordValidation: '',
  data: {},
  loading: false,
  defaultValues: {},
};

export default withRouter(PasswordReset);
