import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';

const ClaimMessage = ({ memsSuccess, ucrn }) => (
  <Row className="text-center m-0">
    <Col className="pb-5">
      <Label className="msg-box h-auto">
        {memsSuccess ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 50 50"
            version="1.1"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
          >
            <circle cx="25" cy="25" r="25" fill="#99D538" />
            <path
              fill="none"
              stroke="#FFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M38 15L22 33 12 25"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            enableBackground="new 0 0 32 32"
            overflow="visible"
            viewBox="0 0 32 32"
          >
            <g>
              <g>
                <circle cx="16" cy="16" r="16" fill="#D72828" />
                <path fill="#E6E6E6" d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z" />
              </g>
            </g>
          </svg>
        )}
      </Label>
      <p>
        <h2>{memsSuccess ? 'Your claim has been successfully submitted' : 'Claim submission failed'}</h2>
      </p>
      <p>
        {memsSuccess
          ? `Your claim reference number is: ${ucrn}`
          : ucrn
          ? `The claim is drafted with tracking number #${ucrn}`
          : 'There was issue with submitting claim'}
      </p>
      {/* <p>
        <h4>
          {memsSuccess
            ? 'We have received your claim and we will start process your request.'
            : 'We have saved your claim as draft. You can resubmit your claim from draft.'}
        </h4>
      </p> */}
      <Link to="/claims">Back to claims</Link>
    </Col>
  </Row>
);

ClaimMessage.propTypes = {
  memsSuccess: PropTypes.bool.isRequired,
  ucrn: PropTypes.string.isRequired,
};

ClaimMessage.defaultProps = {};

export default withRouter(ClaimMessage);
