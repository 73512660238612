import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';
import ViewBenefits from './ViewBenefits';

const MemberBenefits = ({ data, downloadTOB, tob_file_uploaded_at }) => {
  const [viewBenefits, setViewBenefits] = useState(false);

  const toggle = () => {
    setViewBenefits(!viewBenefits);
  };

  return (
    <Col className="member-coverage">
      {data?.length
        ? data?.map((ben) => (
            <div key={ben?.package_name}>
              <Row className="justify-content-between mt-3 row">
                <Col md="5">
                  <Label className="f-mlc-m display-3 tab-title">Member Coverages</Label>
                </Col>
                <Col>
                  {tob_file_uploaded_at ? (
                    <span
                      className="float-right view-benefits font-weight-bold pointer"
                      onClick={() => {
                        downloadTOB();
                      }}
                    >
                      <svg
                        id="Icon_Download"
                        data-name="Icon/Download"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <rect id="Mask" width="24" height="24" fill="none" />
                        <path
                          id="Bottom"
                          d="M7.33,0H2.135V.463A2.182,2.182,0,0,1,.041,2.727H9.67A2.236,2.236,0,0,0,11.875.463V0Z"
                          transform="translate(10.125 17.636)"
                          fill="#007abc"
                        />
                        <path
                          id="Middle"
                          d="M2.2,2.182A2.091,2.091,0,0,0,.147,0H10.939a3.011,3.011,0,0,1,3.027,2.995l.05,6.344v2.353H6.737v.673a2.225,2.225,0,0,1-2.2,2.182H4.41A2.194,2.194,0,0,1,2.2,12.364V10.237"
                          transform="translate(4.347 4)"
                          fill="#007abc"
                        />
                        <path
                          id="Arrow"
                          d="M11551,22147.725h2.269V22145h.911v2.725h2.274l-2.729,2.729Z"
                          transform="translate(-11541.272 -22137.363)"
                          fill="#fff"
                          stroke="rgba(0,0,0,0)"
                          strokeWidth="1"
                        />
                        <path
                          id="Corner"
                          d="M1.637,0A1.681,1.681,0,0,0,0,1.7v.477H3.273V1.7A1.68,1.68,0,0,0,1.637,0"
                          transform="translate(2 5.455)"
                          fill="#007abc"
                        />
                      </svg>
                      Download TOB
                    </span>
                  ) : null}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="12">
                  <div className="card p-4 shadow-none border mb-4 rounded-0">
                    <h3 className="f-mlc-m sub-title">
                      {ben?.package_name}
                      {/* <span
                        className="float-right view-benefits"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          toggle();
                        }}
                      >
                        <svg
                          id="Icon_FindVision"
                          data-name="Icon/FindVision"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                        >
                          <rect id="Mask" width="17" height="17" fill="none" />
                          <path
                            id="Eye"
                            d="M17,5.571,12.75,1.632a6.351,6.351,0,0,0-8.5,0L0,5.571,4.25,9.51a6.351,6.351,0,0,0,8.5,0Z"
                            transform="translate(0 2.929)"
                            fill="#007abc"
                          />
                          <path
                            id="Inner_Dot"
                            data-name="Inner Dot"
                            d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4"
                            transform="translate(4.5 4.5)"
                            fill="#fff"
                          />
                          <path
                            id="Dot"
                            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2"
                            transform="translate(6.5 6.5)"
                            fill="#007abc"
                          />
                        </svg>
                        View Benefits
                      </span> */}
                    </h3>
                    <div className="grid grid-2 info-list-wrapper">
                      <ul className="info-list list-unstyled">
                        <li>
                          <div className="d-flex justify-content-between">
                            <Label>Coverage Type</Label>
                            <h4>{ben?.coverage_type}</h4>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <Label>Status</Label>
                            <h4>{ben?.status}</h4>
                          </div>
                        </li>
                      </ul>
                      <ul className="info-list list-unstyled">
                        <li>
                          <div className="d-flex justify-content-between">
                            <Label>Effective Date</Label>
                            <h4>{ben?.effective_date}</h4>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <Label>Plan Name</Label>
                            <h4>{ben?.plan_name}</h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <ViewBenefits data={ben?.benefits} toggle={toggle} titleName={ben?.package_name} isOpen={viewBenefits} />
            </div>
          ))
        : 'No data found.'}
    </Col>
  );
};

MemberBenefits.propTypes = {
  downloadTOB: PropTypes.func.isRequired,
  tob_file_uploaded_at: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      package_name: string,
      coverage_type: string,
      status: string,
      plan_name: string,
      effective_date: string,
      benefits: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
};

MemberBenefits.defaultProps = {
  data: null,
  tob_file_uploaded_at: null,
};

export default withRouter(MemberBenefits);
