// import moment from 'moment';
import initialState from '../store/articles';
// import pagination from '../lib/pagination';
import userApi from '../api/UserApi';

export default {
  namespace: 'pre_approval',

  /**
   *  Initial state
   */
  state: initialState,

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * get bank list
     */
    async getCurrencyList() {
      return userApi.getCurrencyList();
    },

    /**
     * get bank list
     */
    async getCountryList() {
      return userApi.getCountryList();
    },

    /**
     * Add PreApproval
     */
    async addPreApproval(payload) {
      return userApi.addPreApproval(payload);
    },

    /**
     * Get PreApproval List
     */
    async getPreApprovals([policyId, filters]) {
      return userApi.getPreApprovals(policyId, filters);
    },

    /**
     * Get PreApproval Details
     */
    async getPreApprovalDetails(id) {
      return userApi.getPreApprovalDetails(id);
    },

    /**
     * Edit PreApproval
     */
    async editPreApproval(payload) {
      return userApi.editPreApproval(payload);
    },

    /**
     * Re Submit Pre Approval
     */
    async reSubmitPreApproval(payload) {
      return userApi.reSubmitPreApproval(payload);
    },

    /**
     * Downlload file
     */
    async download(payload) {
      return userApi.download(payload);
    },
  }),

  /**
   * Reducers
   */
  reducers: {
    /**
     * Replace list in store
     * @param {obj} state
     * @param {obj} payload
     */
    replace(state, payload) {
      return { ...state, phoneNumber: payload.phone_number, userID: payload.user_id };
    },
  },
};
