import React, { Fragment, useEffect, useState } from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import InputField, { isValidPhoneNumber } from 'react-phone-number-input/input';
import validator from 'validator';
import { errorMessages } from '../../../constants/messages';
import AddBankDetails from './AddBankDetails';
import BankDetailsCard from './BankDetailsCard';

const StepOne = ({
  loading,
  onConfirm,
  onContinue,
  data,
  bankList,
  currencyList,
  countryList,
  addBankAccount,
  deleteBank,
  bankSelected,
  bankDeleteLoading,
}) => {
  const { register, control, handleSubmit, errors, setValue, getValues, reset } = useForm();
  // const [editContact, setEditContact] = useState(false);
  const [addBankDetails, setAddBankDetails] = useState(false);
  const [showBanks, setSetShowBanks] = useState(true);
  const [num, setNum] = useState(null);
  const [email, setEmail] = useState(null);

  /* eslint-disable */
  const handlePaymentMethod = (e) => {
    if (e.target.value === '0') {
      setSetShowBanks(true);
    } else {
      setSetShowBanks(false);
    }
    setValue('payment_method', e.target.value);
  };

  useEffect(() => {
    setNum(
      data?.member_details?.phone_number
        ? `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`
        : '',
    );
    setEmail(data?.member_details?.email);
    reset({
      email: data?.member_details?.email,
      mobile_number: data?.member_details?.phone_number
        ? `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`
        : '',
    });
    // if (data?.member_details?.payment_method === 0) {
    //   setSetShowBanks(true);
    // } else {
    //   setSetShowBanks(false);
    // }
  }, [data]);

  const toggle = () => {
    setAddBankDetails(!addBankDetails);
  };

  const handleSetPayload = (formdata, draft = false) => {
    if (draft) {
      const numValidation = num ? isValidPhoneNumber(num) : false;
      const emailValidation = validator.isEmail(getValues().email);
      onContinue(formdata, numValidation && emailValidation);
    } else {
      onConfirm(formdata);
    }
  };

  const changePatient = (patient_id) => {
    if (patient_id !== '0') {
      const patient = data?.dependents?.filter((item) => item?.id === parseInt(patient_id, 10))[0];
      if (patient?.phone_number) {
        setValue('mobile_number', `+${patient?.phone_number.replace('-', '').replace('+', '')}`);
        setNum(`+${patient?.phone_number.replace('-', '').replace('+', '')}`);
      }
      if (patient?.email) {
        setValue('email', patient?.email);
        setEmail(patient?.email);
      }
    } else {
      setNum(`+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`);
      setEmail(data?.member_details?.email);
      setValue('mobile_number', `+${data?.member_details?.phone_number.replace('-', '').replace('+', '')}`);
      setValue('email', data?.member_details?.email);
    }
    setValue('patient', patient_id);
  };

  return (
    <Fragment>
      <Row className="justify-content-between mt-3">
        <Col md="5">
          <Label className="f-mlc-m display-3 tab-title">Personal details</Label>
        </Col>
      </Row>
      <hr />
      <p>Before submitting claim information, please check the following details.</p>
      <Form onSubmit={handleSubmit((values) => handleSetPayload(values, true))} className="mt-3">
        <FormGroup>
          <Row className="justify-content-between mt-3">
            <Col md="6">
              <div className="form-label-group form-group mb-0">
                <Input
                  type="select"
                  name="patient"
                  id="patient"
                  placeholder="Your answer"
                  disabled={loading}
                  invalid={!!errors.patient}
                  innerRef={register()}
                  onChange={(e) => {
                    changePatient(e.target.value);
                  }}
                >
                  <option key="principal_member" value={0}>
                    {data?.member_details?.member_name}
                  </option>
                  {data?.dependents?.map((patient) => (
                    <option key={patient.id} value={patient.id}>
                      {patient.member_name}
                    </option>
                  ))}
                </Input>
                <Label for="patient">Who is the patient.</Label>
                {errors.dependent && <p className="invalid-feedback">{errors.patient.message}</p>}
              </div>
            </Col>
          </Row>

          <h3 className="mt-5 f-ns-m">How should we contact you about the claim?</h3>
          <p>This will only be used for this specific claim unless you update it from the profile page.</p>
          <Row className="align-items-start mt-3">
            <Col className="col-12 col-md-5 mb-4 mb-md-auto">
              <div className="form-label-group form-group mb-0">
                <Controller
                  name="mobile_number"
                  id="mobile_number"
                  control={control}
                  innerRef={register({ required: errorMessages.missingData })}
                  render={() => (
                    <InputField
                      type="text"
                      // readOnly={!editContact}
                      name="mobile_number"
                      id="mobile_number"
                      international
                      withCountryCallingCode
                      className={`form-control ${num ? (!isValidPhoneNumber(num) ? 'is-invalid' : '') : 'is-invalid'}`}
                      disabled={loading}
                      invalid={!!errors.mobile_number}
                      value={num}
                      onChange={(e) => {
                        setNum(e);
                        setValue('mobile_number', e);
                      }}
                    />
                  )}
                />
                <Label for="mobile_number">Mobile Number</Label>
                <p className="phone-info">
                  Please enter your phone number prefixed with the country dialing code, without appending any zeroes.
                  Eg.(+971 123 1 1234)
                </p>
                {errors.dependent && <p className="invalid-feedback">{errors.mobile_number.message}</p>}
              </div>
            </Col>
            <Col className="col-12 col-md-5 mb-3 mb-md-auto">
              <div className="form-label-group form-group mb-0">
                <Input
                  type="text"
                  // readOnly={!editContact}
                  name="email"
                  id="email"
                  placeholder="email address"
                  disabled={loading}
                  invalid={!validator.isEmail(email ?? '')}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => {
                    setValue('email', e.target.value);
                    setEmail(e.target.value);
                  }}
                />
                <Label for="email">Email Address</Label>
                <p className="invalid-feedback">{`${!validator.isEmail(email ?? '') ? 'Invalid Email' : ''}`}</p>
              </div>
            </Col>
            {/* <Col>
              <Button
                className="px-3 claim-contact-edit"
                type="button"
                onClick={() => setEditContact(!editContact)}
                color="outline-primary"
              >
                <svg
                  className="mr-0"
                  id="Icon_PencilEdit"
                  data-name="Icon/PencilEdit"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.519"
                  height="22.519"
                  viewBox="0 0 22.519 22.519"
                >
                  <rect
                    id="Rectangle_95"
                    data-name="Rectangle 95"
                    width="4.03"
                    height="5.689"
                    transform="translate(15.639 2.849) rotate(-45)"
                    fill="#0090da"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M5.252,18.107,21.489,2l4.03,4.027L9.4,22.253Zm2.37,5.8-4.03-4.027L3,24.5Z"
                    transform="translate(-3 -1.984)"
                    fill="#007abc"
                  />
                </svg>
              </Button>
            </Col> */}
          </Row>

          <h3 className="mt-5 f-ns-m">How would you like to receive your reimbursement?</h3>
          <p>I would like to receive my medical claim(s) reimbursement via: </p>
          <FormGroup tag="fieldset">
            {/* <Row>
              <Col className="d-flex">
                <FormGroup check className="mr-4">
                  <Label check>
                    <Input
                      type="radio"
                      name="payment_method"
                      value="0"
                      // defaultChecked={data?.member_details?.payment_method === 0}
                      defaultChecked
                      disabled={loading}
                      invalid={!!errors.payment_method}
                      innerRef={register({ required: errorMessages.missingData })}
                      onInput={handlePaymentMethod}
                    />
                    Wire Transfer
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="payment_method"
                      value="1"
                      defaultChecked={data?.member_details?.payment_method === 1}
                      disabled={loading}
                      invalid={!!errors.payment_method}
                      innerRef={register({ required: errorMessages.missingData })}
                      onInput={handlePaymentMethod}
                    />
                    Cheque
                  </Label>
                  {errors.bank_id && <p className="invalid-feedback">Please Chose a Payment Method</p>}
                </FormGroup>
              </Col>
            </Row> */}
          </FormGroup>
          {showBanks ? (
            <div>
              <Row>
                <Col>
                  <h3>Bank Details</h3>
                </Col>
                <Col className="text-right text-primary font-weight-bold">
                  <a onClick={toggle}>+ Add Bank Details</a>
                </Col>
              </Row>
              <Row>
                <p className="mt-1 ml-3">
                  (Note: If the country of Bank you choose is United Arab Emirates, Qatar or Kuwait, please choose a bank account having IBAN number.)
                </p>
              </Row>
              <Row>
                {data?.member_details?.bank_accounts?.map((x) => (
                  <BankDetailsCard
                    register={register}
                    setValue={setValue}
                    key={x.id}
                    data={x}
                    defaultChecked={x?.id?.toString() === data?.member_details?.bank_id?.toString()}
                    deleteBank={deleteBank}
                    loading={bankDeleteLoading}
                  />
                ))}
              </Row>
              {!bankSelected && (
                <Col className="text-right">
                  <p style={{ color: 'red' }}> Please choose a bank account</p>
                </Col>
              )}
            </div>
          ) : (
            <Row>
              <Col>
                <FormGroup className="form-label-group">
                  <Input
                    type="payment_to"
                    readOnly
                    defaultValue={data?.member_details?.member_name}
                    name="payment_to"
                    id="payment_to"
                    placeholder="payment_to address"
                    disabled={loading}
                    invalid={!!errors.payment_to}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('payment_to', e.target.value)}
                  />
                  <Label for="payment_to">Paid To</Label>
                  {errors.dependent && <p className="invalid-feedback">{errors.email.message}</p>}
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="text-right">
              <Button color="outline-primary" className="mt-4">
                Continue
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
      <AddBankDetails
        toggle={toggle}
        isOpen={addBankDetails}
        bankList={bankList}
        currencyList={currencyList}
        countryList={countryList}
        onConfirm={addBankAccount}
        loading={loading}
        user={data?.member_details}
      />
    </Fragment>
  );
};

StepOne.propTypes = {
  data: shape({
    dependents: arrayOf(
      shape({
        name: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.string,
      }),
    ),
    user: shape({
      email: PropTypes.string,
      phone_number: PropTypes.string,
      bank_accounts: PropTypes.arrayOf({}),
    }),
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  bankList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addBankAccount: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  bankSelected: PropTypes.bool.isRequired,
  bankDeleteLoading: PropTypes.bool,
};

StepOne.defaultProps = {
  loading: false,
  bankDeleteLoading: false,
};

export default withRouter(StepOne);
