import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Alert from '../components/UI/Alert';
import Layout from '../components/PreApprovals/AddPreApprovals/AddPreApproval';

class AddPreApproval extends Component {
  constructor() {
    super();
    this.state = {
      active: '1',
      defaultValues: {},
    };
  }

  componentDidMount() {
    this.getCountryList();
    this.getCurrencyList();
  }

  /**
   * get currency list
   */
  getCurrencyList = async () => {
    const { getCurrencyList } = this.props;
    try {
      const { success, data } = await getCurrencyList();
      if (success) {
        this.setState((prev) => ({ ...prev, currencyList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   * get currency list
   */
  getCountryList = async () => {
    const { getCountryList } = this.props;
    try {
      const { success, data } = await getCountryList();
      if (success) {
        this.setState((prev) => ({ ...prev, countryList: data }));
      }
      return success;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  /**
   *
   *Active Tab
   */
  setActive = (tab) => {
    const { firstStepPassed } = this.state;
    if (tab === '2' && firstStepPassed) {
      this.setState((pre) => ({ ...pre, active: tab }));
    } else if (tab === '1') {
      this.setState((pre) => ({ ...pre, active: tab }));
    }
  };

  /**
   * On Form Submission
   */
  viewDetails = async (data) => {
    const { viewDetails, history } = this.props;
    try {
      const success = await viewDetails(data);
      console.log(success);
      history.push('/preApprovals/details');
    } catch (error) {
      console.log('ERROR');
    }
  };

  onContinue = async (data, validate) => {
    if (validate) {
      this.setState((pre) => ({
        ...pre,
        newPreApp: { ...pre?.newPreApp, ...data },
        active: '2',
        firstStepPassed: true,
      }));
    }
  };

  onFileSelected = (fileList, type) => {
    if (fileList.length) {
      this.setState((pre) => ({
        ...pre,
        newPreApp: { ...pre.newPreApp, [type]: fileList },
      }));
    }
  };

  // Submit new Pre approval : from step 2
  onSubmit = async (data) => {
    const { newPreApp } = this.state;
    const { currentPolicy, addPreApproval, history } = this.props;

    const payload = {
      ...newPreApp,
      policy_id: currentPolicy?.id,
      // submission_type: 'member_portal',
      ...data,
    };

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key === 'claimed_amount') {
        payload[key] = payload[key].replaceAll(',', '');
      }
      if (typeof payload[key] === 'object' && payload[key]) {
        Object.keys(payload[key]).forEach((subKey) => {
          formData.append(`${key}[${subKey}]`, payload[key][subKey]);
        });
        return;
      }
      formData.append(key, payload[key]);
    });

    // POST REQUEST
    this.setState((pre) => ({ ...pre, loading: true }));
    const preApprovalData = await addPreApproval(formData);
    if (preApprovalData?.success) {
      if (data?.status === 'draft') {
        toast.custom(<Alert message={`Pre-Approval - Drafted successfully. Tracking number is ${preApprovalData?.reference_number}`} />);
        history.push('/preApprovals');
      } else {
        this.setState((pre) => ({
          ...pre,
          loading: false,
          success: true,
          mems_success: preApprovalData?.mems_success,
          ucrn: preApprovalData?.reference_number,
          draft: data?.status === 'draft',
        }));
      }
    } else {
      this.setState((pre) => ({ ...pre, loading: false, success: false, message: preApprovalData?.message }));
      toast.custom(<Alert message={preApprovalData?.message} />);
    }
  };

  /**
   * Render
   */
  render = () => {
    const { defaultValues, countryList, currencyList, active, loading, success, mems_success, ucrn, draft, message } =
      this.state;
    const { currentPolicy } = this.props;

    return (
      <Layout
        loading={loading}
        data={defaultValues}
        onContinue={this.onContinue}
        onSubmit={this.onSubmit}
        currencyList={currencyList}
        countryList={countryList}
        policyData={currentPolicy}
        active={active}
        setActive={this.setActive}
        success={success}
        memsSuccess={mems_success}
        referenceNumber={ucrn}
        draft={draft}
        message={message}
        onFileSelected={this.onFileSelected}
      />
    );
  };
}

AddPreApproval.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  viewDetails: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentPolicy: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  getCurrencyList: PropTypes.func.isRequired,
  getCountryList: PropTypes.func.isRequired,
  addPreApproval: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
});

const mapDispatchToProps = (dispatch) => ({
  viewDetails: dispatch.policy.viewDetails,
  getCurrencyList: dispatch.pre_approval.getCurrencyList,
  getCountryList: dispatch.pre_approval.getCountryList,
  addPreApproval: dispatch.pre_approval.addPreApproval,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPreApproval);
