import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Alert, Form, FormGroup, Input, Button, CardText, Label, Spinner } from 'reactstrap';
import { errorMessages } from '../constants/messages';
import logo from '../assets/images/myMetlife-logo.png';
import Footer from './UI/Footer';

const VerifyAccount = ({ error, loading, success, onFormSubmit, defaultValues, countryList, dependentFound }) => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues });

  const transformContact = (messageText) => {
    const position = messageText.toLowerCase().search('contact');
    return position >= 0 ? (
      <span className="text-align-center mt-3">
        {messageText.substring(0, position)}
        <Link to="/contactUs">contact</Link>
        {messageText.substring(position + 7)}
      </span>
    ) : (
      messageText
    );
  };

  return (
    <Container className="mt-3 account-registration">
      <Row className="">
        <Col className="">
          <img className="logo" src={logo} alt="" />
        </Col>
      </Row>
      <Row className="page-header-section">
        <Col className="">
          <h1>Account Registration</h1>
          <p>Please fill up the fields as per MetLife Record.</p>
        </Col>
      </Row>
      <div className="container-fluid">
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          {!!success && <Alert color="success">{success}</Alert>}
          {!!error && <Alert color="danger">{transformContact(error)}</Alert>}
          {/* {!!loading && <Alert color="warning">Loading...</Alert>} */}
          <Row className="mt-5 pb-5">
            <Col md="6">
              <Input
                type="hidden"
                name="user_id"
                id="user_id"
                innerRef={register({ required: errorMessages.missingData })}
              />
              {dependentFound ? (
                <FormGroup className="form-label-group">
                  <Input
                    type="number"
                    name="dependents_count"
                    id="dependents_count"
                    placeholder="Your answer"
                    disabled={loading}
                    invalid={!!errors.dependents_count}
                    innerRef={register({ required: errorMessages.missingData })}
                    onChange={(e) => setValue('dependents_count', e.target.value)}
                  />
                  <Label for="dependents">
                    How many dependents are under your certificate number are insured with MetLife?
                  </Label>
                  {errors.dependents_count && <p className="invalid-feedback">{errors.dependents_count.message}</p>}
                </FormGroup>
              ) : null}
              <FormGroup className="form-label-group">
                <Input
                  type="select"
                  name="nationality_country_code"
                  id="nationality_country_code"
                  placeholder="Your answer"
                  disabled={loading}
                  invalid={!!errors.nationality_country_code}
                  innerRef={register({ required: errorMessages.missingData, min: {} })}
                  onChange={(e) => setValue('nationality_country_code', e.target.value)}
                >
                  <option disabled>select</option>
                  {countryList?.map((country) => (
                    <option key={country?.iso2} value={country?.iso2}>
                      {country?.nationality}
                    </option>
                  ))}
                </Input>
                <Label for="nationality">Primary member's nationality as per MerLife record</Label>
                {errors.nationality_country_code && (
                  <p className="invalid-feedback">{errors.nationality_country_code.message}</p>
                )}
              </FormGroup>
              <FormGroup className="form-label-group">
                <Input
                  type="select"
                  name="primary_mem_gender"
                  id="primary_mem_gender"
                  placeholder="Your answer"
                  disabled={loading}
                  invalid={!!errors.primary_mem_gender}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('primary_mem_gender', e.target.value)}
                >
                  <option disabled selected>
                    select
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="0">Other</option>
                </Input>
                <Label for="primary_mem_gender">Primary Member's Gender as per MetLife Record</Label>
                {errors.primary_mem_gender && <p className="invalid-feedback">{errors.primary_mem_gender.message}</p>}
              </FormGroup>
            </Col>
            <Col>
              {dependentFound ? (
                <>
                  <FormGroup className="form-label-group">
                    <Input
                      type="select"
                      name="dependent_gender"
                      id="dependent_gender"
                      placeholder="Your answer"
                      disabled={loading}
                      invalid={!!errors.dependent_gender}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => setValue('dependent_gender', e.target.value)}
                    >
                      <option disabled selected>
                        select
                      </option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="0">Other</option>
                    </Input>
                    <Label for="dependent_gender">Any of Dependant's Gender as per MetLife Record.</Label>
                    {errors.dependent_gender && <p className="invalid-feedback">{errors.dependent_gender.message}</p>}
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Input
                      type="date"
                      name="dependent_dob"
                      id="dependent_dob"
                      placeholder="dd/mm/yyyy"
                      className="form-control placeholder"
                      disabled={loading}
                      invalid={!!errors.dependent_dob}
                      innerRef={register({ required: errorMessages.missingData })}
                      onChange={(e) => {
                        setValue('dependent_dob', e.target.value);
                      }}
                      onClick={(e) => {
                        e.target.showPicker();
                        e.target.classList.add('active');
                      }}
                    />
                    <Label for="dependent_dob">Any of Dependent's DOB as per MetLife Record</Label>
                    {errors.dependent_dob && <p className="invalid-feedback">{errors.dependent_dob.message}</p>}
                  </FormGroup>
                </>
              ) : null}
              <FormGroup className="form-label-group">
                <Input
                  type="select"
                  name="marital_status"
                  id="marital_status"
                  placeholder="Your answer"
                  disabled={loading}
                  invalid={!!errors.marital_status}
                  innerRef={register({ required: errorMessages.missingData })}
                  onChange={(e) => setValue('marital_status', e.target.value)}
                >
                  <option disabled selected>
                    select
                  </option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </Input>
                <Label for="marital_status">Primary Member's Marital Status as per MetLife Record.</Label>
                {errors.marital_status && <p className="invalid-feedback">{errors.marital_status.message}</p>}
              </FormGroup>
            </Col>
            <Col md="12 mt-5" className="text-center">
              <Button type="submit" color="primary" disabled={loading} className="mb-5">
                {loading ?
                  (
                    <>
                      <Spinner
                        size="sm"
                        color="light"
                      />
                      <span>&nbsp;Loading</span>
                    </>
                  ) : 'Continue'}
              </Button>
              <CardText>
                By proceding you are accepting the
                <br />
                <a href="/" target="blank">
                  End User License Agreement(EULA)
                </a>
              </CardText>
              <a className="text-primary">Hi, Need Any help?</a>
            </Col>
          </Row>
        </Form>
      </div>
      <Footer />
    </Container>
  );
};

VerifyAccount.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.string,
  defaultValues: PropTypes.shape({
    otp: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      iso2: PropTypes.string,
      nationality: PropTypes.string,
    }),
  ),
  dependentFound: PropTypes.number,
};

VerifyAccount.defaultProps = {
  error: null,
  success: null,
  loading: false,
  countryList: [],
  dependentFound: 0,
  defaultValues: {},
};

export default withRouter(VerifyAccount);
