import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Layout from '../components/PolicyDetails/SearchProvider/SearchProvider';
import Alert from '../components/UI/Alert';

class SearchProvider extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      success: null,
      loading: false,
      data: [],
      cities: [],
      countries: [],
      facilities: [],
      specialities: [],
      filteredCities: [],
      filteredRegions: [],
      filteredFacilities: [],
      filteredSpecialities: [],
      urlData: { country_id: 234 },
    };
  }

  /* eslint-disable */
  getGeoLoc = () =>
    new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            console.log(`Unable to retrieve your location due to ${error.code}: ${error.message}`);
            resolve({});
          },
          {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 27000,
          },
        );
      } else {
        console.log('Geolocation services are not supported by your web browser.');
        resolve({});
      }
    });

  async componentDidMount() {
    const { getProviders, getCities, getCountries, getSpecialities, getFacilities, getAllRegions, user } = this.props;
    this.setState({ loading: true });
    const location = await this.getGeoLoc();
    const countries = await getCountries();
    const { data, total_count } = await getProviders({ country_id: user?.country_id, ...location });
    const cities = await getCities();
    console.log(user?.country_id, cities);
    const specialities = await getSpecialities();
    const facilities = await getFacilities();
    const regions = await getAllRegions();
    this.setState({ cities: cities?.data, regions: regions?.data });
    await this.setState({
      data,
      total_count,
      loading: false,
      cities: cities?.data,
      countries: countries?.data,
      specialities: specialities?.data,
      facilities: facilities?.data,
      regions: regions?.data,
    });
    this.filter(user?.country_id ?? '', 'country_id');
  }

  /**
   * On Form Submission
   */
  onFormSubmit = async (values, submit = true, download = false) => {
    console.log('search providers');
    const { getProviders, downloadProviders, user } = this.props;
    const location = await this.getGeoLoc();

    let obj = values;
    Object.keys(values).every(async (e) => {
      if (values[e]) {
        obj[e] = values[e];
      } else {
        delete obj[e];
      }
      return e;
    });

    if (download) {
      // toast('The file will be downloaded shortly." />, {
      //   duration: 3000,
      // });
      // const pdf = await downloadProviders(obj);
      toast.promise(downloadProviders(obj), {
        loading: (
          <Alert message="Please wait while the Providers is downloading" isOpen />
        ),
        success: (pdf) => {
          console.log(typeof pdf.type);
          if (pdf?.type === 'application/pdf') {
            // const file = new Blob([...pdf], { type: 'application/pdf' });
            // const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            const pdfFile = new Blob([pdf]);
            // if (typeof pdf === 'object') {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfFile);
            link.download = 'Providers.pdf';
            link.click();
            return (
              <Alert message="Providers has downloaded" />
            );
          }
          this.setState((state) => ({ ...state, ecardDownload: false }));
          return (
            <Alert message={pdf?.message || 'Unable to download Providers'} />
          );
        },
        error: (
          <div className="custom-alert alert alert-danger alert-dismissible">
            <span className="alert-icon">!</span>
            <h3>Error:</h3>
            Unable to download Providers
            <button type="button" className="close">×</button>
          </div>
        ),
      });
      // }
    } else {
      this.setState({ success: null, error: null, loading: true, data: [], total_count: null });
      if (!submit) {
        obj = {
          country_id: user?.country_id,
        };
      }

      const data = await getProviders({ ...obj, ...location });
      await this.setState({
        success: data?.success,
        error: null,
        loading: false,
        data: data?.data,
        total_count: data?.total_count,
      });
    }
  };

  filter = async (value, type) => {
    const { cities, regions, facilities, specialities } = this.state;
    if (type === 'country_id') {
      const filteredCities = value ? cities?.filter((e) => e?.country_id?.toString() === value.toString()) : cities;
      const filteredRegions = value ? regions?.filter((e) => e?.country_id?.toString() === value.toString()) : regions;
      const filteredFacilities = value ? facilities?.filter((e) => e?.country_id.toString() === value.toString()) : facilities;
      const filteredSpecialities = value
        ? specialities?.filter((e) => e?.country_id.toString() === value.toString())
        : specialities;
      this.setState({ filteredCities, filteredRegions, filteredFacilities, filteredSpecialities });
    }
    if (type === 'city_id') {
      const filteredRegions = value ? regions?.filter((e) => e?.city_id?.toString() === value) : regions;
      this.setState((pre) => ({ ...pre, filteredRegions }));
    }
    // if (value) {
    //   await this.setState({ urlData: { ...urlData, [`${type}`]: value } });
    // } else {
    //   await delete urlData[`${type}`];
    //   await this.setState({ urlData: { ...urlData } });
    // }
  };

  getCities = async (id) => {
    const { getCities } = this.props;
    const { data } = await getCities(id);
    this.setState({ cities: data });
  };

  /**
   * Render
   */
  render = () => {
    const { userInput, currentPolicy, user } = this.props;
    const {
      error,
      loading,
      success,
      data,
      total_count,
      filteredCities,
      filteredRegions,
      countries,
      filteredSpecialities,
      filteredFacilities,
    } = this.state;

    const benefits = [
      {
        id: 1,
        name: 'Dental',
      },
      {
        id: 2,
        name: 'Optical',
      },
      {
        id: 3,
        name: 'maternity',
      },
      {
        id: 4,
        name: 'alternative',
      },
      {
        id: 5,
        name: ' medicine',
      },
      {
        id: 6,
        name: 'psychiatry',
      },
    ];

    return (
      <Layout
        error={error}
        loading={loading}
        success={success}
        defaultValues={userInput}
        policyData={currentPolicy}
        data={data}
        user={user}
        totalCount={total_count}
        filter={this.filter}
        cities={filteredCities}
        countries={countries}
        specialities={filteredSpecialities}
        facilities={filteredFacilities}
        regions={filteredRegions}
        benefits={benefits}
        onFormSubmit={this.onFormSubmit}
      />
    );
  };
}

SearchProvider.propTypes = {
  userInput: PropTypes.shape({}).isRequired,
  getProviders: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getSpecialities: PropTypes.func.isRequired,
  getFacilities: PropTypes.func.isRequired,
  getAllRegions: PropTypes.func.isRequired,
  downloadProviders: PropTypes.func.isRequired,
  currentPolicy: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  userInput: state.articles.userInput || {},
  currentPolicy: state.policy.currentPolicy || {},
  history: {},
  user: state.member.user || {},
});

const mapDispatchToProps = (dispatch) => ({
  getProviders: dispatch.policy.getProviders,
  getCities: dispatch.policy.getCities,
  getCountries: dispatch.member.getProviderCountries,
  getSpecialities: dispatch.policy.getSpecialities,
  getFacilities: dispatch.policy.getFacilities,
  downloadProviders: dispatch.policy.downloadProviders,
  getAllRegions: dispatch.policy.getAllRegions,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchProvider);
