// import AsyncStorage from '@react-native-community/async-storage';
// import NodeRSA from 'node-rsa';
import useBrowserFingerprint from '../DeviceFingerPrint';
import BaseApi from './BaseApi';

class UserApi extends BaseApi {
  async login(data) {
    const browserFingerPrint = await useBrowserFingerprint();
    const payload = data;
    payload.browser_finger_print = browserFingerPrint;
    return this.publicApi()
      .post('loginV2', payload)
      .then(
        (res) => {
          if (res.data.success) {
            const { token } = res.data;
            localStorage.setItem('token', token);
          }
          return res.data;
        },
        (error) => {
          throw error;
        },
      );
  }

  async verifyLoginOTP(data) {
    const browserFingerPrint = await useBrowserFingerprint();
    const payload = data;
    payload.browser_finger_print = browserFingerPrint;
    return this.publicApi()
      .post('verify-login-otpV2', payload)
      .then(
        (res) => {
          if (res.data.success) {
            const { token } = res.data;
            localStorage.setItem('token', token);
          }
          return res.data;
        },
        (error) => {
          throw error;
        },
      );
  }

  resendLoginOTP(data) {
    return this.publicApi()
      .post('/resend-login-otp-v2', data)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  updatePassword(data) {
    return this.publicApi()
      .post('/update-password-v2', data)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getPolicies() {
    return this.authenticatedApi()
      .get('user-policies')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getAllQuestions(email = null) {
    const url = email ? `get-all-questions/${email}` : 'get-all-questions';
    return this.publicApi()
      .get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getPolicyDetails(policyData) {
    return this.authenticatedApi()
      .get(`policy/${policyData.id}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  recoverUsername(data) {
    return this.publicApi()
      .post('forgot-email', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  photoUpload(data) {
    return this.authenticatedApi(true)
      .post('photo-upload', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getAllMembers(policyNumber) {
    return this.authenticatedApi()
      .get(`get-all-members/${policyNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getProviders(data) {
    let URL = 'providers';
    if (data) {
      const objectKeys = Object.keys(data);
      objectKeys.forEach((element, index) => {
        if (index !== 0) {
          URL = `${URL}&${element}=${data[element]}`;
        } else {
          URL = `${URL}?${element}=${data[element]}`;
        }
      });
    }
    return this.authenticatedApi()
      .get(URL)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getCities(id) {
    return this.authenticatedApi()
      .get(id ? `all-cities/${id}` : 'all-cities')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getCountries() {
    return this.authenticatedApi()
      .get('all-countries')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getProviderCountries() {
    return this.authenticatedApi()
      .get('provider-countries')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getFacilities() {
    return this.publicApi()
      .get('all-facility-types')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  downloadProviders(data) {
    // return this.authenticatedApi(false, true)
    //   .get('download-providers')
    //   .then((res) => res.data)
    //   .catch((error) => {
    //     throw error;
    //   });
    let URL = 'download-providers';
    console.log(data);
    if (data) {
      const objectKeys = Object.keys(data);
      objectKeys.forEach((element, index) => {
        if (index !== 0) {
          URL = `${URL}&${element}=${data[element]}`;
        } else {
          URL = `${URL}?${element}=${data[element]}`;
        }
      });
    }
    return this.blobApi()
      .get(URL)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getSpecialities() {
    return this.publicApi()
      .get('all-specialities')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getProfile(id) {
    const URL = 'profile';
    return this.authenticatedApi()
      .get(id ? `${URL}?dependent_id=${id}` : URL)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateUserProfile(data) {
    return this.authenticatedApi()
      .put('profile', data)
      .then((res) => res?.data)
      .catch((error) => {
        throw error;
      });
  }

  updatePersonalDetails(data) {
    return this.authenticatedApi()
      .put('profile/personal-details', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateWorkAndResidentialDetails(data) {
    return this.authenticatedApi()
      .put('profile/work-residential-details', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateVisaInformation(data) {
    return this.authenticatedApi()
      .put('profile/visa-information', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateSponsorDetails(data) {
    return this.authenticatedApi()
      .put('profile/sponsor-details', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getDependents() {
    return this.authenticatedApi()
      .get('dependents')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateSalaryInformation(data) {
    return this.authenticatedApi()
      .put('profile/salary-information', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateRIClaims(data) {
    return this.authenticatedApi()
      .put('profile/ri-claims', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  generateECard(id, policyId) {
    let URL = 'generate-ecard';
    if (id) {
      URL = `${URL}/${id}`;
    }
    URL = `${URL}?policy_id=${policyId}`;
    return this.blobApi()
      .get(URL)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  downloadEOB(claim_id) {
    const timestamp = Date.now();
    return this.blobApi()
      .get(`download-eob?claim_id=${claim_id}&ref=${timestamp}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  downloadCertificate(type, id) {
    console.log(type);
    let URL = '';
    if (type === 1) {
      URL = 'life-insurance';
    }
    if (type === 2) {
      URL = 'medical-insurance';
    }
    if (type === 3) {
      URL = 'temporary-medical-insurance';
    }
    if (type === 4) {
      URL = 'medical-replacement';
    }
    if (type === 5) {
      URL = 'haad-medical-insurance';
    }
    if (id !== null) {
      URL = `${URL}?dependent_id=${id}`;
    }
    if (URL) {
      return this.blobApi()
        .get(URL)
        .then((res) => res.data)
        .catch((error) => {
          throw error;
        });
    }
    return false;
  }

  getVideosAndManuals(type) {
    return this.authenticatedApi()
      .get(`videos-and-manuals/${type}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getComplaints() {
    return this.authenticatedApi()
      .get('/get-complaints')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  downloadForms(type) {
    return this.blobApi()
      .get(`download-forms/${type}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getAllForms() {
    return this.authenticatedApi()
      .get('forms')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getMemberCoverageDetails(policy_id) {
    return this.authenticatedApi()
      .post('get-member-coverage', { policy_id })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getFAQ() {
    if (localStorage.getItem('token')) {
      return this.authenticatedApi()
        .get('V2/faq')
        .then((res) => res.data)
        .catch((error) => {
          throw error;
        });
    }
    return this.publicApi()
      .get('V2/faq')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  submitNewsletter(data) {
    if (!localStorage.getItem('token')) {
      return this.publicApi()
        .post('newsletter', data)
        .then((res) => res.data)
        .catch((error) => {
          throw error;
        });
    }
    return this.authenticatedApi()
      .post('newsletter', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getNewsletter() {
    if (localStorage.getItem('token')) {
      return this.authenticatedApi()
        .get('newsletter')
        .then((res) => res.data)
        .catch((error) => {
          throw error;
        });
    }
    return true;
  }

  getProviderPreApproval(payload = {}) {
    let URL = payload?.dID ? `authorizations/${payload?.dID}` : 'authorizations';
    if (payload?.from_date) {
      URL = `${URL}${URL?.includes('?') ? '&' : '?'}from_date=${payload?.from_date}`;
    }
    if (payload?.to_date) {
      URL = `${URL}${URL?.includes('?') ? '&' : '?'}to_date=${payload?.to_date}`;
    }
    return this.authenticatedApi()
      .get(URL)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getProviderPreApprovalDetails(id) {
    console.log(id);
    return this.authenticatedApi()
      .get(`authorizations/${id}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getTermsAndConditions() {
    return this.publicApi()
      .get('claim-submission-disclaimer/AE')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getAllRegions() {
    return this.authenticatedApi()
      .get('all-regions')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateLoginCredentials(data) {
    return this.authenticatedApi()
      .put('profile/login-credentials-v2', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getStates() {
    return this.publicApi()
      .get('states')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  getMyProfile() {
    return this.authenticatedApi()
      .get('me')
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  userCreate(data) {
    return this.publicApi()
      .post('register', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  validateID(data) {
    return this.publicApi()
      .post('register', data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  updateProfile(data) {
    return this.authenticatedApi()
      .put('/update-profile', data)
      .then((res) => res.data);
  }

  updateProfilePic(data) {
    return this.authenticatedApi()
      .post('me/upload-profile-pic', data)
      .then((res) => res.data);
  }

  removeProfilePic() {
    return this.authenticatedApi()
      .post('me/remove-profile-pic')
      .then((res) => res.data);
  }

  changePassword(data) {
    return this.authenticatedApi()
      .post('/me/change-password', data)
      .then((res) => res.data);
  }

  forgotPassword(data) {
    return this.publicApi()
      .post('/forgot-password', data)
      .then((res) => res.data);
  }

  setNewPassword(data) {
    return this.publicApi()
      .post('/set-new-password-v2', data)
      .then((res) => res.data);
  }

  resendVerificationCode() {
    return this.authenticatedApi()
      .get('/resend-verify-email')
      .then((res) => res.data);
  }

  resetPassword(data) {
    return this.publicApi()
      .post('/reset-password-v2', data)
      .then((res) => res.data);
  }

  userVerification(data) {
    return this.publicApi()
      .post('/verify-email', data)
      .then((res) => res.data);
  }

  verifyOTP(data) {
    return this.publicApi()
      .post('/verify-otp', data)
      .then(
        (res) => {
          if (res.data.success) {
            const { token } = res.data;
            if (token) localStorage.setItem('token', token);
          }
          return res.data;
        },
        (error) => {
          throw error;
        },
      );
  }

  verifyAccount(data) {
    return this.publicApi()
      .post('/verify-account', data)
      .then(
        (res) => {
          if (res.data.success) {
            // const { token } = res.data;
            // localStorage.setItem('token', token);
          }
          return res.data;
        },
        (error) => {
          throw error;
        },
      );
  }

  addPhoneNumber(data) {
    if (localStorage.getItem('token') !== '') {
      return this.authenticatedApi()
        .post('/update-phone-number', data)
        .then(
          (res) => res.data,
          (error) => {
            throw error;
          },
        );
    }
    return this.publicApi()
      .post('/update-phone-number', data)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  logOut(data) {
    try {
      if (localStorage.getItem('token') !== '') {
        return this.authenticatedApi()
          .post('logout', data)
          .then(
            async (res) => {
              await localStorage.setItem('token', '');
              return res.data;
            },
            async (error) => {
              await localStorage.setItem('token', '');
              return error;
            },
          );
      }
      return false;
    } catch (err) {
      return err;
    }
  }

  getBankList() {
    return this.authenticatedApi()
      .get('/bank-listV2')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getCurrencyList() {
    return this.authenticatedApi()
      .get('/currencies')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getCountryList() {
    return this.authenticatedApi()
      .get('/countries')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  addBankAccount(payload) {
    return this.authenticatedApi()
      .post('/update-bank-details', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  addClaim(payload) {
    return this.authenticatedApi()
      .post('/claims', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getClaims(policyId, filters) {
    let url = '';
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        url += `&${key}=${value}`;
      });
    }
    return this.authenticatedApi()
      .get(`/temp-reimbursement-claims?policyId=${policyId}${url}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getClaimDetails(id) {
    return this.authenticatedApi()
      .get(`/reimbursement-claims/${id}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  editClaim(payload) {
    return this.authenticatedApi()
      .post('/update-claim', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  reSubmitClaim(payload) {
    return this.authenticatedApi()
      .post('/re-submit-claim', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  UpdatePaymentInfo(payload) {
    return this.authenticatedApi()
      .post('/update-payment-info', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  downloadTOB(policy_id, dependent_id) {
    let url = `/download-benefits?policy_id=${policy_id}`;
    if (dependent_id) {
      url += `&dependent_id=${dependent_id}`;
    }
    return this.blobApi()
      .get(url)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  me() {
    return this.authenticatedApi()
      .get('/me')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  // Pre Approvals

  getPreApprovals(policyId, filters) {
    let url = '';
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        url += `&${key}=${value}`;
      });
    }
    return this.authenticatedApi()
      .get(`/pre-approvals?policyId=${policyId}${url}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  addPreApproval(payload) {
    return this.authenticatedApi()
      .post('/pre-approvals', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  getPreApprovalDetails(id) {
    return this.authenticatedApi()
      .get(`/pre-approvals/${id}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  editPreApproval(payload) {
    return this.authenticatedApi()
      .post('/edit-pre-approvals', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  reSubmitPreApproval(payload) {
    return this.authenticatedApi()
      .post('/re-submit-pre-approvals', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  download(payload) {
    return this.blobApi()
      .post('/download', payload)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  deleteBank(bankId) {
    return this.authenticatedApi()
      .get(`/delete-bank-details?bank_id=${bankId}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  deleteClaim(claimId) {
    return this.authenticatedApi()
      .get(`/delete-claim/${claimId}`)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  submitComplaint(data) {
    return this.publicApi()
      .post('/complaint', data)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  resendOTP(data) {
    return this.publicApi()
      .post('/resend-code', data)
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  metaData() {
    return this.publicApi()
      .get('meta-data')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }

  guideVideo() {
    return this.publicApi()
      .get('guide-video')
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        },
      );
  }
}

// // Encrypt the password using the public key
// encryptPassword = (publicKey, password) => {
//   console.log(publicKey?.publicKey);
//   const key = new NodeRSA(publicKey?.publicKey);
//   return key.encrypt(password, 'base64');
// };

const userApi = new UserApi();

export default userApi;
