import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import logo from '../../assets/images/myMetlife-logo.png';

const Footer = () => (
  <footer className="bg-light py-4">
    <Container fluid className="max-container">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col>
              <img className="footer-logo" src={logo} alt="" />
              <div className="d-block d-lg-none">
                <hr className="mt-1" />
              </div>
            </Col>

            <Col>
              <p>
                <Link to="/contactUs">Contact Us</Link>
              </p>
            </Col>
            {/* <Col>
              <p>Go to MetLife.ae</p>
            </Col> */}
            <Col>
              <p>
                <Link to="/privacyPolicy">Privacy Policy</Link>
              </p>
            </Col>
            <Col>
              <p>
                <a href="https://www.metlife-gulf.com/en/legal-notices/" target="_blank" rel="noreferrer">
                  Terms of Use
                </a>
              </p>
            </Col>
            <Col>
              <p>
                {/* <Link to="/aboutUs">About Us</Link> */}
                <a href="https://www.metlife.ae/who-we-are/about-us/" target="_blank" rel="noreferrer">
                  About Us
                </a>
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg="3" className="align-items-center d-lg-flex justify-content-lg-end">
          <div className="d-block d-lg-none">
            <hr className="mt-1" />
          </div>
          <p className="text-left text-lg-right">&copy;Copyright MetLife 2023</p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
